@import "../../../../../styles/utilities/index.scss";

.optionsContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 18px;

  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 25px;

    .radioButton {
      input {
        display: none;
      }

      label {
        font-size: 15px;
        padding: 10px 14px;
        border-radius: 25px;

        cursor: pointer;
        background-color: $colorWhite;
        transition: background 0.1s;
        border: 1px solid $colorOrange;
      }

      input:checked + label {
        background-color: $colorOrange;
        color: $colorWhite;
        font-weight: 600;
      }
    }

    @media (max-width: 375px) {
      gap: 10px;

      .radioButton {
        label {
          font-size: 14px;
          padding: 8px 12px;
        }
      }
    }
  }
  .text {
    display: block;
  }

  .hide {
    display: none;
  }
}
