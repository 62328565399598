@import "../../../styles/utilities/index.scss";

$boxShadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
$colorGreyLight: #828282;
$colorOrangeLight: #EE802F;
$gradient: linear-gradient(0deg, rgba(255, 138, 52, 0.05), rgba(255, 138, 52, 0.05));

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  
  border-radius: 50%;
  border: 1px solid $colorOrange;
  padding: 15px;
  cursor: pointer;
  background: $colorOrangeLight;
  box-shadow: 1px 1px 4px 0px #828282;
}

.cartCount {
  // padding: 5px;
  // background: red;
  // color: $colorWhite;
  // border-radius: 50%;

  // position: absolute;
  // top: 0;
  // right: 0;

  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;   
  // border: 2px solid #FFF;
  width: 20px;
  height: 20px;  
  background-color: red;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 12px;
  line-height: 20px;
  // font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #FFF;
  font-weight: 700;
  text-align: center;
}

/* colors */

.primary {
  border-style: solid;
  border-color: transparent;
  background-color: $colorOrange;
  color: $colorWhite;

  @media (min-width: $m) {
    &:not(:disabled):hover {
      box-shadow: $boxShadow;
      background-color: $colorOrangeLight;
    }
  }

  &:focus {
    background-color: $colorOrangeDark;
  }

  &:disabled {
    background-color: $colorGreyLight;
  }
}

.ghost {
  border-style: solid;
  border-color: $colorOrange;
  background-color: $colorWhite;
  color: $colorOrange;

  @media (min-width: $m) {
    &:not(:disabled):hover {
      border-color: $colorOrangeLight;
      box-shadow: $boxShadow;
    }
  }

  &:focus {
    border-color: $colorOrangeDark;
    background: $gradient, #FFFFFF;
  }

  &:disabled {
    border-color: $colorGreyLight;
    color: $colorGreyLight;
  }
}

/* sizes */

.xs {
  border-width: 1px;
  border-radius: 20px;
  padding: 3px 19px;
  min-height: 32px;

  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

.s {
  border-width: 1px;
  border-radius: 22px;
  padding: 8px 20px;
  min-height: 46px;

  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
}

.m {
  border-width: 1px;
  border-radius: 22px;
  padding: 11px 32px;
  min-height: 52px;

  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.2px;

  @media (max-width: $m) {
    font-size: 16px;
    line-height: 20px;
    padding: 7px 18px;
    min-height: 32px;
  }
}

/* adaptive sizes */

.asm {
  border-width: 1px;
  border-radius: 22px;
  padding: 8px 20px;
  min-height: 46px;

  font-weight: bold;
  font-size: 16px;
  line-height: 28px;

  @media (min-width: $xxxl) {
    border-width: 1px;
    border-radius: 22px;
    padding: 11px 32px;
    min-height: 52px;

    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
  }
}

/* modifications */

.stretched {
  width: 100%;
}
