@import "../../../styles/utilities/index.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 84px;
  height: 64px;
}

.betaConainer {
  position: relative;
}

.beta {
  position: absolute;

  top: 16px;
  left: 6px;
  
  color: #000;
  background-color: #ff3f33;
  
  padding: 0px 9px;
  font-size: 12px;
}

.image {
  width: 100%;
  height: auto;
}

/* size */

.s {
  width: 62px;
  height: auto;
}

.m {
  width: 84px;
  height: 64px;
}

.l {
  width: 136px;
  height: 104px;
}

.c{
  width: 136px;
  height: 68px;
}

.chooseProjectSize {
  width: 100px;
  height: 50;
}
