@import "../../../styles/utilities/index.scss";

$colorLightGrey: #F6F6F6;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.item {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;

  margin-bottom:10px;
}

li.item:nth-child(9n+1) label{
  background-color: #c7dbff;
}
li.item:nth-child(9n+2) label{
  background-color: #dfd6e8;
}
li.item:nth-child(9n+3) label{
  background-color: #f2dce7;
}
li.item:nth-child(9n+4) label{
  background-color: #FBDCD5;
}
li.item:nth-child(9n+5) label{
  background-color: #FDECD9;
}
li.item:nth-child(9n+6) label{
  background-color: #fbfadd;
}
li.item:nth-child(9n+7) label{
  background-color: #E1F1DE;
}
li.item:nth-child(9n+8) label{
  background-color: #CCEAE4;
}
li.item:nth-child(9n+9) label{
  background-color: #E1EEF9;
}


  .drv.item:nth-child(9n+1) label{
    background-color: #ffe99b;
  }
  .drv.item:nth-child(9n+2) label{
    background-color: #fddda1;
  }
  .drv.item:nth-child(9n+3) label{
    background-color: #fbc8a5;
  }
  .drv.item:nth-child(9n+4) label{
    background-color: #f9baab;
  }
  .drv.item:nth-child(9n+5) label{
    background-color: #f8b0b2;
  }
  .drv.item:nth-child(9n+6) label{
    background-color: #e5b1bb;
  }
  .drv.item:nth-child(9n+7) label{
    background-color: #d6b4d7;
  }
  .drv.item:nth-child(9n+8) label{
    background-color: #d6b4d7
  }
  .drv.item:nth-child(9n+9) label{
    background-color: #c5b6d7;
  }
  .drv.item:nth-child(9n+10) label{
    background-color: #b6b6d6;
  }

li.brown label {
  background-color: #e8c3c3!important;
}

.input {
  display: none;
}

.input:checked {
  & + .label {
    color: $colorBlack;
    font-weight: bold;
    max-width: 100%;
  }
}

.label {
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
  padding: 8px 14px;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: $colorBlueDark;
  transition: 0.5s ease;
  max-width: 90%;

  &:hover {
    color: $colorBlack;
    font-weight: bold;
    max-width: 100%;
  }

  @media (max-width: $m) {
    font-size: 16px;
  }

  @media (max-width: $s) {
    padding: 8px;

  }
}
