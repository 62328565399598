@import "../variables/index.scss";

.bxsh-hard {
  box-shadow: $shadowHard;
}
.bxsh-soft {
  box-shadow: $shadowSoft;
}

.bxsh {
  box-shadow: $boxShadow;
}

.bgc-black-light {
  background-color: $colorBlackLight;
}
.bgc-blue-dark {
  background-color: $colorBlueDark;
}
.bgc-gold {
  background-color: $colorGold;
}
.bgc-pink {
  background-color: $colorPink;
}
.bgc-yellow {
  background-color: $colorYellow;
}
.bgc-grey-light {
  background-color: $colorGreyLight;
}
.bgc-transparent {
  background-color: transparent;
}
.bgc-white {
  background-color: $colorWhite;
}
.bg-gold-gradient--horizontal {
  background: $gradientGoldHorizontal;
}
.bg-gradient-gold-light--horizontal {
  background: $gradientGoldLightHorizontal;
}
.bd-gradient-purple--vertical {
  background: $gradientPurpleVertical;
}
.bd-gradient-brown--vertical {
  background: $gradientBrownVertical;
}
.bd-gradient-lightnavy--horizontal {
  background: $gradientNavyLightVertical;
}

.text-gradient-purple--horizontal {
  background: $gradientPurpleHorizontal;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-orange--horizontal {
  background: $gradientOrangeHorizontal;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.c-blue {
  color: $colorBlue;
}
.c-blue-dark {
  color: $colorBlueDark;
}
.c-black-light {
  color: $colorBlackLight;
}
.c-grey-new {
  color: $colorGreyNew;
}
.c-grey {
  color: $colorGrey;
}
.c-grey-dark {
  color: $colorGreyDark;
}
.c-orange {
  color: $colorOrange;
}
.c-red {
  color: $colorRed;
}
.c-white {
  color: $colorWhite;
}
.c-inherit {
  color: inherit;
}
