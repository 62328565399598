@import "../../../styles/utilities/index.scss";

$boxShadow: 0px 0px 1px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.16);
$colorGreyLight: #c1bbbb;
$darkBlue: #27305a;

.container {
  position: relative;
  display: block;
  width: 100%;
  max-width: 450px;
  // margin-left: auto;
  // margin-right: auto;
  margin-top: 30px;
  margin-bottom: 20px;

  @media (max-width: $m) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.kidsLayout {
  margin: 0;

  .asyncWrapper {
    border: 2px solid #ee802f;
    border-radius: 5px;
  }
}

.illuminate {
  max-width: 550px;
  .asyncWrapper {
    input {
      border: 2px solid #c74d58;
      border-radius: 20px;
    }
  }
  .icon {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 16px;
    padding-top: 9px;
  }
}

.dailyRebbeVideo {
  max-width: 550px;
  .asyncWrapper {
    input {
      border: 2px solid $colorDarkYellow;
      border-radius: 20px;
    }
  }
  .icon {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 16px;
    padding-top: 9px;
  }
}

.rbt {
  background: black;
}

.asyncWrapper {
  display: block;

  input {
    width: 100%;
    padding: 10px 12px;
    border: none;
    border-radius: 12px;
    font-weight: 400;
    font-size: 16px;

    &::placeholder {
      color: $colorGrey;
    }

    :focus {
      outline: none;
    }

    :focus-visible {
      outline: none !important;
    }
  }

  a {
    display: block;
    line-height: 2;
    text-decoration: none;
    font-size: 1rem;
  }
}

.asyncWrapper div {
  display: block !important;
}

.inputBox {
  // overflow: hidden;
  // position: relative;
  // width: 100%;
}

.indicator {
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 2px;
  // height: 100%;

  // display: inline-block;
}

.icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  padding-top: 7px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // height: 100%;
}

.label {
  // overflow: hidden;
  // position: absolute;
  // transition: all $timeS;

  // color: $colorGrey;
  // cursor: pointer;
  // white-space: nowrap;
}

.tape {
  // overflow: hidden;
  // position: absolute;

  // color: $colorWhite;
  // white-space: nowrap;
  // opacity: 0;

  // &.visible {
  //   opacity: 1;
  // }
}

.tooltip {
  // overflow: hidden;
  // position: absolute;
  // right: 0;
  // bottom: 100%;

  // max-width: 100%;

  // text-align: center;
}

/* colors */

.primary {
  .input {
    // border-color: $colorGreyLight;
    // background-color: $colorWhite;
    // color: $colorGrey;

    // &::placeholder {
    //   color: $colorGrey;
    // }

    // & + .indicator {
    //   background-color: $colorBlueDark;

    //   & + .icon {
    //     color: $colorGrey;
    //   }
    // }
  }

  .input:focus {
    // color: $colorOrange;

    // & + .indicator {
    //   background-color: $colorOrange;

    //   & + .icon {
    //     color: $colorOrange;
    //   }
    // }
  }

  .input:not(:focus).error {
    // color: $colorRed;

    // &::placeholder {
    //   color: $colorRed;
    // }

    // & + .indicator {
    //   background-color: $colorRed;

    //   & + .icon {
    //     color: $colorRed;
    //   }
    // }
  }

  .input:disabled {
    // color: $colorGrey;

    // & +.indicator {
    //   background-color: $colorGrey;

    //   & + .icon {
    //     color: $colorGrey;
    //   }
    // }
  }

  .tape {
    // background: $colorWhite;
  }

  .tooltip {
    // box-shadow: $boxShadow;
    // background-color: $colorRed;
    // color: $colorWhite;
  }
}

/* modifications */

.stretched {
  &.container {
    width: 100%;
    max-width: 100%;
  }
}
.library {
  margin-top: 0;
  margin-bottom: 10px;
}

.mobileSearchLayout {
  cursor: pointer;
  margin: 0;

  input {
    border: 1px solid #ff8a34;
    border-radius: 20px;
  }
}
