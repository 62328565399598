@import "../../../../../../styles/utilities/index.scss";

.fieldsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 70px;
}
.container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  max-height: 185px;
  justify-content: center;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 768px;
}

.infoTop {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.infoTop > h4 {
  text-transform: uppercase;
  color: $colorBlack;
  font-size: 22px;
}

.infoCenter > p {
  color: $colorBlack;
  font-size: 16px;
}

.infoBottom > p {
  font-size: 16px;
}

.imageContainer {
  background-color: white;
  border-radius: 20px;
  max-width: 300px;
  min-width: 160px;
  width: 100%;
  height: 170px;
  max-height: 190px;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.imageContainer > img {
  object-fit: contain;
  height: auto;
  width: 200px;
  max-width: 200px;
}

.sidebarButton {
  border-radius: 12px;
  padding: 8px 10px;
  height: 25px;
  font-size: 14px;
  line-height: 16px;
  background-color: $colorOrange;
  color: $colorWhite;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100px;
  max-width: 100px;
  justify-content: space-evenly;
  font-weight: bold;
  justify-content: space-around;
}

.italic {
  font-style: italic;
  font-size: 14px;
}

.italicBold {
  color: $colorOrangeDark;
  font-weight: bold;
}

.mbText {
  display: none;
}

.mobileDisplay {
  display: none;
}

@media (max-width: 888px) {
  .infoCenter {
    p {
      font-size: 15px;
    }
  }

  .infoBottom {
    p {
      font-size: 15px;
    }
  }
}

@media (max-width: 945px) {
  .imageContainer {
    object-fit: contain;
    height: auto;
    width: 240px;
    max-width: 240px;
    height: 150px;
  }
}

@media (max-width: 860px) {
  .container {
    gap: 20px;
  }

  .imageContainer {
    object-fit: contain;
    height: auto;
    width: 200px;
    max-width: 200px;
    height: 140px;
  }
}

@media (max-width: 840px) {
  .infoCenter {
    p {
      font-size: 14px;
    }
  }

  .infoBottom {
    p {
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .fieldsContainer {
    gap: 30px;
  }

  .marginZero {
    margin-top: 0px !important;
  }

  .container {
    flex-direction: column;
    margin-top: 55px;
  }

  .mbText {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
  }

  .infoContainer {
    max-width: 420px;
    min-width: 160px;
    width: 100%;
  }

  .br {
    display: none;
  }

  .imageContainer {
    display: none;
  }

  .infoTop {
    display: none;
  }

  .mobileDisplay {
    display: block;
  }
  .mobileTopContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .infoCenter {
    p {
      font-size: 17px !important;
      text-align: justify;
      padding: 0px 3px;
    }
  }

  .infoBottom {
    p {
      font-size: 15px !important;
      text-align: justify;
      padding: 0px 3px;
    }

    .italic {
      display: block;
      text-align: right;
      font-size: 13px;
    }
  }
  .logoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $colorWhite;
    max-width: 350px;
    min-width: 340px;
    gap: 16px;
    padding: 6px 25px;
    width: 100%;
    border-radius: 8px;

    img {
      width: auto;
      height: 50px;
    }

    h3 {
      color: $colorBlueDark;
      text-transform: uppercase;
      font-size: 22px;
      margin: 0 auto;
    }
  }

  .illuminateLogo {
    img {
      width: 95px;
      object-fit: contain;

      height: 50px;
    }
  }

  .libraryContainer {
    Button {
      color: $colorOrange;
      background-color: $colorWhite;

      display: flex;
      flex-direction: column;
      height: 63px;
      width: 62px;
      margin: 0;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@media (max-width: 550px) {
  .logoContainer {
    min-width: 345px;
  }
  .container {
    flex-direction: column;
    gap: 20px;
  }

  .infoCenter {
    p {
      text-align: justify;
    }
  }
}

@media (max-width: 460px) {
  .logoContainer {
    h3 {
      font-size: 19px;
    }
  }

  .infoCenter {
    p {
      padding: 0px 10px;
    }
  }

  .infoBottom {
    p {
      padding: 0px 10px;
    }
  }
}

@media (max-width: 460px) {
  .logoContainer {
    min-width: 310px;
    max-width: 300px;
    padding: 5px;
    gap: 0px;
  }
}

@media (max-width: 425px) {
  .container {
    margin-top: 52px;
    gap: 18px;
  }
  .mobileTopContainer {
    gap: 8px;
  }
  .fieldsContainer {
    gap: 40px;
  }
  .logoContainer {
    max-width: 340px;
    min-width: 300px;
    padding: 5px 5px;
    gap: 8px;

    h3 {
      font-size: 20px;
    }
    .libraryContainer {
      Button {
        height: 58px;
        width: 52px;
      }
    }
  }
}

@media (max-width: 412px) {
  .container {
    padding: 0px 8px;
  }
  .infoCenter {
    p {
      padding: 0px 0px;
    }
  }
  .logoContainer {
    max-width: 340px;
    min-width: 290px;
  }

  .infoBottom {
    p {
      padding: 0px 0px;
    }
  }
}

@media (max-width: 390px) {
  .container {
    gap: 18px;
  }
  .mobileTopContainer {
    gap: 8px;
  }

  .fieldsContainer {
    gap: 50px;
    margin-top: 16px;
  }

  .logoContainer {
    max-width: 300px;
    min-width: 280px;
    padding: 5px 5px;
    gap: 8px;

    h3 {
      font-size: 16px;
    }
  }

  .libraryContainer {
    Button {
      height: 58px;
      width: 52px;
    }
  }
}

@media (max-width: 375px) {
  .container {
    padding: 0px 10px;
  }
  .logoContainer {
    max-width: 300px;
    min-width: 260px;
    padding: 5px 5px;

    h3 {
      font-size: 16px;
    }
  }
}

@media (max-width: 350px) {
  .mt {
    margin-top: 65px;
  }
  .container {
    padding: 0px 5px;
  }
  .logoContainer {
    max-width: 300px;
    min-width: 233px;
    padding: 5px 5px;
    gap: 0px;

    h3 {
      font-size: 16px;
    }
  }

  .infoCenter {
    p {
      padding: 0px 0px;
    }
  }

  .infoBottom {
    p {
      padding: 0px 0px;
    }
  }
}
