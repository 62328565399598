@import "../../../../../styles/utilities/index.scss";

$colorGreyLight: #f6f6f6;

.hamburger {
  $size: $size-08;

  border: none;
  padding: 0;
  width: $size;
  height: $size;
  margin: 0px;

  background-color: transparent;
  color: $colorOrange;

  &.small {
    $size: $size-05;

    width: $size;
    height: $size;
  }
}

.body {
  width: 100%;
  min-height: calc(100vh);

  background-color: $colorGreyLight;
  color: $colorBlueDark;
}

.banner {
  background-color: $colorWhite;
}

.navigation {
  padding-top: $size-05;
  padding-bottom: $size-13;
}

.header {
  display: flex;
  justify-content: flex-end;
}

.main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: $size-06;
  min-height: $size-10;
}

.footer {
  margin-top: $size-06;
}

