@import "../../../styles/utilities/index.scss";

$boxShadow: 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 30px 40px rgba(0, 0, 0, 0.05);

// .container {}

.menu {
  display: flex;
  align-items: center;
  cursor: pointer;

  .paidMember {
    .avatar {
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
      background-repeat: no-repeat;
      background-size: auto;
      font-weight: 300;
    }
  }

  .silverMember {
    .avatar {
      color: #1b1b25b8;
      background-image: url(../../../../src/assets/images/userIcon.png);
    }
  }

  .goldMember {
    .avatar {
      color: #1b1b25b8;
      background-image: url(../../../../src/assets/images/goldIcon.png);
    }
  }

  .bronzeMember {
    .avatar {
      color: #1b1b25b8;
      background-image: url(../../../../src/assets/images/bronzeIcon.png);
    }
  }

  .partner {
    .avatar {
      width: 59px;
      height: 38px;
      background-size: cover;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      background-image: url(../../../../src/assets/images/partnerIcon.png);
    }
  }
}

.subscription {
  font-size: $size-04;

  span {
    margin-bottom: 5px;
  }
}

// .avatar {}

.greetings {
  font-size: $size-04;
  line-height: $size-06;
  margin-bottom: 20px;
}

.information {
  @include set-margin-left($size-03);
  color: $colorOrange;
  cursor: pointer;
}

.dropdown {
  box-shadow: $boxShadow;
  list-style-type: none;
  margin: $size-02 0 0;
  border: 2px solid $colorGreyLight;
  border-radius: $size-02;
  padding: 20px 20px 10px 20px;
  width: 240px;

  background-color: $colorWhite;
  color: $colorBlueDark;
}

.item {
  &:not(:first-of-type) {
    margin-top: $size-05;
  }
}

.button {
  display: flex;
  align-items: flex-start;
  border: none;
  padding: 0;
  width: 100%;

  line-height: $size-06;
  letter-spacing: 0.2px;
  background-color: transparent;
  cursor: pointer;
}

.authorization {
  display: flex;
  align-items: center;
}

.signIn {
  @include set-margin-left($size-03);
}

.signUp {
  // display: none;

  line-height: $size-06;
  color: $colorOrange;

  @media (min-width: $breakpointL) {
    display: block;
  }
}

.familyWrapper {
  display: flex;
  gap: 10px;
  align-items: center;

  .familyWelcomeText {
    font-weight: normal;
  }

  .membershipBadge {
    img {
      width: 40px;
      height: auto;
    }
  }

  .becomePartner {
    font-size: 16px;
  }
}

.boundary {
  border: none;
  border-top: 1px solid $colorGreyNew;
  margin: 15px 0px 6px 0px;
}

.mailText {
  color: gray;
  font-size: 12px;
  text-align: center;
}

.accountText {
  font-size: 10px;
  color: gray;
  font-weight: 900;
  text-align: center;
  display: block;
  margin-top: -1px;

  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
}

.textProfileMenu {
  font-size: 12px;
  line-height: 16px;
}

.marginBottom {
  margin-bottom: -14px;
}
