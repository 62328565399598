.videolist-slider .slick-list {
  padding: 0px !important;
  /* margin: 0 -20px !important; */
}

.videolist-slider .slick-next {
  top: 43% !important;
  right: -60px !important;
}

.videolist-slider .slick-prev {
  top: 36% !important;
  left: -50px !important;
  transform: rotate(180deg);
}

.videolist-slider .slick-track {
  margin-left: 0;
}

.my-maor-main-content .slick-track:first-child {
  margin-left: -20px !important;
}

@media (max-width: 786px) {
  .videolist-slider .slick-next {
    display: none !important;
  }

  .videolist-slider .slick-prev {
    display: none !important;
  }

  .videolist-slider .slick-list {
    margin: 0 -40px 0 -20px !important;
  }

  /* .slick-track:first-child {
    margin-left: 0px !important;
  } */

  .my-maor-main-content .slick-track:first-child {
    margin-left: 0px !important;
  }
}

.videolist-slider .slick-disabled {
  display: none !important;
}