@import "../../../styles/utilities/index.scss";

$boxShadow: 0px 0px 1px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.16);
$colorGreyLight: #c1bbbb;
$colorNewGrey: #b9b8b8;

.container {
  position: relative;
}

.inputBox {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.input {
  outline: none;
  resize: none;
  width: 100%;
}

.indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;

  display: inline-block;
}

.icon {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.label {
  overflow: hidden;
  position: absolute;
  transition: all $timeS;

  color: $colorGrey;
  cursor: pointer;
  white-space: nowrap;
}

.tape {
  overflow: hidden;
  position: absolute;

  color: $colorWhite;
  white-space: nowrap;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.title {
  position: absolute;
  bottom: 100%;
  right: 0;
}

.tooltip {
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 100%;

  max-width: 100%;
}

/* colors */

.primary {
  .input {
    border-color: $colorGreyLight;
    background-color: transparent;
    color: #000;

    &::placeholder {
      color: $colorGrey;
    }

    & + .indicator {
      background-color: $colorBlueDark;

      & + .icon {
        color: #ffc000;
      }
    }
  }

  .input:focus {
    color: #000;

    & + .indicator {
      background-color: $colorNewGrey;

      & + .icon {
        color: $colorNewGrey;
      }
    }
  }

  .input:not(:focus).error {
    color: $colorRed;

    &::placeholder {
      color: $colorRed;
    }

    & + .indicator {
      background-color: $colorRed;

      & + .icon {
        color: $colorRed;
      }
    }
  }

  .input:disabled {
    color: $colorGrey;

    & + .indicator {
      background-color: $colorGrey;

      & + .icon {
        color: $colorGrey;
      }
    }
  }

  .tape {
    background: $colorWhite;
  }

  .tooltip {
    box-shadow: $boxShadow;
    background-color: $colorRed;
    color: $colorWhite;
  }
}

/* sizes */

.medium {
  &.container {
    // max-width: 320px;
    width: 100%;
  }

  .inputBox {
    border-radius: 8px;
  }

  .input {
    border: none;
    border-bottom: 1px solid $colorNewGrey;
    border-radius: 0px;
    padding: 10px 5px;
    height: 80px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;

    &.withIcon {
      padding-right: 51px;
    }

    &::placeholder {
      font-weight: 400;
    }
  }

  .tape {
    top: 0;
    left: 20px;
    max-width: calc(100% - 40px);

    padding: 0 14px;
    height: 2px;
  }

  .title {
    font-size: 14px;
    line-height: 20px;
    color: $colorGrey;
  }

  .tooltip {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 24px 24px 0 24px;

    font-size: 14px;
    line-height: 16px;
  }
}

/* modifications */

.stretched {
  &.container {
    width: 100%;
    max-width: 100%;
  }
}
