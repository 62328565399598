@import "../../../styles/utilities/index.scss";

$backgroundGradientOrange: linear-gradient(95.02deg, #FFFFFF -38.03%, #FFEAD2 18.65%, #FFFFFF 97.92%);
$colorGreyLight: #F6F6F6;

.container {
  background: $backgroundGradientOrange;
}

.wrapper {
  padding-top: 40px;
  padding-bottom: 80px;
  max-width: 1326px !important;
  min-height: 100vh;

  @media (min-width: $m) {
    padding-top: 100px;
    padding-bottom: 106px;
  }
}

.logotype {
  display: flex;
  justify-content: center;
}

.card {
  margin-top: 20px;
  
  border-radius: 26px 26px 26px 26px;
  background-color: $colorWhite;
}

.main {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 48px;
  padding: 48px 16px;
}

.footer {
  border-radius: 0 0 26px 26px;
  padding: 24px 16px;
}

.checkoutMain {
  display: flex;
  padding: 40px;

  @media (max-width: 1080px) {
    flex-wrap:wrap;
    grid-row-gap: 0;
    padding: 20px;
  }

  .cart {
    border-right: 1px dotted $colorOrange;
    width: 100%;
    max-width: calc(100% - 380px);

    @media (max-width: 1080px) {
      max-width: 100%;
      border-right: none;
      margin-bottom: 20px;
      padding-bottom: 40px;
      position: relative;

      &:after{
        content:"";
        position: absolute;
        bottom: 0;
        width: 200px;
        left:50%;
        transform:translateX(-50%);
        border-bottom: 1px dotted $colorOrange;

      }
    }
  }
}

