@import "../../../styles/utilities/index.scss";

$colorGreyLight: #f6f6f6;

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ranger {
  display: flex;
  width: 100%;
  padding: 7px 0;
}

.track {
  align-self: center;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, .1);
  border-radius: 12px;
  width: 100%;
  height: 8px;
}

.thumb {
  $size: 20px;

  outline: none;
  border-radius: 50%;
  width: $size;
  height: $size;

  background-color: $colorOrange;
}

.labels {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 7px 0 0;
  padding: 0;
  width: 100%;
}

.item {
  position: relative;
  width: 0px;
  height: 20px;
}

.title {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-align: center;
}
