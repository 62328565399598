@import "../../../../../styles/utilities/index.scss";

// .container {}

.wrapper {
  min-height: 48px;
}

.message {
  line-height: 48px;
  font-size: 32px;

  text-align: center;
  color: $colorBlueDark;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.item {
  &:not(:first-of-type) {
    margin-top: 40px;
  }
}