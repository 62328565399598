@import "../../../../../../../styles/utilities/index.scss";

// .container {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
// }

.wrapper {
  margin: 0 auto;
  padding: 80px 16px;
  width: 100%;
  max-width: 612px;
  min-height: 100vh;
}

.card {
  border-radius: 26px;
  padding: 32px 32px 64px;
  
  background-color: $colorWhite;
  color: $colorBlueDark;
}

.header {
  display: flex;
  justify-content: flex-end;
}

.main {
  margin-top: 32px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  min-height: 52px;
}

.close {
  outline: none;
  border: none;
  padding: 0;

  background-color: transparent;
  cursor: pointer;
}

.title {
  margin: 0;
  text-align: center;

  font-size: 30px;
  line-height: 42px;
}
