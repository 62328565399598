@import "../../../../../styles/utilities/index.scss";

.container {
  padding: 75px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
  color: $colorBlueDark;
}

.headingContainer > h3 {
  text-transform: uppercase;
}

.headingContainer > p {
  font-size: 20px;
  line-height: 22px;
}

.cardsContainer {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.card {
  height: 375px;
  max-height: 400px;
  width: 225px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  padding: 18px;
  grid-gap: 25px;
  gap: 25px;
  position: relative;

  img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-top: 12px;
  }
  .cardTextContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    h4 {
      color: $colorBlueDark;
      font-size: 20px;
    }
    p {
      color: $colorBlack;
      text-align: center;
      font-size: 16px;
    }
  }

  Button {
    position: absolute;
    bottom: 18px;
    min-width: 155px;
  }
}

.btnWrap {
  Button {
    text-transform: uppercase;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 50px 0px;
    gap: 35px;
  }
  .card {
    max-height: 340px;
  }
  .headingContainer {
    gap: 18px;

    p {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .cardsContainer {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 700px) {
  .card {
    max-width: 330px;
    max-height: 320px;
    width: 100%;
  }
}
