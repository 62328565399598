@import "../../../../styles/utilities/index.scss";

.cardContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  box-shadow: 0px 10px 20px 0px #0000001a;
  background: white;
  padding: 20px;
  border-radius: 20px;

  .cardItem {
    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 357px;
    border-radius: 20px;
    border: 2px solid $colorOrange;
    padding: 20px 20px;
  }
}

.headingContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  h1 {
    // font-size: 40px;
    // line-height: 45px;

    font-size: 24px;
    line-height: 29px;

    @media (max-width: 800px) {
        font-size: 24px;
        line-height: 29px;
    }
  }
}

.numberContainer {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.actionContainer {
  display: flex;
  justify-content: flex-end;
}