@import "../../../styles/utilities/index.scss";

$labelSize: 25px;
$btnSize: 20px;

.default {
	display: flex;

	.reactSwitchCheckbox {
		height: 0;
		width: 0;
		visibility: hidden;
	}
	
	.reactSwitchLabel {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		width: $labelSize * 2;
		height: $labelSize;
		background: #636363;
		border-radius: $labelSize * 2;
		position: relative;
		transition: background-color 0.2s;
	}
	.reactSwitchLabel:after {
		content: "OFF";
		position: absolute;
		font-size: 10px;
		right: 8px;
		color: #fff;
		font-weight: bold;
	}
	.reactSwitchLabel.checked:after {
		content: "ON";
		position: absolute;
		font-size: 10px;
		right: unset;
		color: #fff;
		font-weight: bold;
		left: 6px;
	}
	.reactSwitchLabel .reactSwitchButton {
		content: "";
		position: absolute;
		top: 2px;
		left: 2px;
		width: $btnSize;
		height: $btnSize;
		border-radius: $btnSize;
		transition: 0.2s;
		background: $colorWhite;
		box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
	}
	
	.reactSwitchCheckbox:checked + .reactSwitchLabel .reactSwitchButton {
		left: calc(100% - 2px);
		transform: translateX(-100%);
	}
	
	// .reactSwitchLabel:active .reactSwitchButton {
	// 	width: 60px;
	// }
	
	.checked {
		background-color: $colorOrange
	}
}

.bigDot {
	$bigBtnSize: 30px;
	$bigLabelSize: 24px;

	display: flex;
	
	.reactSwitchCheckbox {
		height: 0;
		width: 0;
		visibility: hidden;
	}
	
	.reactSwitchLabel {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		width: 60px;
		height: $bigLabelSize;
		background: $colorWhite;
		border-radius: $bigLabelSize * 2;
		position: relative;
		transition: background-color 0.2s;
	}
	.reactSwitchLabel:after {
		content: "";
		position: absolute;
		font-size: 10px;
		right: 8px;
		color: #fff;
		font-weight: bold;
	}
	.reactSwitchLabel.checked:after {
		content: "";
		position: absolute;
		font-size: 10px;
		right: unset;
		color: #fff;
		font-weight: bold;
		left: 6px;
	}
	.reactSwitchLabel .reactSwitchButton {
		content: "";
		position: absolute;
		top: -3px;
		left: -1px;
		width: $bigBtnSize;
		height: $bigBtnSize;
		border-radius: $bigBtnSize;
		transition: 0.2s;
		background: $colorOrange;
		box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
	}
	
	.reactSwitchCheckbox:checked + .reactSwitchLabel .reactSwitchButton {
		left: 103%;
		transform: translateX(-103%);
	}
	
	// .reactSwitchLabel:active .reactSwitchButton {
	// 	width: 60px;
	// }
	
	.checked {
		background-color: $colorWhite;
	}
}