.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 55px;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  text-align: center;
  color: #27305a;

  h3 {
    font-size: 30px;
    line-height: 34px;
    text-transform: uppercase;

    @media (max-width: 786px) {
      font-size: 24px;
    }
  }

  p {
    font-size: 20px;
    line-height: 22px;
  }
}

.partners {
  ul {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 60px;
    row-gap: 25px;
    justify-content: center;
    align-items: center;

    li {
      p {
        color: #ab9023;
        font-size: 22px;
      }
    }

    @media (max-width: 1000px) {
      grid-template-columns: auto auto;
    }

    @media (max-width: 700px) {
      column-gap: 40px;

      li {
        p {
          font-size: 22px;
        }
      }
    }

    @media (max-width: 560px) {
      grid-template-columns: auto;
      text-align: center;
    }
  }
}
