@import "../../../styles/utilities/index.scss";

.wrapper {
  padding-top: 40px;
  padding-bottom: 80px;
  max-width: 1326px !important;
  min-height: 100vh;

  @media (min-width: $m) {
    padding-top: 100px;
      padding-bottom: 106px;
  }
}
.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 114px;
  grid-row-gap: 56px;

  @media (min-width: $xxl) {
    grid-template-columns: 46fr 54fr;
  }
}
.successWrap{
  border-radius: 26px 26px 26px 26px;
  background-color: #FFFFFF;
  padding: 30px;
  text-align: center;
  align-items: center;
}
.message {
  margin: 0 auto;
  max-width: 560px;
  text-align: center;
}

.illustration {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.share {
  display: flex;
  justify-content: center;
  margin-top: $size-05;
}
