@import "../../../../../styles/utilities/index.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  max-width: 900px;
}
.headingContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  text-align: center;
  color: #27305a;

  h3 {
    font-size: 30px;
    line-height: 34px;
    text-transform: uppercase;

    @media (max-width: 786px) {
      font-size: 24px;
    }
  }

  p {
    font-size: 20px;
    line-height: 22px;
  }
}

.cardsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.card {
  height: 220px;
  max-height: 250px;
  width: 225px;
  max-width: 235px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  padding: 18px;
  grid-gap: 25px;
  gap: 25px;
  position: relative;

  img {
    width: 190px;
    height: 110px;
    object-fit: contain;
    margin-top: 8px;
  }
  .cardTextContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    h4 {
      color: $colorBlueDark;
      font-size: 20px;
    }
    p {
      color: $colorBlack;
      text-align: justify;
      font-size: 16px;
      line-height: 22px;
    }
  }

  Button {
    position: absolute;
    bottom: 30px;
    min-width: 155px;
    margin-top: 12px;
  }
}

.kirshCard {
  .btn {
    background-color: #ae8c5c;

    &:hover {
      background-color: #ae8c5c !important;
    }
  }
}

.pinkCard {
  .btn {
    background-color: #e82f94;

    &:hover {
      background-color: #e82f94 !important;
    }
  }
}

.ciaCard {
  .btn {
    background-color: #00529c;

    &:hover {
      background-color: #00529c !important;
    }
  }
}

.bugsnagCard {
  .btn {
    background-color: #4595c8;

    &:hover {
      background-color: #4595c8 !important;
    }
  }
}

.delmarCard {
  .btn {
    background-color: #c8b152;

    &:hover {
      background-color: #c8b152 !important;
    }
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-clip: border-box;
  text-align: center;
  cursor: pointer;

  border-style: solid;
  border-color: transparent;
  color: #ffffff;

  border-width: 1px;
  border-radius: 20px;
  padding: 3px 19px;
  min-height: 32px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;

  position: absolute;
  bottom: 30px;
  min-width: 155px;
  margin-top: 12px;
  background-color: #e82f94;

  a {
    text-decoration: none;
  }
}
