@import "../../../styles/utilities/index.scss";

.container {}

.label {
  display: block;
  cursor: pointer;
}

.input {
  outline: none;
  border: none;
  border-bottom: 1px solid currentColor;
  padding: 0;
  width: 100%;
  max-width: 320px;

  font-size: inherit;
}
