@import "../../../../../styles/utilities/index.scss";

.container {
  padding: 75px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
  text-align: center;
  color: $colorBlueDark;
}

.headingContainer > h3 {
  text-transform: uppercase;
}

.headingContainer > p {
  font-weight: 400;
  font-size: 20px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}

p {
  font-size: 16px;
}
.imgContainer {
  img {
    width: 400px;
    height: auto;
    max-width: 400px;
  }
}

.storyContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;

  span {
    font-weight: 700;
    font-size: 16px;
  }

  h4 {
    font-size: 18px !important;
  }
}

.italic {
  font-style: italic;
}

.story {
  width: 690px;
  max-width: 690px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
}

.maorStory {
  display: flex;
  flex-direction: row;
  gap: 29px;
  align-items: center;
}

.ml {
  margin-left: 75px;
}

@media only screen and (max-width: 1330px) {
  .content {
    flex-direction: column-reverse;
  }
}

@media (max-width: 750px) {
  .story {
    max-width: 680px;
    min-width: 300px;
  }

  .ml {
    margin-left: 0px;
  }
  .storyContainer {
    align-items: center;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 50px 0px;
  }

  .story {
    max-width: 500px;

    .Icon {
      align-self: baseline;
      margin-top: 3px;
      width: 32px;
      height: 32px;
    }

    p {
      text-align: justify;
    }
  }

  .headingContainer {
    p {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .content {
    img {
      display: none;
    }
  }

  .storyContainer {
    span {
      font-size: 15px !important;
    }
    h4 {
      font-size: 17px !important;
    }
  }
}

@media (max-width: 545px) {
  .story {
    gap: 35px;
    max-width: 430px;
  }
}

@media (max-width: 460px) {
  .story {
    gap: 26px;
    max-width: 395px;
  }
}

@media (max-width: 425px) {
  .story {
    max-width: 350px;
  }

  .storyContainer {
    h4 {
      font-size: 4vw;
    }
  }

  .imgContainer {
    img {
      max-width: 360px;
    }
  }
}

@media (max-width: 395px) {
  .story {
    gap: 23px;
    max-width: 310px;
  }
  .imgContainer {
    img {
      max-width: 300px;
    }
  }
}

@media (max-width: 370px) {
  .story {
    max-width: 280px;
    gap: 20px;
  }
}