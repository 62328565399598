@import "../../../styles/utilities/index.scss";

.row {
  flex-direction: column;

  @media (min-width: $m) {
    flex-direction: row;
  }
}

.button {
  margin-top: $size-05;

  @media (min-width: $m) {
    margin-top: 0;
    margin-left: $size-05;
  }
}

.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: $colorOrange;
}

.link {
  color: $colorOrange;
  font-weight: bold;
}

.popupButton{
  border-style: solid;
  border-color: transparent;
  background-color: #FF8A34;
  color: #FFFFFF;
  border-width: 1px;
  border-radius: 22px;
  padding: 6px 15px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:6px;

  &:hover{
    box-shadow: 0px 4px 8px rgba(255, 138, 52,0.3);
      background-color: #EE802F;
  }
}

.subscribeMailingAction {
	display: flex;
	justify-content: center;
	gap: 10px;
}

.heading {
  font-size: 24px;
  line-height: 28px;

  text-align: center;
}

.innerHeading {
  font-size: 22px;
  line-height: 29px;

  text-align: center;
}

.socialMediaIconContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .logoBg {
    background-color: #fff;
    padding: 8px 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}

.instaLogo {
  cursor: pointer;
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.shareLinkContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: #fff;
  // border: 1px solid $colorOrange;
  border-radius: 12px;
  padding: 12px 10px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}