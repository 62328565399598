.characterFormModal {
  background-color: #fff;

  // border: 10px solid #ffc000;
  border-radius: 30px;
  padding: 50px;
}

.characterFormContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  column-gap: 35px;
  margin: 30px 0;
}

.red {
  color: #ea5455;
}

.grandstanderFamily {
  font-family: "Grandstander", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.heading {
  font-size: 28px;
  line-height: 32px;
  text-align: center;

  span {
    font-family: "Roboto";
    font-size: 12px;
    margin-left: 20px;
    font-weight: 400;
  }
}

.headerCharacter {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.bodyCharacter {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.characterItemsContainer {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.maxWxs {
  max-width: 20rem;
}

.mxAuto {
  margin-left: auto;
  margin-right: auto;
}

.p4 {
  padding: 1rem;
}

.bgYellow50 {
  background-color: #fefcbf;
}

.roundedLg {
  border-radius: 0.5rem;
}

.shadowMd {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.spaceY4 {
  & > * {
    margin-bottom: 1rem;
  }
}

.bgYellow200 {
  background-color: #fef3c7;
}

.roundedFull {
  border-radius: 9999px;
}

.textCenter {
  text-align: center;
}

.py2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.fontBold {
  font-weight: bold;
}

.textLg {
  font-size: 1.125rem;
}

.spaceY2 {
  & > * {
    margin-bottom: 0.5rem;
  }
}

.textSm {
  font-size: 0.875rem;
}

.fontMedium {
  font-weight: 500;
}

.bgYellow300 {
  background-color: #fef08a;
}

.hoverBgYellow400:hover {
  background-color: #fde047;
}

.textBlack {
  color: #000;
}

.wFull {
  width: 100%;
}

.flex {
  display: flex;
}

.itemsCenter {
  align-items: center;
}

.justifyCenter {
  justify-content: center;
}

.ml2 {
  margin-left: 0.5rem;
}

.h4 {
  height: 1rem;
}

.w4 {
  width: 1rem;
}

.characterSettingContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;

  p {
    strong {
      color: #c27900;
      margin-right: 6px;
    }

    span{
      display: inline-block; 

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .heading {
    font-size: 20px;
    line-height: 24px;
  }

  .bodyCharacter {
    margin-top: 30px;
  }
}
