@import "../../../styles/utilities/index.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;
  text-align: center;
  gap: 40px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  h3 {
    font-weight: 400;
    color: $colorOrange;
  }

  p {
    font-size: 16px;
    color: $colorBlack;
  }
}

Button {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.imgContainer {
  position: relative;

  .upperLetter {
    rotate: 18deg;
    position: relative;
    z-index: 1;
  }

  .bottomRightLetter {
    position: absolute;
    width: 140px;
    top: 78px;
    right: -58px;
    z-index: 0;
    rotate: 10deg;
  }

  .bottomLeftLetter {
    position: absolute;
    rotate: 40deg;
    width: 130px;
    top: 100px;
    right: 120px;
    z-index: 2;
  }
}

.logoContainer {
  img {
    object-fit: contain;
    width: 165px;
    height: auto;
  }
}
