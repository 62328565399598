.writingCompetitionSuccess{
    display: flex;
    gap: 80px;
    flex-direction: column;
    align-items: center;

    padding: 50px 0;

    @media (max-width: 500px) {
      gap: 40px;
      padding: 20px 0;

    }
}

.successTopText {
  color: #ffc000;
  display: flex;
  gap: 25px;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    text-transform: uppercase;
  }

  h1{
    line-height: 35px;
  }
}

.successBottomText {
    display: flex;
    gap: 25px;
    flex-direction: column;
    align-items: center;
    text-align: center;

  h4 {
    color: #77a23f;
  }

  p {
    color: #d755b3;
    max-width: 400px;
  }
}

.grandstanderFamily {
  font-family: "Grandstander", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.crossButton{
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
}