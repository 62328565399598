@import "../variables/index";

.wrapper-basic {
  margin-right: auto;
  margin-left: auto;
  padding-right: $size-04;
  padding-left: $size-04;
  width: 100%;

  @media (min-width: 769px) {
    padding-right: $size-11;
    padding-left: $size-11;
  }
}

.wrapper-xs {
  @extend .wrapper-basic;

  max-width: 375px;
}

.wrapper-s {
  @extend .wrapper-basic;

  max-width: 768px;
}

.wrapper-m {
  @extend .wrapper-basic;
  
  max-width: 1248px;
}

.wrapper-l {
  @extend .wrapper-basic;

  max-width: 1440px;
}

.wrapper-xl {
  @extend .wrapper-basic;

  max-width: 1920px;
}

.wrapper-vertical-s {
  $value: $size-10;

  padding-top: $value;
  padding-bottom: $value;
}

.wrapper-vertical-m {
  $value: $size-13;

  padding-top: $value;
  padding-bottom: $value;
}

/* Adaptive (Dynamic) */

.wrapper-vertical-s-a {
  @extend .wrapper-vertical-s;

  @media (min-width: $m) {
    $value: $size-12;

    padding-top: $value;
    padding-bottom: $value;
  }

  @media (min-width: $l) {
    $value: $size-13;

    padding-top: $value;
    padding-bottom: $value;
  }
}

.wrapper-vertical-m-a {
  @extend .wrapper-vertical-m;

  @media (min-width: $l) {
    $value: $size-15;

    padding-top: $value;
    padding-bottom: $value;
  }
}
