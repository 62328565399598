@import "../../../styles/utilities/index.scss";

$boxShadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
$colorGreyLight: #BDBDBD;

.container {
  position: relative;
}

.target {
  position: relative;

  outline: none;
  display: block;
  width: 100%;

  text-align: left;
  cursor: pointer;
}

.value, .icon {
  pointer-events: none;
}

.icon {
  position: absolute;
  top: 50%;
  transform-origin: top center;
  transform: translateY(-50%);

  &.rotated {
    transform: rotate(180deg) translateY(-50%);
  }
}

.list {
  overflow-y: auto;

  list-style-type: none;
  margin: -1px 0 0;
  padding: 0;
}

.button {
  outline: none;
  display: block;
  margin: 0;
  border: none;
  padding: 0;
  width: 100%;

  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
}

.tooltip {
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 100%;

  max-width: 100%;

  text-align: center;
}

/* colors */

.primary {
  .target {
    border-color: $colorGreyLight;
    border-style: solid;

    background-color: $colorWhite;
    color: $colorGrey;

    &:focus {
      color: $colorOrange;
    }

    @media (min-width: $l) {
      &:hover {
        box-shadow: $boxShadow;
      }
    }
  }

  .value {
    &.selected {
      color: $colorOrange;
    }
  }

  .icon {
    color: $colorOrange;
  }

  &.list {
    box-shadow: $boxShadow;
    border-style: solid;
    border-color: $colorGreyLight;
  }

  .item {
    background-color: $colorWhite;
    color: $colorGrey;

    &.selected {
      background-color: #F8F8FC;
      color: $colorOrange;
    }
  }

  .tooltip {
    box-shadow: $boxShadow;
    background-color: $colorRed;
    color: $colorWhite;
  }
}

/* sizes */

.medium {
  &.container {
    width: 100%;
    max-width: 320px;
  }
  
  .target {
    border-width: 1px;
    border-radius: 8px;
    padding: 16px 56px 16px 16px;

    font-size: 18px;
    line-height: 20px;

    &.opened {
      border-bottom-color: transparent;
      border-radius: 8px 8px 0 0;
    }
  }

  .value {
    &.selected {
      font-weight: 700;
    }
  }

  .icon {
    right: 17px;
  }

  &.list {
    border-width: 0;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-radius: 0 0 8px 8px;
    max-height: 161px;
  }
  
  .item {
    font-size: 16px;
    line-height: 20px;

    &.selected {
      font-weight: 700;
    }
  }

  .button {
    padding: 10px 15px;
  }

  .tooltip {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 24px 24px 0 24px;

    font-size: 14px;
    line-height: 16px;
  }
}

/* modifications */

.stretched {
  &.container {
    max-width: 100%;
  }
}

.errorText{
  color: #ffff;
}