@import "../../../styles/utilities/index.scss";

.partnersMain {
  background-color: white;
}

.backgroundLight {
  background-color: #fff6e7;
}

.mainContent {
  position: relative;
}

.backgroundLight::after {
  content: "";
  position: absolute;
  background-image: url(../../../assets/images/business-section-waves.png);
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 65px;
}

.partnersMainContent {
  padding: 0px 0px 120px;
  background-color: #fff6e7;
}

.banner {
  background-color: #fff6e7;
  position: relative;
}

.bannerContent {
  display: none;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 12%;
  left: 9%;
  // width: 100%;
}

.bannerTitle {
  text-shadow: 3px 0 #fff, -3px 0 #fff, 0 3px #fff, 0 -3px #fff, 2px 2px #fff,
    -2px -2px #fff, 2px -2px #fff, -2px 2px #fff;
  color: #bc9c22;
  font-size: 48px;
  line-height: 52px;
  letter-spacing: 1px;
}

.colorOrange {
  color: #e48f01;
}

.bannerText {
  font-size: 20px;
  line-height: 24px;
  color: #604f0d;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;
  flex-wrap: wrap;
  position: relative;
}

.flipCard {
  background-color: transparent;
  max-width: 214px;
  max-height: 315px;
  perspective: 1000px;
  width: 100%;
  height: 315px;
  cursor: pointer;
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 30px;
}

.flipCardBrower:hover .flipCardInner {
  transform: rotateY(180deg);
}

.flipCard.rotate .flipCardInner {
  transform: rotateY(180deg);
}

.flipCardInner.flipCardRotate {
  transform: rotateY(0deg) !important;
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000;
  padding: 30px 22px;
}

.flipCardFront {
  gap: 30px;
  padding: 30px 30px;

  h4 {
    line-height: 26px;
  }
}

.flipCardBack {
  background-color: white;
  transform: rotateY(180deg);
  gap: 15px;
  justify-content: flex-start;
  padding: 20px 20px 30px;

  .flipCardBackTop {
    display: flex;
    color: #000000;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex-wrap: wrap;
    min-height: 50px;

    p {
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
    }
  }

  .cardInfo {
    color: #000000;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
}

.darkYellow {
  $color: #e4b353;
  .flipCardFront {
    background-color: $color;
  }

  .flipCardBack {
    border: 4px solid $color;
    background-color: white;
  }
}

.yellow {
  $color: #fbc63e;
  .flipCardFront {
    background-color: $color;
  }

  .flipCardBack {
    border: 4px solid $color;
    background-color: white;
  }
}

.blue {
  $color: #33aba9;
  .flipCardFront {
    background-color: $color;
  }

  .flipCardBack {
    border: 4px solid $color;
    background-color: white;
  }
}

.green {
  $color: #87c762;
  .flipCardFront {
    background-color: $color;
  }

  .flipCardBack {
    border: 4px solid $color;
    background-color: white;
  }
}

.pink {
  $color: #ff6e9a;
  .flipCardFront {
    background-color: $color;
  }

  .flipCardBack {
    border: 4px solid $color;
    background-color: white;
  }
}

.clickGif {
  max-width: 80px;
}

.clickGifContainer {
  position: absolute;
  top: 75px;
  right: -30px;
}

.packagesInnerSec {
  padding: 120px 0;
}

.packagesInnerSec .d-flex {
  background-color: #fff;
  border-radius: 20px;
}

.packagesInnerLeft {
  max-width: 400px;
  width: 100%;

  img {
    width: 100%;
    border-radius: 30px;
    box-shadow: 8px 8px 10px 0px rgba(0, 0, 0, 0.5);
  }
}

.packagesInnerright {
  padding-left: 30px;

  p {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }

  strong {
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.partnersFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 120px 40px !important;
  gap: 40px;
}

.limitedOfferContainer {
  color: #6c3213;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  max-width: 915px;
  border-radius: 25px;
  background-color: #fff1dd;
  width: 100%;

  .horizontalShadow {
    display: none;
  }
}

.limitedOfferRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  // gap: 50px;
  position: relative;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  justify-content: space-around;
  margin-left: -20px;

  .oneTimeInfo {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 5px;

    h1 {
      font-size: 24px;
      line-height: 28px;
      font-weight: 400;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }

    .oneTimePrice {
      font-weight: 900;
      font-size: 36px;
      line-height: 40px;
    }
  }

  h1 {
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    span {
      font-size: 36px;
      line-height: 44px;
      font-weight: 400;
    }
  }
}

.limitedOfferLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  position: relative;
}

.limitedOfferInfo {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .limitedOfferInfoText {
    display: flex;
    align-items: center;
    gap: 15px;

    p {
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      color: #af6d01;
    }
  }
}

.limitedOfferButton {
  background-color: #ffb641;
  font-size: 20px;
  line-height: 24px;
  font-weight: 800;
  border-radius: 25px;
  padding: 25px 0;
  width: 185px;
  cursor: pointer;
  text-align: center;
  max-height: 70px;
}

.vertical {
  height: 120px;
  width: 0.5px;
  background-color: #e9d6c6;
  position: relative;
  box-shadow: 3px 0px 8px rgba(0, 0, 0, 10);
}

.hrr {
  width: 1px;
  height: 20px;
  display: inline-block;
}

.partnersFooterBottom {
  width: 100%;
  max-width: 915px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

.birthdayDiscount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  max-width: 420px;
  width: 100%;
  border-radius: 20px;
  background-color: #fff1dd;
  gap: 20px;

  position: relative;
}

.birthdayDiscountTop {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-end;

  h4 {
    color: #ff4f88;
  }
}

.birthdayDiscountInfo {
  display: flex;
  align-items: center;
  gap: 15px;

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: #945f38;
  }
}

.birthdayDiscountBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  // gap: 10px;
}

.birthdayDiscountPrice {
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  color: #6c3213;
  text-align: center;

  span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
}

.birthdayDiscountButton {
  background-color: #ffd637;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  color: #6c3213;
  border-radius: 15px;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  width: 100%;
  max-width: 185px;

  margin-top: 10px;
}

.horizontal {
  height: 1px;
  width: 100%;
  background-color: #e9d6c6;
  position: relative;
  box-shadow: 3px 0px 8px rgba(0, 0, 0, 10);
}

.maorSupporter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  max-width: 450px;
  width: 100%;
  border-radius: 20px;
  background-color: #fff1dd;
  gap: 20px;
  position: relative;
}

.maorSupporterTop {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;

  h4 {
    color: #f99c00;
  }
}

.maorSupporterInfo {
  display: flex;
  align-items: center;
  gap: 15px;

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: #945f38;
  }
}

.maorSupporterBottomConatiner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.maorSupporterBottomInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.maorSupporterBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.orText {
  font-size: 16px;
  line-height: 20px;
  align-self: baseline;
  margin-top: 5px;
}

.maorSupporterPrice {
  font-size: 20px;
  line-height: 36px;
  font-weight: bold;
  color: #6c3213;
  text-align: center;

  span {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }
}

.maorSupporterButton {
  background-color: #ffd637;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  color: #6c3213;
  border-radius: 12px;
  text-align: center;
  padding: 8px 20px;
  font-weight: bold;
  width: 100%;
  max-width: 185px;
}

.balloon {
  position: absolute;
  max-width: 140px;
  bottom: -12px;
  left: -65px;
}

.handShake {
  position: absolute;
  max-width: 140px;
  top: -11px;
  right: -45px;
}

.businessSection {
  @media (max-width: 426px) {
    background-color: #ffedba;
  }
  background-color: #ffffff;
  padding: 60px 60px;
  border-radius: 25px;
}

.mobileCardImage {
  width: 100%;
}

.businessSectionTop {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 70px;

  margin-bottom: 35px;
}

.businessSectionTopLeft {
  display: flex;
  flex-direction: column;
  gap: 65px;
}

.partnersCard {
  max-width: 350px;
  margin-top: -95px;
}

.businessSectionTopInfo {
  display: flex;
  flex-direction: column;
  gap: 30px;

  h1 {
    font-size: 40px;
    line-height: 46px;
    color: #bc9c22;

    span {
      color: #945f38;
    }
  }

  p {
    color: #000000;
    font-size: 18px;
    line-height: 26px;
  }
}

.businessSectionBottom {
  display: flex;
  flex-direction: column;
  gap: 45px;
  margin-top: 30px;

  p {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #000000;
  }

  .businessSectionButtonConatiner {
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: center;
    align-items: center;
    // flex-wrap: wrap;
  }
}

@media (max-width: 800px) {
  .packagesInnerSec {
    div {
      flex-wrap: wrap;
      text-align: center;
      padding-left: 0;
    }
  }
  .packagesInnerLeft {
    margin: 0 auto 20px;
  }
}

.businessCardConatiner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  row-gap: 35px;
}

.businessCard {
  background-color: white;
  max-width: 120px;
  max-height: 190px;
  perspective: 1000px;
  width: 100%;
  height: 190px;
  border-radius: 15px;

  .cardTitle {
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
  }

  .businessCardOffer {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
  }

  .cardExplaination {
    font-size: 10px;
    line-height: 14px;
  }

  .businessCardImage {
    // max-width: 95px;
    max-height: 60px;
  }
}

.bannerButton {
  position: absolute;
  top: 53%;
  left: 31%;
  max-width: 300px;
  width: 100%;
}

.maorPartnersBanner {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  width: 100%;
  min-height: 330px;
}

.joinNowButton {
  max-width: 300px;
  border-radius: 20px;
  outline: none;
  background-clip: border-box;
  text-align: center;
  cursor: pointer;
  width: 100%;

  $color: #e48f01;
  border-style: solid;
  border-color: transparent;
  background-color: $color;
  color: $colorWhite;
  padding: 15px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 900;
  box-shadow: $boxShadow;

  &:focus {
    background-color: $color;
  }

  &:disabled {
    background-color: $color;
  }
}

.businessCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.offerCardFooter {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.businessCard:hover .businessCardInner {
  transform: rotateY(180deg);
}

.businessCardFront,
.businessCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000;
  padding: 20px;
}

.businessCardFront {
  padding: 20px 20px 15px;
  justify-content: space-between;
  // background-color: white;
  background-color: #fff2cb;
  background: radial-gradient(
    circle at 50% 50%,
    #ffffff,
    #fff9e9,
    #fff3d2,
    #ffedba,
    #ffedba
  );
}

.businessCardBack {
  background-color: white;
  transform: rotateY(180deg);
  justify-content: flex-start;
  border: 4px solid #fff2cb;

  .businessCardBackTop {
    display: flex;
    color: #000000;
    align-items: center;
    justify-content: center;

    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
    }
  }

  .cardInfo {
    color: #000000;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
}

.verticalShadow {
  transform: rotate(-90deg);
  position: absolute;
  max-width: 120px;
  left: 47%;
}

.horizontalShadow {
  max-width: 330px;
}

.cardBackInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.noWrap {
  text-wrap: nowrap;
}

.packagesInnerSecMobile {
  padding: 80px 40px;
  background-image: url(../../../assets/images/mobile-partners-wave.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 110% 99%;
  width: 100vw;
  margin-left: -15px;

  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.packagesInnerSecMobileContent {
  max-width: 550px;
}

.packagesInnerMobileUpper {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    border-radius: 15px;
    box-shadow: 8px 8px 10px 0px rgba(0, 0, 0, 0.5);
    max-width: 250px;
  }
}

.packagesInnerMobileUpper {
  width: 100%;
}

.packagesInnerMobileInfo {
  max-width: 280px;
}

.lightGolden {
  color: #bc9c22;
}

.packagesInnerMobileLower {
  margin-top: 40px;
}

.browserHide {
  display: none;
}

.termsContainer {
  margin: 40px auto;
  max-width: 700px;

  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 426px) {
    flex-direction: column;
    max-width: 100%;
  }

  .img {
    img {
      width: 100%;
    }

    @media (max-width: 426px) {
      width: 100%
    }

    width: 36%;
  }

  .text {
    @media (max-width: 426px) {
      width: 100%
    }

    width: 56%;

    p {
      @media (max-width: 426px) {
        font-size: 10px;
        line-height: 12px;
      }

      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #000000;
      text-align: center;
    }
  }
}

@media (max-width: 1240px) {
  .partnersCard {
    max-width: 300px;
  }

  .joinNowButton {
    max-width: 210px;
    font-size: 18px;
    line-height: 20px;
    padding: 10px;
    width: 100%;
  }
}

@media (max-width: 786px) {
  .businessCardConatiner {
    justify-content: center;
    gap: 10px;
  }

  .partnersMainContent {
    background-color: #fdf4e7;
  }

  .backgroundLight::after {
    content: "";
    position: absolute;
    background-image: url(../../../assets/images/business-section-waves-mobile.png);
    background-repeat: no-repeat;
    background-position: center;
    height: 60px;
    background-size: 110% 99%;
    bottom: -5px;
  }

  .joinNowButton {
    max-width: 250px;
    border-radius: 30px;
    font-size: 18px;
    line-height: 20px;
    padding: 12px;
    width: 100%;
  }

  .maorPartnersBannerMobile {
    object-fit: cover;
    max-width: 100%;
    height: auto;
    width: 100%;
  }

  .bannerButton {
    // position: absolute;
    // top: 68%;
    // left: 9%;
    // max-width: 300px;
    // width: 100%;

    position: unset;
    margin-top: 15px;
  }

  .browserHide {
    display: block;
  }

  .packagesInnerSecMobile {
    display: flex;
  }

  .packagesInnerSec {
    display: none;
  }

  .limitedOfferContainer {
    flex-wrap: wrap;
    justify-content: center;
    max-width: 435px;
    padding: 30px;
    gap: 25px;
  }

  .limitedOfferRight {
    justify-content: space-between;
    width: 100%;
    margin: 0;

    h1 {
      font-size: 20px;
      line-height: 24px;
      text-align: center;

      span {
        font-size: 26px;
        line-height: 38px;
        font-weight: 400;
      }
    }
  }

  .verticalShadow {
    left: 43%;
    max-width: 70px;
  }

  .limitedOfferInfo {
    gap: 10px;

    .limitedOfferInfoText {
      gap: 8px;

      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .limitedOfferLeft {
    width: 100%;
    justify-content: center;
  }

  .limitedOfferButton {
    max-width: 250px;
    width: 100%;
    padding: 15px;
    font-size: 16px;
    line-height: 20px;
  }

  .cardContainer {
    display: flex;
    flex-direction: column;

    margin-bottom: 80px;
  }

  .businessSection {
    margin-top: 80px;
    background-color: transparent;
    padding: 60px 40px 0px;
  }

  .businessSectionTop {
    gap: 20px;

    h1 {
      // text-wrap: nowrap;
      font-size: 28px;
    }
  }

  .businessSectionTopInfo {
    gap: 15px;

    p {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .businessSectionTopLeft {
    gap: 30px;
  }

  .partnersCard {
    max-width: 125px;
    margin-top: -15px;
  }

  .flipCard {
    max-width: 550px;
    height: 150px;
  }

  .flipCardFront {
    gap: 15px;
    padding: 20px;

    h4 {
      font-size: 16px !important;
    }
  }

  .flipCardBack {
    padding: 5px 20px 15px;
    gap: 10px;
  }

  .mobileHide {
    display: none;
  }

  .packagesInnerMobileInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .bannerContent {
    display: flex;
  }
}

@media (max-width: 650px) {
  .bannerTitle {
    color: #bc9c22;
    font-size: 40px;
    line-height: 48px;
  }

  .bannerText {
    max-width: 250px;
    font-size: 16px;
    line-height: 20px;
    color: #604f0d;
  }
}

@media (max-width: 600px) {
  .businessSectionBottom {
    gap: 30px;
  }

  .businessSectionButtonConatiner {
    gap: 15px !important;
    flex-wrap: wrap;
  }
  .cardContainer {
    padding: 0px 20px;
    margin-bottom: 40px;
  }

  .flipCard {
    height: 160px;
  }

  .flipCardInner {
    border-radius: 18px;
  }

  .flipCardFront,
  .flipCardBack {
    border-radius: 18px;
  }

  .flipCardFront {
    gap: 10px;
  }

  .flipCardBack {
    padding: 10px 10px;
    gap: 5px;

    .cardInfo {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      margin: auto 0;
    }

    .flipCardBackTop {
      min-height: 20px;

      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .packagesInnerSecMobile {
    padding: 80px 20px;
  }

  .packagesInnerMobileUpper {
    gap: 20px;
  }

  .packagesInnerMobileUpper {
    img {
      min-width: 180px;
      height: 130px;
    }
  }
  .packagesInnerMobileInfo {
    max-width: 230px;

    P {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .packagesInnerMobileInfo {
    gap: 8px;
  }

  .bannerButton {
    // position: absolute;
    // top: 65%;
    // left: 9%;
    // max-width: 300px;
    // width: 100%;

    margin-top: 10px;
  }

  .joinNowButton {
    max-width: 180px;
    border-radius: 30px;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 3px;
    width: 100%;
  }

  .businessSection {
    padding: 0px 0px 0;
    margin-top: 0px;
  }

  .businessSectionTop {
    gap: 0px;
    h1 {
      font-size: 28px;
      line-height: 34px;
    }

    .partnersCard {
      max-width: 110px;
      margin-top: -5px;
    }
  }

  .businessSectionBottom {
    p {
      font-size: 10px;
      line-height: 16px;
    }
  }

  .limitedOfferContainer {
    padding: 20px;
  }

  .limitedOfferRight {
    h1 {
      font-size: 16px;
      line-height: 20px;

      span {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  .limitedOfferInfo {
    .limitedOfferInfoText {
      p {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .birthdayDiscount {
    padding: 20px;
  }

  .birthdayDiscountInfo {
    gap: 8px;
  }

  .birthdayDiscountTop {
    h4 {
      font-size: 18px !important;
      line-height: 22px;
      text-wrap: nowrap;
    }
  }
  .birthdayDiscountTop {
    p {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .birthdayDiscountPrice {
    font-size: 16px;
    line-height: 20px;
  }

  .birthdayDiscountButton {
    max-width: 150px;
  }

  .balloon {
    max-width: 90px;
    left: -30px;
    bottom: -16px;
  }

  .handShake {
    max-width: 90px;
    top: -5px;
    right: -30px;
  }

  .maorSupporter {
    padding: 20px;
  }

  .maorSupporterInfo {
    gap: 8px;
  }

  .maorSupporterTop {
    h4 {
      font-size: 18px !important;
      line-height: 22px;
      margin-top: 10px;
      text-wrap: nowrap;
    }
  }
  .maorSupporterTop {
    p {
      font-size: 14px;
      line-height: 18px;
      margin-top: 5px;
    }
  }

  .maorSupporterPrice {
    font-size: 16px;
    line-height: 20px;
  }

  .maorSupporterButton {
    max-width: 110px;
  }

  .partnersFooter {
    padding: 80px 40px 60px !important;
  }

  .partnersMainContent {
    padding: 0px 0px 80px;
  }

  .bannerTitle {
    color: #bc9c22;
    font-size: 35px;
    line-height: 42px;
  }

  .bannerText {
    font-size: 16px;
    line-height: 18px;
    color: #604f0d;
  }
}

@media (max-width: 550px) {
  .bannerTitle {
    color: #bc9c22;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0px;
  }

  .bannerText {
    font-size: 16px;
    line-height: 18px;
    color: #604f0d;
  }
}

@media (max-width: 440px) {
  .bannerButton {
    margin-top: 5px;
  }

  .maorSupporterBottomConatiner {
    justify-content: center;
  }

  .verticalShadow {
    display: none;
  }

  .horizontalShadow {
    max-width: 280px;
  }

  .limitedOfferContainer {
    .horizontalShadow {
      display: block;
    }
  }

  .limitedOfferRight {
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  }

  .packagesInnerMobileUpper {
    flex-wrap: wrap;
  }
  .packagesInnerMobileInfo {
    max-width: 100%;
  }
}

@media (max-width: 410px) {
  .partnersCard {
    display: none;
  }
}

@media (max-width: 390px) {
  .bannerText {
    font-size: 12px;
    line-height: 15px;
  }

  .partnersCard {
    display: none;
  }

  .verticalShadow {
    display: none;
  }

  .horizontalShadow {
    max-width: 250px;
  }

  .limitedOfferContainer {
    .horizontalShadow {
      display: block;
    }
  }

  .limitedOfferRight {
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  }
}

@media (max-width: 375px) {
  .businessSectionTop {
    h1 {
      font-size: 22px;
      line-height: 30px;
    }
  }
}

@media (max-width: 370px) {
  .bannerButton {
    max-width: 150px;
  }

  .bannerContent {
    top: 14%;
    left: 8%;
  }
}
