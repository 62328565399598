@import "../../../../../styles/utilities/index.scss";

.container {
  padding: 75px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 65px;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  color: $colorBlueDark;
}

.headingContainer > h3 {
  text-transform: uppercase;
}

.iconsContainer {
  display: flex;
  flex-direction: row;
  gap: 35px;
  justify-content: center;
  align-items: center;
}

.imgWidth{
  width: 165px;
}

@media (max-width: 1024px) {
  .container {
    padding: 50px 0px 0px 0px;
  }
}

@media (max-width: 768px) {

  .container {
    padding: 50px 0px 0px 0px;
  }
  .iconsContainer {
    gap: 30px;
    img {
      width: 90px;
      height: auto;
      object-fit: contain;
    }
  }

  .imgWidth{
    width: 115px !important;
  }

  .imgSmallWidth{
    width: 60px !important;
  }

  .colImageWidth{
    width: 45px !important;
  }
}

@media (max-width: 600px) {
  
  .iconsContainer {
    gap: 20px;
    img {
      width: 85px;
      height: auto;
      object-fit: contain;
    }
  }
}

@media (max-width: 540px) {
  
  .iconsContainer {
    gap: 20px;
    img {
      width: 75px;
    
    }
  }

  .imgWidth{
    width: 100px !important;
  }

  .imgSmallWidth{
    width: 55px !important;
  }
}

@media (max-width: 490px) {
  
  .iconsContainer {
    gap: 12px;
    img {
      width: 70px;
    
    }
  }

  
  .imgWidth{
    width: 100px !important;
  }

  .imgSmallWidth{
    width: 55px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .iconsContainer {
    flex-wrap: wrap;
  }
}

@media (max-width: 425px) {
  .iconsContainer {
    gap: 10px;
    img {
      max-width: 60px;
      object-fit: contain;
    }
  }

  .imgSmallWidth{
    width: 42px !important;
  }
  
  .imgWidth{
    max-width: 100px !important;
  }

  .hassidoutImageWidth{
    max-width: 70px !important;
  }

  .colImageWidth{
    width: 36px !important;
  }
}
