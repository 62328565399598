.settingContainer {
    padding-top: 50px;
    padding-bottom: 50px;
  
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 30px;
    column-gap: 35px;
  
    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
    }
  }
  
  .characterFieldContainer {
    display: flex;
    gap: 5px;
    flex-direction: column;
  
    background-color: transparent;
    padding: 30px;
    border-radius: 25px;
    width: 100%;
    max-width: 500px;
    border: 5px solid #fabb17;
  
    label {
      font-size: 16px;
      line-height: 20px;
      padding-left: 5px;
      color: #000;
  
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    @media (max-width: 1024px) {
      width: 100%;
      max-width: 100%;
    }
  
    @media (max-width: 767px) {
      padding: 20px;
    }
  }
  
  .red {
    color: #ff565e;
    margin-left: 5px;
  }
  
  .hrGrey {
    border-bottom: 2px solid #b9b8b8;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
  
  .radioInput {
    margin-left: 28px;
  }
  
  .questionIcon {
    width: 25px;
    height: 25px;
  
    margin-left: auto;
  }
  
  .errorbg {
    background-color: #fee0e1;
  }
  
  .deactive {
    pointer-events: none;
    opacity: 0.5;
  }
  
  .placeNameText {
    font-weight: bold;
    padding: 0px 5px;
    text-transform: capitalize;
  }
  
  
  .saveDraft {
    background-color: #ffc000;
    color: #000;
    font-weight: bold;
    padding: 15px;
    border-radius: 30px;
    font-size: 18px;
    width: 100%;
    max-width: 10rem;
    transition: background-color 0.3s ease;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  .locationContainer{
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: center;
    
    width: 100%;
    
    @media (max-width: 1024px) {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }