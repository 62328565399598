@import "../../../styles/utilities/index.scss";

.packagesSec {
  // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(101, 226, 0, 0.1) 13.54%, rgba(101, 226, 0, 0.1) 78.65%, rgba(255, 255, 255, 0) 100%);
  padding: 0 15px;
  background-color: #fcf4ee;
  background-image: url(../../../assets/images/new-birthday-bg.png);
  padding-bottom: 50px;
  padding-top: 80px;

  .pFont {
    font-size: 18px;
  }
  .centerContent {
    text-align: center;
    margin-top: 32px;
    padding: 0 15px;

    a {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 32px;
      color: #ff8a34;

      @media (max-width: 1200px) {
        font-size: 24px;
        line-height: 28px;
      }
      @media (max-width: 600px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}

.mobilePackagesSec {
  background-color: #fcf4ee;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 50px;
  padding-top: 50px;
  
  .sectionTitle {
    h2 {
      font-size: 22px;
      line-height: 26px;
      max-width: 250px;
      margin: 0 auto 20px;
    }

    h3 {
      font-size: 18px;
      line-height: 20px;
    }
  }
  .mobileWrapper {
    padding: 0;

    .list {
      margin-top: 10px;
      gap: 20px;

      .item {
        border: none;
        border-radius: 0;
        padding: 30px;

        .title {
          margin: 8px 0 10px;
          font-size: 20px;
        }

        .text {
          font-size: 16px;
        }
      }
    }
  }
}

.wrapper {
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 1096px;
  width: 100%;

  @media (min-width: $m) {
    padding-right: 48px;
    padding-left: 48px;
  }

  @media (min-width: $xxxl) {
    max-width: 1576px;
  }
}

.pinkColor {
  color: rgb(242, 27, 84);
}

.greenColor {
  color: rgb(10, 164, 164);
}

.sectionTitle {
  position: relative;
  display: flex;
  align-items: flex-start;
  text-align: center;
  width: 100%;
  max-width: 960px;

  h2 {
    font-size: 28px;
    line-height: 30px;
  }

  h3 {
    font-weight: 400;
    font-size: 22px;

    @media (max-width: 660px) {
      font-size: 20px;
    }
  }

  h2 {
    @media (max-width: 680px) {
      font-size: 28px;
      line-height: 32px;
    }
  }
  .leftImg {
    transform: rotate(-8.67276deg);
  }
  .rightImg {
    transform: rotate(18.6356deg);
  }

  img {
    @media (max-width: 920px) {
      max-width: 110px;
    }

    @media (max-width: 660px) {
      max-width: 80px;
    }

    @media (max-width: 550px) {
      display: none;
    }
  }
}
