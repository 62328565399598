@import "../../../../../styles/utilities/index.scss";

// .container {}
.errorMsg {
  color: red;
}

.details {
  min-height: 328px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  // box-shadow: 0px 100px 80px rgba(222, 126, 77, 0.07), 0px 64.8148px 46.8519px rgba(222, 126, 77, 0.0531481), 0px 38.5185px 25.4815px rgba(222, 126, 77, 0.0425185), 0px 20px 13px rgba(222, 126, 77, 0.035), 0px 8.14815px 6.51852px rgba(222, 126, 77, 0.0274815), 0px 1.85185px 3.14815px rgba(222, 126, 77, 0.0168519);
  border-radius: 25px;
  padding: 30px 60px;
  background-image: url(../../../../../../src/assets/images/questions-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .country * {
    max-width: 100%;
  }

  .addBtn {
    display: flex;
    justify-content: center;

    button {
      background-color: #fff;
      background: #fff;
      border: none;
      border-radius: 30px;
      padding: 10px 30px;
      font-size: 18px;
      transition: all 0.4s ease-in-out;

      &:disabled {
        cursor: not-allowed;
      }
      &:hover {
        background-color: $colorOrange;
        color: #fff;
      }
    }

    .backBtn {
      color: #000;
    }

    .addBtn {
      &:hover {
        color: #fff;
      }
    }
  }
}

.mobileDetails {
  border-radius: 0;
  padding: 15px;
  min-height: 248px;

  .addBtn {
    button {
      padding: 6px 20px;
      font-size: 14px;
    }
  }
}

.hide {
  display: none;
}

.disabelShkiyaCheckbox {
  pointer-events: none;
  filter: contrast(0.5);
}

.birthdayTop {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.birthdayOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
}

.dateContainer {
  margin-bottom: 15px;
}

.datePickerEng {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;

  @media (max-width: 1070px) {
    grid-template-columns: auto;
  }
}

.datePicker {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;

  @media (max-width: 1070px) {
    grid-template-columns: auto;
  }
}

.inputStyle {
  border-color: #6a7480;
  border-width: 2px;
  border-radius: 4px;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  color: #636363;
  padding: 12px 30px 10px 15px;
  display: flex;
  justify-content: center;
  border: 2px solid;
}

.tape {
  top: 0;
  left: 30px;
  max-width: calc(100% - 40px);
  padding: 0 14px;
  height: 2px;
  overflow: hidden;
  position: absolute;
  color: #ffffff;
  white-space: nowrap;
  opacity: 1;
  background: #ffffff;
  width: 70px;
}

.inputLabel {
  overflow: hidden;
  position: absolute;
  transition: all 0.25s;
  color: #636363;
  cursor: pointer;
  white-space: nowrap;
  padding: 0 14px;
  max-width: calc(100% - 40px);
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  left: 20px;
  bottom: 100%;
  transform: translateY(50%);
}

.relative {
  position: relative;
  width: fit-content;

  @media (max-width: 1180px) {
    width: 100%;
    max-width: 320px;
  }
}

.inputLine {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  display: inline-block;
  background-color: #27305a;
}

.birthday{
    flex-wrap: wrap;
    gap: 10px;
	flex-direction: column;
}