@import "../../../styles/utilities/index.scss";

.container {
  background-color: $colorBlack;
  border-radius: 20px;
  width: inherit;

  // @media (min-width: 1025px) {
  //   background-color: transparent;
  // }
}

.player {
  position: relative !important;
  padding-bottom: 56.25% !important;
  width: 100% !important;
  height: 0 !important;

  > iframe {
    // position: absolute !important;
    // top: 0 !important;
    // left: 0 !important;

    // width: 100% !important;
    // height: 100% !important;
    // border-radius: 12px;
    // box-shadow: $boxShadow;

    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    width: 101% !important;
    height: 101% !important;
    border-radius: 20px;
    box-shadow: $boxShadow;
  }
}

.dailyRebbeVideo,
.illuminate {
  .player {
    > iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.mobileHeight {
  min-height: 248px;
}
.desktopHeight {
  min-height: 328px;
}

.regularPlayer {
  > iframe {
    width: 101% !important;
    height: 100% !important;
    box-shadow: none !important;
  }
}

.illuminate {
  .desktopHeight {
    min-height: 600px;
  }
}

.writingCompetition {
  background-color: rgb(135, 186, 72);

  .player {
    > iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
