@import "../../../styles/utilities/index.scss";

.container {
  border-radius: $size-06 $size-06 $size-06 0px;
  margin-bottom: $size-01;
  padding: $size-04;
  width: 100%;
  max-width: 350px;

  font-size: $size-03;
  line-height: $size-04;
  text-align: center;
}

/* colors */

.violet {
  box-shadow: $shadowHard;
  background-color: $colorPurple;
  color: $colorWhite;
}

.white {
  box-shadow: $shadowHard;
  background-color: $colorWhite;
  color: $colorBlueDark;
}


.yellow {
  box-shadow: $shadowHard;
  background-color: #fff;
  color: $colorBlueDark;
  font-size: 14px;
}

/* sizes */

.a {
  width: auto;
  max-width: 228px;
}

.m {
  width: 228px;
}

/* modifications */

.angleTopLeft {
  border-radius: 0px $size-06 $size-06 $size-06;
}

.angleTopRight {
  border-radius: $size-06 0px $size-06 $size-06;
}

.angleBottomRight {
  border-radius: $size-06 $size-06 0px $size-06;
}

.angleBottomLeft {
  border-radius: $size-06 $size-06 $size-06 0px;
}

.angleWithout {
  border-radius: $size-06 $size-06 $size-06 $size-06;
}

.iconToolTip {
  padding: 0px;
  min-width: 250px;
}

.messageTooltip {
  position: fixed;
  z-index: 999999;
  left: -40px;
  transform: translate(-50%, -100%);
  padding: 0px;
  min-width: 300px;
}

@media (max-width: 500px) {
  .iconToolTip {
    min-width: 250px;
  }

  .messageTooltip {
    position: fixed;
    z-index: 999999;
    left: -75px;
    transform: translate(-50%, -100%);
  }
}
