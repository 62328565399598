@import "../../../../../styles/utilities/index.scss";

// .container {}

.horizontal {
  display: flex;
  width: 100%;

  .trophyContentMobile,
  .answerContentMobile {
    display: none;
  }

  .winnerList {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    width: 100%;
    max-width: 820px;
    margin: 0 auto;
    // gap:10px;

    img {
      max-width: 30px;
      margin-right: 5px;
    }

    .trophyContent,
    .answerContent {
      text-align: left;
      font-size: 18px;

      @media (max-width: 1200px) {
        font-size: 14px;
      }

      .raffle {
        font-size: 12px;
      }

      @media (max-width: 920px) {
        display: none;
      }
    }

    .details,
    .address {
      transition: all 0.4s ease-in-out;

      @media (max-width: 920px) {
        display: none;
      }

      .name {
        font-size: 16px;

        @media (max-width: 480px) {
          font-size: 12px;
        }
      }
    }

    .raffleWinner,
    .maorWinner {
      width: 100%;
      padding: 10px;
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;

      @media (max-width: 920px) {
        cursor: pointer;
      }
    }

    .raffleWinner {
      justify-content: end;
      padding-right: 20px;

      &:after {
        content: "";
        height: 52px;
        width: 1px;
        border-right: 1.5px dashed #ffc58f;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .mobileDetails {
        display: none;

        .name {
          font-size: 13px;
        }
      }
      &:hover .mobileDetails {
        width: 120px;
        background-color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        display: block;
        font-size: 12px;
        line-height: 14px;
        left: 50%;
        transform: translateX(-50%);
        cursor: default;
      }
      &:hover .mobileDetails .trophyContentMobile {
        display: block;
      }
    }

    .maorWinner {
      justify-content: start;
      padding-left: 20px;

      .mobileAddress {
        display: none;

        .name {
          font-size: 13px;
        }
      }
      &:hover .mobileAddress {
        width: 120px;
        background-color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        display: block;
        font-size: 12px;
        line-height: 14px;
        left: 50%;
        transform: translateX(-50%);
        cursor: default;
      }
      &:hover .mobileAddress .answerContentMobile {
        display: block;
      }
    }
  }

  .mobileWinnerWrapper {
    flex-direction: row-reverse;

    .raffleWinner,
    .maorWinner {
      border: none;
      padding: 20px 0;
      justify-content: center;
      // position: unset;

      svg {
        width: 24px;
        height: 24px;
      }

      .answerContentMobile,
      .trophyContentMobile {
        font-size: 10px;
        margin-bottom: 5px;

        br {
          display: none;
        }
        span:first-child {
          padding-right: 2px;
        }
      }
    }

    .mobileFooterItems {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &:hover span.title {
        color: #2b345e;
        font-weight: bold;
      }
      span {
        width: 100%;
      }
    }

    .raffleWinner {
      &:after {
        display: none;
      }
    }
    .raffleWinner:hover .details,
    .maorWinner:hover .address {
      position: absolute;
      top: -90px;
      background: #ffff;
      padding: 5px;
      width: 130px;
      border-radius: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: 1px solid #ff8a34;
      top: -99%;
      border-bottom: none;
      transition: all 0.4s ease-in-out;
      transform: none;
      left: unset;
      z-index: -1;
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
    }

    .raffleWinner:hover .hoverImage,
    .maorWinner:hover .hoverImage {
      display: inherit;
      width: 24px;
    }

    .hoverHideImage {
      display: none !important;
    }
    .hoverImage {
      display: block;
    }
    // .raffleWinner:hover .hoverHideImage,
    // .maorWinner:hover .hoverHideImage {
    //   display: none;
    // }
    .hoverItems:hover span {
      color: #2b345e;
      font-weight: bold;
    }

    @media (max-width: 480px) {
      .raffleWinner:hover .mobileDetails {
        right: 0;
      }
    }
  }
}

.vertical {
  display: inherit;

  .trophyContentMobile,
  .answerContentMobile {
    display: none;
  }
  .winnerList {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 100%;

    img {
      max-width: 30px;
      margin-right: 5px;
    }

    .trophyContent {
      text-align: left;
    }
    .trophyContent,
    .answerContent {
      @media (max-width: 920px) {
        display: none;
      }
    }

    .details,
    .address {
      transition: all 0.4s ease-in-out;

      @media (max-width: 920px) {
        display: none;
      }
    }
    .raffleWinner,
    .maorWinner {
      width: 100%;
      padding: 10px;
      position: relative;

      @media (max-width: 920px) {
        cursor: pointer;
      }
    }

    .raffleWinner {
      @media (max-width: 920px) {
        &:hover .details {
          width: 120px;
          background-color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 5px 0;
          position: absolute;
          z-index: 1;
          display: block;
          font-size: 12px;
          line-height: 14px;
          left: 100%;
          top: 10px;
          // transform: translateY(-145%);
          cursor: default;
        }
        &:hover .details .trophyContentMobile {
          display: block;
        }
      }
    }

    .maorWinner {
      @media (max-width: 920px) {
        &:hover .address {
          width: 120px;
          background-color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 5px 0;
          position: absolute;
          z-index: 1;
          display: block;
          font-size: 12px;
          line-height: 14px;
          left: 84%;
          transform: translateY(-145%);
          cursor: default;
        }
        &:hover .address .answerContentMobile {
          display: block;
        }
      }
    }
  }
}
.myMaorWinners {
  .winnerList {
    .raffleWinner {
      position: relative;
      justify-content: space-evenly;

      @media (max-width: 920px) {
        gap: 0;
        justify-content: center;
        padding: 10px;

        &:hover .details {
          width: 120px;
          background-color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 5px 0;
          position: absolute;
          z-index: 1;
          display: block;
          font-size: 12px;
          line-height: 14px;
          left: 50%;
          bottom: -100%;
          cursor: default;
          transform: translateX(-50%);
          cursor: default;
        }
        &:hover .details .trophyContentMobile {
          display: block;
        }

        .details .trophyContentMobile {
          line-height: 18px;
        }
      }
    }

    .maorWinner {
      position: relative;

      @media (max-width: 920px) {
        gap: 0;
        justify-content: center;
        padding: 10px;

        &:hover .address {
          width: 120px;
          background-color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 5px 0;
          position: absolute;
          z-index: 1;
          display: block;
          font-size: 12px;
          line-height: 14px;
          left: 50%;
          bottom: -100%;
          cursor: default;
          transform: translateX(-50%);
        }
        &:hover .address .answerContentMobile {
          display: block;
        }

        .address .name {
          line-height: 20px;
        }
      }
    }
  }
}
