@import "../../../styles/utilities/index.scss";

$backgroundGradientOrange: linear-gradient(95.02deg, #FFFFFF -38.03%, #FFEAD2 18.65%, #FFFFFF 97.92%);
$colorGreyLight: #F6F6F6;

.container {
  background: $backgroundGradientOrange;
}

.wrapper {
  padding-top: 40px;
  padding-bottom: 80px;
  max-width: 1326px !important;
  min-height: 100vh;

  @media (min-width: $m) {
    padding-top: 100px;
    padding-bottom: 106px;
  }
}

.logotype {
  display: flex;
  justify-content: center;
}

.card {
  margin-top: 20px;
  
  border-radius: 26px 26px 26px 26px;
  background-color: $colorWhite;
}

.main {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 48px;
  padding: 48px 16px;
}

.footer {
  border-radius: 0 0 26px 26px;
  padding: 24px 16px;

  background-color: $colorGreyLight;
}

.checkoutInfo{
  font-size: 16px;
}
// .cart {}

// .payment {}

.changePadding{
  padding: 48px 16px 0px 16px;
}

.backgroundWhite{
  background:$colorWhite;
}

.pledgeBackgroung{
  background-color: #fbac2e;
  background-image: url(../../../assets/images/maor-pledge-logo-background.png);
}

.errorBg{
  background-color: transparent;
}