@import "../../../styles/utilities/index.scss";

// .container {}

// .list {}

.item {
  height: $size-06;

  &:not(:first-of-type) {
    @include set-margin-left($size-04);
  }
}
