@import "../../../../../../../styles/utilities/index.scss";

.container {
  margin-top: 50px;
}

.item {
  border-radius: 0%;
 
  img {
    max-height: 100%;
    margin: auto;
    display: block;
    border-radius: 50px;
    width: 460px;
    height: 300px;

    object-fit: cover;
  }
}

.sliderWrap {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;

  @media (max-width: 950px) {
    width: 100%;
  }

  position: relative;

  .sliderContainer {
    border-radius: 12px;
  }
}

.slideLayout {
  position: relative;
  outline: none;

  .arrowNavigation {
    bottom: 0px;
    right: 0px;

    button {
      background-color: transparent;
      border: none;
    }
  }
}

.arrowNavigation > button > * {
  color: #ffc084;
}

@media (max-width: 768px) {
  .sliderWrap {
    margin: 0px;
  }
}

@media (max-width: 1050px) {
  .sliderWrap {
    max-width: 1040px;
  }
}

@media (max-width: 1000px) {
  .sliderWrap {
    max-width: 450px;
    min-width: 320px;
  }
}

@media (max-width: 450px) {
  .sliderWrap {
    max-width: 400px;
    min-width: 320px;
  }

  .item {
    img {
      width: 400px;
      height: 280px;
     max-height: 300px;
    object-fit: cover;
    }
  }
}

@media (max-width: 390px) {
  .sliderWrap {
    max-width: 360px;
    min-width: 310px;
  }

  .item {
    img {
      width: 360px;
      height: 260px;
     max-height: 265px;
    object-fit: cover;
    }
  }
}

@media (max-width: 350px) {
  .sliderWrap {
    max-width: 320px;
    min-width: 310px;
  }

  .item {
    img {
      width: 320px;
      height: 235px;
     max-height: 250px;
    object-fit: cover;
    }
  }
}
