@mixin set-value($property, $value, $inverse-value) {
  html[dir=ltr] & {
    #{$property}: $value;
  }

  html[dir=rtl] & {
    #{$property}: $inverse-value;
  }
}

@mixin set-property($property, $inverse-property, $value) {
  html[dir=ltr] & {
    #{$property}: $value;
  }

  html[dir=rtl] & {
    #{$inverse-property}: $value;
  }
}

@mixin set-left($value) {
  @include set-property(left, right, $value);
}

@mixin set-right($value) {
  @include set-property(right, left, $value);
}

@mixin set-margin-left($value) {
  @include set-property(margin-left, margin-right, $value);
}

@mixin set-margin-right($value) {
  @include set-property(margin-right, margin-left, $value);
}

@mixin set-border-left($value) {
  @include set-property(border-left, border-right, $value);
}

@mixin set-border-right($value) {
  @include set-property(border-right, border-left, $value);
}

@mixin set-padding-left($value) {
  @include set-property(padding-left, padding-right, $value);
}

@mixin set-padding-right($value) {
  @include set-property(padding-right, padding-left, $value);
}

@mixin set-text-align($value) {
  @if $value == left {
    html[dir=ltr] & {
      text-align: left;
    }
  
    html[dir=rtl] & {
      text-align: right;
    }
  }

  @if $value == right {
    html[dir=ltr] & {
      text-align: right;
    }
  
    html[dir=rtl] & {
      text-align: left;
    } 
  }

  @if $value == center {
    text-align: center;
  }
}
