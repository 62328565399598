@import "../../../styles/utilities/index.scss";

$colorGrey: #959595;

.container {
  position: fixed;
  right: 16px;
  bottom: 60px;
  z-index: 10;

  opacity: 0;
  transition: opacity $timeS;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }

  @media (min-width: $m) {
    right: 48px;
    bottom: 85px;
  }
}

// .container {
//   $size: 48px;

//   position: sticky;
//   top: calc(100% - 48px - 16px);
//   left: calc(100% - 48px - 16px);
//   width: $size;
//   height: $size;

//   @media (min-width: $m) {

//   }

//   @media (min-width: $xxxl) {
//     left: calc(50% + 960px - $size - 48px);
//   }
// }

.button {
  border: none;
  padding: 0;

  background-color: transparent;
  color: $colorGrey;
  cursor: pointer;
  transition: color $timeS;

  @media (min-width: $l) {
    &:hover {
      color: $colorOrange;
    }
  }
}
