@import "../../../../../styles/utilities/index.scss";

.wrapper {
  max-width: 1920px;

  margin-right: auto;
  margin-left: auto;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
}

.container {
  padding: 30px 15px 75px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  justify-content: center;
}

.bannerContainer {
  display: none;
}
.search {
  position: relative;
  display: block;
  width: 100%;
  max-width: 550px;
  margin-top: -30px;
}

.topContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
  gap: 35px;
}

.content {
  display: flex;
  flex-direction: row;
  gap: 45px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  button {
    background-color: #bf3c48;

    &:hover {
      background-color: #bf3c48;
    }
  }
}

.videoContainer {
  height: 470px;
  width: 330px;
  border-radius: 12px;
  background-image: url(../../../../../assets/images/illuminateVideo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  color: $colorBlack;
  gap: 23px;
  align-self: flex-start;

  hr {
    border: 2px dotted #e4a9ab;
    margin: 0;
    overflow: hidden;
    width: 320px;
    align-self: center;
  }
  .headingContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 25px;

    p {
      font-size: 21px;
      line-height: 30px;
    }

    h3 {
      span {
        display: none;
      }
    }
  }

  .dedicatedContainer {
    display: flex;
    flex-direction: column;
    gap: 14px;
    h3 {
      color: #bf3c48;
      font-size: 21px;
      text-align: center;
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      gap: 3px;
      p {
        font-size: 18px;
        line-height: 24px;
      }
      .bold {
        font-weight: bold;
      }
    }
  }
}

.arrowContainer {
  display: flex;
  flex-direction: row;
  gap: 18px;
}

@media (max-width: 1024px) {
  .container {
    padding: 0px 0px 45px;
  }
  .holidaySection {
    display: none;
  }

  .topContainer {
    display: none;
  }

  .wrapper {
    padding: 0px;
  }

  .infoContainer {
    padding: 0px 15px;
  }

  .bannerContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url(../../../../../assets/images/illuminateVideo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 350px;
  }
  .content {
    gap: 3px;
    Button {
      display: flex;
      gap: 10px;
      justify-content: space-around;
      align-items: center;
      background-color: #c64e59;

      margin-top: 30px;

      &:hover {
        background-color: #c64e59;
      }
    }
  }
  .search {
    display: none;
  }
  .headingContainer {
    h3 {
      span {
        display: inline !important;
      }
    }
  }
  .dedicatedContainer {
    align-items: flex-start;
  }

  .videoContainer {
    display: none;
  }
}

@media (max-width: 450px) {
  .headingContainer {
    h3 {
      span {
        br {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .content {
    Button {
      margin-top: 10px !important;
    }
  }
  .headingContainer {
    p {
      font-size: 16px !important;
      line-height: 24px !important;

      br {
        display: none;
      }
    }
  }
}

@media (max-width: 400px) {
  .content {
    gap: 25px;
  }

  .textContainer {
    p {
      font-size: 16px !important;
    }
  }

  .headingContainer {
    h3 {
      font-size: 22px !important;
    }
  }
}

@media (max-width: 350px) {
  .infoContainer {
    hr {
      width: 280px !important;
    }

    .headingContainer {
      h3 {
        font-size: 18px !important;

        span {
          br {
            display: none;
          }
        }
      }
    }
  }

  .textContainer {
    p {
      font-size: 14px !important;
    }
  }

  .content {
    Button {
      display: flex;
      gap: 10px;
      font-size: 13px;
      margin: 0px !important;
    }
  }
}
