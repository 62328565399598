@import "../../../styles/utilities/index.scss";

.container {
  padding: 75px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 65px;
}

.whiteContainer {
  background-color: $colorWhite;
  width: 100%;
  border-radius: 50px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 30px 250px;
  img {
    width: 150px;
    height: auto;
    margin-top: -95px;
    margin-bottom: 25px;
  }
}

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: justify;
  color: $colorBlueDark;
}

.headingContainer > h3 {
  text-transform: uppercase;
  margin: 50px 0px;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  text-align: justify;
  gap: 30px;

  p {
    font-size: 20px;
    line-height: 28px;
    color: $colorBlueDark;
  }
  a {
    color: $colorOrange;
    text-decoration-color: $colorOrange;
    p {
      color: $colorOrange;
      text-decoration-color: $colorOrange;
    }
  }
}

.title {
  color: $colorOrange !important;
  text-decoration: underline;
  font-size: 24px !important;
}
.space {
  display: flex;
  flex-direction: column;
  text-align: justify;
  gap: 30px;
  margin-top: 30px;
}

.list {
  margin-left: 35px;
  ul {
    list-style-type: disc;
  }

  ul li::marker {
    font-size: 22px;
  }
  p {
    line-height: 30px;
  }
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

@media (max-width: 1180px) {
  .whiteContainer {
    padding: 30px 160px;
  }
}

@media (max-width: 950px) {
  .whiteContainer {
    padding: 20px 120px;
  }
}

@media (max-width: 768px) {
  .whiteContainer {
    padding: 40px 50px;
  }
  .container {
    padding: 50px 0px;

    .whiteContainer {
      p {
        font-size: 15.1px !important ;
        line-height: 20px;
      }
    }
  }

  .headingContainer {
    h3 {
      margin: 15px 0px;
    }
  }

  .title {
    font-size: 18px !important;
  }
}

@media (max-width: 550px) {
  .whiteContainer {
    padding: 30px 40px;
  }
}

@media (max-width: 390px) {
  .whiteContainer {
    padding: 30px 20px;
  }
  .bodyContainer {
    gap: 15px;
    p {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .space {
    gap: 20px;
  }
}
