/* Parent Container */
.progressContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0rem auto 3rem;
  position: relative;

  @media (max-width: 1024px) {
    margin: 3rem auto;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 50px;
    max-width: 500px;
    margin: 3rem auto;
    padding: 0px 30px;
  }

  @media (max-width: 650px) {
    gap: 5px;
    padding: 0px 15px;
  }

  @media (max-width: 400px) {
    gap: 3px;
    padding: 0px 10px;
  }
}

/* Stage Wrapper */
.stageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  position: relative;
}

/* Circle for Each Stage */
.stageCircle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.stageCircleCompleted {
  background-color: #ec4899; /* Pink */
}

.stageCircleActive {
  background-color: #22c55e; /* Green */
}

.stageCircleDefault {
  background-color: #6b7280; /* Gray */
}

/* Stage Icon */
.stageIcon {
  width: 2rem; /* 8 * 2 = 32px */
  height: 2rem;
  color: #fff; /* White */
}

/* Stage Name */
.stageName {
  margin-top: 0.5rem;
  font-size: 0.875rem; /* Text-sm in Tailwind */
  font-weight: 500; /* Medium */
}

/* Days Left */
.stageDaysLeft {
  margin-top: 0.25rem;
  font-size: 17px; /* Text-xs */
  color: #16a34a; /* Green */
  font-weight: bold;
  position: absolute;
  top: -30px;
  text-wrap: nowrap;
  white-space: nowrap;
}

/* Dots Container */
.dotsContainer {
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  z-index: 0;
  padding: 0px 110px;

  @media (max-width: 1200px) {
    top: 24px;
    padding: 0px 90px;
  }

  @media (max-width: 990px) {
    padding: 0px 70px;
  }

  @media (max-width: 900px) {
    top: 20px;
    padding: 0px 50px;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

/* Dots Group Wrapper */
.dotsGroup {
  // flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Individual Dots */
.dot {
  width: 20px; /* 2 * 4 = 8px */
  height: 20px;
  border-radius: 50%;
  margin: 0 10px;
  background-color: #d1d5db; /* Gray */

  @media (max-width: 1200px) {
    width: 10px;
    height: 10px;
  }

  @media (max-width: 900px) {
    width: 8px;
    height: 8px;
  }
}

.dotCompleted {
  background-color: #ec4899; /* Pink */
}

.dotActive {
  background-color: #22c55e; /* Green */
}

.progressIcon {
  width: 120px;
  height: 120px;

  @media (max-width: 1200px) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 400px) {
    width: 65px;
    height: 65px;
  }
}
