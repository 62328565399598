@import "../../../../styles/utilities//index.scss";

.mobileFooter {
  // max-width: 480px;

  .mobileFooterWrap {
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
    box-shadow: 0px 2px 5px 4px rgba(0, 0, 0, 0.03);
    background-color: #fff;

    .footerItems {
      text-align: center;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 25%;
      padding: 20px 0;
      position: relative;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      // &:hover .itemcontent {
      // 	display: block;
      // }

      // &:hover .hoverImage {
      // 	display: inherit;
      // 	width: 24px;
      // }

      // &:hover .hoverHideImage {
      // 	display: none;
      // }

      // &:hover span{
      // 	color: #2b345e;
      // 	font-weight: bold;
      // }

      span {
        width: 100%;
        margin-top: 5px;
      }

      svg {
        width: 24px;
        height: 24px;
      }

      .itemcontent {
        position: absolute;
        top: -90px;
        background: #ffff;
        padding: 5px;
        width: 150px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border: 1px solid #ff8a34;
        top: -90px;
        border-bottom: none;
        transition: all 0.4s ease-in-out;
        display: none;
      }

      .lastItem {
        right: -5px;
      }
    }
    // .hoverItems:hover span {
    // 	color: #2b345e;
    // 	font-weight: bold;
    // }
    .audioItems:hover span {
      color: #b95353;
      font-weight: 400;
    }
    .hoverHideImage {
      display: block;
    }
    .activeItem {
      span {
        color: #2b345e;
        font-weight: bold;
      }

      .hoverImage {
        display: block;
        width: 24px;
      }

      .hoverHideImage {
        display: none;
      }

      .hideColoring {
        display: none !important;
      }

      .hideGrayColoring {
        display: block !important;
      }
    }

    .winnerItem {
      width: 50%;
      padding: 0;
    }

    .audioItems {
      span {
        color: rgb(185, 83, 83);
      }
    }
  }
}

.activeSpan {
  color: #2b345e;
  font-weight: bold;
}

.hideGrayColoring {
  display: none !important;
}

.hideColoring {
  display: none !important;
}

.header {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 550px) {
    text-align: center;
  }
  @media (max-width: 450px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  .logoWrap {
    width: 100%;
    display: inherit;
    align-items: center;
    gap: 10px;

    @media (max-width: 450px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    button {
      padding: 5px;
      border-radius: 4px;
      line-height: 18px;
      min-height: unset;
    }
  }

  .sidebarButtons {
    display: none;

    @media (max-width: 920px) {
      display: inherit;
    }

    button {
      background-color: #ff8a34;
      border: none;
      padding: 5px;
      border-radius: 4px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .addToPlaylistBox button {
    border-radius: 12px;
    padding: 8px 20px;
    // height: 40px;
    font-size: 16px;
    // line-height: 18px;
    background-color: $colorOrange;
    color: $colorWhite;
    border: none;
    // margin-top: 40px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .projectTitle {
    font-size: 1rem;
    letter-spacing: 1px;
    font-weight: bold;
    color: $colorOrange;
  }
}

.logotype {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .projectTitle {
    font-size: 1.13rem;
  }

  .totalVideosCount {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
  }

  a {
    cursor: default;
  }

  .logotypeWrapper {
    a {
      background-color: $colorWhite;
      border-radius: 50%;
      width: 64px;
    }
  }
}

.main {
  margin-top: 28px;
  max-width: 1920px;
  margin: 0 auto;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (min-width: $m) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $xxl) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.item {
  min-width: 0;
}

.link {
  color: $colorOrange;
  text-decoration: none;
  font-weight: bold;
}

.message {
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: $colorBlueDark;

  // &.empty {}
  @media (max-width: $m) {
    font-size: 1.5rem;
  }

  &.error {
    color: $colorRed;
  }
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  min-height: 100vh;

  @media (min-width: $m) {
    padding: 60px 48px;
  }
}

.player {
  width: 100%;
  background-color: #fff;
  max-width: 1200px;
  padding: 40px 30px 30px;
  border-radius: 10px;
  position: relative;
}

.card {
  border-radius: 28px;
  padding: 48px 20px;
  width: 100%;
  max-width: 640px;

  text-align: center;
  background-color: $colorWhite;
  color: $colorBlueDark;
}

.title {
  margin: 0 auto;
  max-width: 600px;

  font-size: 28px;
  line-height: 32px;

  @media (max-width: $m) {
    font-size: 18px;
    line-height: 20px;
  }

  @media (max-width: 451px) {
    max-width: 230px;
  }
}

.text {
  margin: 24px 0 0;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: $m) {
    font-size: 16px;
    line-height: 18px;
    margin-top: 10px;
  }
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  flex-direction: column;

  font-size: 20px;
  line-height: 24px;

  gap: 24px;

  @media (max-width: $m) {
    margin-top: 16px;
    gap: 16px;
    font-size: 16px;
    line-height: 18px;
  }

  @media (max-width: $s) {
    flex-direction: column;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  min-height: 32px;
}

.button {
  border: none;
  padding: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-decoration: underline;
  background-color: transparent;
  color: $colorOrange;
  cursor: pointer;
}

.closeContainer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 12px;
  right: 30px;

  div {
    // color: white;
  }
}

.playlistFixed {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 20px;
  color: white;
  background: #fb8118;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  gap: 6px;
}
