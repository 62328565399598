@import "../../../styles/utilities/index.scss";

$boxShadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
$colorGrey: #e0e0e0;

.container {
  display: flex;
  cursor: pointer;
}

.iconBox {
  flex-shrink: 0;
}

.textBox {
  flex-grow: 1;

  &::selection {
    background: transparent;
  }
}

.input {
  display: none !important;

  &:checked {
    & + .icon {
      .flag {
        opacity: 1;
      }
    }
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flag {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

/* colors */

.primary {
  .icon {
    border-style: solid;
    border-color: $colorOrange;
  }

  .input {
    &:checked {
      & + .icon {
        background-color: $colorOrange;
      }
    }

    &:not(:disabled):not(:checked) {
      @media (min-width: $l) {
        &:hover + .icon {
          box-shadow: $boxShadow;
        }
      }
    }

    &:disabled {
      & + .icon {
        border-color: $colorGrey;
        background-color: $colorGrey;
      }
    }
  }
}

.birthdayCheckBox {
  .icon {
    border-style: solid;
    border-color: $colorOrange;
  }

  .input {
    &:checked {
      & + .icon {
        background-color: $colorOrange;
        border-color: $colorOrange !important;
      }
    }

    &:not(:disabled):not(:checked) {
      @media (min-width: $l) {
        &:hover + .icon {
          box-shadow: $boxShadow;
        }
      }
    }

    &:disabled {
      & + .icon {
        border-color: $colorGrey;
        background-color: $colorGrey;
      }
    }
  }
}

.purpleCheckbox {
  .icon {
    border-style: solid;
    border-color: #ac464d;
  }

  .input {
    &:checked {
      & + .icon {
        background-color: #ac464d;
      }
    }

    &:not(:disabled):not(:checked) {
      @media (min-width: $l) {
        &:hover + .icon {
          box-shadow: $boxShadow;
        }
      }
    }

    &:disabled {
      & + .icon {
        border-color: $colorGrey;
        background-color: $colorGrey;
      }
    }
  }
}

.lightYellowCheckbox {
  .icon {
    border-style: solid;
    border-color: #fabb17;
  }

  .input {
    &:checked {
      & + .icon {
        background-color: #fabb17;
      }
    }

    &:not(:disabled):not(:checked) {
      @media (min-width: $l) {
        &:hover + .icon {
          box-shadow: $boxShadow;
        }
      }
    }

    &:disabled {
      & + .icon {
        border-color: $colorGrey;
        background-color: $colorGrey;
      }
    }
  }
}

.redCheckbox {
  .icon {
    border-style: solid;
    border-color: #ea5455;
  }

  .input {
    &:checked {
      & + .icon {
        background-color: #ea5455;
      }
    }

    &:not(:disabled):not(:checked) {
      @media (min-width: $l) {
        &:hover + .icon {
          box-shadow: $boxShadow;
        }
      }
    }

    &:disabled {
      & + .icon {
        border-color: $colorGrey;
        background-color: $colorGrey;
      }
    }
  }
}

.blackCheckbox {
  .icon {
    border-style: solid;
    border-color: #27305a;
  }

  .input {
    &:checked {
      & + .icon {
        background-color: #27305a;
      }
    }

    &:not(:disabled):not(:checked) {
      @media (min-width: $l) {
        &:hover + .icon {
          box-shadow: $boxShadow;
        }
      }
    }

    &:disabled {
      & + .icon {
        border-color: $colorGrey;
        background-color: $colorGrey;
      }
    }
  }
}

/* sizes */

.s {
  $size: 20px;

  .icon {
    border-width: 2px;
    border-radius: 6px;
    width: $size;
    height: $size;
  }

  .textBox {
    margin-left: 12px;

    font-size: 14px;
    line-height: $size;
    letter-spacing: 0.01em;
  }
}

.m {
  $size: 24px;

  .icon {
    border-width: 3px;
    border-radius: 8px;
    width: $size;
    height: $size;
  }

  .textBox {
    margin-left: 12px;

    font-size: 20px;
    line-height: $size;
    letter-spacing: 0.01em;
  }
}

.pl {
  $size: 24px;

  .icon {
    border-width: 3px;
    border-radius: 8px;
    width: $size;
    height: $size;
  }

  .textBox {
    margin-left: 12px;

    font-size: 16px;
    line-height: $size;
    letter-spacing: 0.01em;
  }
}

@media (max-width: 500px) {
  .textBox {
    margin-left: 10px !important;
    font-size: 16px !important;
  }
}

.birthdayCheckBox {
  border: 0.5px solid;
  background-color: #ffffff;
  color: #636363;
  border-color: #c1bbbb;
  border-radius: 25px;
  align-items: center;
  padding: 1px 10px;
}

.birthdayIcon {
  border: 0.5px solid;
  background-color: #ffffff;
  color: #636363;
  border-color: #c1bbbb !important;
  border-radius: 25px !important;
  width: 16px !important;
  height: 16px !important;
  border-width: 1px !important;
}

.birthdayTextBox {
  font-size: 12px !important;
  color: #636363;
}

.borderOrange {
  border-color: $colorOrange;
}

.birthdayCheckedFont {
  color: $colorOrange;
  font-weight: bold;
}
