@import "../../../styles/utilities/index.scss";

$bgGradientWhite: linear-gradient(180deg, rgba(112, 194, 23, 0.1) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
$bgGradientBlue: linear-gradient(180deg, rgba(45, 131, 213, 0) 1.68%, #2D83D5 39.44%);
$colorBlue: #2D83D5;
$colorOrange: #FF8A34;
$colorCrimson: #EE0979;
$colorGreyDark: #959595;
$colorLightBg: #fff9fd;

.container {
  padding-top: 40px;
  padding-bottom: 136px;

  @media (min-width: $xxxl) {
    padding-bottom: 236px;
  }
}



.wrapper {
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 1096px;
  width: 100%;

  @media (min-width: $m) {
    padding-right: 48px;
    padding-left: 48px;
  }

  @media (min-width: $xxxl) {
    max-width: 1576px;
  }
}

.birthdayPackageContainer {
  @media (max-width: $m) {
    width: 100%;
  }
}

.packages {
  margin-top: 4px;
  // background: $bgGradientWhite;

  @media (min-width: $m) {
    background: none;
  }

  @media (min-width: $l) {
    margin-top: 8px;
  }

  .sectionTitle {
    position: relative;

    margin: 0;

    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    text-align: center;

    @media (min-width: $l) {
      font-size: 24px;
    }

    @media (min-width: $xxxl) {
      font-size: 30px;
      line-height: 42px;
    }
  }

  .list {
    position: relative;
    z-index: 0;
  
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 128px;
    list-style-type: none;
    margin: 68px 0 0;
    padding: 0;
  
    @media (min-width: $m) {
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 0;
      margin-top: 112px;
      border-radius: 16px;

      background: $bgGradientWhite;
    }

    @media (min-width: $l) {
      border-radius: 20px;
    }

    @media (min-width: $xxl) {
      margin-top: 152px;
      border-radius: 26px;
    }

    @media (min-width: $xxxl) {
      margin-top: 208px;
    }
  }

  .item {
    position: relative;
    z-index: 0;

    display: flex;
    flex-direction: column;
    padding-top: 22px;
    padding-right: 8px;
    padding-bottom: 26px;
    padding-left: 8px;

    &:nth-of-type(2) {
      color: $colorWhite;
    }

    &:nth-of-type(3) {
      .title, .cost {
        color: $colorPurple;
      }
    }

    &:nth-of-type(4) {
      .title, .cost {
        color: $colorCrimson;
      }
    }

    &.marked {
      .tooltip {
        color: $colorWhite;
      }
    }

    @media (min-width: $m) {
      padding-top: 24px;
      padding-bottom: 20px;
    }

    @media (min-width: $l) {
      padding-top: 32px;
      padding-right: 24px;
      padding-bottom: 40px;
      padding-left: 24px;
    }

    @media (min-width: $xxl) {
      padding-top: 36px;
      padding-bottom: 28px;
    }

    @media (min-width: $xxxl) {
      padding-top: 68px;
      padding-right: 56px;
      padding-bottom: 20px;
      padding-left: 56px;
    }
  }

  .background {
    overflow: hidden;
    position: absolute;
    top: -30px;
    right: 0;
    bottom: -14px;
    left: 0;
    z-index: -10;

    display: block;
    border-radius: 16px;

    background: $bgGradientBlue;
    filter: drop-shadow(69.1415px 72.2458px 80px rgba(39, 48, 90, 0.07)), drop-shadow(35.0029px 36.5744px 34.875px rgba(39, 48, 90, 0.04725)), drop-shadow(13.8283px 14.4492px 13px rgba(39, 48, 90, 0.035)), drop-shadow(3.02494px 3.16075px 4.625px rgba(39, 48, 90, 0.02275));
  
    @media (min-width: $m) {
      bottom: -20px;
    }

    @media (min-width: $l) {
      border-radius: 20px;
    }

    @media (min-width: $xxl) {
      top: -40px;
      border-radius: 26px;
    }

    @media (min-width: $xxxl) {
      top: -44px;
      bottom: -30px;
    }
  }

  .tape {
    transform-origin: top left;
    transform: rotate(-45deg) translate(-50%, 70%);

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40%;
    height: 28px;

    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    background-color: #FF565E;
    color: $colorWhite;

    @media (min-width: $xxl) {
      transform: rotate(-45deg) translate(-50%, 120%);
      padding-right: 0;
      padding-left: 0;
    }

    @media (min-width: $xxxl) {
      height: 40px;

      font-size: 16px;
      line-height: 18px;
    }
  }

  .illustration {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 14px);
    z-index: 10;
  
    width: 64px;
  
    svg {
      width: 100%;
      height: auto;
    }

    @media (min-width: $m) {
      transform: translate(-50%, 16px);
    }

    @media (min-width: $l) {
      transform: translate(-50%, 24px);
      width: 88px;
    }

    @media (min-width: $xxl) {
      transform: translate(-50%, 28px);
      width: 100px;
    }

    @media (min-width: $xxxl) {
      transform: translate(-50%, 56px);
      width: 148px;
    }
  }
  
  .subitem {
    &:not(:first-of-type) {
      margin-top: 12px;
    }
  }

  .title {
    margin: 0;

    font-size: 16px;
    line-height: 20px;
    text-align: center;

    @media (min-width: $l) {
      line-height: 28px;
    }

    @media (min-width: $xxl) {
      line-height: 32px;
    }

    @media (min-width: $xxxl) {
      font-size: 30px;
      line-height: 48px;
    }
  }

  .sublist {
    list-style-type: none;
    margin: 8px 0;
    padding: 0 16px;

    @media (min-width: $l) {
      padding-right: 0;
      padding-left: 0;
    }

    @media (min-width: $xxl) {
      margin-bottom: 16px;
    }

    @media (min-width: $xxxl) {
      margin-top: 12px;
      margin-bottom: 20px;
    }
  }

  .subitem {
    display: flex;

    &:not(:first-of-type) {
      margin-top: 8px;
    }

    @media (min-width: $xxxl) {
      &:not(:first-of-type) {
        margin-top: 12px;
      }
    }
  }

  .icon {
    line-height: 1;

    &.right {
      margin-left: 8px;
    }

    @media (min-width: $xxl) {
      margin-top: 2px;
    }
  }

  .subtitle {
    margin-left: 8px;

    font-size: 12px;
    line-height: 20px;

    @media (min-width: $xxl) {
      font-size: 14px;
    }

    @media (min-width: $xxxl) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .tooltip {
    color: $colorGreyDark;
  }

  .footer {
    margin-top: auto;
  }

  .cost {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    @media (min-width: $l) {
      font-size: 20px;
      line-height: 24px;
    }

    @media (min-width: $xxl) {
      font-size: 24px;
      line-height: 32px;
    }

    @media (min-width: $xxxl) {
      font-size: 30px;
      line-height: 48px;
    }
  }

  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
  }
}

.features {
  margin-top: 70px;

  @media (min-width: $m) {
    margin-top: 110px;
  }

  @media (min-width: $l) {
    margin-top: 90px;
  }

  @media (min-width: $xxl) {
    margin-top: 132px;
  }

  @media (min-width: $xxxl) {
    margin-top: 186px;
  }

  .sectionTitle {
    position: relative;

    margin: 0;

    font-weight: bold;
    font-size: 24px;
    line-height: 42px;
    text-align: center;

    @media (min-width: $xxxl) {
      font-size: 30px;
    }
  }

  .list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 26px;
    grid-row-gap: 24px;
    list-style-type: none;
    margin: 28px 0 0;
    // padding: 0 20px;
  
   
    @media (min-width: $m) {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 26px;
      padding-right: 36px;
      padding-left: 36px;
    }
  
    @media (min-width: $l) {
      grid-template-columns: repeat(2, 1fr);
      padding-right: 0;
      padding-left: 0;
    }

    @media (min-width: $xxl) {
      grid-column-gap: 28px;
      grid-row-gap: 28px;
    }

    @media (min-width: $xxxl) {
      grid-column-gap: 40px;
      grid-row-gap: 40px;
      margin-top: 40px;
    }
  }
  
  .item {
    border: 4px solid $colorOrange;
    border-radius: 25px;
    padding: 10px 24px 14px;
    min-height: 236px;
    background-image: url('../../../assets/images/background.png');
    background-color: $colorLightBg;
    background-size: auto;
    background-position: top left;
    background-repeat: no-repeat;

      &:nth-child(4n+1){
        background-position: top left;
      }
      &:nth-child(4n+2){
        background-position: bottom left;
      }
      &:nth-child(4n+3){
        background-position: bottom left;
      }
      &:nth-child(4n+4){
        background-position: top left;
      }
    @media (min-width: $xxl) {
      min-height: 254px;
    }

    @media (min-width: $xxxl) {
      padding-right: 36px;
      padding-left: 36px;
      min-height: 340px;
    }
  }
  
  .illustration {
    margin-right: -12px;
    margin-left: -12px;

    svg {
      height: auto;
      margin: 0 auto;
      display: block;
    }

    @media (min-width: $xxxl) {
      margin-right: -24px;
      margin-left: -24px;
    }
  }
  
  .title {
    margin: 8px 0 0;

    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: $colorOrange;

    @media (min-width: $xxl) {
      font-size: 20px;
    }

    @media (min-width: $xxxl) {
      font-size: 22px;
    }
  }
  
  .text {
    margin: 8px 0 0;
    font-size: 14px;
    line-height: 20px;

    @media (min-width: $xxl) {
      font-size: 16px;
      line-height: 24px;
    }

    @media (min-width: $xxxl) {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.anchor {
  position: relative;

  display: flex;
  justify-content: center;
  margin-top: 88px;

  @media (min-width: $xxl) {
    margin-top: 104px;
  }

  @media (min-width: $xxxl) {
    margin-top: 136px;
  }
}

/* decorative elements */

.decoration {
  position: absolute;

  svg {
    width: 100%;
    height: auto;
  }
}

.ballon {
  top: 50%;
  width: 28px;
  line-height: 1;

  &.right {
    left: 100%;
    transform: translateY(-50%) scale(-1, 1);
  }

  &.left {
    right: 100%;
    transform: translateY(-50%);
  }

  @media (min-width: $m) {
    width: 36px;

    &.right {
      left: calc(100% + 12px);
    }

    &.left {
      right: calc(100% + 12px);
    }
  }

  @media (min-width: $xxxl) {
    width: 40px;
  }
}

.celebration {
  top: 50%;
  left: 50%;
  z-index: -10;
  transform: translate(-50%, -50%);

  width: 240px;

  @media (min-width: $xxl) {
    width: 312px;
  }

  @media (min-width: $xxxl) {
    width: 412px;
  }
}

.confetti {
  bottom: 4px;
  width: 32px;
  line-height: 1;

  &.right {
    left: calc(100% + 8px);
  }

  &.left {
    right: calc(100% + 8px);
  }

  @media (min-width: $m) {
    &.right {
      left: calc(100% + 40px);
    }

    &.left {
      right: calc(100% + 40px);
    }
  }

  @media (min-width: $l) {
    &.right {
      left: calc(100% + 12px);
    }
  
    &.left {
      right: calc(100% + 12px);
    }
  }

  @media (min-width: $xxxl) {
    bottom: 0;
    width: 44px;
  }
}

.pattern {
  z-index: -10;

  display: none;
  

  @media (min-width: $l) {
    display: block;
  }
}

.pattern.left {
  top: 132px;
  left: -124px;

  width: 160px;

  @media (min-width: $xxl) {
    top: 176px;
    left: -176px
  }

  @media (min-width: $xxxl) {
    top: 216px;
    left: -96px
  }
}

.pattern.right {
  top: 200px;
  right: -48px;

  width: 300px;

  @media (min-width: $xxl) {
    top: 240px;
    right: -208px;
  }

  @media (min-width: $xxxl) {
    top: 360px;
    right: -208px;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-weight: 600;
  text-align: center;

  .logoutLink {
    color: $colorOrange;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .loggedInText {
    margin-right: 10px;
    color: $colorBlack;
  }
  
  .familyText {
    color: $colorBlue;
  }
}
