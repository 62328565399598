@import "../../../styles/utilities/index.scss";

.writingCompetitionForm {
}

.videoInput {
  display: none;
}

.bgContainer {
  background-color: #ffc000;
  background-image: url(../../../assets/images/start-background.png);
}

.maorCompetitionLogo {
  width: 450px;
  height: auto;
  margin: 0 auto;
}

.maorCompetitionLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.writingCompetitionContainer {
  background-color: #fff;
  border-radius: 30px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  justify-content: center;
  align-items: center;

  margin-top: 40px;
}

.addWriters {
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.addCharacters {
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.heading {
  font-size: 28px;
  line-height: 32px;
  text-align: center;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  span {
    font-family: "Roboto";
    font-size: 12px;
    margin-left: 20px;
    font-weight: 400;
  }
}

.grandstanderFamily {
  font-family: "Grandstander", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.note {
  font-family: "Roboto";
  font-size: 12px;
  margin-left: 20px;
  font-weight: 400;
  text-align: center;
  margin-top: 5px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem; // Equivalent to "gap-4"
}

.uploadButton {
  background-color: #ffc000;
  color: #fff;
  font-weight: bold;
  padding: 10px 2rem;
  border-radius: 25px;
  font-size: 20px;
  width: 100%;
  max-width: 24rem;
  transition: background-color 0.3s ease;
  border: 0;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  &:hover {
    background-color: #fcd34d;
  }

  img {
    width: 50px;
    height: 50px;
  }

  @media (max-width: 450px) {
    padding: 10px;
    font-size: 18px;
    gap: 10px;

    img {
      width: 40px;
      height: 40px;
    }
  }
}

.uploadIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}

.submitScriptButton {
  background-color: #ffc000;
  color: #000;
  font-weight: bold;
  padding: 25px;
  border-radius: 30px;
  font-size: 22px;
  width: 100%;
  max-width: 24rem;
  transition: background-color 0.3s ease;
  border: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  // margin: 0 auto;

  &:hover {
    background-color: #fcd34d;
  }
}

.wFull {
  width: 100%;
}

.uploadedImagesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: center;

  margin-top: 30px;

  .uploadedImageWrapper {
    border: 1px solid #000;
    border-radius: 25px;
    position: relative;
  }

  img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 25px;
  }

  .removeImageButton {
    position: absolute;
    top: 10px;
    right: 10px;

    background: transparent;
    border: 1px solid #000;
    border-radius: 100%;
    padding: 5px;
  }
}

.submitError {
  text-align: center;
  margin: 10px 0px;
  color: red;
}

.submissionConfirmationNote {
  font-size: 20px;
}

.formButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
}

.loader {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.draftSavedMessage {
  font-weight: 600;
  margin-top: 15px;
  padding-left: 25px;
}

.saveDraft {
  background-color: #ffd862;
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  min-height: 100vh;

  @media (min-width: $m) {
    padding: 60px 48px;
  }
}

.card {
  position: relative;
  border-radius: 30px;
  padding: 80px 60px;
  width: 100%;
  max-width: 768px;

  background-color: $colorWhite;
  color: $colorBlueDark;
}

.confirmationModal {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  h2 {
    color: #ffc000;
  }

  .confirmationModalButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 25px;
    width: 100%;

    @media (max-width: 650px) {
      flex-wrap: wrap;
    }
  }

  .greenColor {
    background-color: #87ba48;
  }
}
