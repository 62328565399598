.videoSubmissionContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  padding: 2rem;
  margin: 0 auto;

  position: relative;
}

.videoItemContainer {
  background-color: var(--bg-color, #ffd52d);
  border-radius: 1.5rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 315px;

  position: relative;
}

.header {
  text-align: center;
  flex-grow: 1;
  padding-left: 75px;
  min-height: 80px;
}

.names {
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 0.25rem;
  color: #000;
}

.location {
  font-size: 0.875rem;
  color: #000;
}

.videoContent {
  width: 100%;
  aspect-ratio: 12 / 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2rem;
  overflow: hidden;
  height: 180px;

  position: relative;
}

.playButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  // &:hover {
  //   background-color: rgba(255, 255, 255, 0.9);
  // }

  img {
    width: 80px;
    height: 60px;
  }
}

.scriptButton,
.voteButton {
  width: 100%;
  text-align: center;
  padding: 0.75rem;
  border-radius: 1rem;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.scriptButton {
  background-color: #fef3c9;
  color: #000;

  &:hover {
    background-color: #feedab;
  }
}

.voteButton {
  background-color: #5b5b5b;
  color: #797979;
  text-transform: uppercase;
  padding: 20px;
  border-radius: 30px;
  margin-top: 16px;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
  pointer-events: none;

  &:hover {
    background-color: #5b5b5b;
  }
}

.videoPlayer {
  width: 100%; /* Make the video take up the full width of the parent container */
  max-width: 800px; /* Optional: Limit the maximum width for larger screens */
  border-radius: 8px; /* Optional: Add rounded corners */
  aspect-ratio: 16 / 9; /* Maintain the aspect ratio */
  background-color: #000; /* Optional: Background color to show before video loads */
}

.videoThumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures video fills the container */
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 70px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.playButton img {
  width: 100%;
  height: 100%;
}

.grandstanderFamily {
  font-family: "Grandstander", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.activeButton {
  background-color: #ffc000;
  color: #fff;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  pointer-events: all;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #ffa000;
    transform: translateY(-2px);
  }
}

.voteInfo {
  background-color: #ffc000;
  color: #000;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
  gap: 10px;
  padding: 10px 15px;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  top: 95px;
  z-index: 9999;
  left: -15px;
  font-family: "Grandstander", cursive;

  img {
    width: 30px;
    height: 30px;
  }

  p {
    font-size: 28px;
    margin-top: 5px;
    font-weight: bold;
  }
}

.loader {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}

.addCharacterModalConatiner {
  padding: 80px 16px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  // overflow-y: scroll;
}

.addCharacterModalCard {
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  max-width: 1280px;
  width: 100%;
  position: relative;
}

.iconCross {
  position: absolute;
  top: 25px;
  right: 25px;

  cursor: pointer;
}

.winnerIcon {
  position: absolute;
  top: -10px;
  left: -15px;

  img {
    border: 6px solid #ea5455;
    border-radius: 35px;
    background: #fef3c9;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    left: -15px;
    height: auto;
  }
}
