@import "../../../styles/utilities//index.scss";

.previewFooter {
  background-color: $colorWhite;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 15px 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.previewButtons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.previewButton {
  border-style: solid;
  border-color: transparent;
  background-color: #ff8a34;
  color: #000000;
  border-width: 1px;
  border-radius: 15px;
  padding: 8px 15px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  text-wrap: nowrap;
  white-space: nowrap;
  cursor: pointer;

  @media (max-width: 480px) {
    font-size: 12px;
    line-height: 18px;
  }

  &:hover {
    box-shadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
    background-color: #ee802f;
  }
}

.pink {
  background-color: $colorNewPink;
  &:hover {
    box-shadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
    background-color: $colorNewPink;
  }
}

.green {
  background-color: $colorLightGreen;
  &:hover {
    box-shadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
    background-color: $colorLightGreen;
  }
}

.yellow {
  background-color: $colorLightYellow;
  &:hover {
    box-shadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
    background-color: $colorLightYellow;
  }
}

.previewInfo {
  max-width: 500px;
}

.videoTags {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-height: 30px;
  overflow: hidden;

  //   &::after {
  //     content: "...";
  //     position: absolute;
  //     bottom: -2px;
  //     right: 0px;
  //     background: #fff;
  //     padding-left: 8px;
  //   }

  a {
    color: #2d83d5;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
  }
}

@media (max-width: 786px) {
  .previewFooter{
    background-color: transparent;
    padding: 15px 0px;
  }

  .videoTags {
    max-height: 34px;
    a {
      color: #fff;
      text-align: center;
      text-decoration: underline;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
