@import "../../../../styles/utilities/index.scss";

$boxShadow: 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 30px 40px rgba(0, 0, 0, 0.05);

.iAmText {
  font-weight: 600;
  font-size: 12px;
  margin-right: 10px;
}

.illuminate {
  .mobilelogo {
    img {
      width: 115px !important;
    }
  }
}

.mobileHeader {
  display: flex;
  justify-content: space-between;
  // max-width: 480px;
  gap: 15px;
  padding: 5px 10px;
  // border-bottom: 1px solid #8b949f;
  background-color: #fff;
  position: relative;
  box-shadow: $boxShadow;

  .mobileHeaderSearch {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 15px;
    justify-content: center;
  }

  .mobilelogo {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 48px;

    img {
      width: 65px;
    }
  }

  .mobileIcons {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 16rem; /* Safari 10-11 */
    gap: 16px; /* Safari 12+ */
    align-items: center;

    svg {
      color: #ff8a34;
    }

    .userIcon {
      border: 1px solid #ff8a34;
      border-radius: 100%;
      // padding: 2px;
    }
  }

  .hidden {
    visibility: hidden;
  }
}

.menu {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.subscription {
  font-size: $size-04;

  span {
    margin-bottom: 5px;
  }
}

.dropdown {
  box-shadow: $boxShadow;
  list-style-type: none;
  margin: 16px 0 0;
  border: 2px solid $colorGreyLight;
  border-radius: $size-02;
  padding: $size-05;
  width: 210px;

  background-color: $colorWhite;
  color: $colorBlueDark;
}

.item {
  &:not(:first-of-type) {
    margin-top: $size-05;
  }
}
