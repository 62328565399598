@import '../../../styles/utilities/index.scss';

$colorGreyLight: #c1bbbb;
$white: #fff;
$darkBlue: #27305a;

.container {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  position: relative;
  
  &.vertical {
    flex-direction: column;
    align-items: flex-end;
  }

  @media (max-width: 500px) {
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: unset;
    gap: 10px;
  }
}

.item {
  position: relative;

  &:after {
    content: '';

    position: absolute;
    top: calc(100% + 7px);
    left: 0;

    display: block;
    width: 100%;
    height: 4px;
    background-color: $white;
    z-index: 1;
  }

  .vertical & {
    &:not(:first-of-type) {
      // margin-top: 28px;
    }
  }

  @media (max-width: 500px) {
    // padding: 0px 8px 10px;
    padding: 0px;
    &:after {
      content: none;
      top: calc(100% + 3px);
    }
  }

  @media (max-width: 992px) {
    &:after {
      top: calc(100% + 3px);
    }
  }

  // @media (max-width: $breakpointM) {
  //   // padding: 0px 8px 10px;

  //   &:after {
  //     content: '';
  //     top: calc(100% + 3px);
  //   }
  // }
}

.link {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: $darkBlue;
  text-decoration: none;
  padding: 0px 30px;

  &.active {
    position: relative;
    color: $colorOrange;

    &:after {
      content: '';

      position: absolute;
      top: calc(100% + 8px);
      left: 0;

      display: block;
      border-radius: 4px;
      width: 100%;
      height: 4px;

      background-color: $colorOrange;
      z-index: 2;
    }
  }

  &.disabled {
    color: $colorGreyLight;
    pointer-events: none;
  }

  @media (max-width: $breakpointM) {
    padding: 0px 16px;
    font-size: 13px;
  }

  @media (max-width: 801px) {
    padding: 0px 10px;
  }

  @media (max-width: 500px) {
    padding: 0px;
    &.active {
      &:after {
        top: calc(100% + 5px);
      }
    }
  }
}

.librarymain {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 10px;
}

.librarylist {
  background-color: #fff;
  border: 1px solid #FF8A34;
  border-radius: 25px;
  display: inline-flex;
}

.librarylist li a {
  color: #FF8A34;
  padding: 10px 25px;
  display: inline-block;
  position: relative;
}

.librarylist li a::after {
  position: absolute;
  content: "";
  top: 50%;
  right: -2px;
  width: 1px;
  height: 70%;
  border-right: 1px dotted #ffa362;
  transform: translateY(-50%);
}

.librarylist li a.active {
  background-color: #ffa362;
  color: white;
}

.librarylist li::after, .librarylist li a.active::after  {
  display: none !important;
}

.librarylist li:first-child a {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.librarylist li:last-child a {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.librarylist li:last-child a::after {
  display: none;
}
