@import "../../../../../styles/utilities/index.scss";

// .container {}
.horizontal {
  display: flex;
  width: 100%;

  .goldPartnersList {
    width: 100%;
    position: relative;
    border-top: 2px solid rgba(196, 196, 196, 0.5);
    margin-top: 40px;
    padding: 50px 10px 20px;
    align-items: center;
    display: inherit;
    justify-content: center;

    @media (max-width: 920px) {
      cursor: pointer;

      &:hover .imgTextWrap .titleWrap {
        cursor: default;
        width: 200px;
        background-color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        display: block;
        font-size: 12px;
        line-height: 14px;
        left: -100%;
        bottom: -40px;
      }
    }

    @media (max-width: 920px) {
      display: none;
    }

    @media (max-width: 480px) {
      background-color: #fdf2e2;
      padding: 20px;
    }

    .imgTextWrap {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        @media (max-width: 1450px) {
          position: relative;
          max-width: 80px;
          top: unset;
        }
        @media (max-width: 480px) {
          max-width: 60px;
        }
      }

      .titleWrap {
        width: 100%;
        background: rgb(250, 225, 187);
        background: linear-gradient(
          103deg,
          rgba(250, 225, 187, 0.05) 3%,
          rgba(250, 225, 187, 1) 50%,
          rgba(250, 225, 187, 0.05) 96%
        );

        padding: 30px 10px;

        @media (max-width: 480px) {
          background-image: none;
          padding: 10px;
        }
      }
      .title {
        color: #d99f3b;
        display: block;
        text-align: center;

        @media (max-width: 480px) {
          font-size: 18px;
        }
      }

      ul {
        list-style-type: none;
        column-count: 2;
        padding-left: 20px;
        text-align: center;

        @media (max-width: 450px) {
          column-count: 1;
          padding-left: 0;
        }

        li {
          font-size: 16px;
          margin: 5px 0;
        }
      }
    }
  }

  .mobileGoldPartners {
    display: inherit;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover .imgTextWrap .titleWrap {
      cursor: default;
      width: 100%;
      background-color: unset;
      text-align: center;
      padding: 10px;
      position: relative;
      z-index: 1;
      font-size: 16px;
      line-height: 20px;
      left: unset;
      bottom: unset;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
}
.vertical {
  .goldPartnersList {
    width: 100%;
    position: relative;
    padding: 10px;
    align-items: center;
    display: inherit;

    @media (max-width: 920px) {
      cursor: pointer;

      &:hover .imgTextWrap .titleWrap {
        cursor: default;
        width: 230px;
        background-color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        display: block;
        font-size: 12px;
        line-height: 14px;
        left: 100%;
        bottom: unset;
        top: 10px;
      }
    }

    .imgTextWrap {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        top: -10px;
        left: 10px;
        position: absolute;
        max-width: 110px;

        @media (max-width: 1450px) {
          position: relative;
          max-width: 80px;
          top: unset;
        }

        @media (max-width: 1400px) {
          max-width: 30px;
          left: unset;
        }
      }

      .titleWrap {
        width: 100%;
        padding-left: 120px;

        @media (max-width: 1450px) {
          padding-left: 10px;
        }
        @media (max-width: 1400px) {
          display: none;
        }
      }
      .title {
        color: #d99f3b;
        display: block;
        text-align: center;
        font-size: 14px;
        line-height: 16px;
      }

      ul {
        list-style-type: none;
        column-count: 2;
        padding-left: 18px;
        text-align: left;

        li {
          font-size: 12px;
          line-height: 14px;
          padding: 0 2px;
          margin: 5px 0;
        }
      }
    }
  }
}
