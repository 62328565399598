@import "../../../styles/utilities/index.scss";

.backgroundRefer {
  background-color: #fff8ef;
  // background-image: url(../../../assets//images/pledge-back-6.jpg);
  background-image: url(../../../assets/images/shareLight.png);
}

.container {
  padding: 60px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 65px;
  gap: 65px;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
}

.lightPinkColor {
  color: #ff4f62;
}

.socialMediaIconContainer {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.headerConatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  .shareText {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  h2 {
    font-size: 38px;
    line-height: 42px;
  }
  p {
    color: #605c57;
    font-weight: bold;
  }
}

.shareLinkContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: #fff;
  // border: 1px solid $colorOrange;
  border-radius: 12px;
  padding: 12px 10px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.referralCountContainer {
  display: flex;
  flex-direction: row;
  padding: 12px 15px;
  border-radius: 15px;
  background-color: #fff;
  align-items: center;
  gap: 60px;
  text-align: left;
  position: relative;
}

.arrow {
  position: absolute;
  top: 1px;
  left: 142px;
  transform: rotate(24deg);

  @media (max-width: 500px) {
    top: -2px;
    left: 126px;
  }
}

.referralCount {
  background-color: #fff8ef;
  border-radius: 20px;
  padding: 20px 20px;
  text-align: center;
}

.count {
  font-size: 36px;
  line-height: 38px;
  color: #ff4f62;
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #fff8ef;
}

.imageContainer {
  img {
    object-fit: contain;
    max-height: 600px;
  }

  p {
    font-size: 12px;
  }
}

// .center{
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }
.height {
  height: 100%;
}

.imageContainer {
  img {
    border-radius: 12px;
  }
}

.sideSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  justify-content: center;
}

.socialMediaIconContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .logoBg {
    background-color: #fff;
    padding: 8px 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
}

.instaLogo {
  cursor: pointer;
}

.termsConditions {
  color: #ff4f62;
  cursor: pointer;
}

@media (max-width: 500px) {
  .container {
    gap: 40px;
  }

  .imageContainer {
    img {
      max-height: 500px;
    }
  }

  .referralCountContainer {
    gap: 32px;
  }
}
.popUpTermsConditions {
  margin: 0 auto;
  padding: 80px 16px;
  width: 100%;
  max-width: 612px;
}

.termsConditionsModalCard {
  border-radius: 26px;
  padding: 32px 32px 64px;
  background-color: #fff;
  color: #27305a;
}

.termsConditionsContainer {
  display: flex;
  flex-direction: column;

  h1 {
    text-align: center;
    line-height: 30px;

    @media (max-width: 500px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  ul {
    list-style: auto;
    margin-top: 25px;
    margin-left: 18px;

    li {
      margin-bottom: 10px;
    }

    @media (max-width: 500px) {
      margin-top: 15px;
      li {
        p {
          font-size: 14px;
        }
      }
    }
  }
}

.closeButtonContainer {
  display: flex;
  justify-content: flex-end;

  button {
    outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
  }
}

.font12{
  font-size: 12px;
  line-height: 16px;
}