@import "../../../styles/utilities/index.scss";

.grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background-color: #fff;
  border-radius: 30px;
  padding-right: 0;
  overflow: hidden;
  max-width: 1500px;
  margin: 0 auto;
}

.partnerSectionText {
  p {
    font-size: 18px;
    line-height: 20px;
  }
}
.message {
  max-width: 600px;
  padding:10px 0 10px 40px;
}

.illustration {
  width: calc(100% - 600px);

  img {
    display: block;
    width: 100%;
    // max-height: 500px;
    object-fit: cover;
  }
}

.share {
  display: flex;
  margin-top: $size-05;
}

.messageOne {
  font-size: 18px;
  line-height: 28px;
}

.messageTwo {
  font-size: 16px;
  line-height: 26px;
}

 @media (max-width:1260px) {
    .message {
      max-width: 500px;
    }
    .illustration{
      width: calc(100% - 500px);
    }
}

 @media (max-width:1100px) {
    .message {
      max-width: 100%;
      padding: 40px;
      width: 100%;
      text-align: center;
    }
    .illustration{
      display: none;
    }
    .share {
      justify-content:center;
    }
}
 @media (max-width:500px) {
  .message {
    padding: 20px;
  }
}