@import "../../../styles/utilities/index.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $size-02 0;
  min-height: $size-10;

  color: $colorBlueDark;
}

.icon {
  .image {
    display: block;
    width: $size-06;
    height: auto;
  }
}

.number {
  display: none;
  align-items: center;
  @include set-margin-left($size-06);

  @media (min-width: $m) {
    display: flex;
  }
}

.timer {
  display: none;
  align-items: center;
  @include set-margin-left($size-06);

  @media (min-width: $l) {
    display: flex;
  }
}

.button {
  border: none;
  padding: 0;
 
  font-weight: 700;
  letter-spacing: 0.2px;
  text-decoration: underline;
  background-color: transparent;
  color: $colorOrange;
  cursor: pointer;
}