@import "../../../styles/utilities/index";

// .container {}

.indicator {
  top: 0;
  left: 0;

  height: 4px;
  width: 100%;

  background-color: $colorOrange;
  transition: width $timeM ease-out;
}
