@import "../../../styles/utilities/index.scss";

$boxShadow: 0px 0px 1px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.16);
$colorGreyLight: #C1BBBB;

.container {
  position: relative;
}

.inputBox {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.input {
  outline: none;
  width: 100%;
}

.indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;

  display: inline-block;
}

.icon {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.label {
  overflow: hidden;
  position: absolute;
  transition: all $timeS;

  color: $colorGrey;
  cursor: pointer;
  white-space: nowrap;
}

.tape {
  overflow: hidden;
  position: absolute;

  color: $colorWhite;
  white-space: nowrap;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}

.tooltip {
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 100%;

  max-width: 100%;

  text-align: center;
}


/* colors */

.primary {
  .input {
    border-color: $colorGreyLight;
    background-color: $colorWhite;
    color: $colorGrey;

    &::placeholder {
      color: $colorGrey;
    }

    & + .indicator {
      background-color: $colorBlueDark;

      & + .icon {
        color: $colorGrey;
      }
    }
  }

  .input:focus {
    color: $colorOrange;

    & + .indicator {
      background-color: $colorOrange;

      & + .icon {
        color: $colorOrange;
      }
    }
  }

  .input:not(:focus).error {
    color: $colorRed;

    &::placeholder {
      color: $colorRed;
    }

    & + .indicator {
      background-color: $colorRed;

      & + .icon {
        color: $colorRed;
      }
    }
  }

  .input:disabled {
    color: $colorGrey;

    & +.indicator {
      background-color: $colorGrey;

      & + .icon {
        color: $colorGrey;
      }
    }
  }

  .icon {
    border-left-style: solid;
    border-left-color: $colorGreyLight;
  }

  .tape {
    background: $colorWhite;
  }

  .tooltip {
    box-shadow: $boxShadow;
    background-color: $colorRed;
    color: $colorWhite;
  }
}

.purple{
  .input {
    border-color: $colorGreyLight;
    background-color: $colorWhite;
    color: $colorGrey;

    &::placeholder {
      color: $colorGrey;
    }

    & + .indicator {
      background-color: $colorBlueDark;

      & + .icon {
        color: $colorGrey;
      }
    }
  }

  .input:focus {
    color: #ac464d;

    & + .indicator {
      background-color: #ac464d;

      & + .icon {
        color: #ac464d;
      }
    }
  }

  .input:not(:focus).error {
    color: $colorRed;

    &::placeholder {
      color: $colorRed;
    }

    & + .indicator {
      background-color: $colorRed;

      & + .icon {
        color: $colorRed;
      }
    }
  }

  .input:disabled {
    color: $colorGrey;

    & +.indicator {
      background-color: $colorGrey;

      & + .icon {
        color: $colorGrey;
      }
    }
  }

  .icon {
    border-left-style: solid;
    border-left-color: $colorGreyLight;
  }

  .tape {
    background: $colorWhite;
  }

  .tooltip {
    box-shadow: $boxShadow;
    background-color: $colorRed;
    color: $colorWhite;
  }
  
}

/* sizes */

.m {
  &.container {
    max-width: 320px;
    width: 100%;
  }

  .inputBox {
    border-radius: 8px;
  }

  .input {
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    padding: 17px 16px;
    height: 54px;
  
    font-weight: bold;
    font-size: 18px;
    line-height: 1;

    &.withIcon {
      padding-right: 70px;
    }

    &::placeholder {
      font-weight: 400;
    }
  }

  .icon {
    border-left-width: 1px;
    width: 54px;
  }

  .label {
    left: 3px;
    bottom: 15px;
    transform: translateY(0);

    padding: 0 14px;
    max-width: calc(100% - 40px);

    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;

    &.floated {
      left: 20px;
      bottom: 100% !important;
      transform: translateY(50%);      
    }
  }

  .tape {
    top: 0;
    left: 20px;
    max-width: calc(100% - 40px);

    padding: 0 14px;
    height: 2px;
  }

  .passwordTape{
    left: 28px;
  }

  .tooltip {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 24px 24px 0 24px;

    font-size: 14px;
    line-height: 16px;
  }
}

/* modifications */

.stretched {
  &.container {
    width: 100%;
    max-width: 100%;
  }
}


.smallInput {

  .inputBox {
    border-radius: 4px;

    .input {
      padding: 10px 14px; 
      height: 45px;
      font-size: 14px;
      border-color: #6a7480;
      border-width: 2px;
      border-radius: 4px;
    }

    .indicator{
      display: none;
    }

  }

  .tape {
    display: none;
  }
 

  .label {
    font-size: 12px;
    line-height: 14px;
  }

  .label.floated{
    left: 0;
    top: 3px;
    bottom: unset;
    padding: 0 14px;
    background: transparent;
    transform: none;
    display: none;
  }
  
}

.creditInput {
  max-width: max-content !important;

  .label, .tape {
    display: none;
  } 

  .input {
    width: max-content;
    padding: 10px 5px;
    font-size: 16px;
    height: unset;
    min-width: unset;
    border-color: #ffb571;
    text-align: center;
    font-weight: normal;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .indicator {
    display: none;
  }
}

.bottomChange {
  bottom: 11px !important;
}

.errorText{
  color: #ffff;
}