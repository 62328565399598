.container {
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); // Equivalent to "grid-cols-2"
  gap: 20px; // Equivalent to "gap-4"
  margin-bottom: 2rem; // Equivalent to "mb-8"

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr); // Equivalent to "md:grid-cols-4"
  }

  @media (min-width: 1024px) {
    gap: 30px; // Equivalent to "gap-4"
    grid-template-columns: repeat(4, 1fr); // Equivalent to "md:grid-cols-4"
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.characterSquare {
  width: 100%;
  aspect-ratio: 1 / 1; // Equivalent to "aspect-square"
  border-radius: 30px; // Equivalent to "rounded-3xl"
  background-color: #ffffdd; // Equivalent to "bg-yellow-50"
  border: 12px solid #ffde59; // Equivalent to "border-4 border-yellow-300"
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem; // Equivalent to "mb-2"
  transition: border-color 0.3s ease;
  cursor: pointer;

  .red {
    color: #ff666b; // Equivalent to "bg-red-500"
  }

  .redBox {
    background-color: #ff666b;
  }

  .green {
    color: #87ba48;
  }

  &:hover {
    border-color: #fcd34d; // Equivalent to "hover:border-yellow-400"
  }
}

.icon {
  width: 2rem; // Equivalent to "w-8"
  height: 2rem; // Equivalent to "h-8"
  color: #374151; // Equivalent to "text-gray-800"

  @media (min-width: 768px) {
    width: 4rem; // Equivalent to "w-8"
    height: 4rem;
  }
}

.characterLabel {
  padding: 0.8rem 1.5rem;
  background-color: #ffde59;
  border-radius: 9999px;
  text-align: center;
  font-weight: bold;
  width: 100%;
  font-size: 18px;
  height: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;

  @media (max-width: 767px) {
    min-height: 50px;
  }
}

.addCharacter {
  border-color: #ffc000;
}

.addCharacterLabel {
  background-color: #ffc000;
  width: 100%;
  padding: 0.8rem 1.5rem;
  border-radius: 9999px;
  text-align: center;
  font-weight: bold;
  width: 100%;
  font-size: 18px;

  cursor: pointer;
}

.grandstanderFamily {
  font-family: "Grandstander", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.addCharacterModalConatiner {
  padding: 80px 16px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  // overflow-y: scroll;
}

.addCharacterModalCard {
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  max-width: 980px;
  width: 100%;
  position: relative;
}

.iconCross {
  position: absolute;
  top: 25px;
  right: 25px;

  cursor: pointer;
}

.deleteModal {
  border: 1px solid;
  border-radius: 100%;
  background: transparent;
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  z-index: 9999;
}

.questionIcon {
  width: 25px;
  height: 25px;

  margin-left: auto;
}

.errorBox{
    background-color:#fee0e1;
}