@import "../../../styles/utilities/index.scss";

$boxShadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
$colorGreyLight: #828282;
$colorOrangeLight: #EE802F;
$gradient: linear-gradient(0deg, rgba(255, 138, 52, 0.05), rgba(255, 138, 52, 0.05));

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  
  outline: none;
  background-clip: border-box;
  text-align: center;

  .button {
    background-color: transparent;
    border: none;
    color: $colorOrange;
    .Icon {
      width: 8px;
      height: 8px;
    }
  }
}

/* colors */

.primary {
  border-style: solid;
  border-color: transparent;
  background-color: #fff;
  color: $colorOrange;

  
  @media (min-width: $m) {
    &:not(:disabled):hover {
      // box-shadow: $boxShadow;
      // background-color: $colorOrangeLight;
    }
  }

  &:focus {
    background-color: $colorOrangeDark;
  }

  &:disabled {
    background-color: #fff;
  }
}

.ghost {
  border-style: solid;
  border-color: $colorOrange;
  background-color: $colorWhite;
  color: $colorOrange;

  @media (min-width: $m) {
    &:not(:disabled):hover {
      border-color: $colorOrangeLight;
      box-shadow: $boxShadow;
    }
  }

  &:focus {
    border-color: $colorOrangeDark;
    background: $gradient, #FFFFFF;
  }

  &:disabled {
    border-color: $colorGreyLight;
    color: $colorGreyLight;
  }
}

/* sizes */

.xs {
  border-width: 1px;
  border-radius: 20px;
  padding: 3px 19px;
  min-height: 32px;

  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

.s {
  border-width: 1px;
  border-radius: 22px;
  padding: 8px 20px;
  min-height: 46px;

  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
}

.m {
  border-width: 1px;
  border-radius: 22px;
  padding: 5px 10px;
  // min-height: 52px;

  // font-weight: bold;
  font-size: 1rem;
  // line-height: 28px;
  letter-spacing: 0.2px;
}

/* adaptive sizes */

.asm {
  border-width: 1px;
  border-radius: 22px;
  padding: 8px 20px;
  min-height: 46px;

  font-weight: bold;
  font-size: 16px;
  line-height: 28px;

  @media (min-width: $xxxl) {
    border-width: 1px;
    border-radius: 22px;
    padding: 11px 32px;
    min-height: 52px;

    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
  }
}

/* modifications */

.stretched {
  width: 100%;
}
