@import "../../../styles/utilities/index.scss";

$boxShadow: 0px 0px 1px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.16);
$colorGreyLight: #c1bbbb;

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  min-height: 100vh;

  @media (min-width: $m) {
    padding: 60px 48px;
  }
}

.card {
  position: relative;
  border-radius: 20px;
  padding: 18px;
  width: 100%;
  max-width: 800px;

  background-color: $colorWhite;
  color: $colorBlueDark;
}

.title {
  margin: 0 auto;
  max-width: 600px;

  font-size: 28px;
  line-height: 32px;

  @media (max-width: $m) {
    font-size: 18px;
    line-height: 20px;
  }

  @media (max-width: 451px) {
    max-width: 230px;
  }
}

.text {
  margin: 24px 0 0;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: $m) {
    font-size: 16px;
    line-height: 18px;
    margin-top: 10px;
  }
}

.closeContainer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 999;
  background-color: #fff;
  border-radius: 100%;
  padding: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .card {
    border: none;
    padding: 0;
    width: 95dvw;
  }
}

@media (max-width: 1023px) {
  .closeContainer {
    top: 5px;
    right: 5px;
  }
}
