@import "../../../styles/utilities/index.scss";

.container {
  display: flex;

  // &.horizontal {}

  &.vertical {
    flex-direction: column;
    align-items: flex-end;
  }
}

.item {
  &.hidden {
    display: none;

    @media (min-width: $breakpointL) {
      display: block;
    }
  }

  .horizontal & {
    &:not(:first-of-type) {
      @include set-margin-left($size-06);

      @media (max-width: $breakpointL) {
        @include set-margin-left($size-06);
      }
    }
  }

  .vertical & {
    &:not(:first-of-type) {
      margin-top: $size-07;
    }
  }
}

.link {
  color: inherit;
  text-decoration: none;

  &.active {
    position: relative;
    color: $colorOrange;

    &:after {
      content: "";

      position: absolute;
      top: calc(100% + #{$size-01});
      @include set-left(0);

      display: block;
      border-radius: $size-01;
      width: 100%;
      height: $size-01;

      background-color: $colorOrange;
    }
  }

  &.disabled {
    color: $colorGreyNew;
    pointer-events: none;
  }
}

.home {
  .link {
    color: inherit;
    text-decoration: none;

    &.active {
      position: relative;
      color: #d69b00;

      &:after {
        content: "";

        position: absolute;
        top: calc(100% + #{$size-01});
        @include set-left(0);

        display: block;
        border-radius: $size-01;
        width: 100%;
        height: $size-01;

        background-color: #d69b00;
      }
    }

    &.disabled {
      color: $colorGreyNew;
      pointer-events: none;
    }
  }
}

.myMaor {
  .link {
    color: inherit;
    text-decoration: none;

    &.active {
      position: relative;
      color: #ff820e;

      &:after {
        content: "";

        position: absolute;
        top: calc(100% + #{$size-01});
        @include set-left(0);

        display: block;
        border-radius: $size-01;
        width: 100%;
        height: $size-01;

        background-color: #ff820e;
      }
    }

    &.disabled {
      color: $colorGreyNew;
      pointer-events: none;
    }
  }
}

.maorPartners {
  .link {
    color: inherit;
    text-decoration: none;

    &.active {
      position: relative;
      color: #d69b00;

      &:after {
        content: "";

        position: absolute;
        top: calc(100% + #{$size-01});
        @include set-left(0);

        display: block;
        border-radius: $size-01;
        width: 100%;
        height: $size-01;

        background-color: #d69b00;
      }
    }

    &.disabled {
      color: $colorGreyNew;
      pointer-events: none;
    }
  }
}

.drv {
  .link {
    color: inherit;
    text-decoration: none;

    &.active {
      position: relative;
      color: #602966;

      &:after {
        content: "";

        position: absolute;
        top: calc(100% + #{$size-01});
        @include set-left(0);

        display: block;
        border-radius: $size-01;
        width: 100%;
        height: $size-01;

        background-color: #602966;
      }
    }

    &.disabled {
      color: $colorGreyNew;
      pointer-events: none;
    }
  }
}

.illuminate {
  .link {
    color: inherit;
    text-decoration: none;

    &.active {
      position: relative;
      color: #c44751;

      &:after {
        content: "";

        position: absolute;
        top: calc(100% + #{$size-01});
        @include set-left(0);

        display: block;
        border-radius: $size-01;
        width: 100%;
        height: $size-01;

        background-color: #c44751;
      }
    }

    &.disabled {
      color: $colorGreyNew;
      pointer-events: none;
    }
  }
}

.referral {
  .link {
    color: inherit;
    text-decoration: none;

    &.active {
      position: relative;
      color: #ff820e;

      &:after {
        content: "";

        position: absolute;
        top: calc(100% + #{$size-01});
        @include set-left(0);

        display: block;
        border-radius: $size-01;
        width: 100%;
        height: $size-01;

        background-color: #ff820e;
      }
    }

    &.disabled {
      color: $colorGreyNew;
      pointer-events: none;
    }
  }
}

.purpleColor {
  color: #f21b54;
}

.maroonColor {
  color: #ac464d;
}

.contestColor{
  color:  #ffc000;
}

@media (max-width: 1480px) {
  .item {
    a {
      font-size: 18px !important;
    }
  }
}

@media (max-width: 1410px) {
  .item {
    .horizontal & {
      &:not(:first-of-type) {
        @include set-margin-left($size-05);
      }
    }

    a {
      font-size: 16px !important;
    }
  }
}
