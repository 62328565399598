@import "../../../styles/utilities/index.scss";

.container {
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

/* sizes */

.medium {
  $size: 40px;

  &.container {
    width: $size;
    height: $size;
  }
}
