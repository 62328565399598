@import "../../../../../styles/utilities/index.scss";

.container {
  padding-left: 24px;
  width: 100%;
  max-width: 380px;

  @media (max-width: 1080px) {
    max-width: 100%;
    padding-left: 0;
    margin-top:20px;
  }
}

.wrapper {
  min-height: 48px;
}

.message {
  line-height: 48px;
  font-size: 32px;

  text-align: center;
  color: $colorBlueDark;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
}

.errorMessage{
  font-size: 16px;
  color: red;
  line-height: 20px;
}
