@import "../../../styles/utilities/index";

.container {
  // overflow-y: auto;
  // overflow-x: hidden;

  [class*="slick-prev"],
  [class*="slick-next"] {
    z-index: 10;
    width: auto;
    height: auto;
    color: inherit;

    &:before {
      display: none;
    }
  }

  [class*="slick-prev"] {
    left: 0;
  }

  [class*="slick-next"] {
    right: 0;
  }

  [class*="slick-dots"] {
    bottom: auto;
    top: 100%;

    li {
      margin: 0 $size-01 !important;
      width: auto !important;
      height: auto !important;
    }
  }
}

.dot {
  $size: $size-03;

  border-radius: 50%;
  width: $size;
  height: $size;

  background-color: $colorGreyNew;

  [class*="slick-active"] & {
    background-color: $colorOrange;
  }
}
