@import "../../../../../../../styles/utilities/index.scss";

.creditText {
  font-size: 18px;
}
.creditWrap {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;

  .creditInnerWrap {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    @media (max-width: 460px) {
      flex-wrap: wrap;

      .availCredits {
        width: 100%;
        justify-content: center;
      }
    }

    .creditText {
      font-size: 16px;
    }

    .applyButton {
      border: none;
      border-radius: 22px;
      padding: 8px 20px;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.2px;
      background-color: #ff8a34;
      color: #ffffff;

      &:hover {
        box-shadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
        background-color: #ee802f;
      }
    }
  }

  .success,
  .error {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .success {
    color: green;
  }

  .error {
    color: red;
  }

  .paymentAmount {
    font-size: 20px;
    line-height: 24px;
  }
  .subTotal,
  .creditsUsed {
    font-size: 16px;
    line-height: 18px;
  }
}

.wrapper {
  margin: 0 auto;
  padding: 80px 16px;
  width: 100%;
  max-width: 720px;
  min-height: 100vh;
}

.card {
  border-radius: 26px;
  padding: 32px 32px 64px;

  background-color: $colorWhite;
  color: $colorBlueDark;
  position: relative;
}

.close {
  border: none;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.crossStyle {
  height: 20px;
  width: 20px;
  color: #3a3a3a;
  cursor: pointer;
  margin-bottom: -36px;
  margin-right: 10px;
}

.closeButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;

  button {
    outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    opacity: 0.8;
  }
}
