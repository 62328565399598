.collapseWrapper {
}

.collapseHeader {
  display: flex;
  align-items: center;
}

.collapseBody {
}

.collapseShown {
  display: block;
}

.collapseHidden {
  display: none;
}