@import "./variables/index";

* {
  box-sizing: border-box;
}


body {
  overflow-y: scroll;
  margin: 0;

  font-size: 14px;
  line-height: $size-05;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: $colorGreyDark;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0px !important;
}

body.audio-player-full {
  padding-bottom: 90px;
}

@media (max-width: "768px") {
  body.audio-player-full {
    padding-bottom: 160px;
  }
}

.color-red, .c-red {
  color: #f03131;
  font-size: 16px;
}

.CookieConsent {
  bottom: 90px!important;
  max-width: 400px;
  border-radius: 8px;
  left: 10px!important; 
  background-color: #7e7d7dad !important;
  #rcc-confirm-button {
    margin-top: 0px!important;
  }

  @media (max-width: "768px") {
    bottom: 96px!important;
    max-width: 325px;
  }

  @media (max-width: "345px") {
    max-width: 300px!important;
  }

  div {
    @media (max-width: "345px") {
      flex: 1 0 240px!important;
    }
  }
}


.rbt-input.focus {
  outline: none!important;
}

.rbt-menu {
  background: #fff;
  top: 0;
  padding: 10px 15px;
  border-radius: 12px;
  box-shadow: 1px 2px 3px 0px #3c40434d, 0px 2px 6px 2px #3c404326;
  z-index: 1;
}

.rbt-highlight-text {
  background-color: #FF8A34;
  color: #fff;
}

h1, h2, h3, h4, h5, h6, p, ul, ol {
  margin: 0;
  cursor: default;
}

ul, ol {
  list-style-type: none;
  padding: 0;
}

button, input, textarea {
  font-family: inherit;
}

button {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

a {
  color: inherit;
}
.testimonial-slider-wrap .slick-track {
  display: flex;
  align-items: center;
}

.dropdown-inner-wrap #react-select-1-listbox > div, 
.dropdown-inner-wrap #react-select-2-listbox > div,
.dropdown-inner-wrap #react-select-3-listbox > div,
.dropdown-inner-wrap #react-select-4-listbox > div,
.dropdown-inner-wrap #react-select-5-listbox > div,
.dropdown-inner-wrap #react-select-6-listbox > div,
.dropdown-inner-wrap #react-select-7-listbox > div,
.dropdown-inner-wrap #react-select-8-listbox > div,
.dropdown-inner-wrap #react-select-9-listbox > div,
.dropdown-inner-wrap #react-select-10-listbox > div,
.dropdown-inner-wrap #react-select-11-listbox > div,
.dropdown-inner-wrap #react-select-12-listbox > div,
.dropdown-inner-wrap #react-select-13-listbox > div,
.dropdown-inner-wrap #react-select-14-listbox > div,
.dropdown-inner-wrap #react-select-15-listbox > div {
  padding-bottom: 0;
} 
.dropdown-inner-wrap #react-select-1-listbox > div div:last-child(), 
.dropdown-inner-wrap #react-select-2-listbox > div div:last-child(), 
.dropdown-inner-wrap #react-select-3-listbox > div div:last-child(), 
.dropdown-inner-wrap #react-select-4-listbox > div div:last-child(), 
.dropdown-inner-wrap #react-select-5-listbox > div div:last-child(), 
.dropdown-inner-wrap #react-select-6-listbox > div div:last-child(), 
.dropdown-inner-wrap #react-select-7-listbox > div div:last-child(), 
.dropdown-inner-wrap #react-select-8-listbox > div div:last-child(), 
.dropdown-inner-wrap #react-select-9-listbox > div div:last-child(), 
.dropdown-inner-wrap #react-select-10-listbox > div div:last-child(), 
.dropdown-inner-wrap #react-select-11-listbox > div div:last-child(), 
.dropdown-inner-wrap #react-select-12-listbox > div div:last-child(), 
.dropdown-inner-wrap #react-select-13-listbox > div div:last-child(), 
.dropdown-inner-wrap #react-select-14-listbox > div div:last-child(), 
.dropdown-inner-wrap #react-select-15-listbox > div div:last-child() {
  text-decoration: underline;
  border-top: 1px dotted #b8b8b8;
  position: sticky;
  bottom: 0;
  background: #fff;
  width: calc(100% - 6px);
  margin-left: auto;
} 

.dropdown-inner-wrap > div{
  padding: 4px 5px;
}

@media (max-width: 540px) {
 .dropdown-inner-wrap > div{
   font-size: 12px;
 }
 .css-1hb7zxy-IndicatorsContainer > div{
    padding: 5px;
 }
}