@import "../../../../../styles/utilities/index.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// .checkbox {}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  min-height: 52px;
}

.newLink{
  font-size: 16px;
  color: $colorBlue;
  text-align: center;
}

.orangeColor{
  color: $colorOrange;
  text-decoration-color: $colorOrange ;
}

.hide{
  display: none;
}
