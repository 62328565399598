@import "../../../styles/utilities/index.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  svg {
    pointer-events: none;
    width: 100%;
  }
}

.withoutEvents {
  pointer-events: none;
}

.cursorPointer {
  cursor: pointer;
}

.icon {
  pointer-events: none;
}

.white {
  color: #fff;
}

.primary {
  color: $colorOrange;
}

.purple {
  color: $colorDarkPurple;
}

.black {
  color: $colorBlack;
}

/* sizes */

.xms {
  $size: 14px;

  &.container {
    width: $size;
    height: $size;

    svg {
      width: $size;
      height: $size;
    }
  }
}

.xxs {
  $size: 12px;

  &.container {
    width: $size;
    height: $size;

    svg {
      width: $size;
      height: $size;
    }
  }
}

.xs {
  $size: 16px;

  &.container {
    width: $size;
    height: $size;

    svg {
      width: $size;
      height: $size;
    }
  }
}

.s {
  $size: 20px;

  &.container {
    width: $size;
    height: $size;

    svg {
      width: $size;
      height: $size;
    }
  }
}

.m {
  $size: 24px;

  &.container {
    width: $size;
    height: $size;

    svg {
      width: $size;
      height: $size;
    }
  }
}

.l {
  $size: 36px;

  &.container {
    width: $size;
    height: $size;

    svg {
      width: $size;
      height: $size;
    }
  }
}

.xl {
  $size: 48px;

  &.container {
    width: $size;
    height: $size;

    svg {
      width: $size;
      height: $size;
    }
  }
}

.xxl {
  $size: 56px;

  &.container {
    width: $size;
    height: $size;

    svg {
      width: $size;
      height: $size;
    }
  }
}

.xxxl {
  $size: 85px;

  &.container {
    width: $size;
    height: $size;

    svg {
      width: $size;
      height: $size;
    }
  }
}

.stretched {
  width: 100%;
  height: auto;
}

.custom-popp-img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

/* adaptive sizes */

.axss {
  $size: 16px;

  &.container {
    width: $size;
    height: $size;
  }

  @media (min-width: $xxxl) {
    $size: 20px;

    &.container {
      width: $size;
      height: $size;
    }
  }
}

/* modifications */

.rotated {
  transform: rotate(180deg);
}

.orange{
  color: $colorOrange;
}

.brown {
  color: #7b4527;
}
