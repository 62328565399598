@import '../../../styles/utilities/index.scss';

$colorGreyLight: #c1bbbb;
$white: #fff;
$darkBlue: #27305a;

.container {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  position: relative;
  gap: 10px;
  

  &.vertical {
    flex-direction: column;
    align-items: flex-end;
  }

  @media (max-width: 500px) {
    // flex-wrap: wrap;
    justify-content: center;
    flex-direction: unset;
  }
}

.item {
}

.itemMobile {
  border: 1px solid $colorOrange;
  border-radius: 22px;
  width: max-content;
}

.link {
  
}
