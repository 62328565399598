@import "../variables/index";

/* Texts */

.text-basic {
  letter-spacing: 0;
}

/* Static */

.text-xs {
  @extend .text-basic;

  font-size: $size-03;
  line-height: $size-04;
}

.text-m {
  @extend .text-basic;

  font-size: 14px;
  line-height: $size-05;
}

.text-l {
  @extend .text-basic;

  font-size: $size-04;
  line-height: $size-06;
}

.text-xl {
  @extend .text-basic;

  font-size: $size-05;
  line-height: $size-07;
}

/* Adaptive (Dynamic) */

// .text-s-a {}

.text-m-a {
  @extend .text-m;

  // @media (min-width: $breakpointS) {}

  @media (min-width: $breakpointM) {
    font-size: $size-04;
    line-height: $size-06;
  }

  // @media (min-width: $breakpointL) {}

  // @media (min-width: $breakpointXL) {}

  // @media (min-width: $breakpointXXL) {}
}

.text-l-a {
  @extend .text-l;

  // @media (min-width: $breakpointS) {}

  @media (min-width: $breakpointM) {
    font-size: 18px;
    line-height: $size-07;
  }

  // @media (min-width: $breakpointL) {}

  // @media (min-width: $breakpointXL) {}

  // @media (min-width: $breakpointXXL) {}
}

.text-xl-a {
  @extend .text-xl;

  @media (min-width: $breakpointS) {
    font-size: $size-06;
    line-height: $size-08;
  }

  // @media (min-width: $breakpointM) {}

  // @media (min-width: $breakpointL) {}

  // @media (min-width: $breakpointXL) {}

  @media (min-width: $breakpointXXL) {
    font-size: $size-07;
    line-height: $size-09;
  }
}

/* Headings */

.heading-basic {
  margin: 0;
  letter-spacing: 0;
}

/* Static */

.heading-s {
  @extend .heading-basic;

  font-size: 18px;
  line-height: $size-05;
}

.heading-m {
  @extend .heading-basic;

  font-size: $size-05;
  line-height: $size-06;
}

.heading-l {
  @extend .heading-basic;

  font-size: $size-06;
  line-height: $size-07;
}

.heading-xl {
  @extend .heading-basic;

  font-size: $size-10;
  line-height: 44px;
}

/* Adaptive (Dynamic) */

.heading-s-a {
  @extend .heading-s;

  // @media (min-width: $breakpointS) {}
  
  @media (min-width: $breakpointL) {
    font-size: $size-05;
    line-height: $size-06;
  }

  // @media (min-width: $breakpointL) {}
  
  // @media (min-width: $breakpointXL) {}

  // @media (min-width: $breakpointXXL) {}
}

.heading-m-a {
  @extend .heading-m;

  // @media (min-width: $breakpointS) {}
  
  // @media (min-width: $breakpointM) {}

  @media (min-width: $breakpointL) {
    font-size: $size-06;
    line-height: $size-07;
  }
  
  // @media (min-width: $breakpointXL) {}

  @media (min-width: $breakpointXXL) {
    font-size: $size-07;
    line-height: $size-08;
  }
}

.heading-l-a {
  @extend .heading-l;

  @media (min-width: $breakpointS) {
    font-size: $size-07;
    line-height: $size-08;
  }

  // @media (min-width: $breakpointM) {}

  @media (min-width: $breakpointL) {
    font-size: $size-08;
    line-height: $size-09;
  }

  // @media (min-width: $breakpointXL) {}

  @media (min-width: $breakpointXXL) {
    font-size: $size-09;
    line-height: $size-10;
  }
}

.heading-xl-a {
  @extend .heading-xl;

  @media (min-width: $breakpointS) {
    font-size: $size-11;
    line-height: 52px;
  }

  // @media (min-width: $breakpointM) {}

  @media (min-width: $breakpointL) {
    font-size: 52px;
    line-height: 56px;
  }

  // @media (min-width: $breakpointXL) {}

  @media (min-width: $breakpointXXL) {
    font-size: 60px;
    line-height: $size-12;
  }
}
