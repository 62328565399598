.writingCompetitionForm {
}

.videoInput {
  display: none;
}

.bgContainer {
  background-color: #ffc000;
  background-image: url(../../../assets/images/start-background.png);
}

.maorCompetitionLogo {
  width: 450px;
  height: auto;
  margin: 0 auto;

  @media (max-width: 650px) {
    width: 350px;
  }
}

.maorCompetitionLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.writingCompetitionContainer {
  background-color: #fff;
  border-radius: 30px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  justify-content: center;
  align-items: center;

  margin-top: 40px;
}

.addWriters {
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addCharacters {
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.heading {
  font-size: 28px;
  line-height: 32px;
  text-align: center;

  span {
    font-family: "Roboto";
    font-size: 12px;
    margin-left: 20px;
    font-weight: 400;
  }
}

.grandstanderFamily {
  font-family: "Grandstander", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.note {
  font-family: "Roboto";
  font-size: 12px;
  margin-left: 20px;
  font-weight: 400;
  text-align: center;
  margin-top: 5px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem; // Equivalent to "gap-4"
}

.uploadButton {
  background-color: #77a23f;
  color: #fff;
  font-weight: bold;
  padding: 30px;
  border-radius: 40px;
  font-size: 20px;
  width: 100%;
  max-width: 25rem;
  transition: background-color 0.3s ease;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  min-width: 300px;

  &:hover {
    background-color: #8fb958;
  }

  .uploadIcon {
    padding: 15px;
    background-color: #fcd34d;
    display: flex;
    border-radius: 25px;

    img {
      width: 50px;
      height: 50px;
    }
  }

  @media (max-width: 650px) {
    padding: 20px;
    gap: 20px;

    .uploadIcon {
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.toichenOptions {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 40px;
  align-items: center;

  h4 {
  }

  .optionsContainer {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 650px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  gap: 45px;
  margin: 60px auto;
}

.uploadButtonText {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;

  p {
    font-size: 14px;
    font-weight: 600;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }

  @media (max-width: 650px) {
    h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.submitScriptButton {
  background-color: #ffc000;
  color: #000;
  font-weight: bold;
  padding: 25px;
  border-radius: 30px;
  font-size: 22px;
  width: 100%;
  max-width: 25rem;
  transition: background-color 0.3s ease;
  border: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  margin: 0 auto;

  &:hover {
    background-color: #fcd34d;
  }
}

.wFull {
  width: 100%;
}

.uploadedImagesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: center;

  margin-top: 30px;

  .uploadedImageWrapper {
    border: 1px solid #000;
    border-radius: 25px;
    position: relative;
  }

  img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 25px;
  }

  .removeImageButton {
    position: absolute;
    top: 10px;
    right: 10px;

    background: transparent;
    border: 1px solid #000;
    border-radius: 100%;
    padding: 5px;
  }
}

.submitError {
  text-align: center;
  margin: 15px 0px;
  color: red;
}

.formButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.uploadButton {
}

.redHeading {
  margin-top: 10px;
  color: #ea5455;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.mainContainer {
  width: 100%;
}

.hiddenInput {
  display: none;
}

.submitError {
  text-align: center;
  margin: 10px 0px;
  color: red;
}

.fileName {
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
  max-width: 365px;
}

.loader {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 30px auto 60px;
  gap: 20px;
  align-items: flex-start;
  max-width: 390px;
}

.borderRed {
  border: 5px solid #ea5455;
}

.lightRed {
  color: #ea5455;
  text-align: center;
  margin-bottom: 15px;
}
