.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// .checkbox {}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  min-height: 52px;
}

.hide{
  display: none;
}