.bgContainer {
  background-color: #ffc000;
  background-image: url(../../../assets/images/start-background.png);
}

.maorCompetitionLogo {
  width: 450px;
  height: auto;
  margin: 0 auto;

  @media (max-width: 650px) {
    width: 300px;
  }
}

.maorCompetitionLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  img{
    cursor: pointer;
  }
}

.writingCompetitionContainer {
  background-color: #fff;
  border-radius: 30px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  position: relative;

  @media (max-width: 650px) {
    padding: 40px 20px;
  }
}
