@import "../../../styles//utilities//index.scss";
// .container {}

// .header {}

.episodeTitle {
    color: #fff;
    margin-left: 20px;
    font-size: 1.2rem;
    float: right;
}

.logotype {
  display: flex;
  justify-content: center;
}

.main {
  margin-top: 28px;
  max-width: 1920px;
  margin: 0 auto;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (min-width: $m) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $xxl) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.item {
  min-width: 0;
}

.message {
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: $colorBlueDark;

  // &.empty {}

  &.error {
    color: $colorRed;
  }
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  min-height: 100vh;

  @media (min-width: $m) {
    padding: 60px 48px;
  }
}

.player {
  width: 100%;
  max-width: 992px;
}

.card {
  border-radius: 28px;
  padding: 48px 20px;
  width: 100%;
  max-width: 640px;

  text-align: center;
  background-color: $colorWhite;
  color: $colorBlueDark;
}

.title {
  margin: 0 auto;
  max-width: 440px;

  font-size: 28px;
  line-height: 32px;

  @media(max-width: $m) {
    font-size: 18px;
    line-height: 20px;
    max-width: 290px;
  }


}

.text {
  margin: 24px 0 0;

  font-size: 20px;
  line-height: 24px;

  @media(max-width: $m) {
    font-size: 16px;
    line-height: 18px;
    margin-top: 10px;
  }
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  font-size: 20px;
  line-height: 24px;
  
  gap: 10px;

  @media (max-width: $m) {
    margin-top: 16px;
    font-size: 16px;
    line-height: 18px;
  }

  @media (max-width: $s) {
    flex-direction: column;
  }

}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  min-height: 32px;
}

.button {
  border: none;
  padding: 0;

  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-decoration: underline;
  background-color: transparent;
  color: $colorOrange;
  cursor: pointer;
}

.autioTitle {
  color: #fff;
  font-size: 16px;
}

.link {
  color: $colorOrange;
  text-decoration: none;
  font-weight: bold;
}