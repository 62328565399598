@import "../../../styles/utilities/index.scss";

.back {
  position: absolute;
  top: $size-06; 
  left: $size-06;
  z-index: 10;

  @media (min-width: $m) {
    top: $size-11;
    left: $size-11;
  }

  @media (min-width: $m) {
    left: $size-12;
  }
}
