@import "../../../styles/utilities/index.scss";

.button {
  $size: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  padding: 0;
  width: $size;
  height: $size;

  background-color: transparent;
  color: inherit;

  cursor: pointer;
}

// .icon {}
