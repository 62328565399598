/* Roboto, 400 */

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

/* Roboto, 700 */

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "FrankfurterMedium";
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/FrankfurterMedium/Frankfurter-Medium-Std-Regular.otf")
    format("truetype");
}
