@import "../../../styles/utilities/index.scss";

h3 {
  font-size: 30px;
  line-height: 34px;
}

h4 {
  font-size: 20px !important;
}

h5{
    font-size: 15px !important;
}

p {
  font-size: 18px;
}

@media (max-width:768px){
  h3 {
    font-size: 24px;
  }
  
  h4 {
    font-size: 20px !important;
  }
  
  h5{
      font-size: 14px !important;
  }
  
 .headingContainer{
  p{
    font-size: 16px;
  }
 }
}
