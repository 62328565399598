@import '../../../styles/utilities/index.scss';

.container {
    width: 100%;
    background: #fff;
    padding: 0px 40px 80px;
    box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.25);
    max-width: 320px;
    transition: 0.7s ease;

    .sidebarSection {
        padding-top: 40px;

        .collapseHeader {
            margin-bottom: 30px;
        }

        .equalizerWrap{
            position: relative;
            margin-bottom: 10px;
            // border-radius: 100%;
            // box-shadow: 0px 7px 8px 2px rgba(0, 0, 0,0.4);

            .equalizer{
                width: 30px;
                height: 30px;
                background-color: #FF8A34;
                border-radius: 100%;
                display: flex;
                align-items: center;
                position: absolute;
                bottom: 26px;
                right: 4px;

                img{
                    width: 100%;
                }
            }
        }
        
    }

    @media (max-width: 920px) {
        left: -200%;
        position: relative;
        width: 0;
        height: 0;
        padding: 0px;
        overflow: hidden;

    }
}

.sidebarMobileWrap{
    display: none;

    @media (max-width: 920px) {
        display: inherit;
        flex-direction:column;
        padding: 60px 15px 10px;
        align-items: center;

        .sidebarIconsWrap{
            display: inherit;
            flex-direction:column;

            img{
                max-width: 30px;
                display: block;
                border-bottom: 3px solid #fcead4;
                margin-bottom: 20px;
                padding-bottom: 20px;

                &:last-child{
                    border:none;
                    padding-bottom:0;

                }
            }

        }
        
    }


    .sidebarSearchButton {
        margin-bottom: 40px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        
    }

    .sidebarOpenButton {
        margin-top: 20px;
        cursor: pointer;
        display: flex;
        justify-content: center;
    }

}


.openSidebar {
    position: relative;

    @media (max-width: 920px) {
        position: absolute;
        width: 230px;
        left: 0%;
        overflow-y: scroll;
        z-index: 9;
        padding: 0px 10px 80px;
        position: absolute;
        top: 0;
        bottom: 0;
        min-height: 100vh;
        height: 100%;
        overflow: visible;
    }
}

.sidebarCloseButton {
    display: none;

    @media (max-width: 768px) {
        position: absolute;
        right: 5px;
        display: block;
        top: 5px;
    }
}

.showMoreContainer {
    text-align: center;

    .button {
        border: none;
        padding: 0;
      
        font-size: 16px;
        line-height: 32px;
        text-decoration: underline;
        background-color: transparent;
        color: $colorOrange;
        cursor: pointer;
      }
}
.sidebarButton{
    border-radius: 12px;
    padding: 8px 10px;
    height: 40px;
    font-size: 16px;
    line-height: 18px;
    background-color: $colorOrange;
    color: $colorWhite;
    border: none;
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
    width: 100%;
    max-width: 90%;
    justify-content: space-evenly;
}
.myfeaturesButton{
    margin-top: 40px;
}
.audioContainer {

    .audioTitle {
      font-size: 16px;
    }
  
    .episodeIndex {
        line-height: 14px;

        small {
            font-size: 12px;
            line-height: 14px;
            font-weight: bold;
        }
    }
  }