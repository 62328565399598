@import "../../../styles/utilities/index.scss";

.slider {
  // padding-bottom: $size-09;

  @media (min-width: $breakpointS) {
    display: none;
  }
}

.showcase {
  display: none;

  @media (min-width: $breakpointS) {
    display: block;
  }
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0;
  grid-row-gap: $size-12;
  max-width: 344px;

  @media (min-width: $breakpointS) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 656px;
  }

  @media (min-width: 1180px) {
    grid-template-columns: repeat(4, 1fr);
    max-width: none;
  }
}

.birthdayNotice {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.fixed {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.packageWrap {
  max-width: 80% !important;
  // margin-bottom: 60px;
  position: relative;
  // border-image: linear-gradient(#ffa957, transparent) 1;
  // border-width: 4px;
  // border-style: solid;
  // border-bottom: none;
  // border-top-left-radius: 12px !important;
  // border-top-right-radius: 12px !important;

  .gradientBorder {
    background: linear-gradient(#ffa957, transparent) border-box;
    -webkit-mask: linear-gradient(#8c8c8c 0 0) padding-box,
      linear-gradient(#0d0d0d 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border-bottom: none;
    border: 4px solid transparent;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
  }

  @media (max-width: 460px) {
    max-width: 100% !important;
  }
}

.green {
  color: #007500;
}

.birthdayGradientBackgroud {
  background: linear-gradient(
    180deg,
    rgba(246, 246, 246, 0) 0%,
    #fcf4ee 11.65%
  );
}
