@import "../../../styles/utilities/index.scss";

.header {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: $size-06;
  grid-row-gap: $size-03;

  @media (min-width: $m) {
    grid-template-columns: 100px 1fr;
  }

  .message {
    text-align: center;

    @media (min-width: $m) {
      text-align: left;
    }
  }
}

.group {
  transition: opacity $timeS;

  &:not(:first-of-type) {
    margin-top: $size-08;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.boundary {
  border: none;
  border-top: 1px solid $colorGreyNew;
}

.list {
  display: grid;
  grid-column-gap: $size-06;
  grid-row-gap: $size-06;
}

.address {
  grid-template-areas:
    "street"
    "apartment"
    "city"
    "state"
    "zip"
    "country";
  grid-template-columns: 1fr;

  @media (min-width: $l) {
    grid-template-areas:
      "street street street"
      "apartment apartment apartment"
      "city state zip"
      "country country country";
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: $xxl) {
    grid-template-areas:
      "street street street apartment"
      "city state zip country";
    grid-template-columns: 1fr 1fr 1fr 320px;
  }

  .street {
    grid-area: street;
  }

  .apartment {
    grid-area: apartment;
  }

  .city {
    grid-area: city;
  }

  .state {
    grid-area: state;
  }

  .zip {
    grid-area: zip;
  }

  .country {
    grid-area: country;
  }
}

.personal {
  grid-template-areas:
    "firstName"
    "lastName"
    "email"
    "confirmEmail"
    "createAccount"
    "password"
    "confirmPassword";
  grid-template-columns: 1fr;

  @media (min-width: $l) {
    grid-template-areas:
      "firstName lastName"
      "email confirmEmail"
      "createAccount createAccount"
      "password confirmPassword";
    grid-template-columns: 1fr 1fr;
  }

  .firstName {
    grid-area: firstName;
  }

  .lastName {
    grid-area: lastName;
  }

  .email {
    grid-area: email;
  }

  .confirmEmail {
    grid-area: confirmEmail;
  }

  .createAccount {
    grid-area: createAccount;
  }

  .password {
    grid-area: password;
  }

  .confirmPassword {
    grid-area: confirmPassword;
  }
}

.logoutLink {
  margin-left: 10px;
  color: $colorOrange;
  font-weight: bold;
  text-decoration: none;
}

.error {
  color: $colorRed;
  margin: 5px 0 10px;
  display: block;
  font-weight: 600;
}

.anonymous {
  margin-bottom: 20px;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.textHeader {
  grid-area: header;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: $colorBlue;
  font-weight: 600;
}

 .loggedInText{
    color: #000;
 }

 .familyText{
  color: #2d83d5;
 }

 .logoutLinkText {
  color: #ff8a34;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.createAnAccount{
  color: #2d83d5;
}
