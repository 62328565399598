@import "../../../../../styles/utilities/index.scss";

.container {
  padding: 75px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 65px;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  color: $colorBlueDark;
}

.headingContainer > h3 {
  text-transform: uppercase;
}

.filedContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}

.profileCard {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 26px;
  text-align: center;
  max-width: 195px;
  width: 195px;
  position: relative;
  margin-top: 25px;
  h3 {
    color: $colorOrange;
    font-size: 20px;
    line-height: 22px;
    margin-top: 20px;
  }
  p {
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
  }
  img {
    object-fit: contain;
    width: 80px;
    position: absolute;
    top: -32px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 50px 0px;
    gap: 40px;
  }

  .profileCard {
    h3 {
      margin-top: 20px;
      font-size: 18px;
      line-height: 20px;
    }

    p {
      font-size: 14px;
    }
  }
}

@media (max-width: 450px) {
  .filedContainer {
    gap: 10px;
  }
  .profileCard {
    max-width: 160px;
    padding: 30px 10px;
  }
}

@media (max-width: 420px) {
  .filedContainer {
    gap: 8px;

    .profileCard {
      max-width: 140px;
      padding: 20px 5px;
    }
  }
}
