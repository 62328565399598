@import "../../../styles/utilities/index.scss";

.sidebarButton {
  border-radius: 12px;
  padding: 8px 10px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  background-color: #fa9b1f;
  color: $colorWhite;
  border: none;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 90%;
  justify-content: space-evenly;
  max-width: 200px;
  margin: 0px auto auto;

  @media (max-width: 1024px) {
      margin: 0px;
  }

  @media (max-width: 500px) {
    border-radius: 12px;
    padding: 0px 0px;
    height: 40px;
    font-size: 14px;
    max-width: 150px;
  }
}
