@import "../../../styles/utilities/index.scss";

$bgGradientWhite: linear-gradient(
    180deg,
    rgba(112, 194, 23, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  ),
  #ffffff;
$bgGradientBlue: linear-gradient(
  180deg,
  rgba(45, 131, 213, 0) 1.68%,
  #2d83d5 39.44%
);
$colorBlue: #2d83d5;
$colorOrange: #ff8a34;
$colorCrimson: #ee0979;
$colorGreyDark: #959595;
$colorLightBg: #fff9fd;

h2,
h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
}

.pinkColor {
  color: rgb(242, 27, 84);
}

.greenColor {
  color: rgb(10, 164, 164);
}

h2 {
  font-size: 36px;
  line-height: 40px;

  @media (max-width: 1080px) {
    font-size: 32px;
    line-height: 38px;
  }
  @media (max-width: 600px) {
    font-size: 26px;
    line-height: 30px;
  }
}

h3 {
  font-size: 28px;
  line-height: 32px;

  @media (max-width: 1080px) {
    font-size: 24px;
    line-height: 30px;
  }
}

.videoBox {
  width: 100%;
}

.loadingBox {
  display: flex;
  justify-content: center;
  height: 400px;
  width: 100%;

  .innerBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    h3 {
      color: $colorOrange;
    }
  }
}

.videoNotFoundContainer {
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.videoNotFound {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 45px;

  img {
    max-height: 200px;
    width: auto;
  }

  h2 {
    color: $colorOrange;
  }

  @media (max-width: 786px) {
    gap: 25px;
    img {
      max-height: 180px;
    }

    h2 {
      font-size: 24px;
    }
  }
}

.searchSec {
  // background: linear-gradient(180deg, rgba(69, 164, 229, 0.2) 0%, rgba(255, 251, 241, 0) 100%, rgba(69, 164, 229, 0.1) 100%);
  width: 100%;
  background-color: rgb(252, 244, 238);
  display: inline-block;
  padding: 0 0 80px;

  .searchTopSec {
    background-color: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 1344px;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    // gap: 10px;

    @media (max-width: 1270px) {
      gap: 0;
    }

    @media (max-width: 760px) {
      flex-wrap: wrap;
    }
    .searchItems {
      text-align: center;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      max-width: 9%;
      cursor: pointer;

      @media (max-width: 920px) {
        max-width: 10%;
      }

      @media (max-width: 760px) {
        max-width: 12%;
      }

      span {
        width: 100%;
        margin-top: 5px;
      }
    }

    .rightBorder {
      position: relative;

      &:after {
        content: "";
        height: 52px;
        width: 1px;
        border-right: 1.5px dashed #ffc58f;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .audioItems {
      span {
        color: rgb(185, 83, 83);
      }
    }
    .winnerItem {
      max-width: 57%;

      @media (max-width: 920px) {
        max-width: 20%;
      }

      .winnersListContainer {
        width: 100%;
      }
    }
    .searchBar {
      max-width: 25%;
      padding: 10px;

      @media (max-width: 1270px) {
        max-width: 25%;
      }
      @media (max-width: 920px) {
        max-width: 60%;
      }
      @media (max-width: 760px) {
        max-width: 100%;
        padding: 0;
        margin-top: 15px;
      }
    }
  }

  .sortingAndHolidaySection {
    display: flex;
    margin: 0 auto 20px;
    max-width: 700px;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
  }
  .videoContentMain {
    display: flex;
    align-items: center;
    max-width: 1374px;
    width: 100%;
    padding: 0 15px;
    // border-top: 1px solid #8A8A8A;
    padding-top: 30px;
    margin: 0 auto;
    gap: 50px;

    .showNotificationForPortrait {
      display: none;
    }

    @media (max-width: 1024px) {
      gap: 25px;
    }

    @media (max-width: 1024px) and (min-width: 769px) {
      align-items: flex-start;

      .hideNotificationForPortrait {
        display: none;
      }
      .showNotificationForPortrait {
        display: block;
      }

      .marginBottomForPortrait {
        margin-bottom: 80px;
      }

      .ContentWrapMain {
        p {
          font-size: 17px !important;
          line-height: 22px !important;
        }

        h3 {
          font-size: 22px !important;
          line-height: 28px !important;
        }

        .titleContainer {
          margin-bottom: 20px;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 0;
      flex-wrap: wrap;
      background-color: #fff;
      padding-bottom: 40px;
      gap: 10px;
      margin-top: 30px;

      .ContentWrapMain {
        width: 100%;
        padding: 0 15px;

        .videoHeader {
          display: flex;
          grid-gap: 10px;
          gap: 10px;
          justify-content: space-between;
          align-items: flex-start;

          h3 {
            font-size: 22px;
            line-height: 26px;
          }
        }

        .subtitle {
          text-align: left;
        }
        .boxedlayout:before {
          max-width: 90%;
          border-width: 2px;
        }
      }
    }

    // @media (max-width: 780px) {
    //   flex-wrap: wrap;
    //   flex-direction: column;
    // }

    .videoWrapMain {
      width: 100%;
      max-width: 804px;

      @media (max-width: 1200px) {
        max-width: 50%;
      }
      @media (max-width: 1024px) {
        max-width: 50%;
      }

      .videoBoxInner {
        position: relative;

        @media (max-width: 768px) {
          border-radius: 0;
          background-image: url(../../../assets//images/waves.png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100% 99%;
          padding: 50px 20px;
          background-color: #fff;
        }
      }
      .videoInfo {
        z-index: 9;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        .videoInfoText {
          background: #fff;
          color: #27305a;
          border-radius: 6px;
          line-height: 16px;
          font-size: 14px;
          padding: 4px 10px;
        }
        .videoInfoBtn {
          display: inherit;
          gap: 5px;

          button {
            background: #ffffff;
            border-radius: 12px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #ff8a34;
            border: none;
            padding: 8px 14px;
          }
        }
      }
      .videoTags {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        a {
          color: #2d83d5;
          font-size: 14px;
          line-height: 20px;
          text-decoration: none;
        }
      }

      @media (max-width: 768px) {
        max-width: 100%;

        .videoTags {
          width: 100%;
          overflow-x: auto;
          flex-wrap: nowrap;
          align-items: center;
          gap: 7px;
          margin-top: 5px;
          padding: 0 10px;

          a {
            font-size: 12px;
            min-width: max-content;
          }
        }
      }
    }

    .ContentWrapMain {
      flex-basis: 0;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .noWrap {
        text-wrap: nowrap;
      }

      h3 {
        font-size: 24px;
        line-height: 30px;
      }
      @media (max-width: 700px) {
        width: 100%;

        h3 {
          font-size: 20px;
          line-height: 26px;
        }
      }
      p {
        font-size: 18px;
        line-height: 24px;
        word-spacing: 2px;
      }
      .popupButton {
        border-style: solid;
        border-color: transparent;
        background-color: #ff8a34;
        color: #ffffff;
        border-width: 1px;
        border-radius: 22px;
        padding: 6px 15px;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.2px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;

        &:hover {
          box-shadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
          background-color: #ee802f;
        }
      }
      .boxedlayout {
        // background: #FFFFFF;
        // border: 1px solid #FF8A34;
        // border-radius: 16px;
        // padding:20px;
        margin-top: 25px;
        padding-top: 25px;
        position: relative;

        &:before {
          content: "";
          width: 100%;
          border-top: 2px dotted #ff8a34;
          position: absolute;
          top: 0;
          max-width: 320px;
          left: 50%;
          transform: translateX(-50%);
        }

        * {
          font-size: 16px;
          line-height: 24px;
        }

        .subtitle {
          font-size: 18px;

          @media (max-width: 1024px) {
            line-height: 20px;
          }
        }
      }

      .dedicationHeading {
        color: $colorNewPink;
        font-size: 18px;
        text-align: center;
      }

      .textContainer {
        display: flex;
        flex-direction: column;
        gap: 3px;
        margin-top: 14px;
        margin-bottom: 15px;
        text-align: center;

        p {
          font-size: 16px !important;
          line-height: 30px !important;
        }

        @media (max-width: 1024px) {
          align-self: center;
        }
        @media (max-width: 1024px) {
          strong {
            color: #046969;
          }
        }
        @media (max-width: 350px) {
          p {
            font-size: 14px !important;
          }
        }
        .bold {
          font-weight: bold;
        }
      }
    }
  }

  .mobileVideoContent {
    flex-wrap: wrap;
    background-color: #fff;
    padding-bottom: 40px;
    gap: 10px;
    margin-top: 30px;

    .videoWrapMain {
      max-width: 100%;

      .videoTags {
        width: 100%;
        overflow-x: auto;
        flex-wrap: nowrap;
        align-items: center;
        gap: 7px;
        margin-top: 5px;
        padding: 0 10px;

        a {
          font-size: 12px;
          min-width: max-content;
        }
      }
    }

    .ContentWrapMain {
      width: 100%;
      padding: 0 15px;

      .videoHeader {
        display: flex;
        grid-gap: 10px;
        gap: 10px;
        justify-content: space-between;
        align-items: flex-start;

        h3 {
          font-size: 22px;
          line-height: 26px;
        }
      }

      .subtitle {
        text-align: left;
      }
      .boxedlayout:before {
        max-width: 90%;
        border-width: 2px;
      }
    }
  }
}

.packagesSec {
  // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(101, 226, 0, 0.1) 13.54%, rgba(101, 226, 0, 0.1) 78.65%, rgba(255, 255, 255, 0) 100%);
  padding: 0 15px;
  background-color: #fcf4ee;
  // background-image: url(../../../assets/images/new-birthday-bg.png);
  padding-bottom: 80px;

  .pFont {
    font-size: 18px;
  }
  .centerContent {
    text-align: center;
    margin-top: 32px;
    padding: 0 15px;

    a {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 32px;
      color: #ff8a34;

      @media (max-width: 1200px) {
        font-size: 24px;
        line-height: 28px;
      }
      @media (max-width: 600px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}
.testimonialSec {
  text-align: center;
  background-color: rgb(252, 244, 238);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../../../assets/images/testimonial-sec-bg.png);

  .btnWrap button {
    margin: 0 auto;
  }
  .testimonialInner {
    display: flex;
    align-items: flex-start;
    max-width: 1625px;
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;
    gap: 70px;

    @media (max-width: 1300px) {
      gap: 30px;
    }
    @media (max-width: 767px) {
      gap: 0;
    }

    .testimonialImages {
      flex-direction: column;
      display: inherit;
      width: 100%;

      @media (max-width: 767px) {
        display: none;
      }

      img {
        border-radius: 100%;
      }
      .kid1Img {
        background: #f1e6d7;
        width: 200px;
        height: 200px;
        margin-left: auto;

        @media (max-width: 1280px) {
          width: 150px;
          height: 150px;
        }
      }
      .kid2Img {
        background: linear-gradient(145.64deg, #ffb800 9.67%, #ff3d00 107.49%);
        width: 182px;
        height: 182px;

        @media (max-width: 1280px) {
          width: 130px;
          height: 130px;
        }
      }
      .kid3Img {
        background: #ff8a34;
        width: 153px;
        height: 153px;

        @media (max-width: 1280px) {
          width: 120px;
          height: 120px;
        }
      }
      .kid4Img {
        background: #ff8a34;
        width: 183px;
        height: 183px;
        margin-left: auto;

        @media (max-width: 1280px) {
          width: 130px;
          height: 130px;
        }
      }
    }

    .sliderWrap {
      max-width: 460px;
      width: 100%;
      margin: 0 auto;

      // @media (max-width: 1080px) {
      //     width: 500px;
      //  	}

      // 		@media (max-width: 900px) {
      // 		    width: 380px;
      // 	  	}
      @media (max-width: 767px) {
        width: 100%;
      }

      border-radius: 12px;
      position: relative;

      // &:after{
      // 	content:"";
      // 	width: 474px;
      // 	height: 214px;
      // 	top: 60%;
      // 	z-index: -1;
      // 	position: absolute;
      // 	opacity: 0.2;
      // 	background-repeat: no-repeat;
      // 	background-position: center;
      // 	background-size: auto;
      // 	background-image: url(../../../assets/images/pinkDots.png);

      // 	@media (max-width: 1080px) {
      // 	    width: 374px;
      // 	    height: 194px;
      // 	    background-size: contain;
      //   	}
      //   	@media (max-width: 767px) {
      //   		display: none;
      //   	}
      // }

      .slideLayout {
        background: #ffffff;
        border-radius: 12px;
        padding: 20px 30px;
        // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 30px 40px rgba(0, 0, 0, 0.05);
        text-align: center;

        * {
          font-size: 16px;
          line-height: 24px;

          @media (max-width: 450px) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .slideAbout {
          text-align: left;
          margin-top: 20px;

          p {
            font-style: italic;
          }
        }
      }
    }
  }
}

.MoreVideosSec {
  // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(167, 70, 186, 0.1) 13.54%, rgba(167, 70, 186, 0.15) 78.65%, rgba(255, 255, 255, 0) 100%);
  position: relative;
  background-color: #fcece0;
  padding: 80px 10px 80px;
  overflow: hidden;

  @media (max-width: 500px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  .videosWrapper {
    z-index: 1;
    position: relative;
  }

  .videoSecImg {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    opacity: 0.3;
    align-items: center;
    z-index: 0;
    left: 0;
    right: 0;

    @media (max-width: 767px) {
      display: none;
    }

    .headphoneImg {
      position: absolute;
      left: -170px;
      top: 0;
    }
    .tvImg {
      position: absolute;
      right: -140px;
      top: 148px;
    }
  }

  .videosBottomImg {
    bottom: 0;
  }

  .centerContent {
    text-align: center;
  }

  .MoreVideosWrap {
    max-width: 1344px;
    width: 100%;
    margin: 20px auto 40px;
  }
}
.wrapper {
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 1096px;
  width: 100%;

  @media (min-width: $m) {
    padding-right: 48px;
    padding-left: 48px;
  }

  @media (min-width: $xxxl) {
    max-width: 1576px;
  }
}

.sectionTitle {
  position: relative;
  display: flex;
  align-items: flex-start;
  text-align: center;
  width: 100%;
  max-width: 960px;

  h2 {
    font-size: 28px;
    line-height: 30px;
  }

  h3 {
    font-weight: 400;
    font-size: 22px;

    @media (max-width: 660px) {
      font-size: 20px;
    }
  }

  h2 {
    @media (max-width: 680px) {
      font-size: 28px;
      line-height: 32px;
    }
  }
  .leftImg {
    transform: rotate(-8.67276deg);
  }
  .rightImg {
    transform: rotate(18.6356deg);
  }

  img {
    @media (max-width: 920px) {
      max-width: 110px;
    }

    @media (max-width: 660px) {
      max-width: 80px;
    }

    @media (max-width: 550px) {
      display: none;
    }
  }
}

.decoration {
  position: absolute;

  @media (max-width: 767px) {
    display: none;
  }
  svg {
    width: 100%;
    height: auto;
  }
}

.confetti {
  bottom: 4px;
  width: 32px;
  line-height: 1;

  &.right {
    left: calc(100% + 8px);
  }

  &.left {
    right: calc(100% + 8px);
  }

  @media (min-width: $m) {
    &.right {
      left: calc(100% + 40px);
    }

    &.left {
      right: calc(100% + 40px);
    }
  }

  @media (min-width: $l) {
    &.right {
      left: calc(100% + 12px);
    }

    &.left {
      right: calc(100% + 12px);
    }
  }

  @media (min-width: $xxxl) {
    bottom: 0;
    width: 44px;
  }
}

// Mobile screens

.mobileFooter {
  max-width: 480px;

  .mobileFooterWrap {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;

    .footerItems {
      text-align: center;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      span {
        width: 100%;
        margin-top: 5px;
      }
    }

    .audioItems {
      span {
        color: rgb(185, 83, 83);
      }
    }
  }
}
.mobileVideos {
  // max-width: 480px;
  gap: 15px;
  padding: 40px 0;

  .videoWrap {
    position: relative;

    img {
      width: 100%;
    }
    .pdfWrap,
    .playlistWrap {
      position: absolute;
      top: 10px;
      background-color: #fff;
      border-radius: 100%;
      padding: 4px;
    }
    .pdfWrap {
      left: 10px;
    }
    .playlistWrap {
      right: 10px;

      svg {
        color: #ff8a34;
      }
    }
  }

  .videoHeader {
    padding: 20px;
    display: flex;
    gap: 10px;
    align-items: flex-start;

    h1 {
      font-size: 18px;
      font-weight: 400;
    }

    svg {
      color: #000;
    }
  }
  .showMoreBtn {
    text-align: center;

    a {
      font-size: 18px;
      line-height: 20px;
    }
  }
}
.mobileHeader {
  display: flex;
  justify-content: space-between;
  max-width: 480px;
  gap: 15px;
  padding: 20px;
  border-bottom: 1px solid #8b949f;

  .mobilelogo {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .mobileIcons {
    display: flex;
    gap: 20px;
    align-items: center;

    svg {
      color: #ff8a34;
    }

    .usericon {
      border: 1px solid #ff8a34;
      border-radius: 100%;
      padding: 2px;
    }
  }
}
.mobileTags {
  padding: 20px;
  max-width: 480px;

  .tagsListWrap {
    display: flex;
    align-items: flex-start;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow-x: auto;
    gap: 10px;

    .tagsList {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;

      span {
        border-radius: 8px;
        text-align: left;
        cursor: pointer;
        padding: 8px 14px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        color: #27305a;
        transition: 0.5s ease;
        width: max-content;
      }
    }

    li.tagsList:nth-child(9n + 1) span {
      background-color: #c7dbff;
    }
    li.tagsList:nth-child(9n + 2) span {
      background-color: #dfd6e8;
    }
    li.tagsList:nth-child(9n + 3) span {
      background-color: #f2dce7;
    }
    li.tagsList:nth-child(9n + 4) span {
      background-color: #fbdcd5;
    }
    li.tagsList:nth-child(9n + 5) span {
      background-color: #fdecd9;
    }
    li.tagsList:nth-child(9n + 6) span {
      background-color: #fbfadd;
    }
    li.tagsList:nth-child(9n + 7) span {
      background-color: #e1f1de;
    }
    li.tagsList:nth-child(9n + 8) span {
      background-color: #cceae4;
    }
    li.tagsList:nth-child(9n + 9) span {
      background-color: #e1eef9;
    }
  }
}

/* MyMaorPackage */

.wrapper {
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 1096px;
  width: 100%;

  @media (min-width: $m) {
    padding-right: 48px;
    padding-left: 48px;
  }

  @media (min-width: $xxxl) {
    max-width: 1576px;
  }
}

.birthdayPackageContainer {
  @media (max-width: $m) {
    width: 100%;
  }
}

.packages {
  margin-top: 4px;
  // background: $bgGradientWhite;

  @media (min-width: $m) {
    background: none;
  }

  @media (min-width: $l) {
    margin-top: 8px;
  }

  .sectionTitle {
    position: relative;

    margin: 0;

    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    text-align: center;

    @media (min-width: $l) {
      font-size: 24px;
    }

    @media (min-width: $xxxl) {
      font-size: 30px;
      line-height: 42px;
    }
  }

  .list {
    position: relative;
    z-index: 0;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 128px;
    list-style-type: none;
    margin: 68px 0 0;
    padding: 0;

    @media (min-width: $m) {
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 0;
      margin-top: 112px;
      border-radius: 16px;

      background: $bgGradientWhite;
    }

    @media (min-width: $l) {
      border-radius: 20px;
    }

    @media (min-width: $xxl) {
      margin-top: 152px;
      border-radius: 26px;
    }

    @media (min-width: $xxxl) {
      margin-top: 208px;
    }
  }

  .item {
    position: relative;
    z-index: 0;

    display: flex;
    flex-direction: column;
    padding-top: 22px;
    padding-right: 8px;
    padding-bottom: 26px;
    padding-left: 8px;

    &:nth-of-type(2) {
      color: $colorWhite;
    }

    &:nth-of-type(3) {
      .title,
      .cost {
        color: $colorPurple;
      }
    }

    &:nth-of-type(4) {
      .title,
      .cost {
        color: $colorCrimson;
      }
    }

    &.marked {
      .tooltip {
        color: $colorWhite;
      }
    }

    @media (min-width: $m) {
      padding-top: 24px;
      padding-bottom: 20px;
    }

    @media (min-width: $l) {
      padding-top: 32px;
      padding-right: 24px;
      padding-bottom: 40px;
      padding-left: 24px;
    }

    @media (min-width: $xxl) {
      padding-top: 36px;
      padding-bottom: 28px;
    }

    @media (min-width: $xxxl) {
      padding-top: 68px;
      padding-right: 56px;
      padding-bottom: 20px;
      padding-left: 56px;
    }
  }

  .background {
    overflow: hidden;
    position: absolute;
    top: -30px;
    right: 0;
    bottom: -14px;
    left: 0;
    z-index: -10;

    display: block;
    border-radius: 16px;

    background: $bgGradientBlue;
    filter: drop-shadow(69.1415px 72.2458px 80px rgba(39, 48, 90, 0.07)),
      drop-shadow(35.0029px 36.5744px 34.875px rgba(39, 48, 90, 0.04725)),
      drop-shadow(13.8283px 14.4492px 13px rgba(39, 48, 90, 0.035)),
      drop-shadow(3.02494px 3.16075px 4.625px rgba(39, 48, 90, 0.02275));

    @media (min-width: $m) {
      bottom: -20px;
    }

    @media (min-width: $l) {
      border-radius: 20px;
    }

    @media (min-width: $xxl) {
      top: -40px;
      border-radius: 26px;
    }

    @media (min-width: $xxxl) {
      top: -44px;
      bottom: -30px;
    }
  }

  .tape {
    transform-origin: top left;
    transform: rotate(-45deg) translate(-50%, 70%);

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40%;
    height: 28px;

    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    background-color: #ff565e;
    color: $colorWhite;

    @media (min-width: $xxl) {
      transform: rotate(-45deg) translate(-50%, 120%);
      padding-right: 0;
      padding-left: 0;
    }

    @media (min-width: $xxxl) {
      height: 40px;

      font-size: 16px;
      line-height: 18px;
    }
  }

  .illustration {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 14px);
    z-index: 10;

    width: 64px;

    svg {
      width: 100%;
      height: auto;
    }

    @media (min-width: $m) {
      transform: translate(-50%, 16px);
    }

    @media (min-width: $l) {
      transform: translate(-50%, 24px);
      width: 88px;
    }

    @media (min-width: $xxl) {
      transform: translate(-50%, 28px);
      width: 100px;
    }

    @media (min-width: $xxxl) {
      transform: translate(-50%, 56px);
      width: 148px;
    }
  }

  .subitem {
    &:not(:first-of-type) {
      margin-top: 12px;
    }
  }

  .title {
    margin: 0;

    font-size: 16px;
    line-height: 20px;
    text-align: center;

    @media (min-width: $l) {
      line-height: 28px;
    }

    @media (min-width: $xxl) {
      line-height: 32px;
    }

    @media (min-width: $xxxl) {
      font-size: 30px;
      line-height: 48px;
    }
  }

  .sublist {
    list-style-type: none;
    margin: 8px 0;
    padding: 0 16px;

    @media (min-width: $l) {
      padding-right: 0;
      padding-left: 0;
    }

    @media (min-width: $xxl) {
      margin-bottom: 16px;
    }

    @media (min-width: $xxxl) {
      margin-top: 12px;
      margin-bottom: 20px;
    }
  }

  .subitem {
    display: flex;

    &:not(:first-of-type) {
      margin-top: 8px;
    }

    @media (min-width: $xxxl) {
      &:not(:first-of-type) {
        margin-top: 12px;
      }
    }
  }

  .icon {
    line-height: 1;

    &.right {
      margin-left: 8px;
    }

    @media (min-width: $xxl) {
      margin-top: 2px;
    }
  }

  .subtitle {
    margin-left: 8px;

    font-size: 12px;
    line-height: 20px;

    @media (min-width: $xxl) {
      font-size: 14px;
    }

    @media (min-width: $xxxl) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .tooltip {
    color: $colorGreyDark;
  }

  .footer {
    margin-top: auto;
  }

  .cost {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    @media (min-width: $l) {
      font-size: 20px;
      line-height: 24px;
    }

    @media (min-width: $xxl) {
      font-size: 24px;
      line-height: 32px;
    }

    @media (min-width: $xxxl) {
      font-size: 30px;
      line-height: 48px;
    }
  }

  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
  }
}

.features {
  margin-top: 80px;

  @media (max-width: 500px) {
    margin-top: 40px;
  }
  .sectionTitle {
    position: relative;
    margin: 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 42px;
    text-align: center;

    @media (min-width: $xxxl) {
      font-size: 30px;
    }
  }

  .list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 26px;
    grid-row-gap: 24px;
    list-style-type: none;
    // margin: 28px 0 0;
    justify-items: center;
    // padding: 0 20px;

    @media (min-width: $m) {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 26px;
      padding-right: 36px;
      padding-left: 36px;
    }

    @media (min-width: $l) {
      grid-template-columns: repeat(2, 1fr);
      padding-right: 0;
      padding-left: 0;
    }

    @media (min-width: $xxl) {
      grid-column-gap: 28px;
      grid-row-gap: 28px;
    }

    @media (min-width: $xxxl) {
      grid-column-gap: 40px;
      grid-row-gap: 40px;
      // margin-top: 40px;
    }
  }

  .item {
    border: 4px solid $colorOrange;
    border-radius: 12px;
    padding: 20px 24px;
    min-height: 236px;
    background-color: rgba(255, 255, 255, 0.7);
    background-size: auto;
    background-position: top left;
    background-repeat: no-repeat;
    max-width: 600px;

    &:nth-child(4n + 1) {
      background-position: top left;
    }
    &:nth-child(4n + 2) {
      background-position: bottom left;
    }
    &:nth-child(4n + 3) {
      background-position: bottom left;
    }
    &:nth-child(4n + 4) {
      background-position: top left;
    }
    @media (min-width: $xxl) {
      min-height: 254px;
    }

    @media (min-width: $xxxl) {
      padding-right: 36px;
      padding-left: 36px;
      min-height: 340px;
    }
  }

  .illustration {
    margin-right: -12px;
    margin-left: -12px;
    margin-bottom: 20px;

    svg {
      height: auto;
      margin: 0 auto;
      display: block;
    }

    @media (min-width: $xxxl) {
      margin-right: -24px;
      margin-left: -24px;
    }
  }

  .title {
    margin: 8px 0 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: $colorOrange;

    @media (min-width: $xxl) {
      font-size: 20px;
    }

    @media (min-width: $xxxl) {
      font-size: 22px;
    }
  }

  .text {
    margin: 8px 0 0;
    font-size: 14px;
    line-height: 20px;

    @media (min-width: $xxl) {
      font-size: 16px;
      line-height: 24px;
    }

    @media (min-width: $xxxl) {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.anchor {
  position: relative;

  display: flex;
  justify-content: center;
  margin-top: 88px;

  @media (min-width: $xxl) {
    margin-top: 104px;
  }

  @media (min-width: $xxxl) {
    margin-top: 136px;
  }
}

/* decorative elements */

.decoration {
  position: absolute;

  svg {
    width: 100%;
    height: auto;
  }
}

.ballon {
  top: 50%;
  width: 28px;
  line-height: 1;

  &.right {
    left: 100%;
    transform: translateY(-50%) scale(-1, 1);
  }

  &.left {
    right: 100%;
    transform: translateY(-50%);
  }

  @media (min-width: $m) {
    width: 36px;

    &.right {
      left: calc(100% + 12px);
    }

    &.left {
      right: calc(100% + 12px);
    }
  }

  @media (min-width: $xxxl) {
    width: 40px;
  }
}

.celebration {
  top: 50%;
  left: 50%;
  z-index: -10;
  transform: translate(-50%, -50%);

  width: 240px;

  @media (min-width: $xxl) {
    width: 312px;
  }

  @media (min-width: $xxxl) {
    width: 412px;
  }
}

.confetti {
  bottom: 4px;
  width: 32px;
  line-height: 1;

  &.right {
    left: calc(100% + 8px);
  }

  &.left {
    right: calc(100% + 8px);
  }

  @media (min-width: $m) {
    &.right {
      left: calc(100% + 40px);
    }

    &.left {
      right: calc(100% + 40px);
    }
  }

  @media (min-width: $l) {
    &.right {
      left: calc(100% + 12px);
    }

    &.left {
      right: calc(100% + 12px);
    }
  }

  @media (min-width: $xxxl) {
    bottom: 0;
    width: 44px;
  }
}

.pattern {
  z-index: -10;

  display: none;

  @media (min-width: $l) {
    display: block;
  }
}

.pattern.left {
  top: 132px;
  left: -124px;

  width: 160px;

  @media (min-width: $xxl) {
    top: 176px;
    left: -176px;
  }

  @media (min-width: $xxxl) {
    top: 216px;
    left: -96px;
  }
}

.pattern.right {
  top: 200px;
  right: -48px;

  width: 300px;

  @media (min-width: $xxl) {
    top: 240px;
    right: -208px;
  }

  @media (min-width: $xxxl) {
    top: 360px;
    right: -208px;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-weight: 600;
  text-align: center;

  .logoutLink {
    color: $colorOrange;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  .loggedInText {
    margin-right: 10px;
    color: $colorBlack;
  }

  .familyText {
    color: $colorBlue;
  }
}
.mobileSearch {
  padding-bottom: 0;
}
.mobileTestimonial {
  background-color: #ffead2;
  background-image: url(../../../assets/images/new-birthday-bg.webp);
}

.mobileVideos {
  background-color: #fff;

  .videosWrapper {
    padding: 0;

    .MoreVideosWrap {
      h3 {
        padding: 0 15px;
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
  }
}
.mobilePackagesSec {
  padding: 0;
  background-color: #fcf4ee;

  .sectionTitle {
    h2 {
      font-size: 22px;
      line-height: 26px;
      max-width: 250px;
      margin: 0 auto 20px;
    }

    h3 {
      font-size: 18px;
      line-height: 20px;
    }
  }
  .mobileWrapper {
    padding: 0;

    .list {
      margin-top: 10px;
      gap: 20px;

      .item {
        border: none;
        border-radius: 0;
        padding: 30px;

        .title {
          margin: 8px 0 10px;
          font-size: 20px;
        }

        .text {
          font-size: 16px;
        }
      }
    }
  }
}

.sliderContainer {
  position: relative;

  .arrowNavigation {
    display: flex;
    position: absolute;
    bottom: -40px;
    right: 4px;

    button {
      background-color: transparent;
      border: none;
    }
  }
}

.subscribeMailingAction {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.paddingMobileTestimonial {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.mainMobilePadding {
  padding-top: 40px !important;
  padding-bottom: 40px !important;

  @media (max-width: 786px) {
    padding-top: 0px !important;
  }
}
.paddingTestimonial {
  padding-top: 60px;
  padding-bottom: 80px;

  @media (max-width: 1600px) {
    padding-top: 0px;
  }
}

.myMaorTop {
  display: flex;
  max-width: 1374px;
  width: 100%;
  // padding: 30px 15px 0;
  margin: 0 auto;

  align-items: center;
  padding: 0 15px;
  padding-top: 30px;
  gap: 50px;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
}

.myMaorTitle {
  display: flex;
  flex-direction: row;
  // align-items: center;
  gap: 5px;
}

.dedicateVideo {
  display: flex;
  justify-content: center;
}

.wavyLine {
  --mask: radial-gradient(
        8.18px at 50% calc(100% + 5.5px),
        #0000 calc(99% - 1.5px),
        #000 calc(101% - 1.5px) 99%,
        #0000 101%
      )
      calc(50% - 10px) calc(50% - 3.25px + 0.5px) / 20px 6.5px repeat-x,
    radial-gradient(
        8.18px at 50% -5.5px,
        #0000 calc(99% - 1.5px),
        #000 calc(101% - 1.5px) 99%,
        #0000 101%
      )
      50% calc(50% + 3.25px) / 20px 6.5px repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  height: 25px;
  min-height: 25px;
  background: #fe7fa5;
  // max-width: 280px;
  // background: $colorNewPink;
}

.searchTop {
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  // margin-top: 15px;

  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: column;
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

.waveContainer {
  max-width: 260px;
  margin-top: -5px;
  width: 100%;
  margin: auto;
}

.dedicationText {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.videoListContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  gap: 50px;
  overflow: hidden;

  @media (max-width: 600px) {
    margin-bottom: 0px;
    gap: 40px;
  }
}

.videoDedicationSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.titleContainer {
  margin-bottom: 40px;
}

.videoButtons {
  display: none;
}

.searchMobile {
  display: none;
}

@media (max-width: 768px) {
  .videoDedicationSection {
    background-color: #faeadb;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 30px 0;
  }

  .textContainer {
    p {
      text-align: center;
    }
  }

  .titleContainer {
    margin-bottom: 0px;
  }

  .videoButtons {
    display: block;
  }

  .previewContainer {
    border-radius: 0;
  }

  .previewMobile {
    border-radius: 15px;
  }

  .mobilePackagesSec {
    background-color: #fff;
    background-image: none;
  }

  .searchSec {
    background-color: #fff;
  }

  .mobileHide {
    display: none;
  }

  .searchMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-wrap: wrap;
    gap: 10px;
    margin-top: 40px;
  }
}

.alignCenter {
  align-items: center !important;
}

.searchResultSection{
  border-bottom: 1px solid #f48622;
  padding-bottom: 40px;
}