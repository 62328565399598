@import "../../../styles/utilities/index.scss";

$boxShadow: 0px 0px 1px rgba(0, 0, 0, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.16);

.container {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.item {
  display: flex;
}

.input {
  display: none;

  &:checked + .icon:after {
    opacity: 1;
  }
}

.icon {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  cursor: pointer;

  &:after {
    content: "";
    display: block;
    border-radius: 50%;

    opacity: 0;
  }
}

.label {
  cursor: pointer;
}

/* sizes */

.medium {
  $size: 28px;

  .item {
    font-size: 16px;
    line-height: $size;
    letter-spacing: 0.2px;
  }

  .icon {
    border-width: 3px;
    width: $size;
    height: $size;

    &:after {
      $size: 12px;
      width: $size;
      height: $size;
    }
  }

  .label {
    margin-left: 8px;
    width: calc(100% - 36px);
    text-align: left;
  }
}

.medium.horizontal {
  .item {
    &:not(:first-of-type) {
      margin-left: 48px;
    }
  }
}

.medium.vertical {
  .item {
    &:not(:first-of-type) {
      margin-top: 16px;
    }
  }
}

/* colors */

.primary {
  .icon {
    border-color: #fabb17;
    border-style: solid;
    background-color: $colorWhite;

    &:after {
      background-color: #fabb17;
    }
  }
}

/* modifications */

.horizontal {
  flex-direction: row;
}

.orangeRadio {
  width: 90%;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 1054px) {
    width: 100%;
  }

  @media (max-width: 950px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: max-content;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
  .item {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 2px;

    @media (max-width: 520px) {
      font-size: 14px;
      line-height: 18px;
    }

    &:not(:first-of-type) {
      margin-top: 10px !important;

      @media (max-width: 450px) {
        margin-top: 5px !important;
      }
    }

    @media (max-width: 520px) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0;
    }

    .icon {
      border-color: #fff;
      background-color: #fabb17;
      width: 19px;
      height: 19px;

      &:after {
        background-color: #fff;
        width: 11px;
        height: 11px;
      }
    }
  }
}

.tooltip {
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 100%;
  max-width: 100%;
  text-align: center;
  box-shadow: $boxShadow;
  background-color: $colorRed;
  color: $colorWhite;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 24px 24px 0 24px;
  font-size: 14px;
  line-height: 16px;
}

.orangeRadioTheme {
  border: none;
  background-color: transparent;
  color: #636363;
  border-color: #c1bbbb;
  border-radius: 25px;
  align-items: center;
  // padding: 0px 10px;

  .icon {
    border-width: 2px;
    width: 18px;
    height: 18px;
    border-color: #c1bbbb;
    background-color: #fff5cd;
  }

  .label {
    font-size: 16px;
    width: auto;
  }

  .input:checked + .icon + .label {
    color: #000;
    font-weight: bold;
  }
  .input:checked + .icon {
    background-color: #fabb17;
    border-color: #fabb17;
  }
}

.orangeRadioBorder {
  border-color: #fabb17;
}

.errorText {
  color: #ffff;
}

.borderError {
  border-color: $colorRed !important;
}

.scriptFormContainer {
  flex-direction: row;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .item {
    margin: 0px !important;
  }

  .icon {
    border-width: 3px;
    border-radius: 8px;
  }

  @media (max-width: 650px) {
    justify-content: center;
  }
}
