@import "../../../styles/utilities/index.scss";

$boxShadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
$colorGreyLight: #828282;
$colorOrangeLight: #ee802f;
$gradient: linear-gradient(
  0deg,
  rgba(255, 138, 52, 0.05),
  rgba(255, 138, 52, 0.05)
);

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  outline: none;
  background-clip: border-box;
  text-align: center;
  cursor: pointer;
}

/* colors */

.primary {
  border-style: solid;
  border-color: transparent;
  background-color: $colorOrange;
  color: $colorWhite;

  @media (min-width: $m) {
    &:not(:disabled):hover {
      box-shadow: $boxShadow;
      background-color: $colorOrangeLight;
    }
  }

  &:focus {
    background-color: $colorOrangeDark;
  }

  &:disabled {
    background-color: $colorGreyLight;
  }
}

.purple {
  border-style: solid;
  border-color: transparent;
  background-color: #ac464d;
  color: $colorWhite;

  @media (min-width: $m) {
    &:not(:disabled):hover {
      box-shadow: $boxShadow;
      background-color: #c9515a;
    }
  }

  &:focus {
    background-color: #ac464d;
  }

  &:disabled {
    background-color: $colorGreyLight;
  }
}

.pink {
  border-style: solid;
  border-color: transparent;
  background-color: $colorNewPink;
  color: $colorWhite;

  @media (min-width: $m) {
    &:not(:disabled):hover {
      box-shadow: $boxShadow;
      background-color: $colorNewPink;
    }
  }

  &:focus {
    background-color: $colorNewPink;
  }

  &:disabled {
    background-color: $colorGreyLight;
  }
}

.ghost {
  border-style: solid;
  border-color: $colorOrange;
  background-color: $colorWhite;
  color: $colorOrange;

  @media (min-width: $m) {
    &:not(:disabled):hover {
      border-color: $colorOrangeLight;
      box-shadow: $boxShadow;
    }
  }

  &:focus {
    border-color: $colorOrangeDark;
    background: $gradient, #ffffff;
  }

  &:disabled {
    border-color: $colorGreyLight;
    color: $colorGreyLight;
  }
}

.birthdayButtonMarked {
  border: none;
  background-color: $colorWhite;
  color: $colorOrange;
  width: 100%;

  @media (min-width: $m) {
    &:not(:disabled):hover {
      box-shadow: $boxShadow;
    }
  }

  &:focus {
    background: $gradient, #ffffff;
  }

  &:disabled {
    color: $colorGreyLight;
  }
}

.birthdayButton {
  border: none;
  background-color: $colorOrange;
  color: $colorWhite;
  width: 100%;

  @media (min-width: $m) {
    &:not(:disabled):hover {
      box-shadow: $boxShadow;
    }
  }

  &:focus {
    background: $gradient, #ffffff;
  }

  &:disabled {
    color: $colorGreyLight;
  }
}

/* sizes */

.xs {
  border-width: 1px;
  border-radius: 20px;
  padding: 3px 19px;
  min-height: 32px;

  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

.s {
  border-width: 1px;
  border-radius: 22px;
  padding: 8px 20px;
  min-height: 46px;

  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
}

.m {
  border-width: 1px;
  border-radius: 22px;
  padding: 11px 32px;
  min-height: 52px;

  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.2px;

  @media (max-width: $m) {
    font-size: 16px;
    line-height: 20px;
    padding: 7px 18px;
    min-height: 32px;
  }
}

.xl {
  border-width: 1px;
  border-radius: 25px;
  padding: 11px 32px;
  min-height: 70px;

  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.2px;

  @media (max-width: $m) {
    border-radius: 20px;
    font-size: 16px;
    line-height: 20px;
    padding: 11px 32px;
    min-height: 60px;
  }
}

/* adaptive sizes */

.asm {
  border-width: 1px;
  border-radius: 22px;
  padding: 8px 20px;
  min-height: 46px;

  font-weight: bold;
  font-size: 16px;
  line-height: 28px;

  @media (min-width: $xxxl) {
    border-width: 1px;
    border-radius: 22px;
    padding: 11px 32px;
    min-height: 52px;

    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
  }
}

/* modifications */

.stretched {
  width: 100%;
  min-height: 40px;
}

.borderRadius {
  border-radius: 8px;
  font-size: 18px;
}

.borderRadius30 {
  border-radius: 30px;
}

.grayColor {
  border-style: solid;
  border-color: transparent;
  background-color: $colorGreyLight;
  color: $colorWhite;

  @media (min-width: $m) {
    &:not(:disabled):hover {
      box-shadow: $boxShadow;
      background-color: $colorGreyLight;
    }
  }

  &:focus {
    background-color: $colorGreyLight;
  }

  &:disabled {
    background-color: $colorGreyLight;
  }
}

.yellow {
  $color: #fcab28;
  border-style: solid;
  border-color: transparent;
  background-color: $color;
  color: $colorWhite;

  &:focus {
    background-color: $color;
  }

  &:disabled {
    background-color: $color;
  }
}

.brown {
  $color: #be7e4e;
  border-style: solid;
  border-color: transparent;
  background-color: $color;
  color: $colorWhite;

  &:focus {
    background-color: $color;
  }

  &:disabled {
    background-color: $color;
  }
}

.darkOrange {
  $color: #e48f01;
  border-style: solid;
  border-color: transparent;
  background-color: $color;
  color: $colorWhite;

  &:focus {
    background-color: $color;
  }

  &:disabled {
    background-color: $color;
  }
}


.yellowGradient {
  $color: #e3b048;
  border-style: solid;
  border-color: transparent;
  background-color: $color;
  color: #59381f;
  background: radial-gradient(circle at 0% 0%, #dba033, #e3b048, #e3b048, #e8c96b, #e3b048, #dba033);


  &:focus {
    background-color: $color;
  }

  &:disabled {
    background-color: $color;
  }
}
