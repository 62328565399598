@import "../../../../../../../styles/utilities/index.scss";

$backgroundGradientOrange: linear-gradient(
    57.38deg,
    #ffffff -6.94%,
    rgba(255, 255, 255, 0) 43.98%
  ),
  linear-gradient(168.7deg, #ffffff -10.99%, rgba(255, 255, 255, 0) 44.7%),
  linear-gradient(
    97.47deg,
    rgba(255, 162, 52, 0.5) -37.82%,
    rgba(255, 255, 255, 0) 101.55%
  ),
  #ffffff;
$colorGrey: #959595;

.container {
  color: $colorBlueDark;
  display: flex;
  width: 100%;
  gap: 20px;
  padding-right: 24px;
  // align-items: center;

  @media (max-width: 1080px) {
    padding-right: 0;
  }

  @media (max-width: 500px) {
    gap: 10px;
  }

  @media (max-width: 460px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  .main {
    background: linear-gradient(transparent 50%, rgba(255, 162, 52, 0.1));
    padding: 20px 25px 15px;
    border-radius: 12px;
    position: relative;
    min-width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;

    @media (max-width: 600px) {
      min-width: 160px;
      padding: 20px 15px 15px;
    }

    @media (max-width: 500px) {
      min-width: max-content;
    }

    @media (max-width: 460px) {
      margin-bottom: 15px;
    }

    &:after {
      position: absolute;
      content: "Selected Package:";
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 11px;
      line-height: 13px;
      color: #000;
      background-color: #fff;
      width: max-content;
      padding: 0 5px;
    }

    .gradientBorder {
      background: linear-gradient(#ffa957, rgba(255, 169, 87, 0.4)) border-box;
      -webkit-mask: linear-gradient(#8c8c8c 0 0) padding-box,
        linear-gradient(#0d0d0d 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      border: 3.2px solid transparent;
      border-radius: 6px;
      position: absolute;
      width: 99.8%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
    }
  }
}

// .grid {
//   display: grid;
//   grid-template-areas:
//     "main options"
//     "details details"
//   ;
//   grid-template-columns: 1fr 36px;
//   grid-template-rows: auto auto;
//   grid-column-gap: 24px;
//   grid-row-gap: 24px;
//   padding: 16px 20px 22px;

//   @media (min-width: $m) {
//     grid-template-areas:
//       "main details options"
//     ;
//     grid-template-columns: 171px 1fr 36px;
//     grid-template-rows: auto;
//   }
// }

.main {
  grid-area: main;
}

.details {
  display: inline-flex;
  align-items: center;
  width: 100%;

  @media (max-width: 460px) {
    width: calc(100% - 30px);
  }

  .userName {
    text-transform: capitalize;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
  }

  .fullName{
    display: flex;
    gap: 5px;
  }
}

.options {
  grid-area: options;
}

.package {
  display: flex;
  flex-direction: column;
  align-items: center;
  // border-right: 1px solid $colorGrey;
}

.illustration {
  max-width: 100px;
  width: 100%;
  line-height: 1;

  @media (max-width: 500px) {
    max-width: 70px;
  }

  svg {
    width: inherit;

    @media (max-width: 500px) {
      height: unset;
    }
  }
}

.title {
  margin: 8px 0 0;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-column-gap: 24px;
  grid-row-gap: 12px;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (min-width: $xxl) {
    grid-template-columns: 1fr;
  }
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 20px;

  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 16px;
  }

  .icon {
    flex-shrink: 0;
    margin-right: 15px;
    min-width: 20px;

    color: $colorOrange;
  }

  // .userIcon{
  //   border:1px solid $colorOrange;
  //   border-radius: 100%;
  // }
}

.buttons {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
}

.changeButton {
  font-weight: 400;
  color: #0d0d0d;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 20px;
}

.videoSelectModalWrapper {
  margin: 0 auto;
  padding: 80px 16px;
  width: 100%;
  max-width: 612px;
  min-height: 100vh;
}

.videoModalCard {
  border-radius: 26px;
  padding: 32px 32px 64px;
  background-color: #ffffff;
  color: #27305a;
}

.closeButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.close {
  outline: none;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.saveButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.videoDateMain {
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  margin-top: 8px;
}

.videoDateMain {
  h1 {
    line-height: 30px;
  }
  p {
    margin-top: 16px;
  }
}

@media (max-width: 650px) {
  .videoDateMain {
    h1 {
      font-size: 24px;
    }
    p {
      margin-top: 12px;
    }
  }
}

.videoLabel {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  margin-left: 15px;
  margin-bottom: 5px;
  color: #ffa957;
  margin-top: 20px;
}
