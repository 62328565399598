@import "../../../styles/utilities/index.scss";

$colorGreyLight: rgba(196, 196, 196, 0.5);

// .container {}

.wrapper {
	padding-top: 20px;
	padding-bottom: 60px;
	background-image: linear-gradient(
		to right,
		#fcead4,
		#ffece4,
		#fff2f4,
		#fff9fd,
		#ffffff
	);

	@media (min-width: $m) {
		padding-bottom: 196px;
	}

	@media (max-width: $m) {
		background: rgba(252, 234, 212, 1);
		padding-top: 10px;
	}
}

.mainWrapper {
	display: flex;

	.libraryWrapper {
		@media (min-width: $xxxl) {
			max-width: 100%;
		}

		.searchBarWrap {
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			justify-content: center;
			
			@media (max-width: 920px) {
				display: none;
			}
			
			.winnersListContainer,
			.partnersListContainer {
				width: 100%;
				max-width: 35%;
				display: inherit;
			}
			.winnersListContainer {
				max-width: 100%;
				border-top-left-radius: 25px;
				border-bottom-left-radius: 25px;

				@media (max-width: 920px) {
					display: none;
				}	
			}

			.partnersListContainer {
				background-color: #fde9cb;
				border-top-right-radius: 25px;
				border-bottom-right-radius: 25px;
			}

			.filterWrapper {
				width: 100%;
				max-width: 100%;
				padding: 20px 10px;
				display:flex;
				justify-content: center;
				gap: 50px;

				@media (max-width: 1350px) {
					flex-wrap:wrap;
					gap:20px;
				}
				
			}

			.openFilterWrap {
				background-color: transparent!important;
			}
		}

		.openFilterWrap {
			display: inherit;

			.partnersListContainer {
				display: none;
			}

			.winnersListContainer {
				display: none;
			}
		}
	}
}

.chooseProjectWrapper {
	flex-direction: column;
}

.mobileWrapper {
	background-color: #fff;
	padding: 0px;
}

.section {
	border-top: 2px solid $colorGreyLight;
	padding-top: 14px;
	margin-top: 20px;
	
	@media (max-width: $m) {
		border-top: none;
		padding-top: 0px;
	}
	@media (max-width: 500px) {
		margin-top: 0px;
	}
}

.openSidebarWrap {
	// Add overlay
	cursor: pointer;
	position: relative;

	@media (max-width:920px) {
		&:before {
			content: "";
			background: #000000c9;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 5;
			min-height: 100vh;
			height: 100%;
		}
	}
}

.searchInfo {
	max-width: 590px;
	margin: 0 auto;
	font-size: 1rem;
}

.goToSection {
	width: 100%;
	max-width: 590px;
	margin: 0 auto 60px;
	text-align: center;
	font-size: 1rem;

	.goToLabel {
		font-weight: bold;
	}

	.goToItem {
		margin: 5px 10px;
		border: none;
		background: none;

		border: none;
		padding: 0;
		text-decoration: underline;
		background-color: transparent;
		color: #ff8a34;
		cursor: pointer;
	}
}

.sortingAndHolidaySection {
	display: flex;
	margin: 0 0 20px;
	max-width: 768px;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;

	.sortingWrapper {
		display: flex;
		align-items: center;
		gap: 10px;

		@media (max-width: 1470px) {
			flex-wrap: wrap;
			justify-content: center;
		}
	}
}

.clearFilterSection {
	margin: 20px 0px;
	display: flex;
	align-items:center;
	gap:10px;
	justify-content:center;

	.mobileFilters{
		overflow-x: auto;
		width: 100%;
		display: flex;
		align-items: center;
		gap: 10px;
		padding:0 10px;
	}
	.playlistBtn{
		background-color: #FF8A34;
		border: none;
		padding: 9px 10px;
		border-radius: 15px;
		color: #fff;
		line-height: 16px;
		text-decoration: none;
		width: max-content;
	}
}

.filterWrapper {
	@media (max-width: $m) {
		display: none;
	}
}

.openFilterWrap {
	display: block;
}
