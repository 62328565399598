@import "../../../styles/utilities/index";
.header {
  margin-bottom: 10px;

  .projectTitle {
    font-size: 1rem;
    letter-spacing: 1px;
    font-weight: bold;
    color: $colorOrange;
  }
}
.submitAnswer {
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  &:hover {
    color: #000;
  }
}
.logotype {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 14px;

  .projectTitle {
    font-size: 1.13rem;
  }

  .totalVideosCount {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
  }

  a {
    cursor: default;
  }

  .logotypeWrapper {
    a {
      background-color: $colorWhite;
      border-radius: 50%;
      width: 64px;
    }
  }
}

.main {
  margin-top: 28px;
  max-width: 1920px;
  margin: 0 auto;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (min-width: $m) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $xxl) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.item {
  min-width: 0;
}

.link {
  color: $colorOrange;
  text-decoration: none;
  font-weight: bold;
}

.message {
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: $colorBlueDark;

  // &.empty {}
  @media (max-width: $m) {
    font-size: 1.5rem;
  }

  &.error {
    color: $colorRed;
  }
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  min-height: 100vh;

  @media (min-width: $m) {
    padding: 60px 48px;
  }
}
.myMaorVideo {
  padding: 0;
  min-height: unset;

  .dropdownContainer {
    margin: 0 auto 40px auto;
  }

  .questionOptions button {
    margin: 40px auto 0;
  }

  .questionOptionsGuest button {
    margin: 40px auto 0;
  }

  .questionInner {
    h3 {
      margin-bottom: 30px;
    }
  }
  .mobileQuestions h3 {
    margin-bottom: 10px;
  }
  .player {
    background-color: transparent;
    max-width: unset;
    padding: 0;
    border-radius: 0;

    .playerboxWrapper {
      border: none;

      .playerWrapper {
        width: 100%;
        margin-bottom: 0;
        padding: 0;
      }
    }
  }

  .whiteBg {
    min-height: 450px;
  }
}
.mobileoverlay {
  padding: 0;
  min-height: unset;

  // max-height: 100vh;
  // overflow: scroll;
  // padding: 200px 0px 10px;
  // min-height: unset;

  .playlistCountInner {
    padding: 10px 10px;
  }

  .playlistCount {
    span {
      padding: 8px 32px;
      height: 35px;
      font-size: 14px;
      line-height: 24px;
    }
  }

  @media (min-width: $m) {
    padding: 0;
  }

  .answerSubmitted {
    .answerHeader {
      img {
        max-width: 40px;
      }
    }

    p {
      font-size: 16px;
      line-height: 20px;
      margin: 10px auto 0;

      a {
        font-size: 14px;
      }
    }
  }

  .answerSubmittedGuest {
    .answerHeaderGuest {
      img {
        max-width: 40px;
      }
    }

    p {
      font-size: 16px;
      line-height: 20px;
      margin: 10px auto 0;

      a {
        font-size: 14px;
      }
    }
  }

  .whiteBg {
    border-radius: 0;
  }

  .player {
    border-radius: 0;
    padding: 0;
    // margin-bottom: 50px;

    overflow: scroll;
  }

  // .player::before {
  //   content: " ";
  //   position: absolute;
  //   background-image: url(../../../assets/images/wave-main-up.png);
  //   background-position: top;
  //   background-repeat: no-repeat;
  //   width: 100%;
  //   background-size: 102%;
  //   top: -16px;
  //   height: 28px;
  // }

  // .player::after {
  //   content: " ";
  //   position: absolute;
  //   background-image: url(../../../assets/images/wave-main-down.png);
  //   background-position: top;
  //   background-repeat: no-repeat;
  //   width: 100%;
  //   background-size: 102%;
  //   bottom: -27px;
  //   height: 28px;
  // }

  .playerboxWrapper {
    border: none;
    flex-wrap: wrap;
    height: 100%;

    .playlistBox {
      margin: 0px 20px;
    }

    .playerWrapper,
    .playlistBox {
      width: 100%;
    }
    .playerWrapper {
      padding: 50px 20px 0px;
      margin-bottom: 0px;
    }
    .vimeoPlayer {
      border-radius: 25px;
      margin-bottom: 10px;
    }

    .videoTags {
      padding: 0 10px;
    }
    .VideoHeading {
      text-align: left;
      padding: 0 10px;

      .VideoHeadingWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        @media (max-width: 480px) {
          h3 {
            font-size: 18px;
          }

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .skipDedicationWrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;

      .skipDedicationInner {
        width: calc(100% - 135px);

        @media (max-width: 480px) {
          font-size: 12px;
          width: calc(100% - 105px);
        }
        @media (max-width: 360px) {
          width: calc(100% - 85px);
        }

        .clearBtn {
          background: #636363;
          padding: 5px 10px;
          font-size: 12px;
          color: #fff;
          border-radius: 20px;
          text-decoration: none;
          line-height: 1;
          display: flex;
          align-items: center;
          border: 0px;
        }

        .skipDedication {
          justify-content: start;
          flex-direction: row-reverse;
          gap: 8px;
          max-width: 118px;
          margin-bottom: 0;
          margin-left: 0;
        }
      }

      p {
        font-size: 18px;
        width: 135px;

        @media (max-width: 480px) {
          font-size: 14px;
          width: 105px;
        }
        @media (max-width: 360px) {
          font-size: 12px;
          width: 85px;
        }
      }
    }
  }
  .playlistBox {
    background-color: transparent;

    .playlistBoxInner,
    .playlistBoxFooter {
      border: none;

      &:after {
        background-color: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background: transparent;
      }
    }
    .playlistBoxInner {
      height: 68%;
      background-color: #f9e7d6;

      @media (max-width: 1024px) {
        // height: 100vh;
        height: 100%;
        background-color: white;
      }

      .playlistItems {
        max-height: 100%;
      }

      // padding-bottom: 40px;
      // border-bottom-left-radius: 30px;
      // border-bottom-right-radius: 30px;
      // .playlistItems{
      // 	// padding-bottom: 140px;
      // }
    }
    .playlistBoxFooter {
      // position: fixed;
      // bottom: 95px;
      // width: 90%;
      // border-radius: 30px;

      position: relative;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
    }
    .playlistFooter {
      border-radius: 30px;
    }
  }
}
.player {
  width: 100%;
  background-color: #fff;
  max-width: 1200px;
  padding: 40px;
  border-radius: 30px;
  position: relative;

  @media (max-width: 950px) {
    max-width: 540px;
    overflow: scroll;
  }
}
.mobilePlayer {
  max-width: 100%;
}
// .questionbg{
// 	background-image: url(../../../../src/assets/images/Question-bg.png);
// 	background-repeat: no-repeat;
// 	background-position: center;
// 	background-size: cover;
// }
.whiteBg {
  min-height: 328px;
  background: #ffffff;
  border-radius: 12px;
  padding: 25px 85px;
  margin-bottom: 5px;
  background-image: url(../../../../src/assets/images/questions-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1150px) {
    padding: 40px 55px;
  }
  @media (max-width: 1054px) {
    padding: 40px 35px;
  }
}
.answersWrap {
  padding: 40px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  border-radius: 28px;
  padding: 48px 20px;
  width: 100%;
  max-width: 640px;
  text-align: center;
  background-color: $colorWhite;
  color: $colorBlueDark;
}

.title {
  margin: 0 auto;
  max-width: 412px;

  font-size: 28px;
  line-height: 32px;

  @media (max-width: $m) {
    font-size: 18px;
    line-height: 20px;
  }

  @media (max-width: 451px) {
    max-width: 230px;
  }
}

.text {
  margin: 24px 0 0;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: $m) {
    font-size: 16px;
    line-height: 18px;
    margin-top: 10px;
  }
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  font-size: 20px;
  line-height: 24px;

  gap: 10px;

  @media (max-width: $m) {
    margin-top: 16px;
    font-size: 16px;
    line-height: 18px;
  }

  @media (max-width: $s) {
    flex-direction: column;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  min-height: 32px;
}

.button {
  border: none;
  padding: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-decoration: underline;
  background-color: transparent;
  color: $colorOrange;
  cursor: pointer;
}

.closeContainer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 15px;
  right: 20px;

  div {
    // color: white;
  }
}

.closeMobileContainer {
  background-color: $colorWhite;
  z-index: 999999999;
  padding: 5px;
  border-radius: 50%;
  box-shadow: 1px 1px 9px 2px #484747d1;

  div {
    svg {
      width: 10px !important;
    }
  }
}

.answerwrap {
  padding: 60px 40px;
}
.answerSubmitted {
  .timerContainer {
    right: 10px;
    top: 0px;

    .countdownTimer {
      width: 54px;
      height: 64px;
      margin-top: 10px;

      p {
        margin-top: 5px;

        span {
          font-size: 16px;
        }
      }
    }
  }

  p {
    font-size: 20px;
    line-height: 22px;
    max-width: 400px;
    margin: 20px auto 0;
  }

  .answerHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    .leftImg {
      transform: rotate(-7.3726deg);
      width: 70px;

      @media (max-width: 990px) {
        width: 60px;
      }
    }

    .rightImg {
      transform: rotate(359deg);
      width: 80px;

      @media (max-width: 990px) {
        width: 60px;
      }
    }
  }
}
.playerboxWrapper {
  display: flex;
  // border-bottom: 2px solid #ffead2;

  .homePLayer {
    border-radius: 30px 30px 0 0 !important;
    padding: 20px !important;
  }

  .mobilePlaylist {
    box-shadow: $boxShadow;
  }

  .modalMyPlaylist {
    border-radius: 25px 25px 0 0 !important;
  }

  .vimeoPlayer {
    width: 100%;
    border-radius: 25px;
    overflow: hidden;

    @media (min-width: 1025px) {
      padding: 15px;
      background-color: #f9ba17;
    }

    // @media (max-width: 1024px) {
    //   box-shadow: $boxShadow;
    // }
  }
  .playerWrapper {
    width: 75%;
    margin-bottom: 3px;
    padding: 0px 20px;
    padding-left: 0;
  }
  .VideoHeading {
    // text-align: center;
    margin-bottom: 20px;

    // span {
    //   max-width: 80%;
    //   margin: 0 auto;
    //   display: block;

    //   @media (max-width: 480px) {
    //     max-width: 100%;
    //   }
    // }
  }
  .playlistItems {
    height: 100%;
    // max-height: 430px;
    max-height: 480px;
    overflow: scroll;
    padding-bottom: 30px;

    @media (max-width: 786px) {
      padding-bottom: 100px;
    }
  }

  .mobilePlaylist {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .mobilePlaylist::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  // .mobilePlaylist {
  // 	max-height: 100vh;
  // }
}
.playerQuesWrapper {
  width: 100%;
  justify-content: center;
  border: none;
}
.showAddChildForm {
  color: $colorOrange;
  font-weight: 700;
  font-size: 18px;
}
.playlistBox {
  background-color: #f9e7d6;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
}

.playlistBoxContainer {
  background-color: #f9e7d6;
  width: 42%;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
}

.playlistCount {
  background-color: #f9e7d6;
  // padding: 0 10px;
  text-align: center;
  // border-bottom: 1px solid #c6aa98;
}
.playlistCountInner {
  padding: 15px 10px;
  // border-left: 2px solid #404040;
  position: relative;
}
.playlistCount span {
  background-color: #fa9b1f;
  color: #ffffff;
  border-radius: 15px;
  padding: 8px 32px;
  height: 40px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  gap: 10px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  font-weight: bold;
}
.playlistBoxInner {
  // border-left: 2px solid #404040;
  position: relative;
  // margin: 0 10px;
  height: calc(100% - 120px);
}
.emptylist.mobilePlaylistBox {
  height: unset;
}
// .playlistBoxInner:after,
// .playlistCountInner:after {
//   position: absolute;
//   content: "";
//   width: 2px;
//   height: 100%;
//   background-color: #404040;
//   top: 0;
//   right: 2px;
// }
.playlistFooter {
  text-align: center;
  margin: 2px 0 0;
  padding-right: 10px;
  position: absolute;
  top: -3px;
  right: 0px;
  width: 100%;
  background-color: #f9e7d6;
}
.playlistFooter button {
  background: #b6b6b6;
  color: #000;
  border: none;
  border-radius: 30px;
  margin-bottom: 10px;
  transition: all 0.4s ease-in-out;
  padding: 5px;
  width: 110px;
  text-align: center;
  margin-left: 15px;
  margin-top: 5px;
  justify-content: center;

  p {
    font-weight: bold;
    font-size: 12px;
    text-align: center;
  }
}
.playlistFooter button:hover {
  background: #b6b6b6e7;
}

.videoCategoryName {
  background-color: #92d194;
  color: #000;
  border-radius: 4px;
  padding: 4px;
  font-weight: bold;
  font-size: 12px;
  margin-right: 5px;
}
.videoDateNumber {
  background-color: #404040;
  color: #fff;
  border-radius: 4px;
  padding: 4px;
  font-size: 12px;
}
.videoPlaying {
  background-color: $colorWhite;
}

.videoFooterInformation,
.videoInformation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  width: 100%;
}

.videoInformationBottom {
  background-color: #fff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

// .videoFooterInformation {
//   margin-top: 20px;
// }
.videoInformation {
  margin-bottom: 5px;
}
.videoTags a {
  color: #4285ff;
  font-size: 12px;
  text-decoration: none;
  margin-right: 2px;
  // background-color: rgba(29, 161, 242, 0.2);
  border-radius: 4px;
  padding: 0px 2px;
  transition: all 0.4s ease-in-out;
}

.videoTagsMobile {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: center;
  gap: 3px;

  span {
    font-size: 12px;
    min-width: max-content;
  }

  a {
    min-width: max-content;
    color: #17afaf;
    font-size: 12px;
    text-decoration: none;
    margin-right: 2px;
    border-radius: 4px;
    padding: 2px;
    transition: all 0.4s ease-in-out;
  }
}

.videoTags a:hover {
  background-color: #1da1f2;
  color: #fff;
}
.videoTags a:last-child {
  margin-right: 0;
}
.skipDedication {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: bold;
  width: 100%;
  max-width: 155px;
  margin-left: auto;
  justify-content: flex-end;
}
.videoTags {
  margin-bottom: 5px;
  overflow: hidden;
  max-height: 40px;
  // white-space: nowrap;
}
.videoActionWrapper {
  .pdfWrap > div {
    width: 20px;
    height: 20px;
  }
  button {
    background-color: #ff8a34;
    color: #ffffff;
    border: 1px solid transparent;
    border-radius: 22px;
    padding: 8px 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
    transition: all 0.4s ease-in-out;

    &:hover {
      background-color: #ee802f;
    }
  }
}
.playlistItem {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  justify-content: space-between;
  // border-bottom: 1px solid #efd1bd;
}
.playlistItemInfoWrap {
  display: inline-flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  .videoNumbers {
    align-items: center;
    display: flex;
    height: 100%;
  }
}
.playlistItemInfo {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  position: relative;
}
.playlistItemInfo img {
  border-radius: 8px;
}
.videoTime {
  position: absolute;
  color: #fff;
  background: #404040;
  font-size: 10px;
  padding: 3px;
  line-height: 1;
  right: 2px;
  bottom: 2px;
  border-radius: 4px;
}
.playlistItemName {
  padding: 0 20px;
  margin-bottom: 8px;
  // font-weight: bold;
}
.playlistItemName span {
  transition: all 0.4s ease-in-out;
  font-weight: bold;
}
.playlistItemName span:hover {
  color: #ff8a34;
}
.emptyplaylist {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px 0;
}

.videoName {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 3px;
}
.playlistCatInfo {
  display: flex;
  align-items: center;

  .videoCategoriesName {
    display: inline-block;
    font-weight: 100;
    margin-right: 5px;
    font-size: 12px;
    line-height: 14px;
  }

  .dot {
    height: 4px;
    width: 4px;
    background-color: #555;
    border-radius: 50%;
    display: inline-block;
  }
}

.playlistBoxFooter {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 30px 0 10px 0;
  gap: 10px;
  background-color: #fce9d9;
  position: absolute;
  width: 100%;
  bottom: 0px;
  background-size: cover;

  // border-left: 2px solid #000;
  // border-right: 2px solid #000;
  // background-image: url(../../../assets/images/playlist-bg.jpg);

  // &:after {
  //   position: absolute;
  //   content: "";
  //   width: 2px;
  //   height: 100%;
  //   background-color: #404040;
  //   top: 0;
  //   right: 2px;
  // }
  .playlistBoxFooterInner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 0 0;
    gap: 10px;
    width: 100%;

    .birthdayLink {
      width: 100%;
      text-decoration: none;
      justify-content: center;
    }
  }
  img {
    max-width: 60px;

    @media (max-width: 400px) {
      max-width: 40px;
    }
  }

  .leftImg {
    transform: rotate(-8.67276deg);
  }
  .rightImg {
    transform: rotate(18.6356deg);
  }
  .playlistFooterContent {
    text-align: center;

    .pinkColor {
      color: #f21b54;
      font-size: 15px;
      line-height: 17px;
      text-decoration: none;
    }
    span {
      font-size: 12px;
    }
  }
}
.hideClearPlaylist {
  padding-top: 10px;
}
.mobileView {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #fce9d9;
  position: fixed;
  width: 100%;
  bottom: 60px;
  z-index: 9999999999999999;
  background-image: url(../../../assets/images/playlist-bg.jpg);

  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
    position: sticky;
  }
  @media all and (device-width: 1180px) and (device-height: 820px) and (orientation: landscape) {
    position: sticky;
  }
  .playlistBoxFooterInner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 0;
    gap: 10px;
    width: 100%;

    .birthdayLink {
      width: 100%;
      text-decoration: none;
      justify-content: center;

      img {
        max-width: 70px;

        @media (max-width: 390px) {
          max-width: 50px;
        }
      }

      .leftImg {
        transform: rotate(-8.67276deg);
      }
      .rightImg {
        transform: rotate(18.6356deg);
      }
      .playlistFooterContent {
        text-align: center;

        @media (max-width: 390px) {
          font-size: 12px;
          line-height: 14px;
        }

        .pinkColor {
          color: #f21b54;
          font-size: 16px;
          text-decoration: none;

          @media (max-width: 390px) {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}
/* width */
.player ::-webkit-scrollbar {
  width: 6px;
}
.videoTagsMobile::-webkit-scrollbar {
  display: none;
}
/* Track */
.player ::-webkit-scrollbar-track {
  background: transparent;
  width: 2px;
}

/* Handle */
.player ::-webkit-scrollbar-thumb {
  background: #404040;
}

/* Handle on hover */
.player ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: $l) {
  .playlistBox {
    width: 50%;
  }
  .playerboxWrapper .playerWrapper {
    width: 50%;
    // padding: 0 20px;
    // padding-left: 0;
  }
  // .player {
  // 	padding: 40px 20px 20px;
  // }
}
@media (max-width: 950px) {
  .playerboxWrapper {
    flex-wrap: wrap;
  }
  .playerboxWrapper .playerWrapper,
  .playlistBox {
    width: 100%;
  }
}
@media (max-width: 380px) {
  .playlistItemInfo img {
    max-width: 80px;
  }
  .playlistItemName {
    padding: 0 5px;
  }
  .playlistItemName span {
    font-size: 10px;
    line-height: 1;
  }
  .playlistItemInfo {
    margin-left: 5px;
  }
  .videoCategoryName,
  .videoDateNumber {
    font-size: 10px;
  }
  .playlistCount span {
    padding: 8px 22px;
    height: 35px;
    font-size: 14px;
    line-height: 20px;
  }
}

.dropdownContainer {
  margin: 0 auto 20px auto;
  max-width: 261px;
  width: 100%;

  @media (max-width: 1054px) {
    max-width: 211px;
  }
}

// .questionContainer .dropdownContainer div {
// 	margin: auto;
// }
.questionInner {
  width: 100%;
}
.questionContainerDesktop {
  width: 100%;
}
.questionOptions {
  width: 100%;

  .error {
    margin: 0;
    margin-top: 14px;
  }
  button {
    margin: 10px auto 0;
    background: #fff;
    border: none;
    border-radius: 30px;
    padding: 6px 15px;
    font-size: 16px;

    &:disabled {
      cursor: not-allowed;
    }

    &:hover {
      background-color: $colorOrange;
      color: #fff;
    }
  }
}

.questionOptionsGuest {
  width: 100%;

  .error {
    margin: 0;
    margin-top: 14px;
  }

  button {
    margin: 10px auto 0;
    background: #fff;
    border: none;
    border-radius: 30px;
    padding: 12px 25px;
    font-size: 18px;

    &:disabled {
      cursor: not-allowed;
    }

    &:hover {
      background-color: $colorOrange;
      color: #fff;
    }
  }
}

.questionInner h2 {
  margin: 30px 0 15px;
}

.timerContainer {
  font-size: 18px;
  position: absolute;
  right: 20px;
  top: 10px;

  @media (max-width: 1000px) {
    right: 10px;
  }

  // @media (max-width: 400px) {
  // 	position: unset;
  // }

  .countdownTimer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto 10px;
    width: 84px;
    height: 84px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../../../../src/assets/images/timer.png);

    @media (max-width: 992px) {
      width: 64px;
      height: 64px;
    }

    p {
      // width: 60px;
      // height: 60px;
      padding: 10px;
      border-radius: 100%;
      margin-top: 15px;

      @media (max-width: 1000px) {
        margin-top: 7px;
      }

      span {
        font-size: 21px;
        color: #fff;
        line-height: 1;
        font-weight: 700;

        @media (max-width: 992px) {
          font-size: 16px;
        }
      }
    }
  }
}

.error {
  color: #f21b54;
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: bold;
}

.mobileQuestions {
  h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .dropdownContainer {
    margin: 0 auto 15px auto;

    @media (max-width: 400px) {
      margin-left: 0;
    }
  }
  .whiteBg {
    min-height: 248px;
    padding: 20px 80px;

    @media (max-width: 450px) {
      padding: 15px;

      // .dropdownContainer{
      // 	padding-right: 70px;
      // }
    }

    .timerContainer {
      right: 40px;

      .countdownTimer {
        width: 44px;
        height: 44px;
        margin-top: 10px;

        p {
          margin-top: 10px;

          span {
            font-size: 14px;
          }
        }
      }
    }

    .answerSubmitted {
      padding: 30px 0;
    }

    .answerSubmittedGuest {
      padding: 30px 0;
    }
  }

  .answersWrap {
    padding: 20px;
  }

  .questionOptions {
    button {
      font-size: 14px;
      margin-top: 10px;
    }
  }

  .error {
    font-size: 12px;
  }
}

.screenHeading {
  color: #e82051;
  font-size: 30px;
  line-height: 34px;
}

.pHeading {
  font-size: 24px;
  line-height: 30px;
}

.videoHeadingInfo {
  display: flex;
  gap: 5px;
  color: $colorWhite;
  background-color: #fabb17;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 10px 10px 10px 40px;
  margin-left: -40px;
  margin-bottom: 20px;

  span {
    font-size: 20px;
    line-height: 24px;
    margin: 0 !important;
    width: initial;
    max-width: initial;
  }
}

.description {
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  margin-top: 20px;
}

.videoButton {
  display: flex;
  gap: 10px;
}

.titleText {
  font-weight: 400;
}

.bold {
  font-weight: bold;
}

.videoButtonMobile {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .playlistBoxInner {
    height: 100vh;
    background-color: white;
  }

  .playlistItems {
    background-color: #fce9d9;
    overflow: auto;
    border-radius: 0 0 30px 30px;
  }

  .playlistFooter {
    border-radius: 0 0 15px 15px;
  }

  .playlistBoxFooter {
    padding: 30px 0 10px 0;
    position: fixed;
    bottom: 3px;
    width: calc(100vw - 40px);
    left: 20px;
    border-radius: 0 0 15px 15px;
  }

  .playlistBoxContainer {
    width: calc(100vw - 40px);
    margin: 0 20px;
  }

  .playlistBox {
    margin: 0 !important;
  }
}

.submitAnotherChild {
  text-decoration: underline;
  cursor: pointer;
}

.hideBackgroundColor {
  background-color: transparent !important;
  padding: 0 !important;
  border-radius: 30px !important;
}

.modalMyPlaylistFooter {
  padding: 20px 0px 0px 20px;
}

.playlistPlayer {
  height: 100vh !important;
}