@import "../../../styles/utilities/index.scss";
// .container {}

.header {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 20px;

  .addToPlaylistBox button {
    border-radius: 12px;
    padding: 8px 20px;
    // height: 40px;
    font-size: 16px;
    // line-height: 18px;
    background-color: $colorOrange;
    color: $colorWhite;
    border: none;
    // margin-top: 40px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .projectTitle {
    font-size: 1rem;
    letter-spacing: 1px;
    font-weight: bold;
    color: $colorOrange;
  }
}

.logotype {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 14px;

  .projectTitle {
    font-size: 1.13rem;
  }

  .totalVideosCount {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
  }

  a {
    cursor: default;
  }

  .logotypeWrapper {

    a {
      background-color: $colorWhite;
      border-radius: 50%;
      width: 64px;
    }
  }
}


.main {
  margin-top: 28px;
  max-width: 1920px;
  margin: 0 auto;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (min-width: $m) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: $xxl) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.item {
  min-width: 0;
}

.link {
  color: $colorOrange;
  text-decoration: none;
  font-weight: bold;
}

.message {
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: $colorBlueDark;

  &.empty{
    padding: 80px 0;
  }
  // &.empty {}
  @media (max-width: $m) {
    font-size: 1.5rem;
  }

  &.error {
    color: $colorRed;
  }
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  min-height: 100vh;

  @media (min-width: $m) {
    padding: 60px 48px;
  }
}

.player {
  width: 100%;
  background-color: #fff;
  max-width: 1200px;
  padding: 40px 30px 30px;
  border-radius: 10px;
  position: relative;
}

.card {
  border-radius: 28px;
  padding: 48px 20px;
  width: 100%;
  max-width: 640px;

  text-align: center;
  background-color: $colorWhite;
  color: $colorBlueDark;
}

.title {
  margin: 0 auto;
  max-width: 600px;

  font-size: 28px;
  line-height: 32px;

  @media(max-width: $m) {
    font-size: 18px;
    line-height: 20px;
  }

  @media(max-width: 451px) {
    max-width: 230px;
  }
}

.text {
  margin: 24px 0 0;
  font-size: 20px;
  line-height: 24px;

  @media(max-width: $m) {
    font-size: 16px;
    line-height: 18px;
    margin-top: 10px;
  }
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  flex-direction: column;

  font-size: 20px;
  line-height: 24px;

  gap: 24px;

  @media (max-width: $m) {
    margin-top: 16px;
    gap: 16px;
    font-size: 16px;
    line-height: 18px;
  }

  @media (max-width: $s) {
    flex-direction: column;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  min-height: 32px;
}

.button {
  border: none;
  padding: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-decoration: underline;
  background-color: transparent;
  color: $colorOrange;
  cursor: pointer;
}

.closeContainer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 12px;
  right: 30px;

  div {
    // color: white;
  }
}
