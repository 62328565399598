@import "../../../../../styles/utilities/index.scss";

.container {
  padding: 75px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 65px;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  color: $colorBlueDark;
}

.headingContainer > h3 {
  text-transform: uppercase;
}

.headingContainer > p {
  font-size: 20px;
  line-height: 22px;
}

.browserText {
  display: block;
}
.mobileText {
  display: none;
}

.centerContainer {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.videoContainer {
  height: 290px;
  width: 500px;
  border-radius: 12px;
  background-image: url(../../../../../assets/images/our-impact.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 768px) {
  .container {
    padding: 50px 0px;
    gap: 40px !important;
  }
  .browserText {
    display: none;
  }
  .mobileText {
    display: inline-block;
  }

  .btnWrap {
    button {
      margin: 0px;
    }
  }

  .headingContainer {
    gap: 18px;
    p {
      font-size: 16px !important;
    }
  }
}

@media only screen and (max-width: 1330px) {
  .centerContainer {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 550px) {
  .videoContainer {
    max-height: 230px;
    max-width: 380px;
    min-width: 300px;
    min-height: 180px;
  }
}

@media (max-width: 400px) {
  .videoContainer {
    max-height: 200px;
    max-width: 300px;
    min-height: 180px;
  }
}

@media (max-width: 750px) {
  .headingContainer {
    P {
      font-size: 20px;
      line-height: 20px;
    }
  }
}
