@import "../../../../../styles/utilities/index.scss";


.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;

    p{
        font-size: 20px;
        line-height: 26px;
        text-align: center;
    }
}

.colorOrange{
    color: $colorOrange;
}

.bold{
    font-weight: bold;
}

.fontSmall{
    font-size: 18px !important;
    line-height: 22px !important;
}