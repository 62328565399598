.container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 500px) {
    padding: 24px 0px;
  }
}

.heading {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: 15px;
}

.signUpButton {
  background-color: #ffc107; // Equivalent to bg-yellow-400
  color: black;
  padding: 8px 16px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  font-weight: bold;

  &:hover {
    background-color: #ffca28; // Equivalent to hover:bg-yellow-500
  }
}

.orText {
  font-size: 16px;
  color: #333;
}

.logInButton {
  background-color: #ffc107; // Equivalent to bg-yellow-400
  color: black;
  padding: 8px 16px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;

  &:hover {
    background-color: #ffca28; // Equivalent to hover:bg-yellow-500
  }
}

.grandstanderFamily {
  font-family: "Grandstander", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
