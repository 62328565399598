@import "../../../../styles/utilities/index.scss";

.heading {
	color: $colorOrange;
}

a {
	transition: 0.3s ease-in-out;
}

.anchorContainer {
	display: flex;
}

.maindiv {
	display: flex;
}

.sidebar {
	max-width: none;
	width: 16.6%;
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.sidebar ul {
	margin-right: 20px;
	margin-top: 50px;
}

.sidebar ul li {
	margin-bottom: 24px;
}

.sidebar ul li:last-child {
	margin-bottom: 0px;
}

.sidebar ul li a {
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	padding: 18px 0px;
	text-decoration: none;
	display: block;
	padding-left: 48px;
	border-top-right-radius: 30px;
	border-bottom-right-radius: 30px;
}

.sidebar ul li a img {
	height: 20px;
	width: auto;
	margin-right: 16px;
}

.sidebar ul li a.active,
.sidebar ul li a:hover {
	background-color: $colorOrange;
	color: white;
}

.topheading h4 {
	font-size: 20px;
	line-height: 24px;
	color: $colorBlueDark;
}

.maincontainer {
	max-width: none;
	width: 80%;
	// margin-left: auto;
	margin-right: 48px;
	padding-top: 45px;
	padding-bottom: 128px;
}

.topheading {
	display: flex;
	justify-content: space-between;
}

.savecontainer {
	display: none;
	flex-direction: column;
	gap: 14px;
}

.savecontainer a {
	color: $colorOrange;
}

.savecontainer a:hover {
	color: black;
}

.savecontainer .fillbtn {
	background-color: $colorOrange;
	color: white;
	text-decoration: none;
	padding: 8px 20px;
	border-radius: 30px;
	border: 1px solid $colorOrange;
}

.savecontainer .fillbtn:hover {
	color: $colorOrange;
	background-color: white;
	border: 1px solid $colorOrange;
}

.maincontent {
	// margin-top: 24px;
	// box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
	border-radius: 26px;
	padding: 24px 12px;
	display: flex;
	gap: 25px;
	justify-content: center;
	flex-wrap: wrap;
}

.maincontentflex {
	max-width: 420px;
	width: 100%;
}

.flexcontainer {
	width: 100%;
	text-align: center;
	border: 1px solid rgba(0, 0, 0, 0.5);
	border-radius: 20px;
	padding: 20px;
	margin-bottom: 25px;
}

.flexcontainer:last-child {
	margin-bottom: 0px;
}

.flexcontainer p {
	font-size: 16px;
	line-height: 24px;
	color: $colorBlueDark;
}

.flexcontainer h4 {
	font-size: 20px;
	line-height: 32px;
	color: $colorBlueDark;
	margin-top: 15px;
	padding-top: 15px;
	position: relative;
	margin-bottom: 5px;
}

.flexcontainer h4::after {
	position: absolute;
	content: "";
	width: 53%;
	height: 1px;
	background-color: #8a8a8a;
	top: 0px;
	left: 50%;
	transform: translateX(-50%);
}

.selectfield label {
	font-size: 18px;
	line-height: 28px;
	display: block;
	color: $colorBlackLight;
	margin-bottom: 14px;
}

.selectfield {
	margin-bottom: 15px;
}

.selectfield:last-child {
	margin-bottom: 0px;
}

.selectfield select {
	width: 84%;
	padding: 16px;
	border: 1px solid #8a8a8a;
	border-radius: 10px;
	outline: none;
	color: $colorGreyDark;
	font-size: 16px;
}

.flexcontainer img {
	height: 85px;
	width: auto;
	margin-bottom: 10px;
}

.mobileicon {
	display: none;
}

.sidebar li a {
	display: flex;
	align-items: center;
}

.unsubscribeBtn {
	background: none;
	border: none;
	font-weight: bold;
	color: red;
	font-size: 18px;
	margin-top: 10px;
	text-decoration: underline;

  &:disabled {
    cursor: not-allowed;
  }
}

.noResult {
  font-size: 24px;
  font-weight: bold;
}

// Media Queries

@media only screen and (max-width: 1680px) {
	.sidebar {
		width: 20%;
	}
	.maincontainer {
		width: 72%;
		margin-right: 40px;
	}
	.flexcontainer img {
		height: 60px;
	}
	.selectfield select {
		width: 94%;
	}
	.selectfield label {
		margin-bottom: 5px;
	}
	.selectfield {
		margin-bottom: 10px;
	}
	.flexcontainer h4 {
		margin-top: 9px;
		margin-bottom: 10px;
	}
}

@media only screen and (max-width: 1280px) {
	.maindiv {
		position: relative;
	}
	.sidebar {
		position: absolute;
		width: 50%;
		top: 0px;
		left: 0px;
		height: 100%;
		background-color: white;
		z-index: 99;
		display: none;
	}
	.maincontainer {
		width: 90%;
	}

	// .mobileicon {
	// 	display: block;
	// 	height: 30px;
	// 	width: auto;
	// 	margin-top: 40px;
	// 	margin-left: 10px;
	// }

	.maincontainer {
		padding-bottom: 0px;
		width: 100%;
		margin: 0px 20px;
	}
}

@media only screen and (max-width: 990px) {
	.maincontent {
		flex-wrap: wrap;
		gap: 0px;
	}
	.maincontentflex {
		max-width: none;
		display: flex;
		flex-wrap: wrap;
		gap: 2%;
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.5);
	}
	.maincontentflex:last-child {
		margin-bottom: 0px;
		padding-bottom: 0px;
		border-bottom: 0px;
	}
	.flexcontainer {
		width: 49%;
		margin-bottom: 2%;
	}
}

@media only screen and (max-width: 580px) {
	.flexcontainer {
		width: 100%;
		margin-bottom: 6%;
	}
	.mobileicon {
		height: 20px;
		margin-top: 45px;
	}
}
