@import "../../../styles/utilities/index.scss";

.winnerContainer {
  background-color: $colorWhite;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-wrap: wrap;
  padding: 15px;
  border-radius: 30px;
  row-gap: 20px;
  column-gap: 40px;
}

.winnerName {
  color: #0b8484;
  font-weight: bold;
}

.winnerIno {
  color: $colorOrange;
  text-transform: uppercase;
  font-weight: bold;
}

.winnertextConatiner {
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 8px;
  column-gap: 15px;

  flex-wrap: wrap;
  text-align: center;

  img {
    width: 25px;
    height: 25px;
  }

  @media (max-width: 375px) {
    p {
      font-size: 14px;
    }
  }
}

.iconText {
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 10px;

  @media (max-width: 600px) {
    img {
      display: none;
    }
  }
}

.bold {
  font-weight: bold;
}

.name {
  font-size: 16px;
  font-weight: 500;

  @media (max-width: 480px) {
    font-size: 12px;
  }
}

.winnerTrophy {
  display: none;
}

.winnerChild {
  display: none;
}

@media (max-width: 768px) {
  .winnerContainer {
    box-shadow: none !important;

    background-image: url(../../../assets/images/waves-cream.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 125% 90%;
    padding: 50px 90px;
    background-color: #fff;

    width: 100vw;
    margin-left: -16px;
    min-height: 300px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .winnerChild {
    display: block;
    width: 85px;
    position: absolute;
    left: 0;
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.2));
  }

  .winnerTrophy {
    display: block;
    width: 85px;
    position: absolute;
    right: 0;
    filter: drop-shadow(1px -3px 2px rgba(0, 0, 0, 0.2));
  }
}

.noWrap {
  text-wrap: nowrap;
  white-space: nowrap;
}
