@import "../../../../../../../styles/utilities/index.scss";

.wrapper {
  margin: 0 auto;
  padding: 80px 16px;
  width: 100%;
  max-width: 720px;
  min-height: 100vh;
}

.card {
  border-radius: 26px;
  padding: 32px 32px 32px;

  background-color: $colorWhite;
  color: $colorBlueDark;
  position: relative;
  margin: 0 auto;
  width: fit-content;
}

.close {
  border: none;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.crossStyle {
  height: 20px;
  width: 20px;
  color: #3a3a3a;
  cursor: pointer;
  margin-bottom: -36px;
  margin-right: 10px;
}

.closeButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;

  button {
    outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    opacity: 0.8;
  }
}

@media (max-width:450px) {
  .card{
    padding: 32px 20px 32px;
    width: auto;
  }
}

.errorMessage{
  font-size: 16px;
  color: red;
  line-height: 20px;
}
