.container {
  max-width: 1152px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
}

.grid {
  display: grid; /* Tailwind's grid */
  grid-template-columns: 1fr; /* Tailwind's grid-cols-1 */
  gap: 24px; /* gap-6 -> 1.5 * 16 = 24px */
}

.gridSm {
  @media (min-width: 0px) {
    /* Tailwind's sm:grid-cols-2 */
    grid-template-columns: repeat(2, 1fr);
  }
}

.gridLg {
  @media (min-width: 1024px) {
    /* Tailwind's lg:grid-cols-4 */
    grid-template-columns: repeat(4, 1fr);
  }
}

.card {
  display: flex; /* Tailwind's flex */
  flex-direction: column; /* Tailwind's flex-col */
  align-items: center; /* Tailwind's items-center */
  max-width: 240px;
  width: 100%;
  min-width: 170px;
}

.characterSquare {
  width: 100%; /* Tailwind's w-full */
  aspect-ratio: 1 / 1; /* Tailwind's aspect-square */
  border-radius: 30px; /* rounded-3xl -> 1.5 * 16 = 24px */
  margin-bottom: 16px; /* mb-4 -> 1 * 16 = 16px */
  background: #fffde7;
  max-height: 220px;
  color: #000;

  object-fit: cover;
}

.characterLabel {
  text-align: center; /* Tailwind's text-center */
  color: #000; /* Tailwind's text-white */
  padding: 10px 30px; /* px-6 py-2 -> 0.5 * 16 = 8px, 1.5 * 16 = 24px */
  border-radius: 9999px; /* rounded-full */
  margin-bottom: 12px; /* mb-3 -> 0.75 * 16 = 12px */
  font-weight: bold;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  text-transform: capitalize;
}

.characterInfo {
  text-align: center; /* Tailwind's text-center */
  color: #000;
}

.characterName {
  font-weight: 500; /* Tailwind's font-medium */
  margin-bottom: 4px; /* mb-1 -> 0.25 * 16 = 4px */
  font-weight: bold;
}

.characterLocation {
  font-size: 14px; /* text-sm -> 0.875 * 16 = 14px */
  color: #000; /* Tailwind's text-gray-600 */
}

.grandstanderFamily {
  font-family: "Grandstander", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.addCharacterModalConatiner {
  padding: 80px 16px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  // overflow-y: scroll;
}

.addCharacterModalCard {
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  max-width: 1280px;
  width: 100%;
  position: relative;
}

.iconCross {
  position: absolute;
  top: 25px;
  right: 25px;

  cursor: pointer;
}

.deleteModal {
  border: 1px solid;
  border-radius: 100%;
  background: transparent;
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  z-index: 9999;
}

@media screen and (max-width: 650px) {
  .characterLabel,
  .characterName {
    font-size: 14px;
    line-height: 20px;
  }
}
