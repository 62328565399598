@import "../../../styles/utilities/index.scss";

$colorGold: #FFEAD2;

.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 10px;
}

.input {
  display: none;
}

.input:checked {
  & + .label {
    font-weight: bold;
    font-size: 1rem;
    padding: 15px 12px;
  
  @media (max-width: $m){
    font-size: 15px;
    padding: 14px 12px;
  }
  
  @media (max-width: $s) {
    padding: 8px 20px;
    line-height: normal;
    height: auto;
  }
  }
}

.label {
  text-align: center;
  cursor: pointer;

  background-color: $colorGold;

  border-radius: 8px;
  padding: 8px 10px;
  /* height: 48px; */

  font-size: 16px;
  line-height: 18px;
  color: $colorBlueDark;
  
  @media (max-width: $s) {
    padding: 8px 20px;
    line-height: normal;
    height: auto;
  }
}
