@import "../../../styles/utilities/index";

.container {
  width: calc(286px + 2px);
  position: relative;
}

.anchor {
  position: relative;
}

.trigger {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;
  border: none;
  width: 100%;

  background-color: transparent;
  cursor: pointer;
}

.body {
  box-shadow: $shadowHard;
  margin-top: $size-02;
  border: 1px solid $colorGreyNew;
  border-radius: $size-02;
  padding: $size-06 $size-04;

  background-color: $colorWhite;
}

.bar {
  display: flex;
  align-items: center;

  font-size: $size-04;
  line-height: $size-06;

  .title {
    margin-right: auto;
    margin-left: auto;
  }

  .button {
    border: none;
    padding: 0;

    background-color: transparent;
    color: $colorOrange;
    cursor: pointer;
  }
}

.list {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: $size-03;
  grid-row-gap: $size-03;
  margin: 0;
  padding: 0;

  font-size: $size-03;
  line-height: $size-06;
  text-align: center;
}

.weeks {
  margin-top: $size-05;
  color: $colorOrange;
}

.days {
  margin-top: $size-03;

  // .item {}

  .button {
    box-sizing: border-box;
    border: 1px solid transparent;
    border-radius: $size-02;
    padding: 0;
    width: 100%;

    background-color: transparent;
    cursor: pointer;

    &:hover {
      transition: border-color $timeS;
      border-color: $colorGreyDark;
    }

    &:disabled {
      opacity: 0.25;
      pointer-events: none;
    }

    &.selected {
      border-color: $colorOrange;
    }
  }
}

.mode {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: $size-03;
  margin-top: $size-05;
  
  // font-size: $size-04;
  font-size: 14px;
  line-height: $size-06;

  .button {
    border: 1px solid $colorGreyNew;
    border-radius: $size-02;
    padding: 0;

    background-color: transparent;
    cursor: pointer;
  }

  .selected {
    border-color: $colorOrange;
    background-color: $colorOrange;
    color: $colorWhite;
  }
}

.bornAfterCheckboxWrapper {
  display: flex;
  justify-content: center;

  margin-top: $size-05;
}

.hebrewFormmatedBox {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  strong {
    margin-right: 5px;
  }
}

.rtl {
  direction: rtl!important;
}

.englishDateLabel {
  padding-left: 17px;
  font-size: 18px;
  font-weight: bold;
  padding-top: 5px;
  color: #636363;
}