@import "../../../../../styles/variables/index.scss";

$green-light: #87ba48;
$yellow-light: #ffde59;
$red-light: #ff666b;
$orange-light: #ffae43;
$pink-light: #f472d0;
$teal-light: #49bebc;
$green-medium: #6ec887;
$brown-light: #b29367;
$blue-light: #0097b2;

.howItWorksModal {
  .modalVideoSection {
    display: flex;
    gap: 30px;
    // flex-wrap: wrap;

    @media (max-width: 1023px) {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 25px;
    }

    .videoContainer {
      max-width: 768px;
      width: 100%;
      // min-height: 400px;
      border-radius: 30px;
      padding: 20px;
      background-color: #d755b3;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        object-fit: contain;
        border-radius: 30px;
      }
    }

    .videoContent {
      width: 100%;
      height: 100%;
      min-height: 330px;
      border-radius: 12px;
      background-color: $colorGreyLight;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 650px) {
        min-height: 250px;
      }
    }

    .playButton {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: transparent;
      border: none;
      // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

      // &:hover {
      //   background-color: rgba(255, 255, 255, 0.9);
      // }

      img {
        width: 120px;
        height: 120px;
      }
    }

    .videoInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 0px;
      max-width: 375px;

      @media (max-width: 1023px) {
        max-width: 100%;
      }
    }

    .videoContainer {
      max-width: 680px;
      width: 100%;
      // min-height: 400px;
      border-radius: 30px;
      padding: 20px;
      background-color: #febf00;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        object-fit: contain;
        border-radius: 30px;
      }
    }

    .videoText {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 36px;
        line-height: 46px;
      }

      p {
        font-size: 20px;
        line-height: 24px;
      }

      .videoDescription {
        margin: 30px 0px 0px;
      }

      @media (max-width: 786px) {
        h1 {
          font-size: 27px;
          line-height: 32px;
        }

        p {
          font-size: 14px;
          line-height: 20px;
        }

        .videoDescription {
          margin: 15px 0px 0px;
        }
      }
    }
  }

  .stageContainer {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;

    .stage {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .progressIcon {
      width: 75px;
      height: 75px;

      @media (max-width: 900px) {
        width: 60px;
        height: 60px;
      }
    }

    .stageInfo {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}

.grandstanderFamily {
  font-family: "Grandstander", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
