@import "../../../styles/utilities/index.scss";

$boxShadow: 0px 4px 4px rgba(0, 0, 0, 0.1), 0px 0px 18.9113px rgba(255, 138, 52, 0.1), 0px 0px 73.2115px rgba(255, 138, 52, 0.3), inset 0px 0px 7.32115px rgba(255, 138, 52, 0.1);

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 50%;
  padding: 0;

  cursor: pointer;
}

/* colors */

.primary {
  &.shadow {
    box-shadow: $boxShadow;
  }

  &.container {
    background-color: $colorWhite;
    color: $colorOrange;
  }
}

.warning {
  &.shadow {
    box-shadow: $boxShadow;
  }

  &.container {
    background-color: $colorWhite;
    color: $colorRed;
  }
}

/* sizes */

.medium {
  $size: 36px;

  width: $size;
  height: $size;
}

.container.noBorder {
  background: transparent;
  box-shadow: none;
  width: 20px;
  height: auto;
}