$boxShadow: 4px 5px 10px rgba(0, 0, 0, 0.2);

.maorButtonConatiner {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  width: 100%;
  max-width: 804px;

  .lastChild {
    flex: 1;
    grid-column: span 3;
  }

  @media (max-width: 500px) {
    row-gap: 20px;
    column-gap: 10px;
  }

  @media (max-width: 786px) {
    max-width: 520px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .lastChild {
      grid-column: span 3;
    }
  }
}


.maorButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  box-shadow: $boxShadow;

  background-color: #17afaf;
  //   max-width: 160px;
  max-height: 180px;
  min-width: 160px;
  border-radius: 25px;
  padding: 20px;
  cursor: pointer;

  p {
    font-weight: bold;
    color: black;
    text-align: center;
  }

  @media (max-width: 500px) {
    max-height: 180px;
    min-width: 100px;
    border-radius: 15px;
    padding: 10px;

    p {
      font-size: 14px;
    }

    .buttonIcon {
      margin-top: 3px;
    }
  }
}

.green {
  background-color: #6bbc43;
}

.darkGreen {
  background-color: #17afaf;
}

.yellow {
  background-color: #fabb17;
}

.pink {
  background-color: #ff4f88;
}
