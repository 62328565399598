@import url("https://fonts.googleapis.com/css2?family=Grandstander:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

@import "../../../styles/utilities/index.scss";

@import "../../../styles/variables/index.scss";

$green-light: #87ba48;
$yellow-light: #ffde59;
$red-light: #ff666b;
$orange-light: #ffae43;
$pink-light: #f472d0;
$teal-light: #49bebc;
$green-medium: #6ec887;
$brown-light: #b29367;
$blue-light: #0097b2;

.red {
  color: #ff666b;
}

.grandstanderFamily {
  font-family: "Grandstander", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.wrapper-basic {
  margin-right: auto;
  margin-left: auto;
  padding-right: $size-04;
  padding-left: $size-04;
  width: 100%;

  @media (min-width: 769px) {
    padding-right: $size-11;
    padding-left: $size-11;
  }
}

.wrapper {
  @extend .wrapper-basic;

  max-width: 1024px;
}

// .bannerContainer {
//   width: 100%;
//   height: 260px;
//   display: flex;
//   flex-direction: column;
//   position: relative;

//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: #ffde59;
//   color: #fff;
// }

.videoSection {
  display: flex;
  gap: 45px;
  // flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }
}

.videoInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0px;
  max-width: 430px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.videoContainer {
  max-width: 680px;
  max-height: 390px;
  width: 100%;
  // min-height: 400px;
  border-radius: 30px;
  padding: 20px;
  background-color: #d755b3;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    object-fit: contain;
    border-radius: 30px;
  }
}

.videoText {
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 36px;
    line-height: 46px;
  }

  p {
    font-size: 20px;
    line-height: 24px;
  }

  .videoDescription {
    margin: 30px 0px;
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 27px;
      line-height: 32px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
    }

    .videoDescription {
      margin: 15px 0px;
    }
  }
}

.SubmissionContainer {
  max-width: 1152px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;

  @media (max-width: 1024px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.leadingScriptContainer {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  flex-wrap: wrap;

  .leadingScriptText {
    display: flex;
    align-items: center;
    gap: 15px;

    h1 {
      font-size: 38px;
      line-height: 44px;
      white-space: nowrap;
    }
  }

  .liveGif {
    width: 80px;
    height: auto;
  }

  .leadingScriptBoxContainer {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-grow: 1;
  }
}

.bannerContainer {
  background-image: url(../../../assets/images/writing-competition.png);
  // background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 95%;
  width: 100%;
  padding: 40px 40px 100px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 1023px) {
    // background-size: cover;
    background-size: 200% 90%;
  }
}

.bannerRight {
  display: flex;
  flex-direction: column;
  gap: 25px;
  min-width: 225px;

  .maorLogo {
    width: 110px;
    // height: 80px;
    object-fit: contain;
  }

  .liveGif {
    // height: 30px;
    width: 110px;
  }

  @media (max-width: 1023px) {
    display: none;
  }
}

.bannerCenter {
  display: flex;
  justify-content: center;
  align-items: center;

  .maorCompetitionLogo {
    width: 450px;
    height: auto;
  }

  @media (max-width: 1023px) {
    width: 100%;

    .maorCompetitionLogo {
      width: 300px;
      height: auto;
    }
  }
}

.bannerLeft {
  .bannerText {
  }
}

.competitionCard {
  background: $green-light; /* Green background */
  color: white; /* White text */
  padding: 15px 30px;
  border-radius: 30px; /* Rounded corners */
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Slight shadow */
  cursor: pointer;

  @media (max-width: 1023px) {
    display: none;
  }
}

.submissionHeader {
  font-size: 24px; /* Tailwind's text-2xl */
  font-weight: bold; /* Tailwind's font-bold */
  text-align: center; /* Tailwind's text-center */
  margin-bottom: 30px; /* Tailwind's mb-8 */
  position: relative; /* Tailwind's relative */
}

.submissionHeaderText {
  background-color: white; /* Tailwind's bg-white */
  padding: 0 1rem; /* Tailwind's px-4 */
  position: relative; /* Tailwind's relative */
  z-index: 10; /* Tailwind's z-10 */
  text-transform: uppercase;
}

.submissionHeaderLineWrapper {
  position: absolute; /* Tailwind's absolute */
  inset: 0; /* Tailwind's inset-0 */
  display: flex; /* Tailwind's flex */
  align-items: center; /* Tailwind's items-center */
}

.submissionHeaderLine {
  flex: 1;
  // border-top: 5px dotted;
  // border-color: #000;

  background-image: url(../../../assets/images/cpetition-dots.png);
  // background-repeat: no-repeat;
  // background-position: center;
  background-size: 1%;

  height: 7px;

  @media (max-width: 768px) {
    background-size: 1.5%;
  }

  @media (max-width: 525px) {
    background-size: 2.5%;
  }
}

.grid {
  display: grid; /* Tailwind's grid */
  grid-template-columns: 1fr; /* Tailwind's grid-cols-1 */
  gap: 24px; /* gap-6 -> 1.5 * 16 = 24px */
  justify-items: center;
}

.gridSm {
  @media (min-width: 375px) {
    /* Tailwind's sm:grid-cols-2 */
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    row-gap: 30px;
  }
}

.gridM {
  @media (min-width: 768px) {
    /* Tailwind's lg:grid-cols-4 */
    grid-template-columns: repeat(3, 1fr);
  }
}

.gridLg {
  @media (min-width: 1200px) {
    /* Tailwind's lg:grid-cols-4 */
    grid-template-columns: repeat(3, 1fr);
  }
}

.characterGridLg {
  @media (min-width: 1200px) {
    /* Tailwind's lg:grid-cols-4 */
    grid-template-columns: repeat(4, 1fr);
  }
}

.videoSubmissionContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 2rem;
  gap: 30px;
  justify-items: center;

  @media (max-width: 650px) {
    padding: 0rem;
  }
}

.playButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  // &:hover {
  //   background-color: rgba(255, 255, 255, 0.9);
  // }

  img {
    width: 120px;
    height: 120px;
  }
}

.videoContent {
  width: 100%;
  height: 100%;
  min-height: 350px;
  border-radius: 12px;
  background-color: $colorGreyLight;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 650px) {
    min-height: 250px;
  }
}

.winnerVideoContent {
  width: 100%;
  height: 100%;
  min-height: 350px;
  border-radius: 12px;
  background-color: $colorGreyLight;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 650px) {
    min-height: 250px;
  }
}

.joinButton {
  background-color: #ffc107;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  padding: 12px 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;

  transition: background-color 0.3s ease, transform 0.2s ease;

  border-width: 1px;
  border-radius: 30px;
  padding: 11px 32px;
  min-height: 80px;

  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.2px;

  @media (max-width: $m) {
    font-size: 18px;
    line-height: 20px;
    padding: 11px 32px;
    min-height: 60px;
  }

  &:hover {
    background-color: #ffa000;
    transform: translateY(-2px);
  }

  &:focus {
    outline: 2px solid #ffecb3;
  }
}

.winnerContent {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 45px;
  flex-wrap: wrap;
  position: relative;
}

.winnerContainer {
  background-color: #fef3c9;
  position: relative;
}

.bgYellow {
  background-color: #fef3c9;
}

.statusDescription {
  // background-color: #ffc000;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $colorOrange;
  font-weight: bold;

  padding: 11px 32px;
  min-height: 80px;

  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.2px;

  font-family: "Grandstander", cursive;
  font-optical-sizing: auto;
  font-style: normal;

  span {
    font-size: 14px;
  }

  @media (max-width: $m) {
    font-size: 14px;
  }
}

.winnerOverlay {
  position: absolute;
  inset: 0; // Covers the entire parent container
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black background
  z-index: 999; // Ensure it appears above other content
  display: flex;
  flex-direction: row; // Stacks content vertically
  align-items: center; // Centers horizontally
  justify-content: center; // Centers vertically
  backdrop-filter: blur(8px); // Adds a blur effect to the background

  .ribbonContainer {
    display: flex;
    flex-direction: row; // Stacks content vertically
    align-items: center; // Centers horizontally
    justify-content: center; // Centers vertically
    width: 100%;
    background-color: #d755b3;
    padding: 10px;
    max-height: 60px;

    img.ribbonImage {
      max-width: 100%;
      height: auto;
      margin-bottom: -110px;
      width: 400px;
      margin-left: 30px;
    }

    p {
      color: white;
      font-weight: bold; // Bold text for emphasis
      font-size: 28px; // Larger font for visibility
      text-align: center; // Center-align the text
      text-transform: uppercase; // Uppercase text for styling

      span {
        display: block; // Separate each line into its own block
      }
    }

    @media (max-width: 1024px) {
      img.ribbonImage {
        max-width: 100%;
        height: auto;
        margin-bottom: -50px;
        width: 250px;
        margin-left: 30px;
      }
    }

    @media (max-width: 767px) {
      max-height: fit-content;
      flex-direction: column;
      gap: 25px;

      img.ribbonImage {
        display: none;
      }
    }
  }
}

.mlLess {
  margin-left: -30px;

  @media (max-width: 767px) {
    margin-left: 10px;
  }
}

.grayBg {
  background-color: #bab9b9;
}

.greenBg {
  background-color: #89bb52;
}

.redBg {
  background-color: #bab9b9;
  color: #ea5557;
}

.howItWorksModal {
  .modalVideoSection {
    display: flex;
    gap: 30px;
    // flex-wrap: wrap;

    @media (max-width: 1023px) {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 25px;
    }

    .videoContainer {
      max-width: 768px;
      width: 100%;
      // min-height: 400px;
      border-radius: 30px;
      padding: 20px;
      background-color: #d755b3;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        object-fit: contain;
        border-radius: 30px;
      }
    }

    .videoInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 0px;
      max-width: 375px;

      @media (max-width: 1023px) {
        max-width: 100%;
      }
    }

    .videoContainer {
      max-width: 680px;
      width: 100%;
      // min-height: 400px;
      border-radius: 30px;
      padding: 20px;
      background-color: #febf00;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        object-fit: contain;
        border-radius: 30px;
      }
    }

    .videoText {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 36px;
        line-height: 46px;
      }

      p {
        font-size: 20px;
        line-height: 24px;
      }

      .videoDescription {
        margin: 30px 0px 0px;
      }

      @media (max-width: 786px) {
        h1 {
          font-size: 27px;
          line-height: 32px;
        }

        p {
          font-size: 14px;
          line-height: 20px;
        }

        .videoDescription {
          margin: 15px 0px 0px;
        }
      }
    }
  }

  .stageContainer {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;

    .stage {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .progressIcon {
      width: 75px;
      height: 75px;

      @media (max-width: 900px) {
        width: 60px;
        height: 60px;
      }
    }

    .stageInfo {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}

.competitionCardMobile {
  background: $green-light; /* Green background */
  color: white; /* White text */
  padding: 15px;
  border-radius: 30px; /* Rounded corners */
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Slight shadow */
  cursor: pointer;
  max-width: 320px;
  width: 100%;
  margin: auto;

  @media (min-width: 1024px) {
    display: none;
  }
}

.characterFormTop {
  margin-top: 40px;

  @media (max-width: 1024px) {
    margin-top: 0px;
  }
}
