.subscribeMailingAction {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.heading {
  font-size: 16px;
  line-height: 20px;

  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.illuminate {
  .heading {
    display: none;
  }
}

.dailyRebbeVideo {
  .heading {
    display: none;
  }
}

.popupButton {
  border-style: solid;
  border-color: transparent;
  background-color: #ff8a34;
  color: #ffffff;
  border-width: 1px;
  border-radius: 22px;
  padding: 4px 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  @media (max-width: 480px) {
    font-size: 12px;
    line-height: 18px;
  }

  &:hover {
    box-shadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
    background-color: #ee802f;
  }
}
