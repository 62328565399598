@import "../../../../../../../styles/utilities/index.scss";

.infoContainer {
  display: flex;
  flex-direction: column;
  color: $colorBlack;
  gap: 23px;
  align-self: center;
  background-color: #fcf4ee;
  max-width: 500px;

  .boxedlayout {
    position: relative;

    &::before {
      content: "";
      width: 100%;
      border-top: 3px dotted #e4a9ab;
      position: absolute;
      top: 0;
      max-width: 320px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .headingContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 35px;

    p {
      font-size: 18px;
      line-height: 28px;
    }

    h3 {
      span {
        display: none;
      }
    }
  }

  .dedicatedContainer {
    display: flex;
    flex-direction: column;

    h3 {
      color: #bf3c48;
      font-size: 18px;
      text-align: center;
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      gap: 3px;
      margin-top: 14px;
      margin-bottom: 18px;
      text-align: center;
      p {
        font-size: 16px;
        line-height: 25px;
      }
      .bold {
        font-weight: bold;
      }
    }
  }
}

.arrowContainer {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 1024px) {
  .headingContainer {
    margin-top: 0px !important;
  }

  .infoContainer {
    max-width: 100%;
    margin-top: 15px;
  }

  .textContainer {
    p {
      font-size: 16px !important;
    }
  }
}

@media (max-width: 1024px) {
  .container {
    padding: 0px 0px 45px;
  }

  .wrapper {
    padding: 0px;
  }

  .infoContainer {
    padding: 0px 15px;
    max-width: 100%;
    width: 100%;
    margin-top: 5px;

    Button {
      display: flex;
      gap: 10px;
      justify-content: space-around;
      align-items: center;
      background-color: #c64e59;

      &:hover {
        background-color: #c64e59;
      }
    }
  }

  .bannerContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url(../../../../../../../assets/images/illuminateVideo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 350px;
  }

  .search {
    display: none;
  }
  .headingContainer {
    h3 {
      span {
        display: inline !important;
      }
    }
  }
  .dedicatedContainer {
    align-items: center;
  }

  .videoContainer {
    display: none;
  }
}

@media (max-width: 500px) {
  .headingContainer {
    margin-top: 0px !important;
    p {
      font-size: 16px !important;
      line-height: 24px !important;

      br {
        display: none;
      }
    }
  }
}

@media (max-width: 450px) {
  .container {
    gap: 18px;
  }
  .headingContainer {
    h3 {
      span {
        br {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .textContainer {
    p {
      font-size: 16px !important;
    }
  }

  .headingContainer {
    h3 {
      font-size: 22px !important;
    }
  }
}

@media (max-width: 350px) {
  .infoContainer {
    hr {
      width: 280px !important;
    }

    .headingContainer {
      margin-top: 0px;
      h3 {
        font-size: 18px !important;

        span {
          br {
            display: none;
          }
        }
      }
    }
  }

  .textContainer {
    p {
      font-size: 14px !important;
    }
  }

  Button {
    display: flex;
    gap: 10px;
    font-size: 14px;
  }
}
