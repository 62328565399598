@import "../../../styles/utilities/index.scss";

.container {
  color: $colorBlueDark;

  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
}

.wrapper {
  padding-top: 48px;
  padding-bottom: 48px;
}

.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
}


.subtitle{
  font-size: 20px;
  line-height: 26px;
  margin-top: 15px;
}