@import "../../../../../styles/utilities/index.scss";
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@700;800;900&display=swap");

.pledgePartnersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  background-color: $colorWhite;
  padding: 40px 40px;
  border-radius: 25px;

  h1 {
    text-transform: uppercase;
    color: #ac464d;
    font-family: "League Spartan", sans-serif;
    font-weight: 800;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
  }

  .pledgePartners {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 35px;
    row-gap: 20px;
    padding-right: 90px;
    max-height: 200px;
    overflow-y: scroll;
    width: 100%;
    max-width: 650px;
    p {
      font-size: 18px;
    }

    &::-webkit-scrollbar {
      width: 20x;
      height: 15px;
      background-color: transparent;
      margin-left: 30px;
      border: 0.5px solid #f0d3d6;
      border-radius: 15px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 15px;
      height: 15px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c9515a;
      border-radius: 15px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }
    /* Buttons */
    &::-webkit-scrollbar-button:single-button {
      background-color: transparent;
      display: block;
      border-style: solid;
      height: 14px;
      width: 5px;
    }
    /* Up */
    &::-webkit-scrollbar-button:single-button:vertical:decrement {
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent #c9515a transparent;
      background-clip: padding-box;
    }

    /* Down */
    &::-webkit-scrollbar-button:single-button:vertical:increment {
      border-width: 8px 8px 0 8px;
      border-color: #c9515a transparent transparent transparent;
      background-clip: padding-box;
    }
  }
}

.displayMobile {
  display: none;
}

@media (max-width: 500px) {
  .pledgePartnersContainer {
    padding: 40px 30px;
    border-radius: 12px;

    h1 {
      font-size: 24px !important;
      line-height: 24px !important;
      text-align: center;
    }
  }
  .displayMobile {
    display: block;
  }

  .pledgePartners {
    grid-template-columns: auto !important;
    padding-right: 20px !important;

    p {
      font-size: 14px !important;
    }
  }
}

.myPledgeContainer {
  max-width: 880px;
  width: 100%;
  display: block;
}
