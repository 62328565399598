@import "../../../styles/utilities/index.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  display: block;
  width: 100%;
  height: auto;
  animation: spin $timeXXXL linear infinite;
}

/* color */

.primary {
  .icon {
    > g {
      fill: $colorOrange;
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* size */

.s {
  .box {
    $value: 20px;

    width: $value;
    height: $value;
  }
}

.medium {
  .box {
    $value: 24px;

    width: $value;
    height: $value;
  }
}

.large {
  .box {
    $value: 36px;

    width: $value;
    height: $value;
  }
}

.extraLarge {
  .box {
    $value: 48px;

    width: $value;
    height: $value;
  }
}

.white {
  .icon {
    > g {
      fill: #fff;
    }
  }
}
