@import "../../../styles/utilities/index";

.container {
  position: fixed;
  z-index: 4;
  width: 100%;
  background: #fbe6ccf0;
  text-align: center;
  height: 100vh;
}

.sectionContainer {
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
  color: #233c59;
}

.projects {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.item {
  width: 200px;
  padding: 20px 0px;
  background-color: $colorWhite;
  border-radius: 20px;
}

.image {
  width: 100px;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
  }
}

.title {
  font-size: 18px;
  color: $colorOrange;
  font-weight: bold;
  margin-top: 5px;
}