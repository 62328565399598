@import "../../../styles/utilities//index.scss";

$colorGreyLight: #f6f6f6;
$colorGreyDark: #606060;

.preview {
  border-radius: 10px;
  background-color: $colorGreyLight;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 176px;
}

.languageViewsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.playerControlWrapper {
  display: block;
}

.playerPlay {
  text-align: center;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  cursor: pointer;
  background: #ffffffb3;
  padding: 20px 0px 0px 6px;
  border-radius: 50%;
  border: 1px solid $colorOrange;
  position: absolute;
  left: 0;
  right: 0;
  top: 48px;
}

.illuminate {
  .playerPlay {
    top: 125px;
  }
  .preview {
    height: 350px;
    width: 220px;
  }

  @media (max-width: 768px) {
    .playerPlay {
      top: 48px;
    }

    .preview {
      height: 176px;
      width: auto;
    }
  }
}

.watchlistButton {
  position: absolute;
  right: 14px;
  top: 14px;
  cursor: pointer;
  background-color: #ffffffe0;
  border-radius: 50%;
  padding: 1px;
}

.downloadButton {
  position: absolute;
  left: 14px;
  top: 14px;
  cursor: pointer;
  background-color: #ffffffe0;
  border-radius: 50%;
  padding: 4px;
}

.details {
  margin-top: 8px;

  .videoHeader {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;

    h1 {
      font-size: 16px;
      font-weight: 400;

      // @media (max-width: 480px) {
      //   font-size: 14px;
      // }

      span {
        font-size: 12px;
        display: flex;
        gap: 5px;
        align-items: center;

        i {
          width: 3px;
          height: 3px;
          background-color: #404040;
          border-radius: 100%;
        }
      }
    }

    svg {
      color: #000;
      width: 16px;
    }
  }
}

.title {
  margin: 0;

  font-weight: 400;
  font-size: 1rem!important;
  // line-height: 14px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

h4.title {
  margin: 0;

  font-weight: 400;
  font-size: 1rem;
  // line-height: 14px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.languages {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 8px 0 0;
  padding: 0;

  font-size: 1rem;
  line-height: 20px;

  > .item {
    &:not(:first-of-type) {
      margin-left: 8px;
      border-left: 1px solid black;
      padding-left: 8px;
    }
  }
}
.item {
  position: relative;
}

.button {
  border: none;
  padding: 0;

  text-transform: uppercase;
  background-color: transparent;
  color: $colorOrange;
  cursor: pointer;
}

.statistics {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 8px 0 0;
  padding: 0;

  font-size: 14px;
  line-height: 20px;
  color: $colorGreyDark;

  > .item {
    &:not(:first-of-type) {
      margin-left: 8px;
    }
  }
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  min-height: 100vh;

  @media (min-width: $m) {
    padding: 60px 48px;
  }
}

.videoTime {
  position: absolute;
  color: #fff;
  background: #404040;
  font-size: 14px;
  padding: 3px;
  line-height: 1;
  right: 5px;
  bottom: 5px;
  border-radius: 4px;
}

.mobilePreview {
  border-radius: 0;
}

.mobileDetails {
  padding: 0 15px;
}
