@import '../../../styles/utilities/index.scss';

.planName {
	display: block;
}

.planUpgrade {
	font-size: 14px;
	margin-right: 10px;

	a{
		color: #008000;
	}
}

.renewOnButton {
	background: transparent;
	border: none;
	padding: 0;
	margin-top: 5px;
	color: #008000;
	font-size: 12px;
}

.startDonationButton{
	background: transparent;
	border: none;
	padding: 0;
	margin-top: 5px;
	color: #008000;
	font-size: 14px;
	text-decoration: none;
}

.partnershipStatusText{
	font-size: 10px;
	margin-left: 5px;
	color: gray;
	text-wrap: nowrap;
}

.planCancel {
	color: #ce1e0a;
	cursor:pointer;
	font-size: 12px;
	display: block;
}

.confirmationBox .modal-overlay{
	z-index: 201;
}

.confimrationMessage { 
	font-size: 20px;
	line-height: 24px;
}

.passwordVerification{
	width: 60%;
    margin: 20px auto;
}

.becomePartner{
	background: transparent;
	border: none;
	padding: 0;
	margin-top: 5px;
	color: #008000;
	font-size: 14px;
	text-decoration: none;
}

.fontChange {
	font-size: 20px !important;
}

.boundary {
    border: none;
    border-top: 1px solid $colorGreyNew;
	margin: 15px 0px;
  }

  .red{
	border-top: 1px solid $colorOrange;
  }

  .freeTailExpire{
	color: #2d83d5;
	font-size: 12px;
  }