@import "../../../styles/utilities/index.scss";
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@700;800;900&display=swap");

.backgroundPledge {
  background-color: #fbac2e;
  // background-image: url(../../../assets//images/pledge-back-6.jpg);
  background-image: url(../../../assets/images/maor-pledge-logo-background.png);
}

.container {
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  .bold {
    font-weight: bold;
  }

  a{
    text-decoration: none;
  }
}

.fontFamilyLeagueSpartan {
  font-family: "League Spartan", sans-serif;
  font-weight: 600;
}

.headerText {
  h1 {
    text-transform: uppercase;
    font-size: 42px;
    color: white;
    line-height: 44px;
    text-align: justify;
    font-family: "League Spartan", sans-serif;
    font-weight: 600;
  }

  .wordSpacing {
    word-spacing: 20px;
  }

  .richText {
    font-size: 53px;
    line-height: 53px;
  }
}

.changeColor {
  color: #ac464d;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyStart {
  justify-content: start;
}

.textEnd {
  text-align: end;
}

.testStart {
  text-align: right;
}

.mainContainer {
  background-color: $colorWhite;
  padding: 30px 55px;
  border-radius: 25px;

  p {
    font-size: 18px;
    line-height: 24px;
  }
  .topConatiner {
    position: relative;
    padding-right: 45px;

    .pledgeMoney {
      position: absolute;
      right: -5px;
      top: 78px;
      img {
        width: 135px;
        height: 135px;
      }
    }
    h2 {
      color: #ac464d;
      font-size: 24px;
      line-height: 28px;
      text-transform: uppercase;
    }
  }

  .rebbeTopConatiner {
    display: flex;
    flex-direction: row;
  }

  .smallText {
    display: flex;
    font-size: 12px;
    line-height: 14px;
    flex-direction: column;
  }

  .selfStart{
    align-self: flex-start;
  }

  .hebrewConatiner {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 35px;
    }
  }

  .marginRight {
    margin-right: 80px;
  }
}

.hebrewText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hebrewTextCenter {
  font-size: 18px !important;
  line-height: 19px !important;
  font-weight: 600;
  text-align: center;
}

.headerTextFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 35px;
}

.hebrewText1 {
  background-image: url(../../../assets//images/pledge-1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  width: 100%; /* Adjust the width as needed */
  height: 130px;
}

.hebrewText2 {
  background-image: url(../../../assets//images/pledge-2.png);
  background-repeat: no-repeat;
  background-position: center;

  background-size: 100% auto;
  width: 765px;
  height: 110px;
}

.hebrewText3 {
  background-image: url(../../../assets//images/pledge-3.png);
  background-repeat: no-repeat;
  background-position: center;

  background-size: 100% auto;
  width: 715px;
  height: 190px;
}

.desktopDisplay {
  display: block;
}

.mobileDisplay {
  display: none;
}

.maorPledgeContainer{
  max-width: 880px;
  width: 100%;
  display: block;
}

.mobileHeading{
  display: none;
}

@media (max-width: 910px) {
  .mainContainer {
    padding: 40px 30px;
  }
  .hebrewText1 {
    width: 540px;
    height: 90px;
  }
  .hebrewText2 {
    width: 600px;
    height: 90px;
  }
  .hebrewText3 {
    width: 560px;
    height: 150px;
  }
}

@media (max-width: 720px) {
  .mobileDisplayNone {
    display: none;
  }

  .topConatiner {
    padding-right: 0px !important;
  }

  .marginRight {
    margin-right: 0px !important;
  }

  .mobileMarginRight {
    margin-right: 90px;
  }

  .pledgeMoney {
    right: -8px !important;
    top: 15px !important;
    img {
      width: 100px !important;
      height: 100px !important;
    }
  }

  .mainContainer {
    p {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .mainContainer {
    padding: 40px 30px;
  }
  .hebrewText1 {
    width: 400px;
    height: 80px;
  }
  .hebrewText2 {
    width: 410px;
    height: 70px;
  }
  .hebrewText3 {
    width: 400px;
    height: 100px;
  }

  .headerText {
    h1 {
      font-size: 40px !important;
      line-height: 54px !important;
      text-align: center;
    }

    .wordSpacing {
      word-spacing: 0px;
    }

    .richText {
      font-size: 45px;
      line-height: 55px;
    }
  }
}

.brownBackground {
  background-color: #feebcd;
  border-radius: 10px;
  padding: 1px 0px 0px 4px;
}

@media (max-width: 500px) {

  .mobileHeading{
    font-size: 35px !important;
    line-height: 35px !important;
  }

  .topConatiner {
    h2 {
      font-size: 20px !important;
      line-height: 24px !important;
    }
  }

  .mobileMarginRight {
    margin-right: 90px;
  }

  .desktopDisplay {
    display: none;
  }

  .mobileDisplay {
    display: block;
  }

  .container {
    gap: 40px;
  }
  .mainContainer {
    padding: 30px 30px;
    width: 100vw;
    border-radius: 0px;
  }

  .hebrewText1 {
    background-image: url(../../../assets/images/pledge-back-mobile-1.png);
    width: 390px;
    height: 80px;
  }
  .hebrewText2 {
    background-image: url(../../../assets/images/pledge-back-mobile-2.png);
    width: 390px;
    height: 90px;
  }
  .hebrewText3 {
    background-image: url(../../../assets/images/pledge-back-mobile-3.png);
    width: 390px;
    height: 135px;
  }

  .smallText {
    font-size: 9px !important;
  }

  .mobileDisplay {
    display: block;
  }
}

@media (max-width: 435px) {

  .mobileHeading{
    display: block;
  }

  .desktopHeading{
    display: none;
  }

  .hebrewText1 {
    width: 340px;
    height: 75px;
  }
  .hebrewText2 {
    width: 340px;
    height: 80px;
  }
  .hebrewText3 {
    width: 340px;
    height: 120px;
  }
}

@media (max-width: 380px) {
  .mainContainer {
    padding: 25px 14px;
  }
  .headerText {
    h1 {
      font-size: 35px;
      line-height: 40px;
    }
  }

  .hebrewText1 {
    width: 300px;
    height: 65px;
  }
  .hebrewText2 {
    width: 300px;
    height: 65px;
  }
  .hebrewText3 {
    width: 300px;
    height: 105px;
  }

  .smallText {
    font-size: 8px !important;
  }
}
