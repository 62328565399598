@import "../../../styles/utilities/index.scss";

.container {
  $size: 36px;

  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  background-color: transparent;

  width: $size;
  height: $size;

  transition: opacity $timeS;
  cursor: pointer;

  @media (min-width: $l) {
    &:hover {
      opacity: .75;
    }
  }
}

// .icon {}
