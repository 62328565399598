@import "../../../styles/utilities/index.scss";

$bgGradientViolet: linear-gradient(
  180deg,
  rgba(236, 240, 253, 0) 0%,
  rgba(236, 240, 253, 0.53) 14.32%,
  #ecf0fd 45.83%,
  rgba(236, 240, 253, 0.43) 84.33%,
  rgba(236, 240, 253, 0) 100%
);
$bgGradientVioletLight: linear-gradient(
  180deg,
  rgba(180, 104, 255, 0) -2.05%,
  #b468ff 99.36%
);
$backgroundGradientViolet: linear-gradient(180deg, #ffeedb 0%, #b468ff 45.64%);
$bgGradientWhite: linear-gradient(
    180deg,
    #ffe8d6 0%,
    rgba(255, 255, 255, 0) 44.26%
  ),
  #ffffff;
$colorGreyLight: #f6f6f6;

.container {
  // &.default {}

  &.child {
    background: $bgGradientViolet;
  }

  // &.partners {}
}

.wrapper {
  padding-top: 40px;
  padding-bottom: 104px;
  max-width: 1326px !important;
  min-height: 100vh;

  @media (min-width: $m) {
    padding-top: 100px;
  }

  > .logotype {
    display: flex;
    justify-content: center;
  }
}

.card {
  position: relative;

  margin-top: 20px;
  border-radius: 26px;
  min-height: 634px;

  background-color: $colorWhite;

  @media (min-width: $m) {
    display: grid;
    grid-template-areas:
      "aside header"
      "aside main"
      "aside footer";
    grid-template-columns: 316px 1fr;
    grid-template-rows: auto 1fr auto;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  width: 100%;
  height: 100%;

  @media (min-width: $m) {
    display: none !important;
  }
}

.aside {
  position: absolute;
  top: 0;
  left: -312px;
  z-index: 20;
  min-width: 316px;
  min-height: 100%;

  grid-area: aside;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 26px 0px 0px 26px;
  padding: 60px 44px;
  // padding: 20px 44px 60px;

  transition: left 1s;

  @media (min-width: $m) {
    position: static;
    min-height: 0;
  }

  .default & {
    background: $bgGradientVioletLight;
  }

  .child & {
    // background-color: rgba($colorOrange, .2);
    background: $backgroundGradientViolet;
    color: $colorWhite;
  }

  .partners & {
    background: linear-gradient(180deg, #ffdfbe 0%, #fcab28 90.36%);
    color: $colorWhite;
  }

  &.opened {
    left: 0;
  }
}

.button {
  position: absolute;
  top: 32px;
  right: 0;

  border: none;
  padding: 0;

  background-color: transparent;
  color: $colorOrange;

  @media (min-width: $m) {
    display: none;
  }

  &.rotated {
    transform: rotate(180deg);
  }
}

.progress {
  margin: 0;
  padding-left: 24px;
  width: 100%;

  @media (min-width: $m) {
    margin-bottom: auto;
  }
}

.package {
  position: relative;

  margin-top: 132px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 40px 24px 12px;
  width: 100%;
  max-width: 200px;

  font-size: 18px;
  line-height: 30px;
  background: $bgGradientWhite;
  color: $colorOrange;

  .illustration {
    position: absolute;
    bottom: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    width: 100%;
    max-width: 86px;

    svg {
      width: 100%;
      height: auto;
    }
  }

  .title {
    max-width: 100px;

    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: $colorBlueDark;
  }

  .link {
    margin-top: 8px;
    font-weight: 700;
    font-size: 18px;
  }
}

.header {
  grid-area: header;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 36px 24px 40px;
  min-height: 80px;
  font-size: 20px;
  color: $colorBlue;
  font-weight: 600;
}

.main {
  grid-area: main;
  border-radius: 0px 26px 0px 0px;
  padding: 0 34px 40px 40px;

  @media (min-width: $xxl) {
    padding-right: 56px;
  }
  @media (max-width: $m) {
    padding: 0px 0px 0px 15px;
  }
  @media (max-width: 767px) {
    padding: 0 34px 40px 40px;
  }
}

.footer {
  grid-area: footer;
  border-radius: 0px 0px 26px 0px;
  padding: 20px 25px;
  min-height: 92px;

  background-color: $colorGreyLight;

  @media (min-width: $m) {
    padding-right: 30px;
    padding-left: 30px;
  }

  .item {
    &:not(:first-of-type) {
      // margin-bottom: 12px;
    }

    @media (min-width: $m) {
      &:not(:first-of-type) {
        margin-bottom: 0;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
  }

  .bar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @media (min-width: $m) {
      flex-direction: row;
    }
  }
}

.logoutLink {
  color: $colorOrange;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.loggedInText {
  margin-right: 10px;
  color: $colorBlack;
}

.familyText {
  color: $colorBlue;
}

.createAnAccount {
  color: $colorBlue;
}
