@import "../../../../../../../styles/utilities/index.scss";

.videoContentMain {
  display: flex;
  justify-content: center;
  gap: 28px;

  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    max-width: 768px;
    padding: 0px;
  }
  .videoWrapMain {
    width: 100%;
    max-width: 400px;
    max-height: 650px;
    height: 100%;

    @media (max-width: 786px) {
      max-width: 100%;
    }

    .videoBoxInner {
      position: relative;
    }
    .videoInfo {
      z-index: 9;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      .videoInfoText {
        background: #fff;
        color: #27305a;
        border-radius: 6px;
        line-height: 16px;
        font-size: 14px;
        padding: 4px 10px;
      }
      .videoInfoBtn {
        display: inherit;
        gap: 5px;

        button {
          background: #ffffff;
          border-radius: 12px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #ff8a34;
          border: none;
          padding: 8px 14px;
        }
      }
    }
    .videoTags {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 5px;
      gap: 5px;
      overflow: hidden;
      max-height: 22px;
      white-space: nowrap;

      a {
        color: #2d83d5;
        font-size: 14px;
        line-height: 20px;
        text-decoration: none;
      }
    }
  }

  .ContentWrapMain {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      width: 100%;
    }
    h3 {
      font-size: 24px;
      line-height: 30px;
    }
    p {
      font-size: 18px;
      line-height: 20px;
    }
    .popupButton {
      border-style: solid;
      border-color: transparent;
      background-color: #ff8a34;
      color: #ffffff;
      border-width: 1px;
      border-radius: 22px;
      padding: 6px 15px;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.2px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;

      &:hover {
        box-shadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
        background-color: #ee802f;
      }
    }
    .boxedlayout {
      // background: #FFFFFF;
      // border: 1px solid #FF8A34;
      // border-radius: 16px;
      // padding:20px;
      margin-top: 25px;
      padding-top: 25px;
      position: relative;

      &:before {
        content: "";
        width: 100%;
        border-top: 2px dotted #ff8a34;
        position: absolute;
        top: 0;
        max-width: 320px;
        left: 50%;
        transform: translateX(-50%);
      }

      * {
        font-size: 16px;
        line-height: 24px;
      }

      .subtitle {
        font-size: 18px;

        @media (max-width: 768px) {
          line-height: 20px;
        }
      }
    }
  }
}

.videoNotFoundContainer {
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.videoNotFound {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 45px;

  img {
    max-height: 200px;
    width: auto;
  }

  h2 {
    color: $colorOrange;
  }

  @media (max-width: 786px) {
    gap: 25px;
    img {
      max-height: 180px;
    }

    h2 {
      font-size: 24px;
    }
  }
}

.mobileVideoContent {
  padding: 0;
  flex-wrap: wrap;
  background-color: transparent;
  gap: 10px;

  .videoWrapMain {
    max-width: 100%;

    .videoTags {
      width: 100%;
      overflow-x: auto;
      flex-wrap: nowrap;
      align-items: center;
      gap: 7px;
      overflow: hidden;

      a {
        font-size: 12px;
        min-width: max-content;
      }
    }
  }

  .ContentWrapMain {
    width: 100%;

    .videoHeader {
      display: flex;
      grid-gap: 10px;
      gap: 10px;
      justify-content: space-between;
      align-items: flex-start;

      @media (max-width: 768px) {
        justify-content: center;
      }

      h3 {
        font-size: 22px;
        line-height: 26px;
      }
    }

    .subtitle {
      text-align: left;
    }
    .boxedlayout:before {
      max-width: 90%;
      border-width: 2px;
    }
  }
}

.loadingBox {
  display: flex;
  justify-content: center;
  height: 550px;
  width: 100%;

  @media (max-width: 786px) {
    height: 1000px;
  }

  @media (max-width: 600px) {
    height: 720px;
  }

  .innerBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
