// TODO: rename all the old grey colors and integrate new palette
$colorGrey: #636363; // Old, should be removed

/* palette */
$colorOrange: #ff8a34;
$colorOrangeDark: #ee802f;
$colorBlue: #2d83d5;
$colorBlueDark: #27305a;
$colorGreyLight: #f6f6f6;
$colorGreyNew: #e0e0e0;
$colorGreyDark: #404040;
$colorBlackLight: #1b1b25;
$colorBlack: #000000;
$colorWhite: #ffffff;
$colorGold: #ffead2;
$colorRed: #ff565e;
$colorRedLight: #fdeeee;
$colorGreenLight: #e9f4ee;
$colorPurple: #b468ff;
$colorPink: #f7e6e1;
$colorDarkPink: #bf3c48;
$colorDarkYellow: #e08906;
$colorYellow: #fff4dc;
$colorDarkPurple: #c9515a;
$colorNewPink: #ff4f88;
$colorLightGreen: #6bbc43;
$colorLightYellow: #fabb17;
$colorLightBlue: #17afaf;

/* shadows */
$shadowSoft: 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 30px 40px rgba(0, 0, 0, 0.05);
$shadowHard: 0px 10px 20px rgba(0, 0, 0, 0.1);
$boxShadow: 4px 5px 10px rgba(0, 0, 0, 0.2);

/* gradients */
$gradientPurpleVertical: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0) -1.24%,
  #fcf3ed 11.27%,
  #b468ff 37.69%
);
$gradientGoldHorizontal: linear-gradient(
  95.02deg,
  #ffffff -38.03%,
  #fcead4 18.65%,
  #ffffff 97.92%
);
$gradientGoldLightHorizontal: linear-gradient(
    180deg,
    rgba(239, 192, 161, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  ),
  #ffffff;
$gradientBrownVertical: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0) -1.24%,
  #fcf3ed 20.72%,
  #ffe8d6 50.12%
);
$gradientNavyLightVertical: linear-gradient(
  180deg,
  rgba(246, 246, 246, 0) 0%,
  #f6f6f6 11.65%
);
$gradientPurpleHorizontal: linear-gradient(
  231.81deg,
  #7f00ff 32.28%,
  #e100ff 95.8%
);
$gradientOrangeHorizontal: linear-gradient(
  270.05deg,
  #ff6a00 4.8%,
  #ee0979 96.66%
);
$graidentOrangeShade1: linear-gradient(
  to left,
  #fcead4,
  #ffece4,
  #fff2f4,
  #fff9fd,
  #fcead4
);
