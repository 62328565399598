// Component.scss

.componentContainer {
  max-width: 20rem;
  // margin: 0 auto;
  padding: 1rem;
  background-color: #fff5cd; // yellow-50
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 35px;
  min-width: 20rem;
}

.nameTag {
  background-color: #ffde59; // yellow-200
  border-radius: 9999px;
  text-align: center;
  padding: 15px;
  font-weight: bold;
  font-size: 1.125rem; // text-lg
  text-transform: capitalize;
}

.placeTag {
  text-align: center;
  padding: 15px;
  font-weight: bold;
  font-size: 1.125rem; // text-lg
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.875rem; // text-sm
  padding-left: 25px;
}

.detailsContainer .fontMedium {
  font-weight: bold;
  color: #c27900;
  font-size: 16px;
}

.detailsContainer .showMoreDetails {
  display: block;
}

.detailsButton {
  background-color: #ffde59;
  color: #c27900;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  border: none;
  border-radius: 30px;
  padding: 10px 25px;
  font-weight: bold;
  font-size: 1.125rem;
  margin: 0 auto;
}

.detailsButton:hover {
  background-color: #ffde59; // yellow-400
}

.detailsButton svg {
  margin-left: 0.5rem;
  height: 1rem;
  width: 1rem;
}

.grandstanderFamily {
  font-family: "Grandstander", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.itemKey{
  margin: 5px 0px;

  .desc{
    padding-left: 5px;
    color: #000;
    font-size: 16px;
  }
}