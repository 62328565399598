@import "../../../../../../../../../styles/utilities/index.scss";

$colorGreyLight: #f6f6f6;
$colorGreyDark: #606060;

.container{
  position: relative;
}

.preview {
  border-radius: 10px;
  background-color: $colorGreyLight;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 600px;
  padding: 16px;

  @media (max-width: 768px) {
    height: 420px;
  }

  @media (max-width: 600px) {
    height: 300px;
  }
}

.videoInfoWrap {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-start;

  position: absolute;
  width: 100%;
  padding: 16px;

  @media (max-width: 500px) {
    flex-wrap: wrap;
    gap: 5px;
  }

  .videoInfoText {
    background: #ffffff;
    border-radius: 6px;
    padding: 4px 10px;
    z-index: 9;

    @media (max-width: 500px) {
      font-size: 10px;
      line-height: 12px;
      padding: 4px 5px;
    }
  }

  .videoInfoButtons {
    display: flex;
    justify-content: flex-end;
    gap: 14px;
    align-items: center;
    z-index: 9;

    @media (max-width: 500px) {
      font-size: 10px;
      gap: 5px;
    }

    button {
      background: #ffffff;
      border-radius: 12px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #ff8a34;
      border: none;
      padding: 8px 14px;

      @media (max-width: 500px) {
        padding: 5px 10px;
        font-size: 11px;
        line-height: 14px;
      }
    }

    .shareIcon,
    .pdfIcon {
      background-color: #fff;
      padding: 6px;
      border-radius: 100%;
      cursor: pointer;

      div {
        width: 20px;
        height: 20px;

        @media (max-width: 500px) {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.languageViewsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.playerControlWrapper {
  display: block;
}

.playerPlay {
  text-align: center;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  cursor: pointer;

  position: absolute;
  left: 0;
  right: 0;
  top: 42%;
}

.watchlistButton {
  position: absolute;
  right: 14px;
  top: 14px;
  cursor: pointer;
  background-color: #ffffffe0;
  border-radius: 50%;
  padding: 1px;
}

.downloadButton {
  position: absolute;
  left: 14px;
  top: 14px;
  cursor: pointer;
  background-color: #ffffffe0;
  border-radius: 50%;
  padding: 4px;
}

.details {
  margin-top: 8px;
}

.title {
  margin: 0;

  font-weight: 700;
  font-size: 1rem;
  // line-height: 14px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.languages {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 8px 0 0;
  padding: 0;

  font-size: 1rem;
  line-height: 20px;

  > .item {
    &:not(:first-of-type) {
      margin-left: 8px;
      border-left: 1px solid black;
      padding-left: 8px;
    }
  }
}
.item {
  position: relative;
}

.button {
  border: none;
  padding: 0;

  text-transform: uppercase;
  background-color: transparent;
  color: $colorOrange;
  cursor: pointer;
}

.statistics {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 8px 0 0;
  padding: 0;

  font-size: 14px;
  line-height: 20px;
  color: $colorGreyDark;

  > .item {
    &:not(:first-of-type) {
      margin-left: 8px;
    }
  }
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  min-height: 100vh;

  @media (min-width: $m) {
    padding: 60px 48px;
  }
}

.videoTime {
  position: absolute;
  color: #fff;
  background: #404040;
  font-size: 14px;
  padding: 3px;
  line-height: 1;
  right: 5px;
  bottom: 5px;
  border-radius: 4px;
}
.previewMobile {
  border-radius: 0;
}
