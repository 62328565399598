@import "../../../../../styles/utilities/index.scss";

.businessCardConatiner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  row-gap: 35px;
}

.businessCard {
  background-color: transparent;
  max-width: 120px;
  max-height: 190px;
  perspective: 1000px;
  width: 100%;
  height: 190px;
  border-radius: 15px;

  .cardTitle {
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
  }

  .businessCardOffer {
    font-size: 14px !important;
    line-height: 16px;
    font-weight: bold;
  }

  .cardExplaination {
    font-size: 10px;
    line-height: 14px;
  }

  .businessCardImage {
    max-width: 150px;
    max-height: 100px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.businessCard.rotate .businessCardInner {
  transform: rotateY(180deg);
}

.businessCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.offerCardFooter {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.businessCardBrowser:hover .businessCardInner {
  transform: rotateY(180deg);
}

.businessCardFront,
.businessCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000;
  padding: 20px;
}

.businessCardFront {
  padding: 20px 20px 15px;
  justify-content: space-between;
  @media (max-width: 1024px) {
    // background-color: #ffedba;
    background-color: #fff;
  }
  @media (min-width: 426px) {
    // background-color: #ffedba;
    // background-color: #fff;
    background-color: #fff2cb;
    background: radial-gradient(
      circle at 50% 50%,
      #ffffff,
      #fff9e9,
      #fff3d2,
      #ffedba,
      #ffedba
    );
  }
}

.businessCardBack {
  background-color: white;
  transform: rotateY(180deg);
  justify-content: flex-start;
  border: 4px solid #fff2cb;
  padding: 20px 5px;

  .businessCardBackTop {
    display: flex;
    color: #000000;
    align-items: center;
    justify-content: center;

    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
    }
  }

  .cardInfo {
    color: #000000;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    p {
      color: #000000;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
    }
  }
}

@media (max-width: 500px) {
  .businessCardBack {
    padding: 10px;
  }
}

@media (max-width: 1076px) {
  .businessCard {
    max-width: 120px;
  }
}

@media (max-width: 440px) {
  .businessCard {
    max-width: 107px;
  }
}
