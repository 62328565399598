@import "../../../styles/utilities/index.scss";

.navigation {
  display: grid;
  grid-template-columns: auto;
  justify-items: center;
  gap: $size-05;
  list-style-type: none;
  margin: $size-10 0 0;
  padding: 0;

  @media (min-width: $m) {
    grid-template-columns: auto auto;
  }
}
