@import "../../../../../styles/utilities/index.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 75px 16px;
}

.headingContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: $colorBlueDark;
}

.headingContainer > h3 {
  text-transform: uppercase;
}

.pContainer {
  p {
    font-size: 20px;
    line-height: 24px;
  }
}

.btnWrap {
  button {
    font-size: 18px;
    padding: 10px 32px;
  }
}

.fieldContainer {
  width: 100%;
}

.titleWrap {
  display: none;
}

@media (max-width: 768px) {
  .headingContainer {
    gap: 14px;

    h3 {
      font-size: 22px;
    }
  }

  .btnWrap {
    button {
      font-size: 16px;
      line-height: 20px;
      padding: 7px 18px;
      min-height: 32px;
    }
  }

  .container {
    gap: 45px;
    padding: 50px 16px;
  }

  .pContainer {
    p {
      font-size: 16px;
    }
  }
}

@media (max-width: 460px) {
  .titleWrap {
    display: inline;
  }
  .coma {
    display: none;
  }
}

@media (max-width: 420px) {
  .btnWrap {
    button {
      margin-top: 20px;
    }
  }
}
