@import "../../../styles/utilities/index.scss";

.creditInfo {
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  cursor: pointer;
}

.maorbold {
  color: $colorOrange;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.maorCreditLogo {
  width: 70px;
  height: auto;
  margin-top: -5px ;
}

.creditSpan {
  display: flex;
  align-items: center;
  gap: 3px;
}
