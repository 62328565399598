@import "../../../../../styles/utilities/index.scss";

.list {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  list-style-type: none;
  margin: 16px 0 0;
  padding: 0;

  @media (min-width: $xl) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.nameList {
  @media (min-width: $xl) {
    grid-template-columns: 1fr 1fr 1fr 0.6fr;
  }
}

.birthday {
  display: grid;
  grid-template-areas: "datePicker rank";
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  list-style-type: none;
  // margin: 16px 0 0;
  padding: 0;

  grid-template-columns: 1.5fr 1fr;
  @media (max-width: $xl) {
    grid-template-areas:
      "datePicker"
      "rank";
    grid-template-columns: 1fr;
  }
}
.datePicker {
  display: grid;
  grid-template-areas: "day month year";
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  list-style-type: none;
  // margin: 16px 0 0;
  padding: 0;

  grid-template-columns: 12fr 10fr 8fr;
  @media (max-width: $l) {
    grid-template-areas:
      "day"
      "month"
      "year";
    grid-template-columns: 1fr;
  }
}

.datePickerEng {
  display: grid;
  grid-template-areas: "day month year";
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  list-style-type: none;
  // margin: 16px 0 0;
  padding: 0;

  grid-template-columns: 12fr 8fr 10fr;
  @media (max-width: $l) {
    grid-template-areas:
      "day"
      "month"
      "year";
    grid-template-columns: 1fr;
  }
}
.birthdayTop {
  display: flex;
  align-items: flex-end;
  gap: 25px;
  flex-wrap: wrap;
}

.birthdayOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 25px;
  flex-wrap: wrap;
}

.dateInputContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

/* container for all of the switch elements 
    - adjust "width" to fit the content accordingly 
*/
.switchesContainer {
  width: 170px;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: white;
  line-height: 26px;
  border-radius: 30px;
  border: 0.5px solid;
  color: #636363;
  border-color: #c1bbbb;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switchesContainer input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switchesContainer label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: $colorOrange;
  font-size: 12px;
}

/* switch highlighters wrapper (sliding left / right) 
  - need wrapper to enable the even margins around the highlight box
*/
.switchWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  z-index: 3;
  transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  font-size: 12px;

  /* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
  border-radius: 3rem;
  background: $colorOrange;
  height: 100%;
}

/* switch box labels
  - default setup
  - toggle afterwards based on radio:checked status 
*/
.switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  color: white;
  transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
}

/* slide the switch box from right to left */
.switchesContainer input:nth-of-type(1):checked ~ .switchWrapper {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
.switchesContainer input:nth-of-type(2):checked ~ .switchWrapper {
  transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switchesContainer
  input:nth-of-type(1):checked
  ~ .switchWrapper
  .switch
  div:nth-of-type(1) {
  opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switchesContainer
  input:nth-of-type(2):checked
  ~ .switchWrapper
  .switch
  div:nth-of-type(2) {
  opacity: 1;
}

.disabelShkiyaCheckbox {
  pointer-events: none;
  filter: contrast(0.5);
}

.dateContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.dateText {
  margin-left: 5px;
  p {
    font-weight: bold;
    color: #636363;
  }
}

.hide {
  display: none;
}

.childrenCardContainer {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;
}

.addChildBox {
  width: 330px;
  min-width: 300px;
  height: 164px;
  border: 3px dashed #ff820e;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  color: #ff820e;
  flex-direction: column;
  margin: 16px 32px;
  -webkit-box-shadow: 1px 3px 15px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 3px 15px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 3px 15px -5px rgba(0, 0, 0, 0.75);
}

.pickChildBox {
  width: 330px;
  min-width: 300px;
  height: 164px;
  border: 3px solid #ff820e;
  border-radius: 20px;
  cursor: pointer;
  margin: 16px 32px;
  -webkit-box-shadow: 0px 2px 15px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 15px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 15px -6px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  position: relative;
}

.childBoxContent {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin: 5px 8px;
}

.childName {
  height: 32px;
  width: 32px;
  border-radius: 100px;
  margin-right: 16px;
  border: 2px solid #ff820e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconStyle {
  color: #ff820e;
  height: 32px;
  width: 32px;
  margin-right: 16px;
}

.childrenCardContainer {
  p {
    font-weight: 600;
    line-height: 20px;
  }
}

.font32 {
  font-size: 32px;
}

.addChild {
  font-size: 18px;
  margin-top: 8px;
}

.mt05 {
  margin-top: 5px;
}

@media (max-width: 1280px) {
  .addChildBox {
    width: 300px;
    margin: 16px 16px;
  }
  .pickChildBox {
    width: 340px;
    margin: 16px 16px;
  }
}

@media (max-width: 1150px) {
  .childrenCardContainer {
    justify-content: center;
  }

  .addChildBox {
    width: 340px;
    margin: 16px 16px;
  }
  .pickChildBox {
    width: 340px;
    margin: 16px 16px;
  }
}

@media (max-width: 820px) {
  .pickChildBox {
    width: 310px;
    margin: 16px 16px;
  }
}

.highIndex {
  z-index: 99999;
}

.personalInformation {
  display: grid;
  grid-template-areas: "rank gender";
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  list-style-type: none;
  padding: 0;
  grid-template-columns: 1fr 1.5fr;

  @media (max-width: $xl) {
    grid-template-areas: none;
    grid-template-columns: 1fr;
  }
}

.inputStyle {
  position: relative;
  width: 100%;
  border-radius: 8px;
  border-width: 1px;
  border-color: #c1bbbb;
  background-color: #ffffff;
  color: #636363;
  border: 1px solid #c1bbbb;
  padding: 12px 30px 10px 15px;
  display: flex;
  justify-content: center;
}

.tape {
  top: 0;
  left: 30px;
  max-width: calc(100% - 40px);
  padding: 0 14px;
  height: 2px;
  overflow: hidden;
  position: absolute;
  color: #ffffff;
  white-space: nowrap;
  opacity: 1;
  background: #ffffff;
  width: 70px;
}

.inputLabel {
  overflow: hidden;
  position: absolute;
  transition: all 0.25s;
  color: #636363;
  cursor: pointer;
  white-space: nowrap;
  padding: 0 14px;
  max-width: calc(100% - 40px);
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  left: 20px;
  bottom: 100%;
  transform: translateY(50%);
}

.relative {
  position: relative;
  width: fit-content;

  @media (max-width: 1180px) {
    width: 100%;
    max-width: 320px;
  }
}

.inputLine {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  display: inline-block;
  background-color: #27305a;
}

.crossButton {
  position: absolute;
  top: 8px;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #fff;
  padding: 7px;
  right: 8px;
}

.deleteModalConatiner {
  margin: 0 auto;
  padding: 80px 16px;
  width: 100%;
  max-width: 612px;
  min-height: 100vh;
}

.deleteModalCard {
  border-radius: 26px;
  padding: 32px 32px 64px;
  background-color: #ffffff;
  color: #27305a;
  text-align: center;

  h1 {
    line-height: 30px;
  }

  .main {
    margin-top: 32px;
  }
}

.addBtn {
  border-style: solid;
  border-color: transparent;
  background-color: #ff8a34;
  color: #ffffff !important;
  border-width: 1px;
  border-radius: 22px;
  padding: 8px 25px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  &:hover {
    box-shadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
    background-color: #ee802f;
  }

  &:disabled {
    background-color: #828282;
    cursor: not-allowed;
  }
}
