@import "../../../../styles/utilities/index.scss";

.mobileTags{
	// padding:20px;
	// max-width: 480px;

	.tagsListWrap{
		display: flex;
		align-items: center;
		list-style-type: none;
		margin: 0;
		padding: 10px;
		overflow-x: auto;
		gap: 10px;

		.checked {
			font-weight: bold;
		}

		.tagsList{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;

			span{
				border-radius: 8px;
				text-align: left;
				cursor: pointer;
				padding: 5px 10px;
				font-size: 14px;
				line-height: 16px;
				// font-weight: 700;
				color: #27305A;
				transition: 0.5s ease;
				width: max-content;

				@media (max-width: 400px) {
					font-size: 12px;
					line-height: 16px;
					padding: 5px 10px;
				}
			}
		}

		li.tagsList:nth-child(9n+1) span{
		  background-color: #c7dbff;
		}
		li.tagsList:nth-child(9n+2) span{
		  background-color: #dfd6e8;
		}
		li.tagsList:nth-child(9n+3) span{
		  background-color: #f2dce7;
		}
		li.tagsList:nth-child(9n+4) span{
		  background-color: #FBDCD5;
		}
		li.tagsList:nth-child(9n+5) span{
		  background-color: #FDECD9;
		}
		li.tagsList:nth-child(9n+6) span{
		  background-color: #fbfadd;
		}
		li.tagsList:nth-child(9n+7) span{
		  background-color: #E1F1DE;
		}
		li.tagsList:nth-child(9n+8) span{
		  background-color: #CCEAE4;
		}
		li.tagsList:nth-child(9n+9) span{
		  background-color: #E1EEF9;
		}

    li.tagListDRV:nth-child(9n+1) span {
      background-color: #ffe99b;
    }
    li.tagListDRV:nth-child(9n+2) span {
      background-color: #fddda1;
    }
    li.tagListDRV:nth-child(9n+3) span {
      background-color: #fbc8a5;
    }
    li.tagListDRV:nth-child(9n+4) span {
      background-color: #f9baab;
    }
    li.tagListDRV:nth-child(9n+5) span {
      background-color: #f8b0b2;
    }
    li.tagListDRV:nth-child(9n+6) span {
      background-color: #e5b1bb;
    }
    li.tagListDRV:nth-child(9n+7) span {
      background-color: #d6b4d7;
    }
    li.tagListDRV:nth-child(9n+8) span {
      background-color: #d6b4d7
    }
    li.tagListDRV:nth-child(9n+9) span {
      background-color: #c5b6d7;
    }
    li.tagListDRV:nth-child(9n+10) span {
      background-color: #b6b6d6;
    }
	}
}



$colorLightGrey: #F6F6F6;
$colorLightPurple: #DBD1EE;
$colorDarkPurple: #ae8fe9;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  column-gap: 10px;
}

.itemHoliday {
  display: flex;
  justify-content: center;
  align-items: center;

  .input {
	display: none;
  }

  .input:checked {
    & + .labelHoliday {
	  font-weight: bold;
    }
  }

//   .checkedHoliday {
// 	font-weight: bold;
//   }

  .labelHoliday {
    text-align: center;
    background-color: $colorWhite;
    color: $colorOrange;
    border:1px solid $colorOrange;
    width: max-content;
	  border-radius: 8px;
    cursor: pointer;
    padding: 4px 10px;
    font-size: 14px;
    line-height: 16px;
    transition: 0.5s ease;
    
    @media screen and (max-width:400px) {
      font-size: 12px;
    }
  }

}



/* colors */

.primary {

  .label {
    background-color: $colorLightGrey;
    color: $colorGrey;
  }

  .input:checked {
    & + .label {
      background-color: $colorOrange;
      color: $colorWhite;
    }
  }
  
}

.purple {

  .label {
    background-color: $colorLightPurple;
    color: $colorGrey;
  }

  .input:checked {
    & + .label {
      background-color: $colorDarkPurple;
      color: $colorWhite;
    }
  }
  
}

/* sizes */

.s {
  &.container {
    border: 1px solid $colorLightGrey;
    border-radius: 4px;
    padding: 2px;
    width: 100%;
    max-width: 256px;
    margin: 0 auto;
  }

  .label {
    border-radius: 4px;
    padding: 2px 20px;
    min-width: 124px;
    min-height: 22px;

    font-size: 13px;
    line-height: 18px;
  }
}

.m {
  &.container {
    // width: 100%;
    // max-width: 360px;
    // margin: 0 auto 60px;
  }

  .label {
    border-radius: 12px;
    padding: 8px 32px;
    height: 40px;

    font-size: 16px;
    line-height: 24px;
    color: $colorBlueDark;

    @media (max-width: $m) {
      font-size: 14px;
      padding: 12px 24px;
      line-height: normal;
    }

    @media (max-width: $s) {
      padding: 12px 14px;
    }
  }
    
}

/* modifications */

.vertical {
  &.container {
    max-width: 100%;
  }
}

.vertical {
  &.container {
    flex-direction: column;
  }
}