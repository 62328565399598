@import "../../../styles/utilities/index.scss";

.vbHidden {
  visibility: hidden;
}

.donationHeading {
	color: #000;
}

.header {
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: $size-06;
	grid-row-gap: $size-03;

	@media (min-width: $m) {
		grid-template-columns: 100px 1fr;
	}

	.message {
		text-align: center;

		@media (min-width: $m) {
			text-align: left;
		}
	}
}

.group {
	transition: opacity $timeS;

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}

.boundary {
	border: none;
	border-top: 1px solid $colorGreyNew;
}

.list {
	display: grid;
	grid-column-gap: $size-04;
	grid-row-gap: $size-04;
}

.address {
	grid-template-areas:
		"street"
		"apartment"
		"city"
		"state"
		"zip"
		"country";
	grid-template-columns: 1fr;

	@media (min-width: $l) {
		grid-template-areas:
			"street street street"
			"apartment apartment apartment"
			"city state zip"
			"country country country";
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media (min-width: $xxl) {
		grid-template-areas:
			"street street street apartment"
			"city state zip country";
		grid-template-columns: 1fr 1fr 1fr 320px;
	}

	.street {
		grid-area: street;
	}

	.apartment {
		grid-area: apartment;
	}

	.city {
		grid-area: city;
	}

	.state {
		grid-area: state;
	}

	.zip {
		grid-area: zip;
	}

	.country {
		grid-area: country;
	}
}

.personal {
	grid-template-areas:
		"firstName"
		"lastName"
		"email"
		"confirmEmail"
		"createAccount"
		"password"
		"confirmPassword";
	grid-template-columns: 1fr;

	@media (min-width: $l) {
		grid-template-areas:
			"firstName lastName"
			"email confirmEmail"
			"createAccount createAccount"
			"password confirmPassword";
		grid-template-columns: 1fr 1fr;
	}

	.firstName {
		grid-area: firstName;
	}

	.lastName {
		grid-area: lastName;
	}

	.email {
		grid-area: email;
	}

	.confirmEmail {
		grid-area: confirmEmail;
	}

	.createAccount {
		grid-area: createAccount;
	}

	.password {
		grid-area: password;
	}

	.confirmPassword {
		grid-area: confirmPassword;
	}
}

.logoutLink {
	margin-left: 10px;
	color: $colorOrange;
	font-weight: bold;
	text-decoration: none;
}

.error {
	color: $colorRed;
	margin: 5px 0 10px;
	display: block;
	font-weight: 600;
}

.anonymous {
	margin-bottom: 20px;
}

.donationMain {
	max-width: 500px;
	width: 100%;
	// margin:30px auto;
	margin-left: auto;
	margin-right: auto;

	.donationBanner {
		background-color: #ffb808;
		position: relative;
		padding: 40px 30px 0;
		display: flex;
		// justify-content: center;
		gap: 10px;

		@media (max-width: 460px) {
			padding: 40px 15px 0;
		}
		@media (max-width: 426px) {
			padding: 40px 10px 0;
			gap: 5px;
		}
		.donationShadowLine {
			position: absolute;
			bottom: 8px;
			width: 100%;
			left: 0;
			transform: rotate(351deg);
			z-index: 9;

			@media (max-width: 380px) {
				transform: rotate(353deg);
			}
			@media (max-width: 360px) {
				transform: rotate(355deg);
				bottom: 4px;
			}
		}
		.donationShadowTop {
			position: absolute;
			bottom: 9px;
			width: 100%;
			left: 0;
			transform: rotate(6deg);
			z-index: 9;

			@media (max-width: 430px) {
				transform: rotate(7.5deg);
			}
			@media (max-width: 380px) {
				transform: rotate(8deg);
			}

			@media (max-width: 360px) {
				transform: rotate(9deg);
			}
		}
		.whiteClipWrap {
			position: absolute;
			// filter: drop-shadow(0px -17px 11px rgba(0,0,0,0.3));
			z-index: 1;
			width: 70%;
			background: transparent;
			bottom: -2px;
			left: -1px;

			// &:after{
			//   content: "";
			//   width: 100%;
			//   position: absolute;
			//   box-shadow: 0px -18px 18px rgba(0, 0, 0, 0.3);
			//   height: 7px;
			//   left: 0;
			//   bottom: 4px;
			//   transform: rotate(-6deg);
			// }

			.whiteClip {
				-webkit-clip-path: polygon(0 0, 100% 100%, 0100% 100%, 0 100%);
				clip-path: polygon(0 0, 100% 100%, 0100% 100%, 0 100%);
				background: #fff;
				width: 100%;
				height: 40px;
				position: relative;
			}
		}
		.greenClipWrap {
			position: absolute;
			// filter: drop-shadow(0px 16px 11px rgba(0,0,0,0.3));
			width: 100%;
			background: transparent;
			top: 0;
			left: 0;

			.greenClip {
				p {
					font-weight: 400;
					font-size: 14px;
				}

				-webkit-clip-path: polygon(0 0, 100% 0, 100% 50%, 0 100%);
				clip-path: polygon(0 0, 100% 0, 100% 50%, 0 100%);
				background: #81bd36b3;
				width: 100%;
				height: 100px;
				text-align: right;
				font-weight: bold;
				padding: 10px 20px;
				font-size: 20px;
				position: relative;

				@media (max-width: 380px) {
					height: 100px;
				}

				&:after {
					content: "";
					width: 100%;
					position: absolute;
					box-shadow: 0px -16px 18px rgba(0, 0, 0, 0.3);
					height: 16px;
					left: 0;
					bottom: 8px;
					transform: rotate(-6deg);

					@media (max-width: 380px) {
						bottom: 6px;
					}
				}
			}
		}
		.donationWhiteShadow {
			position: absolute;
			bottom: -1px;
			left: 0px;
			width: 100%;
			z-index: 9;
			max-height: 140px;
			display: none;
		}
		.donationSliderWrap {
			display: inherit;
			// margin-bottom: 30px;

			.donationSlider {
				background-color: #fff;
				border-radius: 30px;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-around;
				z-index: 1;
				position: relative;
				gap: 30px;
				padding: 10px 2px 40px;
				font-size: 10px;

				.donationProgress {
					color: #fff;
					font-size: 12px;
					text-align: center;
					background-color: #db8300;
					width: 28px;
					height: 39%;
					bottom: 0;
					position: absolute;
					border-radius: 30px;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
					z-index: 9;
					padding: 7px 2px;
					box-sizing: border-box;
					font-size: 10px;
					width: 22px;
					bottom: 3px;

					@media (max-width: 426px) {
					}
				}
				&:before {
					// content:"46%";
					color: #fff;
					font-size: 12px;
					text-align: center;
					background-color: #db8300;
					width: 28px;
					height: 39%;
					bottom: 5px;
					position: absolute;
					border-radius: 30px;
					z-index: 0;
					padding: 7px 2px;
					box-sizing: border-box;

					@media (max-width: 426px) {
						font-size: 10px;
						width: 21px;
						bottom: 3px;
					}
				}

				span {
					position: relative;
					z-index: 9;
				}
			}
		}

		.donationContent {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: calc(100% - 194px);

			@media (max-width: 426px) {
				width: calc(100% - 180px);
			}

			h1 {
				text-transform: uppercase;
				font-size: 40px;
				line-height: 1.2;

				@media (max-width: 426px) {
					font-size: 34px;
				}

				@media (max-width: 380px) {
					font-size: 28px;
				}

				@media (max-width: 360px) {
					font-size: 22px;
				}

				span {
					font-size: 24px;
					display: block;
					font-weight: 400;
					text-transform: initial;

					@media (max-width: 380px) {
						font-size: 20px;
					}

					@media (max-width: 360px) {
						font-size: 16px;
					}
				}
			}

			p {
				font-size: 17px;
				color: #db8300;
				line-height: 1;
				text-align: center;
				margin-top: 20px;

				@media (max-width: 426px) {
					font-size: 14px;
					margin-top: 15px;
				}

				@media (max-width: 390px) {
					font-size: 10px;
					margin-top: 15px;
				}

				span {
					width: max-content;
					margin: 0 auto;
					background-color: #fbf0e1;
					padding: 8px;
					border-radius: 8px;
					display: block;

					@media (max-width: 360px) {
						padding: 4px;
					}
				}

				.fundAmount {
					padding-bottom: 4px;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
					font-size: 17px;
					position: relative;

					&:after {
						content: "";
						background-color: #fbf0e1;
						bottom: 0;
						bottom: -6px;
						position: absolute;
						right: -3px;
						width: 10px;
						height: 10px;
						/* border-radius: 100%; */
						transform: rotate(45deg);
					}

					&:before {
						content: "";
						background-color: #fbf0e1;
						bottom: 0;
						bottom: -6px;
						position: absolute;
						left: -3px;
						width: 10px;
						height: 10px;
						/* border-radius: 100%; */
						transform: rotate(45deg);
					}
				}
			}
		}
		.donationImageMain {
			overflow: hidden;
			height: 250px;

			.donationImageWrap {
				position: relative;
				height: 252px;
				max-width: 144px;
				width: 100%;
				display: inherit;
				justify-content: center;

				.donationImageOrg {
					width: 100%;
					position: absolute;
					left: 0;
					height: 30%;
					background-position: bottom;
					bottom: 0;
					background-size: initial;
					background-repeat: no-repeat;
				}

				.donationGrayImg {
					z-index: 1;
					position: relative;
					filter: opacity(30%);
				}
			}
		}

		.donationMobileWrap {
			height: 392px;
			max-width: 260px;
			overflow: hidden;
			overflow-y: hidden;
			width: 100%;
			background-repeat: no-repeat;
			background-size: cover;

			@media (max-width: 380px) {
				height: 302px;
			}

			.donationMobileOrg {
				position: absolute;
				height: 392px;
				max-width: 260px;
				overflow: hidden;
				overflow-y: hidden;
				width: 100%;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}
	}
	.donationDivider {
		width: 100%;
		height: 1px;
		background-color: #ff8a34;
		max-width: 220px;
		margin: 0px auto 0;
		display: block;
	}
	.donationListSliderWrap {
		padding: 40px 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		display: inline-block;
		width: 100%;
		position: relative;

		@media (max-width: 380px) {
			padding-bottom: 20px;
		}

		&:before {
			content: "";
			right: 0;
			top: -1px;
			-webkit-clip-path: polygon(100% 100%, 100% 100%, 100% 0, 50% 0);
			clip-path: polygon(100% 100%, 100% 100%, 100% 0, 50% 0);
			background: #ffb808;
			width: 70%;
			height: 20px;
			position: absolute;
		}
		&:after {
			content: "";
			position: absolute;
			left: 50%;
			top: 30px;
			width: 100%;
			max-width: 390px;
			// height: 100%;
			background-color: #fff;
			border-top-left-radius: 30px;
			border-top-right-radius: 30px;
			transform: translateX(-50%);

			@media (max-width: 380px) {
				max-width: 320px;
			}
		}
		.donationListSlider {
			flex: 50% 1;
			height: 90px;
			max-width: 270px;
			margin: 20px auto;
			overflow: hidden;
			position: relative;

			@media (max-width: 380px) {
				max-width: 280px;
			}

			&:before {
				content: "";
				position: absolute;
				left: 2px;
				top: 0;
				width: 2px;
				height: 100%;
				background-color: #ff8a34;
				z-index: 1;
			}

			ul {
				display: flex;
				flex-wrap: wrap;
				direction: rtl;
				position: relative;
				padding: 0px 20px;
				// height: 100%;
				max-height: 90px;
				overflow: auto;
				position: relative;
				z-index: 1;
				align-items: flex-start;

				@media (max-width: 380px) {
					padding: 0px 10px;
				}

				li {
					// flex: 50% 1;
					width: 100%;
					display: flex;
					direction: ltr;
					align-items: flex-start;
					justify-content: center;
					margin-bottom: 10px;
					padding-right: 10px;
					flex-wrap: wrap;

					@media (max-width: 380px) {
						padding-right: 5px;
					}
				}
			}
		}
	}

	.donationFooter {
		position: relative;
		filter: drop-shadow(0px -4.25px 12px rgba(0, 0, 0, 0.3));

		.clipFooter {
			background-color: #e8405e;
			padding: 90px 15px 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 20px;
			width: 100%;
			clip-path: polygon(-140% 100%, 100% 0, 100% 0%, 100% 100%);

			@media (max-width: 460px) {
				gap: 10px;
			}
			@media (max-width: 440px) {
				flex-wrap: wrap;
				justify-content: center;
			}
		}
		.donationFooterShadow {
			position: absolute;
			top: -47px;
			width: 100%;
			z-index: 9;
			max-height: 140px;
		}
		.donationImage {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 15px;

			img {
				width: 100%;
				max-width: 120px;
			}
		}
	}
}

/* width */
.donationListSlider ul::-webkit-scrollbar {
	width: 6px;
}

/* Track */
.donationListSlider ul::-webkit-scrollbar-track {
	background: transparent;
	width: 2px;
}

/* Handle */
.donationListSlider ul::-webkit-scrollbar-thumb {
	background: $colorOrange;
}

/* Handle on hover */
.donationListSlider ul::-webkit-scrollbar-thumb:hover {
	background: $colorOrange;
}
