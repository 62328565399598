.slider-v1 .slick-slide.slick-active.slick-cloned {
  transform: scale(0.7);
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.slider-v1 .slick-slide.slick-active {
  transform: scale(0.7);
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  position: relative;
  z-index: -1;
  display: block;
}

.slider-v1 .slick-slide {
  transform: scale(0.3);
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);

  transition: 0.8s ease-in-out;
}

.slider-v1 .slick-center.slick-current {
  transform: scale(1) !important;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  z-index: 1;
  display: block;
  width: 334px;
}

.slider-v1 .slick-center.slick-current img {
  border: 16px solid white;
}

.slider-v1 .slick-list {
  padding: 0px !important;
}

@media (max-width: 450px) {
  .slider-v1 .slick-list {
    max-height: 265px;
  }
}

@media (max-width: 390px) {
  .slider-v1 .slick-list {
    max-height: 250px;
  }
}

@media (max-width: 350px) {
  .slider-v1 .slick-list {
    max-height: 220px;
  }
}
