@import "../../../../../styles/utilities/index.scss";

.address {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 14px;
  grid-row-gap: 24px;
  list-style-type: none;
  margin: 16px 0 0;
  padding: 0;

  @media (min-width: $l) {
    &.bottom {
      grid-template-columns: 2fr 1fr 1fr 320px;
    }

    &.top {
      grid-template-columns: 1fr 320px;
    }
  }

  .redText {
    color: red;
  }
}

.searchAddress {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 14px;
  grid-row-gap: 24px;
  list-style-type: none;
  margin: 16px 0 0;
  padding: 0;

  .redText {
    color: red;
  }
}

.street {
  max-width: 320px;

  @media (min-width: $l) {
    max-width: 100%;
  }
}

.wishes {
  display: grid;
  grid-template-areas:
    "from"
    "to"
    "message";
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  list-style-type: none;
  margin: 16px 0 0;
  padding: 0;

  @media (min-width: $l) {
    grid-template-areas:
      "from message message"
      "to message message";
    grid-template-columns: 1fr 2fr;
  }
}

.from {
  grid-area: from;
}

.to {
  grid-area: to;
}

.message {
  grid-area: message;
  max-width: 320px;

  @media (min-width: $l) {
    max-width: 100%;
  }
}

.addressCardContainer {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.addAddressBox {
  width: 330px;
  min-width: 300px;
  height: 164px;
  border: 3px dashed #ff820e;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  color: #ff820e;
  flex-direction: column;
  margin: 16px 32px;
  -webkit-box-shadow: 1px 3px 15px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 3px 15px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 3px 15px -5px rgba(0, 0, 0, 0.75);
}

.pickAddressBox {
  width: 330px;
  min-width: 300px;
  height: 164px;
  border: 3px solid #ff820e;
  border-radius: 20px;
  cursor: pointer;
  margin: 16px 32px;
  -webkit-box-shadow: 0px 2px 15px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 15px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 15px -6px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 8px;
  position: relative;
}

.addressBoxContent {
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 100%;
}

@media (max-width: 1291px) {
  .addAddressBox {
    width: 300px;
    margin: 16px 16px;
  }
  .pickAddressBox {
    width: 300px;
    margin: 16px 16px;
  }
}

@media (max-width: 1150px) {
  .addressCardContainer {
    justify-content: center;
  }

  .addAddressBox {
    width: 340px;
    margin: 16px 16px;
  }
  .pickAddressBox {
    width: 340px;
    margin: 16px 16px;
  }
}

.crossButton {
  position: absolute;
  top: 8px;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #fff;
  padding: 7px;
  right: 8px;
}

.deleteModalConatiner {
  margin: 0 auto;
  padding: 80px 16px;
  width: 100%;
  max-width: 612px;
  min-height: 100vh;
}

.deleteModalCard {
  border-radius: 26px;
  padding: 32px 32px 64px;
  background-color: #ffffff;
  color: #27305a;
  text-align: center;

  h1 {
    line-height: 30px;
  }

  .main {
    margin-top: 32px;
  }
}

.addBtn {
  border-style: solid;
  border-color: transparent;
  background-color: #ff8a34;
  color: #ffffff !important;
  border-width: 1px;
  border-radius: 22px;
  padding: 8px 25px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  &:hover {
    box-shadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
    background-color: #ee802f;
  }

  &:disabled {
    background-color: #828282;
    cursor: not-allowed;
  }
}