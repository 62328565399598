@import "../../../styles/utilities/index.scss";

.previewButton {
  border-style: solid;
  border-color: transparent;
  background-color: $colorNewPink;
  color: #000000;
  border-width: 1px;
  border-radius: 15px;
  padding: 8px 15px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  text-wrap: nowrap;
  white-space: nowrap;
  cursor: pointer;

  @media (max-width: 480px) {
    font-size: 12px;
    line-height: 18px;
  }

  &:hover {
    box-shadow: 0px 4px 8px rgba(255, 138, 52, 0.3);
    background-color: $colorNewPink;
  }
}

.xs {
  padding: 8px 15px;
  font-size: 12px;
  line-height: 16px;
}
