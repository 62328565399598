@import "../../../../../styles/utilities/index.scss";

.MoreVideosSec {
  // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(167, 70, 186, 0.1) 13.54%, rgba(167, 70, 186, 0.15) 78.65%, rgba(255, 255, 255, 0) 100%);
  position: relative;
  background-color: rgb(255, 251, 244);
  background-image: url(../../../../../assets/images/DRV-bg3.png);
  background-size: 62%;
  padding: 100px 10px;
  overflow-x: hidden;

  @media (max-width: 500px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  .videosWrapper {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;

    margin-right: auto;
    margin-left: auto;
    padding-right: 16px;
    padding-left: 16px;
    width: 100%;
    max-width: 1920px;
    Button {
      color: $colorDarkYellow;
    }
  }

  .btnContainer {
    align-self: center;
    margin-top: 24px;
    Button {
      background-color: $colorDarkYellow;
      color: $colorWhite;
      min-height: 44px;
      padding: 6px 32px;
      font-size: 18px;
      &:hover {
        background-color: $colorDarkYellow;
        color: $colorWhite;
      }
    }
  }

  .videoSecImg {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    opacity: 0.3;
    align-items: center;
    z-index: 0;
    left: 0;
    right: 0;

    @media (max-width: 767px) {
      display: none;
    }

    .headphoneImg {
      position: absolute;
      left: -170px;
      top: 0;
    }
    .tvImg {
      position: absolute;
      right: -140px;
      top: 148px;
    }
  }

  .videosBottomImg {
    bottom: 0;
  }

  .centerContent {
    text-align: center;
    h2 {
      color: $colorBlack;
      font-size: 34px;
    }
  }

  .MoreVideosWrap {
    max-width: 1344px;
    width: 100%;
    margin: 20px auto 40px;

    h3 {
      color: $colorDarkYellow;
      margin-left: 18px;
      font-size: 24px;
    }
  }
}

@media (max-width: 768px) {
  .MoreVideosSec {
    background-image: none;
    padding: 50px 0px;
  }

  .btnContainer {
    margin-top: 0px !important;
    button {
      margin-top: 0px !important;
    }
  }
  .centerContent {
    h2 {
      font-size: 30px !important;
    }

    .MoreVideosWrap {
      h3 {
        font-size: 22px !important;
      }
    }
  }
}

@media (max-width: 650px) {
  .videosWrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
