@import "../../../../../../../styles/utilities/index.scss";

$backgroundGradientOrange: linear-gradient(
    57.38deg,
    #ffffff -6.94%,
    rgba(255, 255, 255, 0) 43.98%
  ),
  linear-gradient(168.7deg, #ffffff -10.99%, rgba(255, 255, 255, 0) 44.7%),
  linear-gradient(
    97.47deg,
    rgba(255, 162, 52, 0.5) -37.82%,
    rgba(255, 255, 255, 0) 101.55%
  ),
  #ffffff;
$colorGrey: #959595;

.container {
  background: $backgroundGradientOrange;
  color: $colorBlueDark;
}

.backgroundWhite{
  background: #ffffff;
}

.grid {
  display: grid;
  grid-template-areas:
    "main options"
    "details details";
  grid-template-columns: 1fr 36px;
  grid-template-rows: auto auto;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  padding: 16px 20px 22px;

  @media (min-width: $m) {
    grid-template-areas: "main details options";
    grid-template-columns: 171px 1fr 36px;
    grid-template-rows: auto;
  }
}

.main {
  grid-area: main;
  display: flex;
  align-items: center;
  border-right: 1px solid $colorGrey;
}

.details {
  grid-area: details;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
}

.options {
  grid-area: options;
}

.package {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
}

.illustration {
  width: 100%;
  max-width: 50px;

  line-height: 1;
}

.title {
  margin: 8px 0 0;

  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-column-gap: 24px;
  grid-row-gap: 12px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;

  @media (min-width: $xxl) {
    grid-template-columns: 1fr 1fr;
  }
}

.item {
  display: flex;
  align-items: flex-start;

  font-size: 18px;
  line-height: 24px;

  .icon {
    flex-shrink: 0;
    margin-top: 2px;
    margin-right: 8px;
    min-width: 20px;

    color: $colorOrange;
  }
}

.buttons {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
}

.infoMessage {
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
  color: $colorOrange;
}

.donationText {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  span{
    font-size: 12px;
  }
}

.imageWidth{
  max-width: fit-content;
}

.purple{
  color: $colorDarkPurple;
}

.pledgeText{
  display: flex;
    text-align: center;
    justify-content: center;
}

.brTagDisplayMobile{
  display: none;
}

@media (max-width:1000px) {
  .gridView{
    grid-template-columns: 1fr !important;
  }
}

@media (max-width:767px) {

.pledgeItem{
  display: none;
}

.gridChangeForMobilePledge{
  grid-area: auto;
}

.grid{
  padding: 16px 20px 0px;
}

}

@media (max-width:600px) {
.brTagDisplayMobile{
  display: block;
}

.list {
  grid-template-columns: 1fr;
}
}

@media (max-width:400px) {
.brTagDisplayMobile{
  display: none;
}
}
