@import "../../../styles/utilities/index.scss";

$colorGreyLight: #ECECEC;

.container {
  overflow: hidden;
  border-radius: 100px;
  background-color: $colorGreyLight;
}

.indicator {
  height: 10px;
  background-color: $colorOrange;
  transition: width $timeS;
}
