@import "../../../../../styles/utilities/index";

.profile {
  @media (min-width: $breakpointM) {
    min-width: 84px;
  }
  
  @media (min-width: $breakpointL) {
    min-width: 200px;
  }
}
