@import "../../../styles/utilities/index.scss";

.container {
  text-align: center;
  color: $colorBlueDark;
}

.wrapper {
  padding-top: 120px;
  padding-bottom: 152px;

  @media (min-width: $xxxl) {
    padding-top: 160px;
  }
}

.title {
  margin: 0;

  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;

  &:not(:first-of-type) {
    margin-top: 32px;
  }

  @media (min-width: $m) {
    font-size: 40px;
    line-height: 60px;
  }
}

.paragraph {
  margin: 16px 0 0;

  font-size: 30px;
  line-height: 42px;
}

.button {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
