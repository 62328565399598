@import "../../../../../styles/utilities/index.scss";
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@700;800;900&display=swap");

.PledgeDonationConatiner {
  max-width: 880px;
  width: 100%;
  display: block;

  h1 {
    text-transform: uppercase;
    color: #ac464d;
    font-family: "League Spartan", sans-serif;
    font-weight: 800;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
  }
}

.fontFamilyLeagueSpartan {
  font-family: "League Spartan", sans-serif;
  font-weight: 800;
}

.changeColor {
  color: #ac464d;
}

.amountConatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  gap: 20px;
}

.header {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: $size-06;
  grid-row-gap: $size-03;

  @media (min-width: $m) {
    grid-template-columns: 100px 1fr;
  }

  .message {
    text-align: center;

    @media (min-width: $m) {
      text-align: left;
    }
  }
}

.group {
  transition: opacity $timeS;
  text-align: center;
  background-color: $colorWhite;
  padding: 30px 50px;
  border-radius: 25px;

  &:not(:first-of-type) {
    margin-top: $size-08;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.boundary {
  position: relative;
  display: flex;

  &::before {
    content: "";
    width: 100%;
    border-top: 2px dotted #e1bcbf;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.list {
  display: grid;
  grid-column-gap: $size-06;
  grid-row-gap: $size-04;
}

.address {
  grid-template-areas:
    "street"
    "apartment"
    "city"
    "state"
    "zip"
    "country";
  grid-template-columns: 1fr;

  @media (min-width: $l) {
    grid-template-areas:
      "street street street"
      "apartment apartment apartment"
      "city state zip"
      "country country country";
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: $xxl) {
    grid-template-areas:
      "street street street apartment"
      "city state zip country";
    grid-template-columns: 1fr 1fr 1fr 320px;
  }

  .street {
    grid-area: street;
  }

  .apartment {
    grid-area: apartment;
  }

  .city {
    grid-area: city;
  }

  .state {
    grid-area: state;
  }

  .zip {
    grid-area: zip;
  }

  .country {
    grid-area: country;
  }
}

.sendBrochaContainer {
  grid-template-areas: "sendBrocha" "fillText" "yourName" "motherName";
  grid-template-columns: 1fr;
  grid-row-gap: 12px;

  @media (min-width: $l) {
    grid-template-areas:
      "sendBrocha sendBrocha"
      "fillText fillText"
      "yourName motherName";
    grid-template-columns: 1fr 1fr;
  }

  .yourName {
    grid-area: yourName;
  }

  .motherName {
    grid-area: motherName;
  }

  .fillText {
    grid-area: fillText;
    display: flex;
    justify-content: center;
    font-size: 14px;
  }

  .sendBrocha {
    grid-area: sendBrocha;
    display: flex;
    cursor: pointer;
    justify-content: center;
  }
}

.personal {
  grid-template-areas:
    "firstName"
    "lastName"
    "email"
    "confirmEmail"
    "createAccount"
    "password"
    "confirmPassword";
  grid-template-columns: 1fr;

  @media (min-width: $l) {
    grid-template-areas:
      "firstName lastName"
      "email confirmEmail"
      "createAccount createAccount"
      "password confirmPassword";
    grid-template-columns: 1fr 1fr;
  }

  .firstName {
    grid-area: firstName;
  }

  .lastName {
    grid-area: lastName;
  }

  .email {
    grid-area: email;
  }

  .confirmEmail {
    grid-area: confirmEmail;
  }

  .createAccount {
    grid-area: createAccount;
    display: flex;
    justify-content: center;
  }

  .password {
    grid-area: password;
  }

  .confirmPassword {
    grid-area: confirmPassword;
  }
}

.logoutLink {
  margin-left: 10px;
  color: $colorOrange;
  font-weight: 400;
}

.error {
  color: $colorRed;
  margin: 5px 0 10px;
  display: block;
  font-weight: 600;
}

.anonymous {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.textHeader {
  grid-area: header;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: $colorBlue;
  font-weight: 600;
}

.loggedInText {
  color: #000;
  font-weight: 400;
}

.familyText {
  color: #ac464d;
}

.logoutLinkText {
  color: #f9ab30;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}

.createAnAccount {
  color: #ac464d;
}

.displayMobile {
  display: none;
}

@media (max-width: 500px) {
  .mobileDisplayNone {
    display: none;
  }
  .displayMobile {
    display: block;
  }

  .PledgeDonationConatiner {
    h1 {
      font-size: 24px !important;
      line-height: 24px !important;
      text-align: center;
    }
  }

  .group {
    border-radius: 12px;
    padding: 30px 30px;
  }
}

@media (max-width: 405px) {
  .listCenter {
    justify-items: center;
  }
  .anonymous {
    max-width: 185px;
  }
}

.orangeColor {
  color: $colorOrange;
}

.hideAccountOption {
  display: none !important;
}

.newLink{
  font-size: 16px;
  color: $colorBlue;
  text-align: center;
}

.orangeColor{
  color: $colorOrange;
  text-decoration-color: $colorOrange ;
}

.textDecorationUnderline{
  text-decoration: underline;
}
