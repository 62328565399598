.priceUpdate {
  padding: 2rem;
  display: flex;
  justify-content: center;

  .content {
    position: relative;
    max-width: 800px;
    width: 100%;
    background-color: #fff2e2;
    padding: 50px 70px;
    border-radius: 3rem;

    @media (max-width: 425px) {
      border-radius: 1rem;
      padding: 20px 40px;
    }

    .closeContainer {
      position: absolute;
      top: 32px;
      right: 32px;

      @media (max-width: 425px) {
        top: 16px;
        right: 16px;
      }

      .close {
        background: none;
        border: none;
      }
    }

    .logo {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;

      @media (max-width: 425px) {
        margin-bottom: 1rem;
      }

      .logoImage {
        object-fit: contain;
      }
    }

    h1 {
      text-align: center;
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 2.5rem;

      @media (max-width: 425px) {
        font-size: 1rem;
        margin-bottom: 1.5rem;
      }

      @media (min-width: 768px) {
        font-size: 1.25rem;
      }
    }

    .letterContent {
      line-height: 1.6;

      .greeting {
        margin-bottom: 1.5rem;
        font-weight: bold;

        @media (max-width: 425px) {
          margin-bottom: 1rem;
        }
      }

      p {
        margin-bottom: 1.5rem;

        @media (max-width: 425px) {
          margin-bottom: 1rem;
          font-size: 14px;
        }
      }

      .signature {
        font-style: italic;
        margin: 2rem 0;
      }

      .divider {
        border-top: 2px dashed #000;
        margin: 2rem 40px;

        @media (max-width: 425px) {
          margin: 0px;
        }
      }

      .pricingSection {
        margin: 0px 80px;

        @media (max-width: 425px) {
          margin: 0px 20px;
        }

        h2 {
          font-size: 1.1rem;
          font-weight: 600;
          margin: 1.5rem 0 0;

          @media (max-width: 425px) {
            margin: 1rem 0 0;
          }
        }

        ul {
          list-style: disc;
          padding-left: 2rem;
          margin-bottom: 2rem;

          li {
            margin-bottom: 0.5rem;
            position: relative;
            font-size: 16px;

            @media (max-width: 425px) {
              font-size: 14px;
            }
            // &::before {
            //   content: "•";
            //   position: absolute;
            //   left: -1rem;
            // }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 1rem;

    .content {
      padding: 1rem;
    }
  }
}
