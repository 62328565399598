.memberContainer {
  background-color: #fcf4ee;
  background-image: url(../../../assets/images/illuminate-bg.png);
}

.logotype img {
  width: 125px;
  height: 120px;
}

@media (max-width: 768px) {
  .memberContainer {
    background-image: none;
  }

  .packageContainer {
    background-image: url(../../../assets/images/illuminate-bg.png);
  }
}
