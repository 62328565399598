@import "../../../../../../../styles/utilities/index.scss";

.sliderWrap {
  max-width: 517px;
  width: 517px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;

  // @media (max-width: 1080px) {
  //     width: 500px;
  //  	}

  // 		@media (max-width: 900px) {
  // 		    width: 380px;
  // 	  	}
  @media (max-width: 767px) {
    width: 100%;
  }

  border-radius: 12px;
  position: relative;

  // &:after{
  // 	content:"";
  // 	width: 474px;
  // 	height: 214px;
  // 	top: 60%;
  // 	z-index: -1;
  // 	position: absolute;
  // 	opacity: 0.2;
  // 	background-repeat: no-repeat;
  // 	background-position: center;
  // 	background-size: auto;
  // 	background-image: url(../../../assets/images/pinkDots.png);

  // 	@media (max-width: 1080px) {
  // 	    width: 374px;
  // 	    height: 194px;
  // 	    background-size: contain;
  //   	}
  //   	@media (max-width: 767px) {
  //   		display: none;
  //   	}
  // }

  .sliderContainer {
    border-radius: 12px;
  }

  .slideLayout {
    border-radius: 12px;
    padding: 20px 20px;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 30px 40px rgba(0, 0, 0, 0.05);
    text-align: center;

    * {
      font-size: 16px;
      line-height: 24px;

      @media (max-width: 450px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .slideAbout {
      text-align: left;
      margin-top: 10px;
    }
  }
}

.slideLayout {
  position: relative;
  color: $colorBlack;
  .arrowNavigation {
    display: flex !important;
    bottom: 0px;
    right: 0px;

    button {
      background-color: transparent;
      border: none;
    }
  }

  h5 {
    font-size: 16.2px !important;
  }
}

.info {
  text-align: justify;
  margin-top: 10px;
}

.slideBottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.slideLayout > h4 {
  margin-bottom: 10px;
}

.slideAbout {
  strong {
    font-size: 14px !important;
    line-height: 19px !important;
  }
  p {
    font-size: 14px !important;
    line-height: 19px !important;
  }
}

.arrowNavigation > button > * {
  color: #ffc084;
}
.rotate {
  transform: rotate(180deg);
}

@media (max-width: 768px) {
  .arrowNavigation {
    display: flex;
    button {
    }
  }
}
