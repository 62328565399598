@import "../../../../../styles/utilities/index.scss";

$colorGreyDark: #636363;

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: $size-03;
  grid-row-gap: $size-03;

  @media (min-width: $m) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.input {
  display: none;

  &:checked {
    & + .label {
      border-color: $colorOrange;
      color: $colorOrange;
    }
  }
}

.label {
  border: 1px solid $colorGreyNew;
}
