@import "../../../styles/utilities/index.scss";

$boxShadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
$colorGreyLight: #C1BBBB;
$colorVioletLight: #F3F3F7;

.wrapper {
  position: relative;
}

// .container {}

.input {
  display: block !important;
}

.button {
  > [role="button"] {
    background-color: transparent !important;
  }
}

.search {
  > input {
    display: block !important;
    width: 100%;
  }
}

// .dropdown {}

.tooltip {
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 100%;

  max-width: 100%;

  text-align: center;
}

/* colors */

.primary {
  .input {
    border-color: $colorGreyLight;
    background-color: $colorWhite;
    color: $colorGrey;
  
    &:focus {
      color: $colorOrange;
    }

    @media (min-width: $l) {
      &:hover {
        box-shadow: $boxShadow;
      }
    }
  }

  .button {
    border-color: $colorGreyLight;
    background-color: $colorWhite;
  }

  .dropdown {
    > li[role="option"] {
      &:hover {
        background-color: $colorVioletLight;
        color: $colorOrange;
      }
    }

    > :not([role="option"]) {
      margin: 0;
      border-color: $colorGreyLight;
    }
  }

  .tooltip {
    box-shadow: $boxShadow;
    background-color: $colorRed;
    color: $colorWhite;
  }

  &.error {
    .input {
      color: $colorRed;
    }
  }
}

/* sizes */

.medium {
  &.wrapper {
    max-width: 320px;
  }

  .input {
    border-radius: 8px;
    padding: 17px 16px 17px 68px;
    width: 100%;
    height: 54px;
  
    font-size: 18px;
    line-height: 1;
  }

  .button {
    border-radius: 8px 0 0 8px !important;
    border-color: $colorGreyLight;
  
    > [role="button"] {
      padding: 0;
      padding: 0 8px 0 14px;
      width: 52px;
    }
  }

  .dropdown {
    margin: 4px 0 0;
    border: 1px solid $colorGreyLight;
    border-radius: 8px 0 0 8px;
    width: 320px;
  
    > [role="option"] {
      padding: 12px 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    > :not([role="option"]):not(.search) {
      padding: 0 !important;
    }
  }

  .search {
    // position: static !important;
    padding: 12px 16px !important;

    > input {
      margin: 0 !important;
      border-radius: 4px !important;
      padding: 7px 7px !important;

      line-height: 16px !important;
      font-size: 16px;
    }
  }

  .tooltip {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 24px 24px 0 24px;

    font-size: 14px;
    line-height: 16px;
  }
}

.large {
  &.wrapper {
    max-width: 100%;
  }

  .input {
    border-radius: 8px;
    padding: 17px 16px 17px 68px;
    width: 100%;
    height: 54px;
  
    font-size: 18px;
    line-height: 1;
  }

  .button {
    border-radius: 8px 0 0 8px !important;
    border-color: $colorGreyLight;
  
    > [role="button"] {
      padding: 0;
      padding: 0 8px 0 14px;
      width: 52px;
    }
  }

  .dropdown {
    margin: 4px 0 0;
    border: 1px solid $colorGreyLight;
    border-radius: 8px 0 0 8px;
    width: 320px;
  
    > [role="option"] {
      padding: 12px 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    > :not([role="option"]):not(.search) {
      padding: 0 !important;
    }
  }

  .search {
    // position: static !important;
    padding: 12px 16px !important;

    > input {
      margin: 0 !important;
      border-radius: 4px !important;
      padding: 7px 7px !important;

      line-height: 16px !important;
      font-size: 16px;
    }
  }

  .tooltip {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 24px 24px 0 24px;

    font-size: 14px;
    line-height: 16px;
  }
}
