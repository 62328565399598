@import "../mixins/index";
@import "../variables/index";

.m-0 { margin: 0; }
.mt-0 { margin-top: 0; }
.mr-0 { margin-right: 0; }
.mb-0 { margin-bottom: 0; }
.ml-0 { margin-left: 0; }

.m-auto { margin: auto; }
.mtmb-auto { margin-top: auto; margin-bottom: auto; }
.mrml-auto { margin-right: auto; margin-left: auto; }
.mt-auto { margin-top: auto; }
.mr-auto { margin-right: auto; }
.mb-auto { margin-bottom: auto; }
.ml-auto { margin-left: auto; }

.b-none { border: none; }
.br-50p { border-radius: 50%; }

.p-0 { padding: 0; }
.pt-0 { padding-top: 0; }
.pr-0 { padding-right: 0; }
.pb-0 { padding-bottom: 0; }
.pl-0 { padding-left: 0; }

.w-100 { width: 100%; }
.w-100p { width: 100%; }
.w-100vw { width: 100vw; }
.maxw-100p { max-width: 100%; }

.h-100p { height: 100%; }
.h-100vh { height: 100vh; }
.minh-100vh { min-height: 100vh; }
.maxh-100p { max-height: 100%; }

$sizes: $size-01, $size-02, $size-03, $size-04, $size-05, $size-06, $size-07, $size-08, $size-09, $size-10, $size-11, $size-12, $size-13, $size-14, $size-15, $size-16, $size-17;

@for $i from 1 through length($sizes) {

  .gap-#{$i} {
    gap: nth($sizes, $i);
  }

  @if $i < 10 {
    .m-0#{$i} {
      margin: nth($sizes, $i);
    }

    .mt-0#{$i} {
      margin-top: nth($sizes, $i);
    }

    .mt-0#{$i}--nft {
      &:not(:first-of-type) {
        margin-top: nth($sizes, $i);
      }
    }
  
    .mr-0#{$i} {
      margin-right: nth($sizes, $i);
    }
  
    .mb-0#{$i} {
      margin-bottom: nth($sizes, $i);
    }
  
    .ml-0#{$i} {
      margin-left: nth($sizes, $i);
    }

    .ml-0#{$i}--nft {
      &:not(:first-of-type) {
        margin-left: nth($sizes, $i);
      }
    }

    .br-0#{$i} {
      border-radius: nth($sizes, $i);
    }

    .p-0#{$i} {
      padding: nth($sizes, $i);
    }

    .ptpb-0#{$i} {
      padding-top: nth($sizes, $i);
      padding-bottom: nth($sizes, $i);
    }

    .prpl-0#{$i} {
      padding-right: nth($sizes, $i);
      padding-left: nth($sizes, $i);
    }
  
    .pt-0#{$i} {
      padding-top: nth($sizes, $i);
    }
  
    .pr-0#{$i} {
      padding-right: nth($sizes, $i);
    }
  
    .pb-0#{$i} {
      padding-bottom: nth($sizes, $i);
    }
  
    .pl-0#{$i} {
      padding-left: nth($sizes, $i);
    }

    .w-0#{$i} {
      width: nth($sizes, $i);
    }

    .minw-0#{$i} {
      min-width: nth($sizes, $i);
    }

    .maxw-0#{$i} {
      max-width: nth($sizes, $i);
    }

    .h-0#{$i} {
      height: nth($sizes, $i);
    }

    .minh-0#{$i} {
      min-height: nth($sizes, $i);
    }

    .maxh-0#{$i} {
      max-height: nth($sizes, $i);
    }
  }
  
  @if $i > 9 {
    .m-#{$i} {
      margin: nth($sizes, $i);
    }

    .mt-#{$i} {
      margin-top: nth($sizes, $i);
    }
  
    .mr-#{$i} {
      margin-right: nth($sizes, $i);
    }
  
    .mb-#{$i} {
      margin-bottom: nth($sizes, $i);
    }
  
    .ml-#{$i} {
      margin-left: nth($sizes, $i);
    }

    .br-#{$i} {
      border-radius: nth($sizes, $i);
    }

    .p-#{$i} {
      padding: nth($sizes, $i);
    }

    .ptpb-#{$i} {
      padding-top: nth($sizes, $i);
      padding-bottom: nth($sizes, $i);
    }

    .prpl-#{$i} {
      padding-right: nth($sizes, $i);
      padding-left: nth($sizes, $i);
    }
  
    .pt-#{$i} {
      padding-top: nth($sizes, $i);
    }
  
    .pr-#{$i} {
      padding-right: nth($sizes, $i);
    }
  
    .pb-#{$i} {
      padding-bottom: nth($sizes, $i);
    }
  
    .pl-#{$i} {
      padding-left: nth($sizes, $i);
    }

    .w-#{$i} {
      width: nth($sizes, $i);
    }

    .minw-#{$i} {
      min-width: nth($sizes, $i);
    }

    .maxw-#{$i} {
      max-width: nth($sizes, $i);
    }

    .h-#{$i} {
      height: nth($sizes, $i);
    }

    .minh-#{$i} {
      min-height: nth($sizes, $i);
    }

    .maxh-#{$i} {
      max-height: nth($sizes, $i);
    }
  }
}
