@import "../variables/index";

.pos-relative {
  position: relative;
}
.pos-static {
  position: static;
}
.pos-absolute {
  position: absolute;
}
.pos-fixed {
  position: fixed;
}

.ov-hidden {
  overflow: hidden;
}
.ovx-hidden {
  overflow-x: hidden;
}
.ovy-hidden {
  overflow-y: hidden;
}
.ov-auto {
  overflow: auto;
}
.ovx-auto {
  overflow-x: auto;
}
.ovy-auto {
  overflow-y: auto;
}
.ov-scroll {
  overflow: scroll;
}
.ovx-scroll {
  overflow-x: scroll;
}
.ovy-scroll {
  overflow-y: scroll;
}
.ov-visible {
  overflow: visible;
}
.zi-0 {
  z-index: 0;
}

.d-none {
  display: none !important;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.d-inline {
  display: inline;
}
.d-flex {
  display: flex;
}
.fd-row {
  flex-direction: row;
}
.fd-row-reverse {
  flex-direction: row-reverse;
}
.fd-column {
  flex-direction: column;
}
.fd-column-reverse {
  flex-direction: column-reverse;
}
.fw-wrap {
  flex-wrap: wrap;
}
.fw-nowrap {
  flex-wrap: nowrap;
}
.jc-start {
  justify-content: flex-start;
}
.jc-end {
  justify-content: flex-end;
}
.jc-space-around {
  justify-content: space-around;
}
.jc-space-between {
  justify-content: space-between;
}
.jc-center {
  justify-content: center;
}
.ai-start {
  align-items: start;
}
.ai-end {
  align-items: end;
}
.ai-stretch {
  align-items: stretch;
}
.ai-center {
  align-items: center;
}
.as-start {
  align-self: flex-start;
}
.as-end {
  align-self: flex-end;
}
.as-stretch {
  align-self: stretch;
}
.as-center {
  align-self: center;
}
.fshr-0 {
  flex-shrink: 0;
}
.fgr-1 {
  flex-grow: 1;
}

.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}
.fs-italic {
  font-style: italic;
}
.fs-normal {
  font-style: normal;
}

.lh-1 {
  line-height: 1;
}

.ta-center {
  text-align: center;
}
.ta-right {
  text-align: right;
}
.ta-left {
  text-align: left;
}
.td-none {
  text-decoration: none;
}
.td-underline {
  text-decoration: underline;
}
.tt-uppercase {
  text-transform: uppercase;
}
.whs-nowrap {
  white-space: nowrap;
}
.whs-normal {
  white-space: normal;
}

.pe-none {
  pointer-events: none;
}
.curs-pointer {
  cursor: pointer;
}

.button-reset {
  border: none;
  padding: 0;

  background-color: transparent;
  cursor: pointer;
}

.transparent-selection {
  &::selection {
    background-color: transparent;
  }
}

.cursor-pointer {
  cursor: pointer;
}
