@import "../../../../../styles/utilities/index.scss";

$colorGreyLight: #f6f6f6;
$colorGreyDark: #606060;

.container {
  display: flex;
  flex-direction: column;
}

.preview {
  border-radius: 12px;
  background-color: $colorGreyLight;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 150px;
}

.illuminatePreview {
  height: 350px;
  width: 220px;
  align-self: center;
}

.previewMobile {
  .preview {
    border-radius: 0px;
  }

  .details {
    padding-left: 10px;
    padding-right: 10px;

    .videoHeader {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: flex-start;

      h1 {
        font-size: 16px;
        font-weight: 400;

        // @media (max-width: 480px) {
        //   font-size: 14px;
        // }

        span {
          font-size: 12px;
          display: flex;
          gap: 5px;
          align-items: center;

          i {
            width: 3px;
            height: 3px;
            background-color: #404040;
            border-radius: 100%;
          }
        }
      }

      svg {
        color: #000;
        width: 16px;
      }
    }
  }
}

.languageViewsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.playerControlWrapper {
  display: block;
}

.playerPlay {
  text-align: center;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 32%;
}

.illuminatePlayerPlay {
  top: 130px;
}

.watchlistButton {
  position: absolute;
  right: 14px;
  top: 14px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
}

.watchlistButtonMobile {
  display: none;
}

.downloadButton {
  position: absolute;
  left: 14px;
  top: 14px;
  cursor: pointer;
  background-color: #0f8585;
  border-radius: 50%;
  padding: 4px;
}

.details {
  margin-top: 8px;
}

.title {
  margin: 0;

  font-weight: 400;
  font-size: 1rem;
  // line-height: 14px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.languages {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 8px 0 0;
  padding: 0;

  font-size: 1rem;
  line-height: 20px;

  > .item {
    &:not(:first-of-type) {
      margin-left: 8px;
      border-left: 1px solid black;
      padding-left: 8px;
    }
  }
}
.item {
  position: relative;
}

.button {
  border: none;
  padding: 0;

  text-transform: uppercase;
  background-color: transparent;
  color: $colorOrange;
  cursor: pointer;
}

.statistics {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 8px 0 0;
  padding: 0;

  font-size: 14px;
  line-height: 20px;
  color: $colorGreyDark;

  > .item {
    &:not(:first-of-type) {
      margin-left: 8px;
    }
  }
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  min-height: 100vh;

  @media (min-width: $m) {
    padding: 60px 48px;
  }
}

.videoTime {
  position: absolute;
  color: #fff;
  background: #404040;
  font-size: 14px;
  padding: 3px;
  line-height: 1;
  right: 5px;
  bottom: 5px;
  border-radius: 4px;
}

.mobileTitle {
  display: none;
}

@media (max-width: 768px) {
  .mobilecontainer {
    flex-direction: row;
    gap: 20px;

    .preview {
      width: 100%;
      max-width: 230px;
      max-height: 130px;
      min-width: 200px;

      @media (max-width: 500px) {
        max-width: 200px;
        max-height: 110px;
        min-width: 200px;
      }
    }

    .details {
      margin-top: 20px;
      flex-grow: 1;
    }

    .watchlistButton {
      display: none;
    }

    .watchlistButtonMobile {
      display: block;
      background-color: #fff;
      border-radius: 50%;
      padding: 5px;
      height: fit-content;
      align-self: center;
    }

    .title {
      display: none;
    }

    .mobileTitle {
      display: block;
      white-space: wrap;
    }

    .playerPlay {
      width: 45px;
      height: 45px;
    }
  }
}

@media (max-width: 500px) {
  .preview {
    height: 115px;
  }

  .playerPlay {
    width: 50px;
    height: 50px;
    top: 32%;
  }

  .mobilecontainer {
    gap: 10px;
  }
}
