@import "../../../styles/utilities/index.scss";

$backgroundGradientOrange: linear-gradient(95.02deg, #FFFFFF -38.03%, #FFEAD2 18.65%, #FFFFFF 97.92%);

.container {
  background: $backgroundGradientOrange;
  color: $colorBlueDark;
}

.wrapper {
  padding-top: 60px;
  padding-bottom: 60px;
  min-height: 100vh;
}

.logotype {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin: 24px 0 0;

  font-size: 32px;
  line-height: 40px;
  text-align: center;
}

.loader {
  margin-top: 24px;
}

.error {
  margin-top: 24px;
  text-align: center;
}
