@import "../../../styles/utilities/index.scss";

.adContainer {
  // background-color: $colorWhite;
  // border-radius: 25px;

  // min-height: 195px;
  // max-height: 195px;

  width: 100%;
  display: flex;
  justify-content: center;

  img {
    // cursor: pointer;
    border-radius: 25px;
    object-fit: fill;
    max-height: 320px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02),
      0px 30px 40px rgba(0, 0, 0, 0.05);
    width: 100%;

    @media (max-width: 1020px) {
      max-height: 280px;
    }

    @media (max-width: 800px) {
      max-height: 250px;
    }

    @media (max-width: 767px) {
      max-height: 220px;
    }

    @media (max-width: 650px) {
      max-height: 200px;
    }

    @media (max-width: 550px) {
      max-height: 125px;
    }

    @media (max-width: 350px) {
      max-height: 100px;
    }
  }
}

.browserHideBanner {
  display: none;
}

@media (max-width: 550px) {
  .mobileHideBanner {
    display: none;
  }

  .browserHideBanner {
    display: block;
  }

  .mobileBanner {
    max-height: 100% !important;
    object-fit: contain !important;
    // height: 210px;
  }
}

.pointer {
  img {
    cursor: pointer;
  }
}
