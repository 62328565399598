@import "../../../../../styles/utilities/index.scss";

.bannerContainer {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(../../../../../assets/images/HomeBanner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top bottom;

  width: 100%;
  height: 755px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.btn {
  button {
    min-height: 40px;
    padding: 4px 22px;
    font-size: 18px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 35px;
  position: absolute;
  top: 27%;
  left: 15%;
}

.title {
  display: flex;
  flex-direction: row;
  gap: 45px;
  align-items: center;
  margin-bottom: -18px;

  hr {
    border: 1px solid $colorOrange;
    margin: 0;
    rotate: 90deg;
    height: 50px;
    overflow: hidden;
    margin-left: 25px;
  }

  p {
    color: $colorOrange;
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 25px;
  h3 {
    color: $colorWhite;
    text-transform: uppercase;
  }
  p {
    color: $colorWhite;
    font-weight: 400;
    font-size: 20px;
    opacity: 0.9;
  }
}

@media (max-width: 1150px) {
  .bannerContainer {
    max-height: 560px;
  }

  .text {
    gap: 15px;
    p {
      line-height: 25px;
    }
  }
}

@media (max-width: 1000px) {
  .bannerContainer {
    max-height: 500px;
    padding-bottom: 50px;
  }

  .btn {
    Button {
      border-width: 1px;
      border-radius: 20px;
      padding: 3px 19px;
      min-height: 32px;

      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

@media (max-width: 788px) {
  .bannerContainer {
    max-height: 420px;
  }
}

@media (max-width: 750px) {
  .bannerContainer {
    max-height: 440px;
    background-position: center left;
  }

  .content {
    gap: 20px;
  }

  .text {
    gap: 15px;
  }
}

@media (max-width: 750px) {
  .bannerContainer {
    background-position: center;
  }
  .content {
    top: 32%;
    left: 10%;
  }
}

@media (max-width: 768px) {
  .content {
    top: 38%;
    left: 10%;
  }
  .text {
    gap: 10px;
    p {
      line-height: 20px;
    }
  }
  .bannerContainer {
    .text {
      h3 {
        font-size: 22px !important;
        line-height: 29px !important;
      }
      p {
        font-size: 14px;
      }
    }
  }
  .btn {
    Button {
      margin: 0px;
    }
  }
}

@media (max-width: 425px) {
  .btn {
    Button {
      margin: 0px;
    }
  }
  .bannerContainer {
    max-height: 430px;
    padding-bottom: 30px;
    background-position: center;

    .text {
      h3 {
        font-size: 20px !important;
        line-height: 26px !important;
      }
      p {
        font-size: 12px;
      }
    }
  }

  .content {
    left: 10%;
  }
}

@media (max-width: 375px) {
  .bannerContainer {
    max-height: 430px;
    padding-bottom: 30px;
    background-position: center;
  }

  .text {
    p {
      br {
        display: none;
      }
    }
  }

  .content {
    left: 7%;
  }
}

@media (max-width: 360px) {
  .text {
    h3 {
      font-size: 20px;
      line-height: 26px;
    }
    p {
      font-size: 12px !important;
    }
  }
}
