.memberContainer {
  background-color: rgb(255, 251, 244);
  background-image: url(../../../assets/images/DRV-bg3.png);
  background-size: 62%;
}

@media (max-width: 768px) {
  .memberContainer {
    background-image: none;
  }

  .packageContainer {
    background-image: url(../../../assets/images/DRV-bg3.png);
    background-size: cover;
  }
}
