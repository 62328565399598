@import "../../../styles/index.scss";

.checkEmailButton {
  width: 100%;
  max-width: 300px;
}

.logoContainer{
  display: flex;
  justify-content: center;
  align-items: center;

  width: 84px;
  height: 64px;
}

.image {
  width: 100%;
  height: auto;
}