@import "../../../styles/utilities/index.scss";

.container {
  @media (max-width: 1440px) {
    padding-right: 68px !important;
    padding-left: 68px !important;
  }

  @media (max-width: 1024px) {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}

.header {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  // gap: 20px;
  justify-content: space-between;

  @media (max-width: 550px) {
    text-align: center;
  }
  @media (max-width: 450px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  .logoWrap {
    width: 100%;
    display: inherit;
    align-items: center;
    gap: 10px;
    justify-content: space-between;

    @media (max-width: 380px) {
      align-items: flex-start;
    }

    @media (max-width: 450px) {
      flex-wrap: wrap;
      // justify-content: center;
    }

    // button{
    //   padding: 5px;
    //   border-radius: 4px;
    //   line-height: 18px;
    //   min-height: unset;
    // }
  }

  .sidebarButtons {
    display: none;

    @media (max-width: 920px) {
      display: inherit;
    }

    button {
      background-color: #ff8a34;
      border: none;
      padding: 5px;
      border-radius: 4px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .addToPlaylistBox button {
    border-radius: 12px;
    padding: 8px 20px;
    // height: 40px;
    font-size: 16px;
    // line-height: 18px;
    background-color: $colorOrange;
    color: $colorWhite;
    border: none;
    // margin-top: 40px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .projectTitle {
    font-size: 24px;
    font-weight: bold;
    color: $colorOrange;
  }
}

.logotype {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .projectTitle {
    font-size: 24px;

    @media (max-width: 786px) {
      font-size: 18px;
    }
  }

  .totalVideosCount {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
  }

  a {
    cursor: default;
  }

  .logotypeWrapper {
    a {
      background-color: $colorWhite;
      border-radius: 50%;
      width: 64px;
    }
  }
}

.main {
  margin-top: 28px;
  max-width: 1920px;
  margin: 0 auto;
}

.listShown {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  // transition: all ease 0.5s;
  visibility: visible;
  height: auto;
  opacity: 1;
  transition: opacity 1s linear;

  @media (min-width: 1025px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 769px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: $xxl) {
    grid-template-columns: repeat(4, 1fr);
  }
}
.showASlider {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
  height: auto;
}

.hideSlider,
.listHide {
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s linear;
  height: 0;
  overflow: hidden;
}

.item {
  min-width: 0;
}

.link {
  color: $colorOrange;
  text-decoration: none;
  font-weight: bold;
}

.message {
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: $colorBlueDark;

  // &.empty {}
  @media (max-width: $m) {
    font-size: 1.5rem;
  }

  &.error {
    color: $colorRed;
  }
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  min-height: 100vh;

  @media (min-width: $m) {
    padding: 60px 48px;
  }
}

.player {
  width: 100%;
  background-color: #fff;
  max-width: 1200px;
  padding: 40px 30px 30px;
  border-radius: 10px;
  position: relative;
}

.card {
  border-radius: 28px;
  padding: 48px 20px;
  width: 100%;
  max-width: 640px;

  text-align: center;
  background-color: $colorWhite;
  color: $colorBlueDark;
}

.title {
  margin: 0 auto;
  max-width: 600px;

  font-size: 28px;
  line-height: 32px;

  @media (max-width: $m) {
    font-size: 18px;
    line-height: 20px;
  }

  @media (max-width: 451px) {
    max-width: 230px;
  }
}

.text {
  margin: 24px 0 0;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: $m) {
    font-size: 16px;
    line-height: 18px;
    margin-top: 10px;
  }
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  flex-direction: column;

  font-size: 20px;
  line-height: 24px;

  gap: 24px;

  @media (max-width: $m) {
    margin-top: 16px;
    gap: 16px;
    font-size: 16px;
    line-height: 18px;
  }

  @media (max-width: $s) {
    flex-direction: column;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 28px;
  min-height: 32px;
}

.button {
  border: none;
  padding: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  // text-decoration: underline;
  background-color: transparent;
  color: $colorOrange;
  cursor: pointer;
}

.closeContainer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 12px;
  right: 30px;

  div {
    // color: white;
  }
}

.playlistBtn {
  background-color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 12px;
  color: $colorOrange;
  line-height: 16px;
  text-decoration: none;
  width: max-content;
  font-size: 14px;
  font-weight: bold;
  min-height: 35px;
  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    color: white !important;
    padding: 5px 8px;
    border-radius: 12px;
  }
}

.noResultContainer {
  font-size: 22px;

  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding: 0px 8px;
  row-gap: 0px;
  .filterItems {
    font-weight: bold;
  }

  .clearFilterLink {
    background: none;
    border: none;
    color: $colorOrange;
    padding: 0px;
  }

  .flex {
    display: flex !important ;
    flex-direction: row;
    gap: 5px;
  }

  .mobileBlock {
    display: inline-block;
    @media (max-width: $m) {
      display: block;
    }
  }
}

.viewAllText {
  font-size: 16px;
  font-weight: bold;

  color: $colorOrange;
  text-wrap: nowrap;
  cursor: pointer;
  transition: all ease 0.5s;
}

.sideHeader {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 350px) {
    flex-direction: column-reverse;
    gap: 10px;
    align-items: flex-end;
  }
}

.addToPlayList {
  display: flex;
  flex-direction: row;

  gap: 10px;
  align-items: center;

  p {
    font-size: 14px;
    font-weight: bold;
  }

  img {
    width: 20px;
    height: 18px;
  }

  @media (max-width: 500px) {
    gap: 5px;

    p {
      font-size: 12px;
    }
  }
}

.itemPadding {
  padding-left: 20px;

  @media (max-width: 500px) {
    padding-left: 10px;
  }
}

.green {
  $color: #33b14e;

  .projectTitle {
    color: $color;
  }

  .viewAllTextSlider {
    border-color: $color;
  }

  .viewAllText {
    color: $color;
  }

  .playlistBtn {
    color: $color;

    @media (max-width: 600px) {
      background-color: $color;
    }
  }

  .footer {
    .button {
      color: $color;
    }
  }
}

.pink {
  $color: #ff3b71;

  .viewAllTextSlider {
    border-color: $color;
  }

  .projectTitle {
    color: $color;
  }

  .viewAllText {
    color: $color;
  }

  .playlistBtn {
    color: $color;
    @media (max-width: 600px) {
      background-color: $color;
    }
  }

  .footer {
    .button {
      color: $color;
    }
  }
}

.orange {
  $color: #f48622;

  .viewAllTextSlider {
    border-color: $color;
  }

  .projectTitle {
    color: $color;
  }

  .viewAllText {
    color: $color;
  }

  .playlistBtn {
    color: $color;
    @media (max-width: 600px) {
      background-color: $color;
    }
  }

  .footer {
    .button {
      color: $color;
    }
  }
}

.yellow {
  $color: #fdc500;

  .viewAllTextSlider {
    border-color: $color;
  }

  .projectTitle {
    color: $color;
  }

  .viewAllText {
    color: $color;
  }

  .playlistBtn {
    color: $color;
    @media (max-width: 600px) {
      background-color: $color;
    }
  }

  .footer {
    .button {
      color: $color;
    }
  }
}

.pink {
  $color: #ff3b71;

  .viewAllTextSlider {
    border-color: $color;
  }

  .projectTitle {
    color: $color;
  }

  .viewAllText {
    color: $color;
  }

  .playlistBtn {
    color: $color;
    @media (max-width: 600px) {
      background-color: $color;
    }
  }

  .footer {
    .button {
      color: $color;
    }
  }
}

.blue {
  $color: #08aad4;

  .viewAllTextSlider {
    border-color: $color;
  }

  .projectTitle {
    color: $color;
  }

  .viewAllText {
    color: $color;
  }

  .playlistBtn {
    color: $color;
    @media (max-width: 600px) {
      background-color: $color;
    }
  }

  .footer {
    .button {
      color: $color;
    }
  }
}

.purple {
  $color: #913898;

  .viewAllTextSlider {
    border-color: $color;
  }

  .projectTitle {
    color: $color;
  }

  .viewAllText {
    color: $color;
  }

  .playlistBtn {
    color: $color;
    @media (max-width: 600px) {
      background-color: $color;
    }
  }

  .footer {
    .button {
      color: $color;
    }
  }
}

.viewAllTextSlider {
  border: 1px solid;
  border-radius: 12px;
  height: 150px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  p {
    font-size: 20px;
    line-height: 34px;
  }

  @media (max-width: 500px) {
    height: 115px;
  }
}
