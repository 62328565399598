@import "../../../styles/mixins/index.scss";
@import "../../../styles/utilities/index.scss";

.container {
  border-top: 1px solid $colorGreyDark;
}

.wrapper {
  padding-top: $size-07;
  padding-bottom: $size-07;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: $size-05;
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;

  .donation {
    margin-top: $size-05;
  }

  .sponsors {
    flex-basis: 100%;
    height: 0;

    a{
      font-size: 18px;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: $size-05 0 0;
    padding: 0;

    text-align: center;
    text-transform: uppercase;
    color: $colorBlueDark;
  }

  .item {
    margin-bottom: $size-02;
  }

  .link {
    text-decoration: none;
  }

  @media (min-width: 769px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .donation {
      margin-top: 0;
    }

    .list {
      flex-basis: 100%;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
    }

    .item {
      &:not(:first-of-type) {
        margin-left: $size-06;
      }
    }
  }

  @media (min-width: $breakpointXL) {
    .logotype {
      order: 1;
    }
    .donation {
      order: 3;
    }
    .sponsors {
      order: 4;
    }
    .list {
      order: 2;
    }

    .list {
      flex-basis: auto;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      margin-top: 0;
    }

    .item {
      margin-bottom: 0;
    }
  }
}

.subnavigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .fact {
    margin-bottom: $size-05;
    max-width: 344px;
  }

  .list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  @media (min-width: $breakpointS) {
    flex-direction: row;
    flex-direction: row-reverse; // TODO: delete when elements will be showed
    justify-content: space-between;
    align-items: flex-start;

    .fact {
      text-align: left;
      margin-bottom: 0;
    }

    .list {
      text-align: right;
    }
  }
}

.tools {
  display: flex;
  flex-direction: column;
  align-items: center;

  .languages {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    margin: 0;
    margin-bottom: $size-05;
    padding: 0;
  }

  .item {
    &:not(:first-of-type) {
      @include set-margin-left(20px);
    }
  }

  .button {
    border: none;
    padding: 0;

    text-decoration: underline;
    background: transparent;
    cursor: pointer;

    &.active {
      color: $colorOrange;
    }
  }

  @media (min-width: $breakpointS) {
    flex-direction: row;
    flex-direction: row-reverse; // TODO: delete when elements will be showed
    justify-content: space-between;

    .languages {
      margin-bottom: 0;
      margin-right: $size-05;
    }
  }
}

@media (max-width: $breakpointS) {
  .navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .logotype {
    flex-basis: 100%;
    justify-content: center;
    display: flex;
  }

 

  .sponsors {
    flex-basis: unset !important;
    height: 5px !important;
    margin-left: 20px;
  }
}
