@import "../../../styles/utilities/index.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $size-02 0;
  min-height: $size-10;

  color: $colorBlueDark;
  background-color: #ffead2;
  cursor: pointer;
}

.overlay {
  display: flex;;
  justify-content: center;
  align-items: start;
  min-height: 100vh;
}

.containerText {
  // color: $colorWhite;
  // font-weight: 700;
  font-size: 16.9px;
}

.containerMobileText {
  // color: $colorWhite;
  // font-weight: 700;
  font-size: 14px;
}

.mobilePromotionalContainer {
  border-bottom: 1px solid #8b949f;
}

.icon {
  .image {
    display: block;
    width: $size-06;
    height: auto;
  }
}

.number {
  display: none;
  align-items: center;
  @include set-margin-left($size-06);

  @media (min-width: $m) {
    display: flex;
  }
}

.timer {
  display: none;
  align-items: center;
  @include set-margin-left($size-06);

  @media (min-width: $l) {
    display: flex;
  }
}

.button {
  border: none;
  padding: 0;

  font-weight: 400;
  letter-spacing: 0.2px;
  // text-decoration: underline;
  background-color: transparent;
  color: $colorOrange;
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  flex-wrap: wrap;
}

.flexMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  flex-wrap: wrap;
}

