@import "../../../../../styles/utilities/index.scss";

.container {
  position: relative;
  z-index: 0;

  display: flex;
  padding: $size-12 $size-06 $size-06;
  width: 100%;

  @media (max-width: $m) {
    padding-top: 104px;
  }

  &.marked {
    // color: $colorWhite;

    .icon {
      // color: $colorWhite;
    }

    .tooltip {
      // color: $colorWhite;
    }

    .boundary {
      border-color: #87787f;
    }

    .boundaryLayout {
      &::after {
        border-top: 2px dotted #87787f;
      }
    }
  }

  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -10;
}

.tape {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: unset;
  transform: none;
  position: absolute;
  left: -15px;
  top: -20px;
}

.illustration {
  position: absolute;
  @include set-left(50%);
  @include set-value(transform, translateX(-50%), translateX(50%));
  bottom: calc(100% + #{$size-03});

  width: 90px;

  @media (max-width: $m) {
    width: 45px;
  }

  .image {
    display: block;
    width: 100%;
    height: auto;
  }
}

.tooltip {
  flex-shrink: 0;
  cursor: pointer;
}

.boundary {
  align-self: center;
  border: none;
  border-top: 1px solid $colorOrange;
  width: 216px;
}

.cakePrice {
  font-size: 28px;
  color: $colorBlueDark;
  font-weight: 700;
}

.fadedOut {
  opacity: 0.5;
}

.lineThrough {
  text-decoration: line-through;
}

.packageList {
  font-size: 18px;
  line-height: 20px;

  @media (max-width: 1400px) {
    font-size: 16px;
    line-height: 18px;
  }
}
.mobileContainer.marked {
  .boundary {
    border: none;
  }
}
.mobileContainer {
  padding: 30px;

  @media (max-width: 400px) {
    padding: 30px 15px;
  }

  .tooltip {
    background-color: $colorWhite;
    color: $colorOrange;
    border-radius: 100%;
  }

  .illustration {
    position: relative;
    bottom: unset;
    top: unset;
    margin-bottom: 15px;
    width: 80px;
  }

  h3 {
    color: rgb(43, 52, 94);
    font-size: 26px;
    line-height: 30px;
    background: none;
    -webkit-text-fill-color: inherit;

    @media (max-width: 460px) {
      font-size: 18px;
      line-height: 20px;
    }
  }

  .packageFeatures {
    margin-top: 10px;
  }

  .popularIcon {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: unset;
    transform: none;
    position: absolute;
    left: -15px;
    top: -20px;

    @media (max-width: 460px) {
      max-width: 90px;

      img {
        width: 100%;
      }
    }
  }

  .boundary {
    border: none;
    margin-bottom: 10px;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 2px;
    background-image: url(../../../../../assets/images/seprator.png);
  }
  .cakePrice {
    font-size: 22px;
    margin-top: 10px;
  }

  .mobileBtn {
    background-color: $colorOrange;
    color: #fff;
    font-weight: bold;
    padding: 10px 40px;
    border: none;
    font-size: 18px;
    border-radius: 20px;
  }
}

.bgBrownColor {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) -1.24%,
    #fde6d5 20.72%,
    #fecea8 50.12%
  );
}

.lightBgColorGradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) -1.24%,
    #fbeae3 20.72%,
    #fce4cf 50.12%
  );
}

.maxWidth130 {
  max-width: 130px;
}

.boundaryLayout {
  position: relative;
  margin-bottom: 3px;

  &::after {
    content: "";
    width: 100%;
    border-top: 2px dotted #e08906;
    position: absolute;
    top: 0;
    max-width: 330px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.container {
  .marked {
    .boundaryLayout {
      position: relative;
      margin-bottom: 3px;

      &::after {
        content: "";
        width: 100%;
        border-top: 2px dotted #87787f;
        position: absolute;
        top: 0;
        max-width: 330px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
