@import "../../../../../styles/utilities/index.scss";

.container {
  padding: 75px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 65px;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  color: $colorBlueDark;
}

.headingContainer > h3 {
  text-transform: uppercase;
}

.browserText {
  display: block;
}
.mobileText {
  display: none;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.videoContainer {
  height: 290px;
  width: 500px;
  border-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(../../../../../assets/images/our-method-top.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.videoContainerBottom{
  background-image: url(../../../../../assets/images/our-method-bottom.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.textContainer {
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 470px;
  justify-content: center;
  text-align: justify;
  gap: 25px;

  h1 {
    font-weight: normal;
    line-height: 28px;
    font-size: 20px;
    color: $colorBlack;
  }
  img {
    object-fit: contain;
    height: auto;
    width: 130px;
  }
}

.upperPlayer {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.lowerPlayer {
  display: flex;
  flex-direction: row-reverse;
  gap: 50px;

  .textContainer {
    flex-direction: column-reverse;
  }
}

.rightArrow {
  align-self: flex-start;
  transform: rotate(22deg) scaleY(-1);
}

.leftArrow {
  align-self: flex-end;
  transform: rotate(23deg) scaleX(-1);
}

.pointer {
  cursor: pointer;
}

@media only screen and (max-width: 1330px) {
  .upperPlayer {
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }

  .lowerPlayer {
    flex-direction: column;
    align-items: center;
    gap: 0px;

    .textContainer {
      flex-direction: column;
      margin-top: -15px;
    }
  }

  .textContainer {
    height: 200px;
  }

  .rightArrow {
    display: none;
  }
  .leftArrow {
    display: none;
  }
}

@media (max-width: 768px) {
  .browserText {
    display: none;
  }
  .mobileText {
    display: inline-block;
  }
  .container{
    padding: 50px 0px;
    gap: 0px !important;
  }

  .textContainer{
    h1{
      font-size: 16px !important;
    }
  }

  .upperPlayer {
    flex-direction: column-reverse;
  }

  .lowerPlayer {
    flex-direction: column-reverse;
  }
  .textContainer {
    height: 160px;
    text-align: center;
  }
}


@media (max-width: 750px) {
  .container {
    gap: 25px;
  }

  .textContainer {
    height: 160px;
    text-align: center;
  }

  .mb {
    margin-bottom: -15px;
  }
}

@media (max-width: 550px) {
  .videoContainer {
    max-height: 230px;
    max-width: 380px;
    min-width: 300px;
    min-height: 180px;
  }

  .textContainer {
    max-height: 230px;
    max-width: 380px;
    min-width: 300px;
    min-height: 180px;
  }
}

@media (max-width: 400px) {
  .videoContainer {
    max-height: 200px;
    max-width: 300px;
    min-height: 180px;
  }

  .textContainer {
    max-height: 200px;
    max-width: 300px;
    min-height: 180px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .textContainer {
    h1 {
      font-size: 18px;
    }
  }
}


