@import "../../../../../styles/utilities/index.scss";

// .container {}

.wrapper {
  min-height: 48px;
}

.message {
  line-height: 48px;
  font-size: 32px;

  text-align: center;
  color: $colorBlueDark;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
}

.error {
  color: $colorRed;
}