@import "../../../styles/utilities/index.scss";

.container {
    display: block;
    margin: 0 auto;

    border: 1px solid $colorOrange;
    width: max-content;
    padding: 5px 10px;
    border-radius: 5px;    
}