@import "../../../styles/utilities/index";

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header, .footer {
  flex-shrink: 0;
}

.header {
  transition: transform $timeS;
}

.main {
  flex-grow: 1;
}

.mobileMain {
  padding-bottom: 60px;
}

/* modifications */

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  width: 100%;
}

.sticky {
  position: sticky;
  top: -50px;
  left: 0;
  z-index: 100;

  width: 100%;
}

.hidden {
  transform: translateY(-100%);
}

.mobileFooter {
  position: fixed;
  bottom: -1px;
  width: 100%;
  z-index: 2;
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  min-height: 100vh;

  @media (min-width: $m) {
    padding: 60px 48px;
  }

  .closeContainer {
    position: absolute;
    right: 20px;
    top: 15px;
  }
}

.appDonationCard {
  
  padding: 0px;
  width: 100%;
  max-width: 550px;

  img {
    border-radius: 28px;
    width: 100%;
  }
  position: relative;
 
}

.writingCompetitionBanner{
  background-image: url(../../../assets//images/start-background.png);
  background-color: #ffc000;
  border-radius: 28px;
}

.closeButtonContainer{
  .close{
    background: transparent;
    border: none;
    position: absolute;
    right: 5px;
    top: 10px;
  }
}

.maorCompetitionLogo {
  width: 450px;
  height: auto;
  margin: 0 auto;
  display: flex;

  border-radius: none;
}


.writingCompetitionButton {
  background-color: #77A23F;
  color: #fff;
  padding: 15px;
  border-radius: 30px;
  font-size: 16px;
  width: 100%;
  max-width: 12rem;
  transition: background-color 0.3s ease;
  border: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  margin: 40px auto 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;

  &:hover {
    background-color: #89bb48;
  }
}

.grandstanderFamily {
  font-family: "Grandstander", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}