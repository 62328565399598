@import "../../../styles/utilities/index.scss";

$breakpoint: $breakpointL;
$boxShadow: 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 30px 40px rgba(0, 0, 0, 0.05);

  .iAmText {
    margin-right: 15px;
    font-weight: 600;
    font-size: 14px;
  }

.container {
  position: relative;

  // border-bottom: 1px solid $colorGreyDark;

  background-color: $colorWhite;
  color: #2F363B;
}

.links {
  width: 100%;
  display: flex;
  justify-content: center;
}

.link {
  color: inherit;
  text-decoration: underline;

  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  position: relative;

  &.active {
    position: relative;
    color: $colorOrange;
    // margin-bottom: 8px;

    // &:after {
    //   content: "";

    //   position: absolute;
    //   top: calc(100% + #{$size-01});
    //   @include set-left(0);

    //   display: block;
    //   border-radius: $size-01;
    //   width: 100%;
    //   height: $size-01;

    //   background-color: $colorOrange;
    // }
  }
}

.bar {
  display: flex;
  align-items: center;
  padding-top: $size-01;
  padding-bottom: $size-01;

  padding-right: 10px;
  padding-left: 10px;

  @media (min-width: $breakpointM) {
    padding-right: 48px;
    padding-left: 48px;
  }
}

.logotype {
  @media (min-width: $breakpointM) {
    min-width: 84px;
  }

  @media (min-width: $breakpointL) {
    min-width: 200px;
  }

  @media (max-width: $m) {
    a {
      width: 45px;
      height: auto;
    }
  }
}

/* modifications */

.desktop {
  flex-grow: 1;
  display: none;

  @media (min-width: $breakpoint) {
    display: block;
  }
}

.mobile {
  display: flex;
  align-items: center;
  @include set-margin-left(auto);
  gap: 10px;
  width: 100%;
  justify-content: flex-end;

  @media (min-width: $breakpoint) {
    display: none;
  }
}

.boxShadow {
  box-shadow: $boxShadow;
  -webkit-box-shadow: $boxShadow;
  -moz-box-shadow: $boxShadow;
}
