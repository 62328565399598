@import "../../../styles/utilities/index.scss";

$colorLightGrey: #F6F6F6;
$colorLightPurple: #DBD1EE;
$colorDarkPurple: #ae8fe9;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  column-gap: 10px;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  display: none;
}

.label {
  text-align: center;
  cursor: pointer;
}

/* colors */

.primary {

  .label {
    background-color: $colorLightGrey;
    color: $colorGrey;
  }

  .input:checked {
    & + .label {
      background-color: $colorOrange;
      color: $colorWhite;
    }
  }
  
}

.purple {

  .label {
    background-color: $colorLightPurple;
    color: $colorGrey;
  }

  .input:checked {
    & + .label {
      background-color: $colorDarkPurple;
      color: $colorWhite;
    }
  }
  
}

/* sizes */

.s {
  &.container {
    border: 1px solid $colorLightGrey;
    border-radius: 4px;
    padding: 2px;
    width: 100%;
    max-width: 256px;
    margin: 0 auto;
  }

  .label {
    border-radius: 4px;
    padding: 2px 20px;
    min-width: 124px;
    min-height: 22px;

    font-size: 13px;
    line-height: 18px;
  }
}

.m {
  &.container {
    // width: 100%;
    // max-width: 360px;
    // margin: 0 auto 60px;
  }

  .label {
    border-radius: 12px;
    padding: 8px 32px;
    height: 40px;

    font-size: 16px;
    line-height: 24px;
    color: $colorBlueDark;

    @media (max-width: $m) {
      font-size: 14px;
      padding: 12px 24px;
      line-height: normal;
    }

    @media (max-width: $s) {
      padding: 12px 14px;
    }
  }
    
}

/* modifications */

.vertical {
  &.container {
    max-width: 100%;
  }
}

.vertical {
  &.container {
    flex-direction: column;
  }
}
