@import "../../../../../../../styles/utilities/index.scss";

.container {
  border-radius: 15px;
  max-width: 508px;
  max-height: 290px;
  height: 300px;
  width: 510px;
  padding: 30px 10px 30px 18px;

  display: grid;
  grid-template-columns: auto auto;
  column-gap: 25px;
}

.itemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  .itemInfo {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  .itemInfo > h4 {
    color: $colorBlueDark;
    font-size: 16px !important;
  }

  .itemInfo > p {
    font-size: 14px;
    line-height: 18px;
  }

  img {
    object-fit: contain;
    height: 28px;
    width: 28px;
  }
}

@media (max-width: 768px) {
  .itemInfo > p {
    font-size: 13px !important;
  }

  .container {
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 1680px) {
  .container {
    column-gap: 20px;
  }
}

@media (max-width: 550px) {
  .container {
    max-width: 500px;
    min-width: 300px;
  }
}

@media (max-width: 520px) {
  .container {
    max-width: 370px;
    height: auto;
    max-height: 500px;

    grid-template-columns: auto;
    row-gap: 25px;
  }
}

@media (max-width: 400px) {
  .container {
    max-width: 300px;
  }
}
