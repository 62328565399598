@import "../../../styles/utilities/index.scss";

$colorBlackLight: #242424;
$colorGreyDark: #D5D5D9;
$colorGreyLight: #DADADA;

.container {
  position: relative;
  z-index: 0;

  &.vertical {
    display: flex;
  }
}

.line {
  position: absolute;
  z-index: -10;

  .horizontal & {
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    
  }
  
  .vertical & {
    top: 0;
    transform: translateX(-50%);
    height: 100%;
  }
}

.indicator {
  transition: width $timeS;
}

.dots, .titles {
  position: relative;

  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;

  .horizontal & {
    flex-direction: row;
  }
  
  .vertical & {
    flex-direction: column; 
  } 
}

.dot, .title {
  .vertical & {
    &:not(:first-of-type) {
      margin-top: 32px;
    }
  }
}

.dot {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  transition: border-color $timeS, background-color $timeS;

  &:before {
    content: "";

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 50%;

    transition: background-color $timeS;
  }

  &.checked {
    .icon {
      opacity: 1;
    }
  }
}

.icon {
  position: relative;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transition: opacity $timeS;
}

.title {
  transition: color $timeS, opacity $timeS;

  .horizontal & {
    text-align: center;
  }
}

/* colors */

.primary {
  .line {
    background-color: $colorGreyDark;
  }

  .indicator {
    background-color: $colorOrange;
  }

  .title {
    // color: $colorBlackLight;
    opacity: .3;

    &.active {
      // color: $colorBlackLight;
      opacity: 1;
    }
  }

  .dot {
    border-style: solid;
    border-color: $colorGreyDark;

    background-color: $colorGreyLight;
    background-color: $colorWhite;

    &.active {
      border-color: $colorOrange;

      &:before {
        background-color: $colorOrange;
      }
    }

    &.checked {
      background-color: $colorOrange;
    }
  }
}

/* sizes */

.horizontal.medium {
  .line {
    top: 14px;
  }

  .indicator {
    height: 3px;
  }

  .dot {
    border-width: 3px;
    width: 28px;
    height: 28px;

    &:before {
      width: 12px;
      height: 12px;
    }
  }

  .titles {
    margin-top: 4px;
  }

  .title {
    width: 28px;

    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
  }
}

.vertical.medium {
  .line {
    left: 14px;
  }

  .indicator {
    width: 3px;
  }

  .dot {
    border-width: 3px;
    width: 28px;
    height: 28px;

    &:before {
      width: 12px;
      height: 12px;
    }
  }

  .titles {
    margin-left: 22px;
  }

  .title {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.2px;
  }
}
