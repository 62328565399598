.orSeparator {
  position: relative;
  text-align: center;

  label {
    padding: 0 0.4em;
    position: relative;
    background-color: #fdf2e4;
  }

  &::before {
    content: "";
    border-style: solid;
    border-width: 0 0 1px 0;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    border-color: black;
  }
}

.optionsContainer {
  display: flex;
  flex-direction: column;
}

.signUpContainer {
  max-width: 300px;
  width: 100%;
}

.mobileBreak{
  display: none;

  @media (max-width:500px) {
    display: block;
  }
}
