.characterFormModal {
  background-color: #fff;

  border: 10px solid #ffc000;
  border-radius: 30px;
  padding: 50px;

  @media (max-width: 767px) {
    padding: 25px;
  }
}

.characterFormContainer {
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // row-gap: 30px;
  // column-gap: 35px;
  margin: 30px 0;
  width: 100%;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.characterFieldContainer {
  display: flex;
  gap: 5px;
  flex-direction: column;

  background-color: #fff5cd;
  padding: 30px;
  border-radius: 25px;
  width: 100%;

  label {
    font-size: 16px;
    line-height: 20px;
    padding-left: 5px;
    color: #404040;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  @media (max-width: 767px) {
    padding: 20px;
  }
}

.red {
  color: #ff565e;
  margin-left: 5px;
}

.hrGrey {
  border-bottom: 2px solid #b9b8b8;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.radioInput {
  margin-left: 28px;
}

.questionIcon {
  width: 25px;
  height: 25px;

  margin-left: auto;
}

.errorbg {
  background-color: #fee0e1;
}

.deactive {
  pointer-events: none;
  opacity: 0.5;
}

.placeNameText {
  font-weight: bold;
  text-transform: capitalize;
}

.grandstanderFamily {
  font-family: "Grandstander", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.heading {
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  text-transform: capitalize;

  span {
    font-family: "Roboto";
    font-size: 12px;
    margin-left: 20px;
    font-weight: 400;
  }
}

.submitScriptButton {
  background-color: #ffc000;
  color: #000;
  font-weight: bold;
  padding: 25px;
  border-radius: 30px;
  font-size: 22px;
  width: 100%;
  max-width: 24rem;
  transition: background-color 0.3s ease;
  border: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  // margin: 0 auto;

  &:hover {
    background-color: #fcd34d;
  }
}

.saveDraft{
  background-color: #ffd862;;
}

.errorText {
  color: #ff0000;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;

  margin-bottom: 15px;
}

.errorButtonbg {
  background-color: #fecdce !important;
}

.formButtons{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  flex-wrap: wrap;
}

.loader {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.draftSavedMessage{
  font-weight: 600;
  margin-top: 15px;
  padding-left: 25px;
}