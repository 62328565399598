.componentContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  flex-wrap: wrap;
  justify-content: center;

  .header {
    color: #4a3412;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    max-width: 100px;

    @media (max-width: 1024px) {
      max-width: 100%;
    }
  }

  .optionsContainer {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    flex-grow: 1;
    // justify-content: center;
    align-items: center;
    padding: 0px 20px;

    @media (max-width: 1024px) {
      justify-content: center;
      padding: 20px;
    }

    .button {
      background-color: #87ba48;
      color: white;
      font-weight: bold;
      padding: 1.5rem 2rem; /* Equivalent to px-8 py-6 */
      border-radius: 1rem; /* Equivalent to rounded-2xl */
      border-width: 0px;
      font-size: 20px; /* Equivalent to text-lg */
      transition: background-color 0.2s ease-in-out;
      flex-grow: 1;
      cursor: pointer;
      text-align: center;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      max-width: 300px;
      width: 100%;

      @media (max-width: 1024px) {
        width: 300px;
        max-width: 300px;
      }

      &:hover {
        background-color: #78a73f;
      }
    }
  }
}

.buttonContainer {
  position: relative;
  max-width: 300px;
  width: 100%;

  .audioButton {
    position: absolute;
    top: -14px;
    left: -14px;
    z-index: 10; // Ensures it appears above the second button
    background-color: #d8e9c4;
    border: none;
    border-radius: 20px;
    padding: 8px;

    .headphone {
      width: 30px;
      height: 30px;
    }
  }

  .linkButton {
    position: relative; // Keeps the second button in its normal flow
  }
}

.audioTitle {
  color: #fff;
  font-size: 16px;
}

.grandstanderFamily {
  font-family: "Grandstander", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
