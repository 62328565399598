@import "../../../styles/utilities/index.scss";

$colorBlack: #0d0d0d;

// .container {}

.overlay {
  // overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;

  width: 100%;
  height: 100%;
  overflow: auto;

  background-color: rgba($colorBlack, 0.5);
}
.mobileOverlayWrap {
  top: 8px;
  overflow-y: unset;
  background: #fff;
  z-index: 999999;

  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
    overflow-y: scroll;
  }
  @media all and (device-width: 1180px) and (device-height: 820px) and (orientation: landscape) {
    overflow-y: scroll;
  }
}
.noHeaderOverlayWrap {
  top: 0;
}
.questionsVisible {
  overflow-y: scroll;
}
/* modifications */

.flex {
  &.overlay {
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(3px);
  }
}

@media (max-width: 768px) {
  // .mobileOverlayWrap {
  //   background: none;
  //   background-color: rgba(13, 13, 13, 0.1);
  //   top: 7px;
  // }
}
