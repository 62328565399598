@import "../../../styles/utilities/index.scss";


/* container for all of the switch elements 
    - adjust "width" to fit the content accordingly 
*/
.switchesContainer {
    width: 170px;
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    background: white;
    line-height: 26px;
    border-radius: 30px;
    border: 0.5px solid;
    color: #636363;
    border-color: #c1bbbb;
  }
  
  /* input (radio) for toggling. hidden - use labels for clicking on */
  .switchesContainer input {
    visibility: hidden;
    position: absolute;
    top: 0;
  }
  
  /* labels for the input (radio) boxes - something to click on */
  .switchesContainer label {
    width: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    color: $colorOrange;
    font-size: 12px;
  }
  
  /* switch highlighters wrapper (sliding left / right) 
    - need wrapper to enable the even margins around the highlight box
  */
  .switchWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 3;
    transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    font-size: 12px;
  
    /* transition: transform 1s; */
  }
  
  /* switch box highlighter */
  .switch {
    border-radius: 3rem;
    background: $colorOrange;
    height: 100%;
  }
  
  /* switch box labels
    - default setup
    - toggle afterwards based on radio:checked status 
  */
  .switch div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: white;
    transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  /* slide the switch box from right to left */
  .switchesContainer input:nth-of-type(1):checked ~ .switchWrapper {
    transform: translateX(0%);
  }
  
  /* slide the switch box from left to right */
  .switchesContainer input:nth-of-type(2):checked ~ .switchWrapper {
    transform: translateX(100%);
  }
  
  /* toggle the switch box labels - first checkbox:checked - show first switch div */
  .switchesContainer
    input:nth-of-type(1):checked
    ~ .switchWrapper
    .switch
    div:nth-of-type(1) {
    opacity: 1;
  }
  
  /* toggle the switch box labels - second checkbox:checked - show second switch div */
  .switchesContainer
    input:nth-of-type(2):checked
    ~ .switchWrapper
    .switch
    div:nth-of-type(2) {
    opacity: 1;
  }
  
  .disabelShkiyaCheckbox{
    pointer-events: none;
    filter: contrast(0.5);
  }